import { SET_AUDIENCE_LANGUAGE_FILTER } from "../ActionTypes";

const initialState = [];

const AudienceLanguageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIENCE_LANGUAGE_FILTER:
            const previousValue = state.value;
            console.log('state in audience LANGUAGE', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default AudienceLanguageReducer