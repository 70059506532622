import { SET_CREATOR_LOCATION_FILTER } from "../ActionTypes";

const domainName = window.location.hostname;

const initialState = domainName === 'coke.viralpitch.co' ? ['304751-k-Indonesia'] : [];

const CreatorLocationReducer = (state = initialState, action) => {
    
    // console.log('checking hostname', domainName)
    switch (action.type) {
        case SET_CREATOR_LOCATION_FILTER:
            const previousValue = state.value;
            console.log('state in creator location', previousValue)
            return action.payload;
        default:
            return state;
    }

    // switch (action.type) {
    //     case SET_CREATOR_LOCATION_FILTER:
    //         return {
    //             ...state,
    //             return: action.payload, // Assuming action.payload is an array
    //         };
    //     default:
    //         return state;
    // }
};

export default CreatorLocationReducer