import React from 'react'
import Multiselect from 'multiselect-react-dropdown'

const MultiSelectDropdown = ({ options, onSelect, onRemove, selectedValues, loading, placeholder, onClick }) => {

    
    return (
        <Multiselect
            onClick={onClick}
            className='bg-white'
            options={options}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            selectedValues={selectedValues}
            placeholder={placeholder}
            loading={loading}
            style={{
                multiselectContainer: {
                    width: '100%',
                    borderRadius: '0.375rem',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                },
                searchBox: {
                    border: '0.6px solid #D9D9D9',
                    borderRadius: '0.375rem',
                },
                inputField: {
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#333',

                },
                chips: {
                    background: '#6d5eac',
                    color: '#fff',
                },
                option: {
                    color: '#333',
                },
                groupHeading: {
                    color: '#6d5eac',
                    background: '#6d5eac',
                },
                dropdownList: {
                    background: '#6d5eac',  // Change this to the desired background color
                    '&:hover': {
                        background: '#6d5eac',  // Change this to the desired hover background color
                    },
                },
            }}
        />
    )
}

export default MultiSelectDropdown
