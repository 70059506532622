import React, { useEffect, useState } from "react";
import axiosInstance from "../../interseptor";

import { Avatar, Pagination } from "flowbite-react";
import { FaTrash } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { BiEdit, BiTrash } from "react-icons/bi";
import { BsCardList, BsCalendar2, BsInstagram, BsPerson, BsYoutube, BsFacebook } from "react-icons/bs";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import CreatePlan from "./CreatePlan";
import CreateNewList from "../profile/CreateNewList";

import PlansListSkeleton from "../skeleton/PlansListSkeleton";
import ListsListSkeleton from "../skeleton/ListsListSkeleton";
import capitalise from "./capitalise";
import EditListDetails from "./planlist/EditListDetails";

const GET_PLANS_URL = "/livesearch/planlist";
const VIEW_PLAN_URL = "/livesearch/viewplan";
const GET_INF_LIST = "/livesearch/viewlist";
const DELETE_LIST_URL = "/livesearch/deletelist";
const REMOVE_FROM_LIST = "/livesearch/removefromlist";

//sidebar plans list infl on result page
function PlanComponent({ colors, temp, refreshPlans }) {
  const [planmodalopen, setplanmodalopen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plansLoading, setPlansLoading] = useState(true);
  const [plans, setPlans] = useState(null);
  const [lists, setLists] = useState(null);
  const [listsLoading, setListsLoading] = useState(true);
  const [listDropdownOpen, setListDropdownOpen] = useState({
    isOpen: false,
    id: "",
  });
  const [showPlans, setShowPlans] = useState(true);
  const [curPlanId, setCurPlanId] = useState();
  const [curListId, setCurListId] = useState();
  const [curListName, setCurListName] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentListPage, setCurrentListPage] = useState(1);

  const [infList, setInfList] = useState(null);
  const [showInfList, setShowInfList] = useState(false);
  const [isEditListOpen, setIsEditListOpen] = useState(false);
  const [editListId, setEditListId] = useState(null);


  // setTimeout(()=>setLoading(false),1000)

  const modalonClick = () => {
    setplanmodalopen(true);
  };

  const modalonClose = () => {
    setplanmodalopen(false);
  };

  useEffect(() => {
    getPlans();
    console.log("refreshPlans get Plans")
  }, [refreshPlans]);

  //all plans
  const getPlans = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "planlist");
      data.append("device_id", "search");
      data.append("status", "1");

      const response = await axiosInstance.post(GET_PLANS_URL, data);
      const list = response.data.list;

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        // setPlans(response.data.list)

        const sortedPlans = list.sort(
          (a, b) => new Date(b.created_on) - new Date(a.created_on)
        );
        setPlans(sortedPlans);
        setPlansLoading(false);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const capitalise_ = (name) => {
    const capitalised = capitalise(name);
    return capitalised;
  };

  const item_per_page = 5;
  let totalPlanPage = plans && plans.length;
  let totalListPage = lists && lists.length;

  let paginatedPlans =
    plans &&
    plans.slice((currentPage - 1) * item_per_page, currentPage * item_per_page);

  let paginatedLists =
    lists &&
    lists.slice(
      (currentListPage - 1) * item_per_page,
      currentListPage * item_per_page
    );

  const handlePagination = (pageNo) => {
    let nextPage =
      plans &&
      plans.slice((pageNo - 1) * item_per_page, pageNo * item_per_page);

    if (nextPage.length > 0) {
      setCurrentPage(pageNo);
    }
  };

  const handleListPagination = (pageNo) => {
    let nextPage =
      lists &&
      lists.slice((pageNo - 1) * item_per_page, pageNo * item_per_page);

    if (nextPage?.length > 0) {
      setCurrentListPage(pageNo);
    }
  };

  //each plan info
  const getList = async (plan_id) => {
    console.log("get list called");
    try {
      let data = new FormData();
      data.append("logintype", "viewplan");
      data.append("device_id", "search");
      data.append("planid", plan_id);
      data.append("status", "1");

      const response = await axiosInstance.post(VIEW_PLAN_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        const sortedLists = response?.data?.list?.list_details?.sort(
          (a, b) => new Date(b.list_createdon) - new Date(a.list_createdon)
        );
        setLists(sortedLists);
        setListsLoading(false);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getInfList = async (id) => {
    try {
      let data = new FormData();
      data.append("logintype", "viewlist");
      data.append("device_id", "search");
      data.append("list_id", id);

      const response = await axiosInstance.post(GET_INF_LIST, data);

      setInfList(response.data.list);
      //   setShowInfList(!showInfList);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        // setViewListData(response?.data?.list)
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePlan = (plan_id) => {
    setCurPlanId(plan_id);
    setListsLoading(true);
    getList(plan_id);
    setShowPlans(!showPlans);
    // getInfList(plan_id)
  };

  const updatePlan = () => {
    getPlans();
  };

  // refresh getlist after adding to list
  useEffect(() => {
    getInfList(curListId);
  }, [temp]);

  const currentPlan =
    plans &&
    plans.find((plan_) => {
      return plan_.planid == curPlanId;
    });

  const handleList = async (id, plan_name) => {
    setCurListId(id);
    setCurListName(plan_name);
    setShowInfList(!showInfList);
    // (() => setTimeout(resolve, 100));
    getInfList(id);
  };

  // let list_id_ = null
  // const editList = (list_id) => {
  //   console.log('edit list listid',list_id)
  //   // setEditListId(list_id);
  //   list_id_=list_id
  // };

  useEffect(() => {
    if (editListId != null) {
      console.log('inside if', editListId)
      setTimeout(() => setIsEditListOpen(true), 2000)
    }
  }, [editListId])

  //   delete a list
  const deleteList = async (list_id) => {
    try {
      let data = new FormData();
      data.append("logintype", "deletelist");
      data.append("device_id", "seacrh");
      data.append("list_id", list_id);

      const response = await axiosInstance.post(DELETE_LIST_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        getList(curPlanId);

        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInfList = () => {
    setShowPlans(false);
    setShowInfList(false);
  };

  //   delete inf from lists
  const deletInfluencer = async (handle) => {
    try {
      let data = new FormData();
      data.append("logintype", "removefromlist");
      data.append("device_id", "seacrh");
      data.append("handle", handle);
      data.append("list_id", curListId);

      const response = await axiosInstance.post(REMOVE_FROM_LIST, data);

      getInfList(curListId);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const datefunc = (datestring) => {
    let createdOn = new Date(datestring);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = createdOn.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <React.Fragment>
      {/* right sidebar */}

      <div className={`plan-comp flex justify-start w-full relative z-50 `}>

        {/* <div className="text-2xl font-bold">Plans</div> */}
        {showPlans && (
          <div className="flex flex-col mx-2">

            <h5 className="text-black dark:text-gray-600 ml-1 py-1 position-sticky font-semibold">
              All Plans
            </h5>
            <div className="text-start text-gray-600 py-1 text-sm whitespace-normal ">
              Kindly select a plan from the list below to start adding
              influencers!
            </div>
            <div className="flex justify-center my-2">
              <CreatePlan color="gray" size="md" />
            </div>
            <div className="flex flex-col items-center">
              {plansLoading == true ? (
                <PlansListSkeleton />
              ) : (
                <>
                  {paginatedPlans &&
                    paginatedPlans.length > 0 &&
                    showPlans &&
                    paginatedPlans.map((plan) => {
                      return (
                        <div
                          key={
                            plan.plan_name.replace(
                              /[&\/\#,+()$~%.'":*?<>{}][ ()]s+/g,
                              ""
                            ) +
                            plan.created_on.replace(
                              /[&\/\#,+()$~%.'":*?<>{}][ ()]s+/g,
                              ""
                            )
                          }
                          className="mt-4 p-2 border border-gray-200 shadow-sm rounded-lg w-11/12 hover:border-[#7dc6de] hover:scale-105 duration-100 cursor-pointer"
                          onClick={() => handlePlan(plan.planid)}
                        >

                          <div className="flex gap-1">
                            {/* <FaUserCircle size={20} /> */}
                            <p className="text-[#6d5eac] font-medium">
                              {capitalise_(plan.plan_name)}
                            </p>
                          </div>
                          <div className="flex justify-between items-center mt-4 text-sm text-gray-600">
                            <div className="flex items-center gap-1">
                              <BsCardList />
                              {<p>{plan.list_count} Lists</p>}
                            </div>
                            <div className="flex items-center gap-1">
                              <BsCalendar2 />
                              {datefunc(plan.created_on)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>

            {/* <div className="mt-2 absolute bottom-0 translate-y-8 left-1/2 -translate-x-1/2">
              <Pagination
                currentPage={currentPage}
                layout="navigation"
                onPageChange={handlePagination}
                showIcons
                totalPages={totalPlanPage}
              />
            </div> */}
          </div>
        )}
      </div>

      <div className="flex flex-col justify-start ">
        {showPlans == false && showInfList == false && (
          <div>
            <div
              className="flex p-2 items-center gap-2 cursor-pointer hover:text-indigo-500"
              onClick={() => {
                setShowPlans(!showPlans);
                setCurrentListPage(1);
              }}
            >
              <FiChevronLeft />
              <div>All Plans</div>
            </div>
            <div className="flex justify-between items-center my-5 p-2">
              <div className="flex items-end gap-2">
                <Avatar
                  img={currentPlan.profile_image}
                  size="sm"
                  rounded={true}
                />
                <p>{capitalise_(currentPlan.plan_name)}</p>
              </div>
              {/* <BiEdit size="1.5rem" color="gray" /> */}
              {/* <EditPlanDetail planId={currentPlan.planid} getViewPlan={getList}  /> */}
            </div>
            <div></div>

            <CreateNewList color="gray" planId={curPlanId} getList={getList} />

            {listsLoading == true ? (
              <ListsListSkeleton />
            ) : (
              <>
                {paginatedLists == undefined && (
                  <p className="mt-4">Please create a List first</p>
                )}
                {paginatedLists &&
                  paginatedLists.map((list) => {
                    const capitalised_listname = capitalise_(list.list_name);
                    return (
                      <div
                        className="flex flex-cols justify-center"
                        onClick={() => handleList(list.list_id, list.list_name)}
                      >
                        {/* onClick={() => handleList(list.list_id, list.list_name)} */}

                        <div className="flex flex-col mt-4 p-2 border border-gray-200 shadow-md rounded-lg w-11/12 hover:bg-blue-50 cursor-pointer">
                          <div className="flex justify-between">
                            <p className="text-[#6d5eac] font-semibold">
                              <div>{capitalised_listname}</div>
                            </p>

                            {/* <CiMenuKebab /> */}
                            <div
                              className="relative"
                              onClick={(e) => {
                                e.stopPropagation();
                                setListDropdownOpen((prev) => {
                                  return {
                                    isOpen: !prev.isOpen,
                                    id: list.list_id,
                                  };
                                }); // Toggle the dropdown visibility
                              }}
                            >
                              <HiDotsVertical className=" hover:bg-white rounded p-1 w-6 h-6" />
                              {listDropdownOpen.isOpen == true &&
                                listDropdownOpen.id == list.list_id && (
                                  <ul className="absolute right-2 z-10 py-1 w-fit rounded divide-y divide-gray-100 shadow transition-opacity duration-100 border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white">
                                    <li
                                      className="flex whitespace-nowrap items-center gap-3 justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"

                                    >
                                      <BiEdit size={18} />
                                      Edit List
                                    </li>

                                    {/* {isEditListOpen == true && (
                                      <EditListDetails
                                        editListOpen={isEditListOpen}
                                        setEditListOpen={setIsEditListOpen}
                                        listId={list_id_}
                                        planId={curPlanId}
                                        refreshPlan={getList}
                                      />
                                     
                                    )} */}
                                    <li
                                      className="flex items-center justify-start gap-3 py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
                                      onClick={() => deleteList(list.list_id)}
                                    >
                                      <BiTrash size={18} />
                                      Delete
                                    </li>
                                  </ul>
                                )}
                            </div>
                          </div>
                          <div className="flex justify-between mt-4">
                            <div className="flex items-center gap-1">
                              <div>
                                <BsPerson className="text-[#6d5eac]" />
                              </div>
                              <div>
                                <span className="font-bold">
                                  {list.total_influencer}
                                </span>{" "}
                                <span className="text-sm text-gray-500">
                                  Influencer
                                </span>
                              </div>
                            </div>

                            <div>
                              {
                                list.list_platform == "Youtube"
                                  ? <BsYoutube className="text-[#E1306C]" />
                                  : list.list_platform == "Facebook"
                                    ? <BsFacebook className="text-[#E1306C]" />
                                    : <BsInstagram className="text-[#E1306C]" />
                              }
                              {/* <BsInstagram className="text-[#E1306C]" /> */}
                            </div>
                          </div>
                          <div className="flex items-center gap-1">
                            {list?.deliverables != "" && (
                              <div className=" flex flex-col item-start mt-1">
                                {list?.deliverables
                                  .split(",")
                                  .map((part, index) => (
                                    <div
                                      key={index}
                                      className="text-left text-gray-600 text-[13px]"
                                    >
                                      {part}
                                      {index !==
                                        list?.deliverables.split("").length - 1}
                                    </div>
                                  ))}
                                {/* <span className="font-bold">10</span>
                              <span className="text-sm">Insta Images</span> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="mt-2 absolute bottom-4 left-1/2  -translate-x-1/2">
                  <Pagination
                    currentPage={currentListPage}
                    layout="navigation"
                    onPageChange={handleListPagination}
                    showIcons
                    totalPages={totalListPage}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {showInfList == true && (
        <>
          <div
            className="flex items-center gap-2 cursor-pointer p-2"
            onClick={() => {
              handleInfList(!showPlans);
            }}
          >
            <FiChevronLeft />
            <div>{curListName}</div>
          </div>

          <div className="mt-4 ">
            <div className="flex items-center justify-start mx-4 mb-4 pb-1 border-b-2">
              <div className="flex  ">
                <p className="text-gray-700">Total Influencer - </p>
                <p>{infList?.list_detail?.total_influencers}</p>
              </div>
              {/* <div className="flex flex-col justify-center">
                <p className="text-gray-700">Deliverables</p>
                <p>{infList?.list_detail?.deliverables || 0}</p>
              </div> */}
            </div>
            {console.log({ infList })}

            {infList &&
              infList?.influencer_list.map((influencer) => {
                return (
                  <>
                    <div className="flex flex-col mt-1 p-3 border w-11/12 mx-auto border-gray-200 shadow-sm rounded-lg  hover:bg-gray-200 cursor-pointer">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <Avatar
                            img={influencer.profileimage}
                            size="sm"
                            rounded={true}
                          />

                          <p>{influencer.inf_name}</p>
                        </div>
                        <FaTrash
                          className="text-gray-500 hover:text-red-500"
                          onClick={() => deletInfluencer(influencer.handle)}
                        />
                      </div>
                      {/* <div className="text-left p-1">
                                        <input type="number" name="" id="" value={5} className="w-7 h-6 p-0 text-sm outline-none border-none" />
                                        <p className="text-sm">Instagram Images</p>
                                    </div> */}
                    </div>
                  </>
                );
              })}
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default PlanComponent;
