import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAudienceLocationFilter, setCreatorLocationFilter } from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import Multiselect from "multiselect-react-dropdown";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];

const CreatorLocation = ({ selectedPlatform }) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const audienceLocationUrl = searchParams.get("audienceLocation");
  const creatorLocationUrl = searchParams.get("creatorLocation");

  const [creatorLocationData, setCreatorLocationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [audienceLocationData, setAudienceLocationData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({}); // Use an object to store percentages for each audience location
  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition()

  const audienceLocationArray = audienceLocationUrl
    ? audienceLocationUrl.split(",").map((value) => {
        const parts = value.split("-k-");
        return {
          id: parts[0],
          title: parts[1],
          percentage: parts[2] // Extract the percentage value
        };
      })
    : [];
  const creatorLocationArray = creatorLocationUrl
    ? creatorLocationUrl.split(",")
    : [];

  const handleAudienceLocation = (selectedList) => {
    const selectedValues = selectedList.map(
      (item) => `${item.id}-k-${item.title}-k-${selectedOption[item.id] || '5'}` // Use the selected option for each item
    );
    dispatch(setAudienceLocationFilter(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("audienceLocation", selectedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("audienceLocation");
        return searchParams;
      });
    }

    setIsDropdownOpen(false)
  };

  const handleCreatorLocation = (selectedList) => {
    console.log(selectedList, 'afssafsa')
    const selectedValues = selectedList.map(
      (item) => item.id + "-k-" + item.title
    );
console.log(selectedValues, 'sadasd')
    dispatch(setCreatorLocationFilter(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("creatorLocation", selectedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("creatorLocation");
        return searchParams;
      });
    }
  };

  const fetchCreatorLocationData = async () => {
    setCreatorLocationData([]);
    setAudienceLocationData([]);
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "locations");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append("platform", selectedPlatform);
      formData.append("search_string", searchString);

      const response = await axiosInstance.post("livesearch/getfilters", formData);
      const result = response.data;

      if (result.status === 1) {
        setCreatorLocationData(result.response.data.data);
        const sortedData = result.response.data.data.sort((a, b) => a.title.localeCompare(b.title));
        setAudienceLocationData(sortedData);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchString === "") {
      fetchCreatorLocationData();
    }
  }, [searchString]);
  
  const handleSearchCreatorLocation = () => {
    setSearchString(""); // This will trigger the useEffect
    setIsDropdownOpen(true); // Toggle the dropdown
    fetchCreatorLocationData();
  };

  const handlePercentageChange = (id, value) => {
    // Update the selected option and dispatch changes
    setSelectedOption(prev => {
      const updatedOptions = {
        ...prev,
        [id]: value
      };
      const updatedValues = audienceLocationArray.map(
        (item) => `${item.id}-k-${item.title}-k-${updatedOptions[item.id] || '5'}`
      );

      dispatch(setAudienceLocationFilter(updatedValues));
      setSearchParam((searchParams) => {
        if (updatedValues.length > 0) {
          searchParams.set("audienceLocation", updatedValues.join(","));
        } else {
          searchParams.delete("audienceLocation");
        }
        return searchParams;
      });

      return updatedOptions;
    });

    setIsDropdownOpen(false)
  };

  const selectedValueAudience = audienceLocationArray.map((value) => ({
    id: value.id,
    title: value.title
  }));

  const selectedValueCreator = creatorLocationArray.map((value) => {
    const [id, title] = value.split("-k-");
    return { id, title };
  });

  useEffect(() => {
    if(searchString.length > 1 && selectedPlatform === "Instagram"){
      fetchCreatorLocationData()
    }
  },[searchString])

  return (
    <>
      <div ref={buttonRef} className="CreatorLocation flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleSearchCreatorLocation}
            className={`filterTextColor flex items-center bg-white ${
              isDropdownOpen ? "font-semibold" : "font-normal"
            }`}
          >
            Location
            <span
              className={`${
                isDropdownOpen ? "" : ""
              } flex items-center`}
            >
              {isDropdownOpen ? (
                <RxCross2
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the button's onClick from triggering
                    setIsDropdownOpen(false);
                  }}
                 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2 text-lg" />
              )}
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${isDropdownOpen ? "block" : "hidden"}`}>
            <div className="flex gap-5">
              <div onClick={handleSearchCreatorLocation} className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdPeopleAlt className="text-2xl" /> Audience{" "}
                  <InfoIconTooltip
                    infoContent={`Identify influencers by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with influencers that have audience in ANY of the specified locations.`}/>
                </div>
                <Multiselect
                  className="bg-white"
                  options={audienceLocationData}
                  displayValue="title"
                  selectedValues={selectedValueAudience}
                  onSelect={handleAudienceLocation}
                  onRemove={handleAudienceLocation}
                  placeholder="Search Location"
                  loading={loading}
                  onSearch={(searchInput) => {
                    setSearchString(searchInput); // Update the search string state
                  }}
                  style={{
                    multiselectContainer: {
                      width: "100%",
                      borderRadius: "0.375rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    },
                    searchBox: {
                      border: "0.6px solid #D9D9D9",
                      borderRadius: "0.375rem",
                    },
                    inputField: {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                    },
                    chips: {
                      background: "#6d5eac",
                      color: "#fff",
                    },
                    option: {
                      color: "#333",
                    },
                    groupHeading: {
                      color: "#6d5eac",
                      background: "#6d5eac",
                    },
                    dropdownList: {
                      background: "#6d5eac",
                      "&:hover": {
                        background: "#6d5eac",
                      },
                    },
                  }}
                />

                {selectedValueAudience.map((item) => (
                  <div key={item.id} className="flex items-center justify-between">
                    <span>{item.title}</span>

                    <select
                      value={selectedOption[item.id] || "5"} // Use the selected option for each id
                      onChange={(e) => handlePercentageChange(item.id, e.target.value)}
                      className="cursor-pointer hover:bg-gray-200 rounded-lg shadow-md"
                      style={{ border: "0.6px solid #D9D9D9", maxHeight: "400px", overflowY: "auto" }}
                    >
                      {percentageArr.map((option) => (
                        <option key={option} value={option}>
                          {`>${option}%`}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>

              <div onClick={handleSearchCreatorLocation} className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Influencer{" "}
                  <InfoIconTooltip
                    infoContent={`Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations.`}
                  />
                </div>
                <Multiselect
                  className="bg-white"
                  options={creatorLocationData}
                  displayValue="title"
                  onSelect={handleCreatorLocation}
                  onRemove={handleCreatorLocation}
                  selectedValues={selectedValueCreator}
                  placeholder="Search Location"
                  loading={loading}
                  onSearch={(searchInput) => {
                    setSearchString(searchInput); // Update the search string state
                  }}
                  style={{
                    multiselectContainer: {
                      width: "100%",
                      borderRadius: "0.375rem",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    },
                    searchBox: {
                      border: "0.6px solid #D9D9D9",
                      borderRadius: "0.375rem",
                    },
                    inputField: {
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#333",
                    },
                    chips: {
                      background: "#6d5eac",
                      color: "#fff",
                    },
                    option: {
                      color: "#333",
                    },
                    groupHeading: {
                      color: "#6d5eac",
                      background: "#6d5eac",
                    },
                    dropdownList: {
                      background: "#6d5eac",
                      "&:hover": {
                        background: "#6d5eac",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CreatorLocation;