import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAudienceBrandFilter,
  setCreatorBrandFilter,
  setPartnership,
} from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const Partnership = ({ hoverStates, handleMouseOver, selectedPlatform }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const partnershipUrl = searchParams.get("partnership")
  const partnershipArray = partnershipUrl ? partnershipUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [partnershipData, setPartnershipData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  

  const fetchCreatorBrandData = async () => {
    setPartnershipData([]);
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "brands");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append("platform", selectedPlatform || '');

      // Using axiosInstance to make the POST request
      const response = await axiosInstance.post(
        "livesearch/getfilters",
        formData
      );
      const result = response.data;

      if (result.status === 1) {
        setPartnershipData(result.response.data.brands);
        // console.log("Checking brand", result.response.data.brands);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchCreatorBrand = () => {
    // handleMouseOver("Partnership");
    fetchCreatorBrandData();
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown
  };

  const handleCreatorBrand = (selectedList) => {
    const selectedValues = selectedList?.map(
      (item) => item.id + "-k-" + item.name
    );

    dispatch(setPartnership(selectedValues));

    if (selectedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("partnership", selectedValues.join(","));
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("partnership");
        return searchParams;
      });
    }
  };

  const partnershipIdAndName = partnershipArray.map((value) => {
    const [id, name] = value.split("-k-"); // Assuming the format is "id-k-name"
    return { id, name };
  });

  return (
    <>
      <div ref={buttonRef} className="CreatorBrand flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleSearchCreatorBrand}
            className="flex items-center bg-white"
          >
            <span
              className={`filterTextColor ${
                isDropdownOpen ? "font-semibold" : ""
              } flex items-center`}
            >
              Partnerships
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2  text-lg" />
              )}
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex gap-5">
              <div
                // onClick={() => fetchCreatorBrandData()}
                className="flex flex-col gap-2 w-full"
              >
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Influencer
                  <InfoIconTooltip infoContent={"Identify influencers by their brand affinities. We determine brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands."}/>
                </div>

                <MultiSelectDropdown
                  options={partnershipData}
                  onSelect={handleCreatorBrand}
                  onRemove={handleCreatorBrand}
                  placeholder={"Select Brand"}
                  selectedValues={partnershipIdAndName}
                  loading={loading}
                />

                {/* <div className="flex mt-4 items-center justify-between">
                  <div className="flex items-center gap-2">
                    <input
                      className="rounded-md border-gray-400 bg-gray-50"
                      type="checkbox"
                      onChange={handleAnyInput}                
                    />
                    <label>Any</label>
                  </div>
                  <InfoIconTooltip infoContent={"Identify influencers by their brand affinities. We determine brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands."}/>
                </div> */}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Partnership;
