import { SET_AUDIENCE_BRAND_FILTER } from "../ActionTypes";

const initialState = [];

const AudienceBrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIENCE_BRAND_FILTER:
            const previousValue = state.value;
            console.log('state in audience brand', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default AudienceBrandReducer