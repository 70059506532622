import React from 'react'
import { Card, Avatar, Button, Table } from 'flowbite-react'
import { BiEdit } from 'react-icons/bi';
import { AiFillSetting } from 'react-icons/ai';
import { FiDownload, FiShare2 } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa'
import MyAvatar from './skeletonComponents/MyAvatar';
import MyLabel from './skeletonComponents/MyLabel';
import MySmallCard from './skeletonComponents/MySmallCard';
import MyButton from './skeletonComponents/MyButton';
import MyTable from './skeletonComponents/MyTable';

const PlanListSkeleton = () => {
  return (
    <>
      <div className="flex justify-between">
        <div className='flex ml-4  mt-2'>
          <MyAvatar />
          <div className='ml-2'>
            <MyLabel />
            <div className="h-3  w-[150px] mx-2 mt-[12px] animate-pulse bg-gray-200 shadow-lg "></div>
            <div className="h-3 mt-[5px] w-[150px] mx-2 animate-pulse bg-gray-200 shadow-lg "></div>
          </div>
        </div>
        <div className='flex gap-2 mr-52 mt-4'>
          <MySmallCard />
          <MySmallCard />
          <MySmallCard />
        </div>
      </div>

      {/* nav */}
      <div className='flex m-auto justify-between items-center mt-20 mb-8 animate-pulse'>
        <div className='flex items-center ml-6'>
          <div className="h-[25px] rounded-lg my-1 w-[25px] mx-2 animate-pulse bg-gray-200 shadow-lg "></div>
          <div className="h-[15px]  my-1 w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg "></div>
        </div>

        <div className='flex mr-12'>
          <div className='flex items-center mr-4'>
            <MyButton />
          </div>
          <div className='flex items-center mr-4'>
            <MyButton />
          </div>

          <div className="flex flex-wrap items-center gap-3">
            <div className='py-[4px] border-[2px] border-gray-200 rounded-md'>
              <MyButton />
            </div>
            <div className='py-[4px] border-[2px] border-gray-200 rounded-md'>
              <MyButton />
            </div>
            <div className='py-[4px] flex items-center border-[2px] border-gray-200 rounded-md'>
              <div className="h-[15px]  w-[25px] mx-3 animate-pulse bg-gray-200 shadow-lg "></div>
              <div className="h-[15px] my-1 w-[66px] mx-3 animate-pulse bg-gray-200 shadow-lg "></div>
            </div>
          </div>
        </div>

      </div>

      {/* table */}
      <MyTable />

     
    </>
  )
}

export default PlanListSkeleton