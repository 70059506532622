import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { setIsVerifiedFilter } from '../../redux/filters/actions';
import InfoTooltip from './InfoTooltip';
import InfoIconTooltip from './InfoIconTooltip';
import { useLocation, useSearchParams } from 'react-router-dom';

const IsVerified = ({ hoverStates, handleMouseOver }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParam, setSearchParam] = useSearchParams()
    const searchParams = new URLSearchParams(location.search)

    const isVerifiedUrl = searchParams.get("isVerified");
    const isVerifiedArray = isVerifiedUrl ? true : false;

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        
        dispatch(setIsVerifiedFilter(isChecked)); // Toggle the checkbox state
        if(isChecked){
            setSearchParam((searchParams) => {
                searchParams.set("isVerified", true)
                return searchParams;
            })
        }else{
            setSearchParam((searchParams) => {
                searchParams.delete("isVerified")
                return searchParams;
            })
        }
    };

    const handleButtonClick = () => {
        handleMouseOver('isVerified');
    };

    return (
        <>
            <div className="isVerified flex justify-start">
                <div className="relative w-full">
                    <button
                        onClick={handleButtonClick}
                        className="w-full flex items-center justify-between bg-white"
                    >
                        <span className={`text-[#3D3D3D] flex items-center`}>
                            {/* {hoverStates.isVerified ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
                                checked={isVerifiedArray}
                                onChange={(e) => handleCheckboxChange(e)}
                            />
                            Only Verified Accounts
                        </span>
                            <InfoIconTooltip infoContent={'Refine search results with only influencers that have verified Influencer account.'} />
                    </button>
                    {/* <ul
                        className={`w-full z-50 px-2 py-1 pl-0 bg-white rounded-md ${hoverStates.isVerified ? "block" : "hidden"}`}
                    >
                        <div className="flex items-center gap-2 w-full">
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9]"
                                checked={selected_isVerified}
                                onChange={() => handleCheckboxChange()}
                            />
                            <label>Show only verified accounts</label>
                        </div>
                    </ul> */}
                </div>
            </div>
        </>
    )
}

export default IsVerified
