import { SET_FOLLOWER_GROWTH_FILTER } from "../ActionTypes";

const initialState = [];

const FollowerGwothReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FOLLOWER_GROWTH_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default FollowerGwothReducer