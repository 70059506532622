import React from "react";
import { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiPlus } from "react-icons/hi";
import {
  Button,
  Label,
  Modal,
  Radio,
  TextInput,
  FileInput,
  Select,
  Tooltip,
} from "flowbite-react";
import axiosInstance from "../../interseptor";
import { AiFillDelete } from "react-icons/ai";
import { createPortal } from "react-dom";

const GET_BRANDS_URL = "/livesearch/brandlist";
const CREATE_BRAND_URL = "/livesearch/createbrand";
const CREATE_PLAN_URL = "/livesearch/createplan";

const REGEX = /^[a-zA-Z.\- ?]+$/;

const CreatePlan = ({ color, size, updatePlan, planslist, getPlans }) => {
  const [planmodalopen, setplanmodalopen] = useState(false);
  const [planName, setPlanName] = useState("");
  const [brandName, setBrandName] = useState("")
  const brandRef = useRef("");
  const [btndisabled, setBtndisabled] = useState(true);

  // const [brand,setBrand] = useState('')
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState('');
  const [openNewBrandInput, setOpenNewBrandInput] = useState(false);
  const [addInfluencerBy, setAddInfluencerBy] = useState("0");


  const getBrands = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "brandlist");
      data.append("device_id", "search");

      const response = await axiosInstance.post(GET_BRANDS_URL, data);
      setBrands(response.data.list);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (planmodalopen) {

      getBrands();
    }
  }, [planmodalopen]);

  useEffect(() => {
    if (brandName == "" || brandName == null) {
      setBtndisabled(true);
    } else {
      setBtndisabled(false);
    }
  }, [brandName]);

  const getBrandId = async (sss = brands) => {
    const id = sss
      .filter((brand) => {
        return brand.brandname == brandName;
      })
      .map((item) => item.brand_id)[0];

    return id;
  };

  const createBrand = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "createbrand");
      data.append("device_id", "seacrh");
      data.append("brand_name", brandName);

      const response = await axiosInstance.post(CREATE_BRAND_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        return true;
      }
    } catch (err) { }
  };

  const createPlan = async () => {
    let b_id = brandId;
    if (brandId == "") {
      const bool = await createBrand();
      const allbrands = await getBrands();
      b_id = await getBrandId(allbrands); //get id after creating brand
    }

    try {
      let data = new FormData();
      data.append("logintype", "createplan");
      data.append("device_id", "search");
      data.append("planname", planName);
      data.append("brandid", b_id);
      data.append("add_influencer_by", addInfluencerBy);

      const response = await axiosInstance.post(CREATE_PLAN_URL, data);
      console.log("cr plan res", response);
      // setBrands(response.data.list)
      let validated = false;

      if (response && response?.status === 200) {
        console.log(1);
        if (response && response?.data && response?.data?.status_code === 200) {
          console.log(2);
          if (response.data.status == 1) {
            console.log(3);
            validated = REGEX.test(response?.data?.msg);
            if (validated) {
              setplanmodalopen(false);
              planslist?.();
              updatePlan?.();
              getPlans?.();
              setPlanName('')
              setBrandId('')
              setBrandName('')
              setAddInfluencerBy('0')
              toast.success(response?.data?.msg, { pauseOnHover: false });
            }
          } else if (response.data.status == 0) {
            setPlanName('')
            setBrandId('')
            setBrandName('')
            setAddInfluencerBy('0')
            setplanmodalopen(false);
            toast.error(response?.data?.msg, { pauseOnHover: false });
          }

          console.log("in v", validated);
          return false;
        }
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }
        // setCreating(false)
        setplanmodalopen(false);
        updatePlan();
        return response?.data.list;
      }
    } catch (err) {
      console.log(err.msg);

    }
  };

  const getList = (val) => { };

  const handleBrandSelection = (brand_id) => {
    setBrandId(brand_id)
    setOpenNewBrandInput(false)
    setBrandName('')
  }

  const handleCreateBrand = () => {
    setOpenNewBrandInput(true)
    setBrandId("")
  }

  const deleteBrandName = () => {
    setBrandName("")
    setOpenNewBrandInput(false);
  };

  const modalonClick = () => {
    setplanmodalopen(true);
  };

  const modalonClose = () => {
    console.log("close modal");
    setPlanName('')
    setBrandId('')
    setOpenNewBrandInput(false)
    // console.log('curr', planNameRef)
    // brandRef.current = ''
    setplanmodalopen(false);
  };

  return (
    <>
      <button
        className="flex justify-center whitespace-nowrap shadow-md w-[90%] nowrap items-center rounded-[4px] hover:opacity-90 h-[53px] text-black  px-[24px] border-[0.8px] mr-3 border-[#D9D9D9]"
             onClick={modalonClick}
      >
        <HiPlus className="mr-0.5" />
        Create Plan
      </button>

      <ToastContainer autoClose={1500} />

      {planmodalopen &&
        createPortal(
          <>
            <div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
              <div className="fixed left-1/2 top-32 z-999  -translate-x-1/2 bg-white border-2 border-gray-500 p-4 w-1/3 rounded-xl shadow-md ">
                <div>
                  <h5 className="text-black text-lg  font-[500] mb-4">
                    Create Plan
                  </h5>
                </div>
                <div className="mb-4">
                  <Label
                    className="text-black mb-2"
                    htmlFor="planname"
                    value="Name of the Plan"
                  />

                  <TextInput
                    id="planname"
                    type="text"
                    value={planName}
                    placeholder="Enter Plan Name"
                    onChange={(e) => setPlanName(e.target.value)}
                    required={true}
                    shadow={true}
                    className="mt-2 rounded-[4px]"
                  />
                </div>

                <div className="mb-5">
                  <Label
                    className="text-black"
                    htmlFor="brandlist"
                    value="Select Brand"
                  />
                  <div style={{ position: "relative" }}>
                    <div className="w-full flex items-center gap-1">
                      <Select
                        onChange={(e) => { handleBrandSelection(e.target.value) }}
                        id="brandlist"
                        required={true}
                        className="w-full mt-2"
                      >
                        <option defaultValue="select" disabled selected={brandId == ''}>
                          Select a Brand
                        </option>

                        {brands &&
                          brands.map((brand) => {
                            return (
                              <option
                                key={brand.brand_id}
                                value={brand.brand_id}
                              >
                                {brand.brandname}
                              </option>
                            );
                          })}
                      </Select>

                      <Tooltip content="Create brand" style="dark">
                        <button
                          size="xs"
                          onClick={handleCreateBrand}
                          className="flex justify-center mt-2 items-center bg-white border-[#d9d9d9] border-[1px] hover:opacity-90 w-10 h-10 rounded-[4px]"
                        >
                          <HiPlus className="h-7 w-6 " />
                        </button>
                      </Tooltip>
                    </div>

                    <div className="flex items-center gap-1">
                      {openNewBrandInput == true && (
                        <>
                          <TextInput
                            className="w-full mt-1"
                            autoComplete="off"
                            id="brandname"
                            type="text"
                            sizing="md"
                            list="optionsList"
                            placeholder="Enter brand Name"
                            value={brandName}
                            onChange={(e) => setBrandName(e.target.value)}
                          />
                          <Tooltip
                            content="Cancel"
                            style="light"
                            placement="bottom"
                          >
                            <Button
                              color="failure"
                              size="xs"
                              onClick={deleteBrandName}
                            >
                              <AiFillDelete
                                className="h-6 w-6 "
                                color="white"
                              />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-1">
                  <h5 className="text-gray-600 dark:text-gray-600 text">
                    I want to add influencers by{" "}
                  </h5>
                  <div className="flex flex-col gap-2 pt-2" id="radiobtns">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="fromvp"
                        value={addInfluencerBy}
                        checked={addInfluencerBy == "0" ? true : false}
                        onChange={() => setAddInfluencerBy("0")}
                      />
                      <Label htmlFor="fromvp" className="cursor-pointer">
                        Search Influencer from VP
                      </Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="manuallycsv"
                        value={addInfluencerBy}
                        checked={addInfluencerBy == "1" ? true : false}
                        onChange={() => setAddInfluencerBy("1")}
                      />
                      <Label htmlFor="manuallycsv" className="cursor-pointer">
                        Adding influencers Manually
                      </Label>
                    </div>
                    <div className="flex gap-3 mt-4">
                      {console.log(planName.length, brandId.length, brandName.length)}
                      <Button
                        onClick={createPlan}
                        size="sm"
                        className="bg-[#686FFD] primary_button border-[1px] border-b-[#363FFC] text-white font-[600] text-sm rounded-[4px] hover:bg-[#686FFD] "

                        disabled={planName.length === 0 || (brandId.length === 0 && brandName.length === 0)}
                      >
                        Create Plan
                      </Button>
                      {/* :
                        <div>
                            <Button isProcessing>
                                Creating
                            </Button>
                        </div> */}

                      {/* } */}
                      <Button
                        color="gray"
                        onClick={modalonClose}
                        size="sm"
                      >
                        Cancel
                      </Button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>,
          document.body
        )}
    </>
  );
};

export default CreatePlan;
