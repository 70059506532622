import { SET_AUDIENCE_LOCATION_FILTER } from "../ActionTypes";

const initialState = [];

const AudienceLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIENCE_LOCATION_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default AudienceLocationReducer