import React, { useEffect, useMemo, useRef, useState } from "react";
// import { Avatar, Card } from "flowbite-react";
import { HiTrash } from "react-icons/hi";
import { FaEye } from "react-icons/fa";
import { IoMdHeart } from "react-icons/io";
import { BsChatFill, BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import { RiVideoFill } from "react-icons/ri";
import { BiSolidHide } from "react-icons/bi";
import axiosInstance from "../../interseptor";
import PostModal from "./PostModal";

const UPDATE_BRAND_LIST_URL = "/livesearch/viral_brand_update";

const ViralContent = ({ reportData }) => {
    const [loading, setLoading] = useState(true);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc')
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [initialPostsCount, setInitialPostsCount] = useState(10);
    const loader = useRef(null);

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedType, setSelectedType] = useState('');

    const [showMore, setShowMore] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(50);

    // Function to paginate data
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const memoizedCurrentPage = useMemo(() => currentPage, [currentPage]);
    const handleShowMore = (username) => {
        setShowMore(prevState => ({
            ...prevState,
            [username]: !prevState[username]
        }));
    };

    const handleSort = (column) => {
        setLoading(true)
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }

    };

    useEffect(() => {
        // This effect will run whenever sortColumn or sortOrder changes
        if (reportData) {
            // Sort posts based on selected column and order
            const sortedPosts = Object.values(posts).sort((a, b) => {
                const aValue = a[sortColumn];
                const bValue = b[sortColumn];
                // You may need to implement custom sorting logic based on the column
                if (sortOrder === 'asc') {
                    return aValue > bValue ? 1 : -1;
                } else {
                    return aValue < bValue ? 1 : -1;
                }
            });
        
            const postsByUser = {};
            sortedPosts.forEach((postArray) => { // Changed parameter name to postArray to reflect the fact that it's an array of posts
                postArray.forEach((post) => { // Iterate through each post in the postArray
                    const { username } = post;
                    // Check if the username already exists in the postsByUser object
                    if (postsByUser.hasOwnProperty(username)) {
                        // If it exists, push the post to the existing array
                        postsByUser[username].push(post);
                    } else {
                        // If it doesn't exist, create a new array with the post and assign it to the username key
                        postsByUser[username] = [post];
                    }
                });
            });
        
            console.log("postsByUser", postsByUser);
            setPosts(postsByUser);
            setLoading(false);
        }
        
    }, [sortColumn, sortOrder, reportData])

    function convertUnixTimestampToDateTime(timestamp) {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Get individual components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted string
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

        return formattedDate;
    }


    const fetchMorePosts = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setInitialPostsCount(initialPostsCount + 10); // Load 10 more posts
        }, 1000);
    };

    useEffect(() => {
        // Fetch initial posts
        // Call your API to fetch initial posts here
        // Replace the timeout with your API call
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // Assuming fetched posts data is stored in reportData.post_list
            setPosts(reportData || []);
            // Check if there are more posts to load
            setHasMore(Object.keys(reportData).length > initialPostsCount);
        }, 1000);
    }, []);
    console.log({memoizedCurrentPage})
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                
                fetchMorePosts();
                console.log(currentPage)
                setCurrentPage(memoizedCurrentPage+1)
            }
        }, { threshold: 1 });

        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => observer.disconnect();
    }, [hasMore]);

    const updateViralBrandList = async (selectedCountry, selectedCategory, selectedType, username, action) => {
        // console.log('checking username', username)
        try {
            const data = new FormData();
            data.append("logintype", "viral_brand_update");
            data.append("device_id", "search");
            data.append("update_country", selectedCountry.toLowerCase() || '');
            data.append("update_category", selectedCategory.toLowerCase() || '');
            data.append("update_type", selectedType.toLowerCase() || '');
            data.append("username", username.toLowerCase() || '');
            data.append("type", action);

            const response = await axiosInstance.post(UPDATE_BRAND_LIST_URL, data);
            console.log(response?.data?.d)

            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };

     // Get current posts
     const indexOfLastPost = currentPage * postsPerPage;
     console.log(indexOfLastPost)
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = Object.keys(posts).slice(0, indexOfLastPost);
        console.log(currentPosts)

    return (
        <div>
            <div className="flex justify-between pb-4">
                <p className="font-semibold text-gray-600">{Object.keys(reportData).length} Posts</p>
                <ul className="flex items-center gap-4">
                    <li onClick={() => handleSort('post_time')} className="cursor-pointer flex items-center gap-0.5">
                        Date
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('views')} className="cursor-pointer flex items-center gap-0.5">
                        View
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('likes')} className="cursor-pointer flex items-center gap-0.5">
                        Likes
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('comment')} className="cursor-pointer flex items-center gap-0.5">
                        Comments
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                </ul>
            </div>

            <div className="col-span-1 gap-2">
                {/* <div className="flex justify-between">
          <h1 className="my-5 pl-2">ALL POST</h1>
        </div> */}
                {
                    loading ?
                        <>
                            <div className="w-full flex gap-5 m-2 pr-20">
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                            </div>
                            <div className="w-full flex gap-5 m-2 pr-20 mt-5">
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                            </div>
                        </>
                        :
                        <div className="flex flex-wrap gap-5 m-2">
                            {currentPosts.map((username, ind) => (
                                // console.log(posts[username]),
                                <div key={ind}>
                                    {

                                        posts[username].length > 1 ?
                                            <PostModal
                                                reportData={posts}
                                                username={username}
                                                showMore={showMore}
                                                updateViralBrandList={updateViralBrandList}
                                                handleShowMore={handleShowMore}
                                                convertUnixTimestampToDateTime={convertUnixTimestampToDateTime}
                                                selectedCountry={selectedCountry}
                                                selectedCategory={selectedCategory}
                                                selectedType={selectedType}
                                            />
                                            :
                                            posts[username].map((post, index) => (
                                                <div key={index} className="border h-fit min-w-[280px] rounded-lg overflow-hidden  shadow-md" rel="noreferrer">
                                                    <div className="w-full flex items-center justify-between p-2">
                                                        <div className="flex items-center gap-1">
                                                            <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                                                <img
                                                                    className="w-full h-full object-cover"
                                                                    src={post.profile_image || '/asset/onErrorImage.jpg'}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null || undefined; // prevents looping
                                                                        currentTarget.src = "/asset/onErrorImage.jpg";
                                                                    }}
                                                                    alt="img"
                                                                />
                                                            </div>
                                                            <div className="">
                                                                <p className="">{post?.username}</p>
                                                                <p className="text-xs text-gray-600">{convertUnixTimestampToDateTime(post?.post_time)}</p>
                                                            </div>
                                                        </div>

                                                        <div className="flex items-center gap-1">

                                                            <HiTrash onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, post?.username, 'archive')} className="text-xl text-gray-500 cursor-pointer" />

                                                            {
                                                                reportData?.platform === 1 ?
                                                                    <BsInstagram className="text-red-600 text-lg" />
                                                                    : reportData?.platform === 2 ?
                                                                        <BsYoutube className="text-rose-600 text-lg" />
                                                                        : reportData?.platform === 3 ?
                                                                            <BsTiktok className="text-black text-lg" />
                                                                            : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <a
                                                        href={post.post_link}
                                                        target="_blank"
                                                    >
                                                        <div
                                                            className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                                                        >
                                                            <img
                                                                src={post.post_image}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null || undefined; // prevents looping
                                                                    currentTarget.src = "/asset/onErrorImage.jpg";
                                                                }}
                                                                alt=""
                                                                className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                                                            />
                                                        </div>
                                                    </a>
                                                    <div className="w-full flex gap-4 p-2 py-3">
                                                        <div className="flex items-center text-sm">
                                                            {
                                                                post?.post_type === "Video" &&
                                                                <RiVideoFill className="text-lg" />
                                                            }
                                                        </div>
                                                        <div className="flex items-center gap-1 text-sm">
                                                            <FaEye />
                                                            {Intl.NumberFormat("en-US", {
                                                                notation: "compact",
                                                                maximumFractionDigits: 1,
                                                            }).format(post?.views)}
                                                        </div>
                                                        <div className="flex items-center gap-1 text-sm">
                                                            {
                                                                post?.likes === -1 ?
                                                                    <>
                                                                        <IoMdHeart className="text-red-500 text-lg" /> <BiSolidHide />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <IoMdHeart className="text-red-500 text-lg" />
                                                                        {Intl.NumberFormat("en-US", {
                                                                            notation: "compact",
                                                                            maximumFractionDigits: 1,
                                                                        }).format(post?.likes)}
                                                                    </>
                                                            }
                                                        </div>

                                                        {
                                                            post?.comment > 0 &&
                                                            <div className="flex items-center gap-1 text-sm">
                                                                <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                                                    notation: "compact",
                                                                    maximumFractionDigits: 1,
                                                                }).format(post?.comment)}
                                                            </div>
                                                        }

                                                    </div>

                                                </div>

                                            ))
                                    }
                                </div>
                            ))}
                            {/* {reportData.slice(0, initialPostsCount).map((item, index) => {
                                return (
                                    item?.username !== undefined && item?.username !== "" && item?.username !== null &&
                                    <div key={index} className="border h-fit min-w-[280px] rounded-lg overflow-hidden  shadow-md" rel="noreferrer">

                                        <div className="w-full flex items-center justify-between p-2">
                                            <div className="flex items-center gap-1">
                                                <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                                    <img
                                                        className="w-full h-full object-cover"
                                                        src={item.profile_image || '/asset/onErrorImage.jpg'}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null || undefined; // prevents looping
                                                            currentTarget.src = "/asset/onErrorImage.jpg";
                                                        }}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="">
                                                    <p className="">{item?.username}</p>
                                                    <p className="text-xs text-gray-600">{convertUnixTimestampToDateTime(item?.post_time)}</p>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-1">

                                                <HiTrash onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, item?.username, 'archive')} className="text-xl text-gray-500 cursor-pointer" />

                                                {
                                                    reportData?.platform === 1 ?
                                                        <BsInstagram className="text-red-600 text-lg" />
                                                        : reportData?.platform === 2 ?
                                                            <BsYoutube className="text-rose-600 text-lg" />
                                                            : reportData?.platform === 3 ?
                                                                <BsTiktok className="text-black text-lg" />
                                                                : ""
                                                }
                                            </div>
                                        </div>
                                        <a
                                            href={item.post_link}
                                            target="_blank"
                                        >
                                            <div
                                                className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                                            >
                                                <img
                                                    src={item.post_image}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null || undefined; // prevents looping
                                                        currentTarget.src = "/asset/onErrorImage.jpg";
                                                    }}
                                                    alt=""
                                                    className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                                                />
                                            </div>
                                        </a>
                                        <div className="w-full flex gap-4 p-2 py-3">
                                            <div className="flex items-center text-sm">
                                                {
                                                    item?.post_type === "Video" &&
                                                    <RiVideoFill className="text-lg" />
                                                }
                                            </div>
                                            <div className="flex items-center gap-1 text-sm">
                                                <FaEye />
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(item?.views)}
                                            </div>
                                            <div className="flex items-center gap-1 text-sm">
                                                {
                                                    item?.likes === -1 ?
                                                        <>
                                                            <IoMdHeart className="text-red-500 text-lg" /> <BiSolidHide />
                                                        </>
                                                        :
                                                        <>
                                                            <IoMdHeart className="text-red-500 text-lg" />
                                                            {Intl.NumberFormat("en-US", {
                                                                notation: "compact",
                                                                maximumFractionDigits: 1,
                                                            }).format(item?.likes)}
                                                        </>
                                                }
                                            </div>

                                            {
                                                item?.comment > 0 &&
                                                <div className="flex items-center gap-1 text-sm">
                                                    <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1,
                                                    }).format(item?.comment)}
                                                </div>
                                            }

                                        </div>

                                    </div>
                                );
                            })} */}

                        </div>
                }

                {/* Intersection observer for pagination */}
                <div ref={loader} />
            </div>
        </div>
    );
};

export default ViralContent;

