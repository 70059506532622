
import {
  SET_AGE_FILTER,
  SET_GENDER_FILTER,
  SET_CREATOR_LOCATION_FILTER,
  SET_TOPICS_FILTER,
  SET_NUMBEROFCONTENT_FILTER,
  SET_KEYWORDS_FILTER,
  SET_CREATOR_BRAND_FILTER,
  SET_IS_VP_RESIGTERED_FILTER,
  SET_IS_VERIFIED_FILTER,
  SET_HAS_CONTACT_DETAILS_FILTER,
  SET_MOST_RECENT_POST_FILTER,
  SET_CREATOR_LANGUAGE_FILTER,
  SET_CREATOR_INTEREST_FILTER,
  SET_BIO_PHASE_FILTER,
  SET_MENTIONS_FILTER,
  SET_HASHTAG_FILTER,
  SET_ACCOUNT_TYPE_FILTER,
  SET_AUDIENCE_LOCATION_FILTER,
  SET_AUDIENCE_AGE_FILTER,
  SET_AUDIENCE_GENDER_FILTER,
  SET_AUDIENCE_LANGUAGE_FILTER,
  SET_AUDIENCE_BRAND_FILTER,
  SET_AUDIENCE_INTEREST_FILTER,
  SET_AVERAGE_LIKES_FILTER,
  SET_ENG_RATE_FILTER,
  SET_SPONCORED_POST_FILTER,
  SET_PREVIOUS_BRAND_SPONCOR_FILTER,
  SET_REEL_VIEW_FILTER,
  SET_FOLLOWER_GROWTH_FILTER,
  SET_CREATOR_LOOKALIKE_FILTER,
  SET_AUDIENCE_LOOKALIKE_FILTER,
  SET_FOLLOWER_FILTER,
  SET_PLATFORM,
  SET_ALL_FILTER_OPEN,
  SET_ETHNICITY_FILTER,
  SET_CREDIBLE_ACCOUNT_FILTER,
  SET_EXCLUDE_PRIVATE_ACCOUNTES,
  SET_PREVIOUS_EXPORTED_FILTER,
  SET_HAS_AUDIENCE_DATA,
  SET_EXCLUDE_OFFICIAL_ARTISTS,
  SET_ENGAGEMENT_AND_ENGAGEMENTRATE,
  SET_SAVES,
  SET_SHARES,
  SET_CONTACT,
  SET_PARTNERSHIP,
  SET_REEL_PLAYS_FILTER,
  SET_LASTPOST,
  SET_AUDIENCE_TYPE

} from "./ActionTypes";

export const setFollowerFilter = (follower) => ({
  type: SET_FOLLOWER_FILTER,
  payload: follower,
});

export const setAgeFilter = (age) => ({
  type: SET_AGE_FILTER,
  payload: age,
});

export const setGenderFilter = (gender) => ({
  type: SET_GENDER_FILTER,
  payload: gender,
});

export const setCreatorLocationFilter = (location) => ({
  type: SET_CREATOR_LOCATION_FILTER,
  payload: location,
});

export const setTopicsFilter = (topic) => ({
  type: SET_TOPICS_FILTER,
  payload: topic,
});

export const setNumberOfContentFilter = (numberofcontent) => ({
  type: SET_NUMBEROFCONTENT_FILTER,
  payload: numberofcontent,
});

export const setKeywordsFilter = (keywords) => ({
  type: SET_KEYWORDS_FILTER,
  payload: keywords,
});

export const setCreatorBrandFilter = (creatorBrand) => ({
  type: SET_CREATOR_BRAND_FILTER,
  payload: creatorBrand,
});

export const setIsVpResigtered = (isVpResigtered) => ({
  type: SET_IS_VP_RESIGTERED_FILTER,
  payload: isVpResigtered,
});

export const setIsVerifiedFilter = (isVerified) => ({
  type: SET_IS_VERIFIED_FILTER,
  payload: isVerified,
});

export const setHasContactDetailsFilter = (hasContactDetails) => ({
  type: SET_HAS_CONTACT_DETAILS_FILTER,
  payload: hasContactDetails,
});

export const setMostRecentPostFilter = (mostRecentPost) => ({
  type: SET_MOST_RECENT_POST_FILTER,
  payload: mostRecentPost,
});

export const setCreatorLanguageFilter = (creatorLanguage) => ({
  type: SET_CREATOR_LANGUAGE_FILTER,
  payload: creatorLanguage,
});

export const setCreatorInterestFilter = (creatorInterest) => ({
  type: SET_CREATOR_INTEREST_FILTER,
  payload: creatorInterest,
});

export const setBioPhraseFilter = (bioPhrase) => ({
  type: SET_BIO_PHASE_FILTER,
  payload: bioPhrase,
});

export const setMentionsFilter = (mentions) => ({
  type: SET_MENTIONS_FILTER,
  payload: mentions,
});

export const setHashTagFilter = (hashtag) => ({
  type: SET_HASHTAG_FILTER,
  payload: hashtag,
});

export const setAccountTypeFilter = (accountType) => ({
  type: SET_ACCOUNT_TYPE_FILTER,
  payload: accountType,
});

export const setAudienceLocationFilter = (audienceLocation) => ({
  type: SET_AUDIENCE_LOCATION_FILTER,
  payload: audienceLocation,
});

export const setAudienceAgeFilter = (audienceAge) => ({
  type: SET_AUDIENCE_AGE_FILTER,
  payload: audienceAge,
});

export const setAudienceGenderFilter = (audienceGender) => ({
  type: SET_AUDIENCE_GENDER_FILTER,
  payload: audienceGender,
});

export const setAudienceLanguageFilter = (audienceLanguage) => ({
  type: SET_AUDIENCE_LANGUAGE_FILTER,
  payload: audienceLanguage,
});

export const setAudienceBrandFilter = (audienceBrand) => ({
  type: SET_AUDIENCE_BRAND_FILTER,
  payload: audienceBrand,
});

export const setAudienceInterestFilter = (audienceInterest) => ({
  type: SET_AUDIENCE_INTEREST_FILTER,
  payload: audienceInterest,
});

export const setAverageLikesFilter = (averageLikes) => ({
  type: SET_AVERAGE_LIKES_FILTER,
  payload: averageLikes,
});

export const setEngRateFilter = (engagementRate) => ({
  type: SET_ENG_RATE_FILTER,
  payload: engagementRate,
});

export const setSponcoredPostFilter = (SponcoredPost) => ({
  type: SET_SPONCORED_POST_FILTER,
  payload: SponcoredPost,
});

export const setPreviousBrandSponcorFilter = (previousBrandSponcored) => ({
  type: SET_PREVIOUS_BRAND_SPONCOR_FILTER,
  payload: previousBrandSponcored,
});

export const setReelViewFilter = (reelViews) => ({
  type: SET_REEL_VIEW_FILTER,
  payload: reelViews,
});

export const setReelPlaysFilter = (reelPlays) => ({
  type: SET_REEL_PLAYS_FILTER,
  payload: reelPlays,
});

export const setFolloweGrowthFilter = (followerGrowth) => ({
  type: SET_FOLLOWER_GROWTH_FILTER,
  payload: followerGrowth,
});

export const setCreatorLookALikeFilter = (creatorLookALike) => ({
  type: SET_CREATOR_LOOKALIKE_FILTER,
  payload: creatorLookALike,
});

export const setAudienceLookALikeFilter = (audienceLookALike) => ({
  type: SET_AUDIENCE_LOOKALIKE_FILTER,
  payload: audienceLookALike,
});

export const setPlatformFilter = (platform) => ({
  type: SET_PLATFORM,
  payload: platform,
});

export const setAllFilterOpen = (isOpen) => ({
  type: SET_ALL_FILTER_OPEN,
  payload: isOpen,
});

export const setEthnicityFilter = (ethnicity) => ({
  type: SET_ETHNICITY_FILTER,
  payload: ethnicity
})

export const setContactFilter = (contact) => ({
  type: SET_CONTACT,
  payload: contact
})

export const setCredibleAccountFilter = (credibleAccount) => ({
  type: SET_CREDIBLE_ACCOUNT_FILTER,
  payload: credibleAccount
})

export const setPreviouslyExported = (previouslyExported) => ({
  type: SET_PREVIOUS_EXPORTED_FILTER,
  payload: previouslyExported
})

export const setExcludePrivateAccount = (excludePrivateAccounts) => ({
  type: SET_EXCLUDE_PRIVATE_ACCOUNTES,
  payload: excludePrivateAccounts
})

export const setHasAudienceData = (hasAudienceData) => ({
  type: SET_HAS_AUDIENCE_DATA,
  payload: hasAudienceData
})

export const setExcludeOfficialArtists = (excludeOfficialArtists) => ({
  type: SET_EXCLUDE_OFFICIAL_ARTISTS,
  payload: excludeOfficialArtists
})

export const setEngAndEngRate = (engAndEngrate) => ({
  type: SET_ENGAGEMENT_AND_ENGAGEMENTRATE,
  payload: engAndEngrate
})

export const setSaves = (saves) => ({
  type: SET_SAVES,
  payload: saves
})

export const setShares = (shares) => ({
  type: SET_SHARES,
  payload: shares
})

export const setPartnership = (partnership) => ({
  type: SET_PARTNERSHIP,
  payload: partnership
})

export const setLastPost = (lastPost) => ({
  type: SET_LASTPOST,
  payload: lastPost
})

export const setAudienceType = (audienceType) => ({
  type: SET_AUDIENCE_TYPE,
  payload: audienceType
})