import React from "react";
import capitalise from "../PlanComponent/capitalise";

const Reels = ({ reportData }) => {
  const reelOverview = reportData?.reel_overview || {};
  return (
    <>
      {Object.keys(reelOverview).length > 0 && (
        <div className="gap-4 bg-white p-2">
          <p className="text-lg text-gray-500 font-semibold p-2">Reel</p>

          <div className="flex justify-start gap-3">
            {Object.entries(reelOverview).map(([key, value]) => (
              <div className="w-52 flex flex-col items-center bg-gradient-to-tr from-[#6d5eac] to-[#8a6efa] rounded-md py-2 shadow-lg hover:scale-105 duration-100">
                <p className="text-lg font-bold	text-center text-white dark:text-gray-400">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(value)}</p>
                <p className="text-sm text-white dark:text-gray-400 mt-1">
                  {capitalise(key.replace(/_/g, " "))}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Reels;
