import { SET_PLATFORM } from "../ActionTypes";

const initialState = {};

const PlatformReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLATFORM:
            // const previousValue = state.value;
            // console.log('state in platform', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default PlatformReducer