import React from 'react'
import { CiMenuKebab } from 'react-icons/ci'

const PlansListSkeleton = () => {
    return (
        <>
            <div className="flex flex-col justify-center gap-2 mt-4 p-2 pb-4 border border-gray-200 shadow-sm rounded-lg w-11/12">
                <div className="flex items-center">
                    <div className=' rounded-full mt-[5px] w-[30px] h-[30px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[80px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                </div>
                <div className="flex justify-between items-center">
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>

                </div>

            </div>

            <div className="flex flex-col justify-center gap-2 mt-4 p-2 pb-4 border border-gray-200 shadow-sm rounded-lg w-11/12">
                <div className="flex items-center">
                    <div className=' rounded-full mt-[5px] w-[30px] h-[30px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[80px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                </div>
                <div className="flex justify-between items-center">
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>

                </div>

            </div>

            <div className="flex flex-col justify-center gap-2 mt-4 p-2 pb-4 border border-gray-200 shadow-sm rounded-lg w-11/12">
                <div className="flex items-center">
                    <div className=' rounded-full mt-[5px] w-[30px] h-[30px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[80px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                </div>
                <div className="flex justify-between items-center">
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>

                </div>

            </div>
        </>

    )
}

export default PlansListSkeleton