import { SET_ALL_FILTER_OPEN } from '../ActionTypes';

const initialState = false;

const AllFIlterOpenReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_FILTER_OPEN:
          return action.payload;
        default:
          return state;
      }
}

export default AllFIlterOpenReducer
