import React, { useState, useEffect, useRef } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setMostRecentPostFilter } from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import { useLocation, useSearchParams } from "react-router-dom";
import { MdCampaign } from "react-icons/md";
import InfoIconTooltip from "./InfoIconTooltip";
import useDropdownPosition from "./useDropdownPosition";

const MostRecentPost = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const mostRecentPostUrl = searchParams.get("mostRecentPost");
  const mostRecentPostArray = mostRecentPostUrl ? mostRecentPostUrl.split(",") : [];

  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);

  const { dropdownRef, buttonRef, isDropdownOpen, setIsDropdownOpen } = useDropdownPosition();
  
  const handleFromChange = (value) => {

    if(value === 'Clear'){
      dispatch(setMostRecentPostFilter([]));

      setSearchParam((searchParams) => {
        searchParams.delete("mostRecentPost");
        return searchParams;
      });
    }

    let monthsValue;

    switch (value) {
      case "<1 Month":
        monthsValue = 1;
        break;
      case "<3 Months":
        monthsValue = 3;
        break;
      case "<6 Months":
        monthsValue = 6;
        break;
      default:
        monthsValue = null;
    }

    dispatch(setMostRecentPostFilter([monthsValue]));

    if ([monthsValue].length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("mostRecentPost", [monthsValue].join(","));
        return searchParams;
      });
    }
    setFromDropdownOpen(false);
  };

  const fromOptions = ["Clear", "<1 Month", "<3 Months", "<6 Months"];

  return (
    <>
      <div ref={buttonRef} className="RecentPost flex justify-start">
        <div className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center bg-white filterTextColor"
          >
            <span
              className={`${isDropdownOpen ? "font-semibold" : ""} ${
                location.pathname.includes("result")
                  ? "h-[40px] whitespace-nowrap"
                  : ""
              }  flex items-center`}
            >
              Most recent post{" "}
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2  text-lg" />
              )}
            </span>
          </button>
          
          <ul
            ref={dropdownRef}
            className={`w-fit absolute z-40 p-5 shadow-lg bg-white rounded-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Influencer{" "}
                <InfoIconTooltip
                  infoContent={`Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations.`}
                />
              </div>
              <div className="relative">
                <input
                  type="text"
                  className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                  placeholder="Any"
                  value={mostRecentPostArray}
                  onClick={() => setFromDropdownOpen(!fromDropdownOpen)}
                  readOnly
                />
                {fromDropdownOpen && (
                  <div className="absolute top-full left-0 w-full mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                    {fromOptions.map((option) => (
                      <div
                        key={option}
                        className="p-2 cursor-pointer text-gray-600 hover:bg-[#6d5eac] hover:text-white"
                        onClick={() => handleFromChange(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MostRecentPost;
