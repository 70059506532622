import React, { useState } from 'react';
import { FiUserCheck } from "react-icons/fi";
import { CiFilter } from "react-icons/ci";
const ButtonMovement = ({childToParent}) => {
    const [isSecondButtonFirst, setIsSecondButtonFirst] = useState(true);

    const handleButtonClick = (param) => {
        if(param === "1"){
            setIsSecondButtonFirst(true);
            childToParent(false);
        }else if(param === "0"){
            setIsSecondButtonFirst(false);
            childToParent(true);
        }
    };

    return (

        <div className='w-[346px] h-[60px] flex mt-[24px] text-[#686FFD]' >
            <div className='w-[420px] h-[50px] flex items-center p-[30px] bg-[#EEF5FF] rounded-[8px] relative' >
                
                <button className={` flex  h-[43px] items-center absolute rounded-[4px] ml-2 p-2  ${isSecondButtonFirst ? 'left-[0px] bg-black text-white w-[172px] font-[500] text-[16px] border-b-[2px] border-[#D9D9D9]' : 'left-[180px] w-[158px] '}`}

                    style={{
                        transition: 'left 0.5s', // Add transition for smooth movement
                    }}
                    onClick={() => handleButtonClick("1")}
                >
                    <CiFilter className='mr-1'/>  Search by topic
                </button>

                <button className={` flex h-[43px] whitespace-nowrap items-center absolute rounded-[4px]  py-[12px] px-[16px]  ${  isSecondButtonFirst ? 'left-[180px] w-[180px] font-[400] text-[16px]' : 'left-[0px] ml-3 bg-black text-white w-[172px] font-[500] text-[16px] border-b-[2px] border-[#D9D9D9]'}`}
                    style={{
                        transition: 'left 0.5s', // Add transition for smooth movement
                    }}
                    onClick={() => handleButtonClick("0")}
                >
                    <FiUserCheck  className='mr-1'/> Search by profile
                </button>
                
            </div>
        </div>
    );
};

export default ButtonMovement;
