import React from "react";
import { Label, TextInput } from "flowbite-react";
import axiosInstance from "../../interseptor";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { HiEye } from "react-icons/hi";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCloudDownloadAlt, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import md5 from "md5";
import ViralTableSkelaton from "./ViralTableSkelaton";
import DateFilter from "./DateFilter";
import { IoLocation } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { decryptData } from "../../components/Auth/Encryption";


const GET_REPORT_LIST_URL = "/livesearch/viral_brand_report";
const GET_CATEGORY_LIST_URL = "/livesearch/category";
const UPLOAD_REPORT_URL = "/livesearch/uploadreport";

const CSVFILE_URL = `https://viralpitch.co/d2/dashboard/sample-report-csv.csv`

const ITEMS_PER_PAGE = 50;

const ViralBrandReport = () => {
    const [reportList, setReportList] = useState([]);
    const [reportName, setReportName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [selectPlatform, setSelectPlatform] = useState('instagram')
    const [currentPage, setCurrentPage] = useState(1);
    const [brandInputKeyword, setBrandInputkeyword] = useState('')
    const [brandInput, setBrandInput] = useState('')
    const [country, setCountry] = useState([])

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortDate, setSortDate] = useState('')

    const [typingTimeout, setTypingTimeout] = useState(null);

    const [apiSortOrLoadMore, setApiSortOrLoadMore] = useState(0)

    const handleSort = (date, type) => {

        setSortDate(date)
        setCurrentPage(1)
        setApiSortOrLoadMore(0)
        // If the same column is clicked, toggle the sorting order
        if (type === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(type);
            setSortOrder('asc'); // Default to ascending order
        }

        console.log('checking sortinggg1', sortColumn, sortOrder, date)
        // getReportList()
    };

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const createReport = async (platform) => {
        setLoading(true);
        let data = new FormData();
        data.append("logintype", "uploadreport");
        data.append("device_id", "seacrh");
        data.append("file", selectedFile);
        data.append("platform", (platform === 'instagram') ? 1 : (platform === 'youtube') ? 2 : 3);
        data.append("reportname", reportName);

        try {
            const response = await axiosInstance.post(UPLOAD_REPORT_URL, data);
            if (response?.data?.status_code == 200) {
                if (response?.data?.status == 1) {
                    closeModal();
                    toast.success(response?.data?.msg, { pauseOnHover: false });
                    // Delay the reload by 2 seconds
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    closeModal();
                    toast.error(response?.data?.msg, { pauseOnHover: false });
                    setLoading(false);
                }
            } else if (response && response?.data && response?.data?.status == 0) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            } else if (response && response?.data && response?.data?.status == 3) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            }
            return response?.data.list;
        } catch (error) {
            console.log(error);
        }
    };

    const closeModal = () => {
        setIsCreateOpen(false);
        setReportName("");
        setSelectedFile(null);
    };

    const downloadCsv = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };

    const GravatarImage = ({ brand }) => {
        const hashedBrand = md5(brand);
        const gravatarURL = `https://storage.googleapis.com/viralimage/${hashedBrand}.jpg`;

        return (
            <img
                src={gravatarURL}
                alt={`Gravatar for ${brand}`}
                onError={({ currentTarget, error }) => {
                    console.error('Image failed to load:', error);
                    currentTarget.onerror = null; // Remove the event listener to prevent looping
                    currentTarget.src = "/asset/onErrorImage.jpg";
                }}
                className="w-12 h-12 rounded-full shadow-md border overflow-hidden"
            />
        );
    };

    const getReportList = async (pageNo, sortColumn, sortOrder, sortDate, apiSortOrLoadMore, country, brandInput) => {

        try {
            const data = new FormData();
            data.append("logintype", "viral_brand_report");
            data.append("device_id", "search");
            data.append("page", pageNo);
            data.append("sortDate", sortDate);
            data.append("sortType", sortColumn);
            data.append("sort", sortOrder);
            data.append("country", country || []);
            data.append("brand_name", brandInput);

            const response = await axiosInstance.post(GET_REPORT_LIST_URL, data);
            console.log(response?.data?.d)
            if (response?.data?.status === 0) {
                setIsLoading(false);
                setReportList([]);
            } else {
                setIsLoading(false);

                if (apiSortOrLoadMore === 0) {
                    setReportList(response?.data?.d);
                } else (
                    setReportList(prev => [...prev, ...response?.data?.d])
                )
                // console.log(response?.data?.d)
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
            setIsLoading(false);
        }
    };

    const getCategoryList = async () => {

        try {
            const data = new FormData();
            data.append("logintype", "category");
            data.append("device_id", "search");

            const response = await axiosInstance.post(GET_CATEGORY_LIST_URL, data);
            console.log(response)
            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };

    const handleLoadMore = () => {
        setApiSortOrLoadMore(1)
        setCurrentPage((prevPage) => prevPage + 1); // Increment page number
    };



    // Function to handle key down event
    const handleKeyDown = () => {
        // Clear previous typing timeout
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
    };

    // Function to handle key up event
    const handleKeyUp = (event) => {
        // Set new typing timeout
        const value = event.target.value;
        setTypingTimeout(setTimeout(() => {
            setBrandInput(value);
        }, 500));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setBrandInput(brandInputKeyword)
        // Perform API call here
    };

    useEffect(() => {
        getCategoryList()
    }, [])

    useEffect(() => {
        getReportList(currentPage, sortColumn, sortOrder, sortDate, apiSortOrLoadMore, country, brandInput) // Fetch data for the current page
    }, [currentPage, sortColumn, sortOrder, sortDate, apiSortOrLoadMore, country, brandInput]); // Trigger the effect when currentPage changes

    function convertUnixTimestampToDateTime(timestamp) {
        console.log(timestamp)
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Get individual components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted string
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

        return formattedDate;
    }
    return (
        <div className="p-3 ">
            <ToastContainer autoClose={1500} />
            <div className="flex justify-between">
                <div className="w-full flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl ml-3 font-semibold text-[#323237]">Viral Brand Reports</h1>
                        {/* <div className="mt-2 flex gap-2">
                            <button className="border py-1 px-2 bg-black text-white rounded-md">Report</button>
                            <button className="border py-1 px-2 bg-black text-white rounded-md">Archived</button>
                            <button className="border py-1 px-2 bg-black text-white rounded-md">Updated</button>
                        </div> */}
                    </div>
                  {JSON.parse(decryptData(localStorage.getItem('rights_action')))["77"]?.includes("134") &&  <div className="flex gap-2">

                        <DateFilter setCountry={setCountry} />

                        <form onSubmit={handleSearch}>
                            <div className="relative">
                                <input
                                    onChange={(event) => setBrandInputkeyword(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    className="border-[0.6px] border-[#D9D9D9] rounded-[0.375rem] w-[200px] py-3.5"
                                    type="text"
                                    placeholder="Search by Brand"
                                    value={brandInputKeyword}
                                />
                                {/* <button
                                    type="submit"
                                    className="absolute top-[10%] right-2 translate-y-[100%]"
                                    onClick={handleSearch}
                                >
                                    <CiSearch />
                                </button> */}
                            </div>
                        </form>
                    </div>}
                </div>

                {/* <button
                    className="flex items-center rounded-lg text-white bg-[#1dbb98] hover:opacity-90 px-4 py-1"
                    onClick={() => setIsCreateOpen(true)}
                >
                    <HiPlus className="mr-0.5" />
                    Create Report
                </button> */}

                {isCreateOpen && (
                    <div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
                        <div className="fixed top-32 left-1/2 -translate-x-[50%] z-50 bg-white border-2 border-gray-500 p-8 w-2/5 rounded-xl shadow-md">
                            <div className="flex justify-end">
                                <AiOutlineClose
                                    onClick={closeModal}
                                    className="cursor-pointer"
                                />
                            </div>
                            <Label
                                className="!text-gray-600 mb-1"
                                htmlFor="reportname"
                                value="Name of the Report"
                            />
                            <TextInput
                                id="reportname"
                                type="text"
                                placeholder="Enter Report Name"
                                value={reportName}
                                onChange={(e) => setReportName(e.target.value)}
                                required={true}
                                shadow={true}
                            />

                            <div className="flex gap-2 mt-5 py-2">
                                <button onClick={() => setSelectPlatform('instagram')} className={`${selectPlatform === 'instagram' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaInstagram className="" /> Instagram
                                </button>
                                <button onClick={() => setSelectPlatform('youtube')} className={`${selectPlatform === 'youtube' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaYoutube className="text-lg" /> YouTube
                                </button>
                                <button onClick={() => setSelectPlatform('tiktok')} className={`${selectPlatform === 'tiktok' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaTiktok /> TikTok
                                </button>
                            </div>

                            <div className="mt-5">
                                <Label
                                    className="!text-gray-600 mb-1 mr-1"
                                    htmlFor="reportname"
                                    value="Upload a File"
                                />
                                <br />
                                <input
                                    className="rounded-lg"
                                    type="file"
                                    accept=".csv"
                                    name="file"
                                    id=""
                                    onChange={handleFileInputChange}
                                />
                                {/* <p className=" mt-1 text-xs text-gray-600">
                  Suggested file type: .csv
                </p> */}
                                <button onClick={() => { downloadCsv(CSVFILE_URL) }} className='flex items-center gap-1 text-xs mt-2'>
                                    <FaCloudDownloadAlt /> Download Sample CSV
                                </button>
                            </div>

                            {
                                loading ? <button className="mt-5 bg-[#1dbb99] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                                    <LoadingSpinner /> Creating
                                </button> : <button
                                    disabled={reportName == "" || !selectedFile}
                                    className="mt-5 w-[120px] bg-[#1dbb99] text-white p-2 rounded-lg shadow-md"
                                    onClick={() => createReport(selectPlatform)}
                                >
                                    Create
                                </button>
                            }

                        </div>
                    </div>
                )}
            </div>


            <table className="w-[99%] mt-5 mx-3 rounded-[8px] text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="sticky top-0 z-0 border  bg-[#EEF5FF]  text-[#828282] whitespace-nowrap">
                    <tr className="h-14">
                        <th className="capitalize text-sm font-medium pl-2">Brand Name</th>

                        <th className="text-sm px-5 font-medium capitalize">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-center">Last 7 Days</h1>
                                <div className="flex w-full justify-between">
                                    <div onClick={() => handleSort('7', 'post')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282]">
                                        Posts
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('7', 'view')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] border-l border-r border-gray-400">
                                        Views
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('7', 'eng')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] whitespace-nowrap">
                                        Eng. Rate
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th className="text-sm px-5 font-medium capitalize">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-center">Last 15 Days</h1>
                                <div className="flex w-full justify-between">
                                    <div onClick={() => handleSort('15', 'post')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282]">
                                        Posts
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('15', 'view')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] border-l border-r border-gray-400">
                                        Views
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('15', 'eng')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] whitespace-nowrap">
                                        Eng. Rate
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th className="text-sm px-5 font-medium capitalize">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-center">Last 30 Days</h1>
                                <div className="flex w-full justify-between">
                                    <div onClick={() => handleSort('30', 'post')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282]">
                                        Posts
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('30', 'view')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] border-l border-r border-gray-400">
                                        Views
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('30', 'eng')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] whitespace-nowrap">
                                        Eng. Rate
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </th>

                        <th className="text-sm px-5 font-medium capitalize">
                            <div className="flex flex-col gap-1">
                                <h1 className="text-center">Last 60 Days</h1>
                                <div className="flex w-full justify-between">
                                    <div onClick={() => handleSort('60', 'post')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282]">
                                        Posts
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('60', 'view')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] border-l border-r border-gray-400">
                                        Views
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                    <div onClick={() => handleSort('60', 'eng')} className="cursor-pointer w-full flex items-center justify-center text-xs text-[#828282] whitespace-nowrap">
                                        Eng. Rate
                                        {sortOrder === 'asc' ? <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                        </span> : <span>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                        </span>}
                                    </div>
                                </div>
                            </div>
                        </th>

                        {/* <th className="text-sm font-medium capitalize">Last 90 Days</th> */}
                        <th className="text-sm font-medium capitalize"></th>
                        <th className="text-sm font-medium capitalize"></th>
                    </tr>
                </thead>
                <tbody className="divide-y">
                    {isLoading ? <ViralTableSkelaton />
                        : reportList?.map((report, index) => (
                            <tr className="h-20 border bg-white text-black" key={index}>
                                <td className="pl-2 font-semibold">

                                    <div className="pr-2 py-2 flex items-center">
                                        <Link target="/blank" to={`https://www.instagram.com/${report?.brandname}/`}>
                                            <span className="mr-2 flex items-center gap-1">
                                                <GravatarImage brand={report?.brandname} />
                                            </span>
                                        </Link>
                                        <div className="flex flex-col">
                                            <div>
                                                <span>{report?.brandname}</span>
                                            </div>
                                            <span className="font-normal text-gray-400">{convertUnixTimestampToDateTime(report?.lastupdate)}</span>
                                        </div>
                                    </div>

                                </td>
                                <td className="font-medium">
                                    <div className="flex px-5 justify-center items-center">
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last7day?.post)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last7day?.view)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last7day?.eng)}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="font-medium">
                                    <div className="flex px-5 justify-center items-center">
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last15day?.post)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last15day?.view)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last15day?.eng)}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="font-medium">
                                    <div className="flex px-5 justify-center items-center">
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last30day?.post)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last30day?.view)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last30day?.eng)}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="font-medium">
                                    <div className="flex px-5 justify-center items-center">
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last60day?.post)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last60day?.view)}
                                            </span>
                                        </div>
                                        <div className="w-full flex justify-center">
                                            <span className="">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(report?.last60day?.eng)}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td className="">
                                  {JSON.parse(decryptData(localStorage.getItem('rights_action')))["77"]?.includes("135") &&   <div className="flex items-center justify-center">
                                        <Link
                                            to={`/ViralBrandReport/${report?.brandname}`}
                                            className="flex items-center px-2 py-1.5 rounded-md bg-[#1dbb99] hover:opacity-90 text-sm text-white"
                                        >
                                            <HiEye />
                                            &nbsp;View Report
                                        </Link>
                                    </div>}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {
                (reportList?.length >= 50) &&
                <div className="flex justify-center my-5">
                    <button
                        className="px-4 py-2 bg-[#1dbb99] text-white rounded-lg shadow-md"
                        onClick={handleLoadMore}
                    >
                        {isLoading ? <LoadingSpinner /> : "Load More"}
                    </button>
                </div>
            }

        </div>
    );
};

export default ViralBrandReport;