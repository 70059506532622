import { SET_AUDIENCE_AGE_FILTER } from "../ActionTypes";

const initialState = []

const AudienceAgeReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_AUDIENCE_AGE_FILTER:
        return action.payload;
      default:
        return state;
    }
  };

  export default AudienceAgeReducer