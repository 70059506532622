import React, { useState } from 'react'
import { useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import axiosInstance from '../../interseptor'

const DateFilter = ({ setCountry }) => {
    const [audienceLocationData, setAudienceLocationData] = useState([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedAudienceLocation, setSelectedAudienceLocation] = useState([])
    const [searchString, setSearchString] = useState('');

    const fetchAudieceLocationData = async () => {
        try {
            setLoading(true);
            setError(null);

            const formdata = new FormData();
            formdata.append("logintype", "location");
            formdata.append("device_id", "search");
            formdata.append("search_string", searchString);

            const result = await axiosInstance.post("livesearch/location", formdata);
            console.log(result)
            if (result?.data?.status === 1) {
                const sortedData = result?.data?.list.sort((a, b) => a.name.localeCompare(b.name));
                setAudienceLocationData(sortedData);
            } else {
                setError(result.msg);
                console.error('Error:', result.msg);
            }
        } catch (error) {
            setError('An error occurred while fetching data.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAudienceLocation = (selectedList) => {
        // const selectedValues = selectedList?.map((item) => item.name)
        const selectedValues = selectedList.map((item) => (item.id + '-k-' + item.name));
        setSelectedAudienceLocation(selectedValues);
        
        if (selectedValues.length === 0) {
            setCountry([]);
        }
    };

    useEffect(() => {
        if (selectedAudienceLocation.length > 0) {
            setCountry(selectedAudienceLocation);
        }
    }, [selectedAudienceLocation, setCountry]);

    // Convert selectedValues to a string
    const selectedValue = selectedAudienceLocation?.map((value) => ({ name: value }))
    const selectedValuesString = typeof selectedValue === 'string' ? selectedValue : '';

    // Split the string using the '-k-' delimiter
    const audienceLocation = selectedValuesString.split('-k-');

    return (
        <div className="flex justify-start">
            <div className="relative">

                <ul
                    className={`w-full z-50 bg-white rounded-md`}
                >
                    <div
                        onClick={() => fetchAudieceLocationData()}
                        className="flex gap-2 w-full">

                        <Multiselect
                            className='bg-white'
                            options={audienceLocationData}
                            displayValue="name"
                            onSelect={handleAudienceLocation}
                            onRemove={handleAudienceLocation}
                            selectedValues={audienceLocation[0]}
                            placeholder='Search by Country'
                            loading={loading}
                            onSearch={(searchInput) => {
                                setSearchString(searchInput);  // Update the search string state
                                fetchAudieceLocationData();   // Call the API immediately on search
                            }}
                            style={{
                                multiselectContainer: {
                                    width: '100%',
                                    borderRadius: '0.375rem',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                },
                                searchBox: {
                                    border: '0.6px solid #D9D9D9',
                                    borderRadius: '0.375rem',
                                },
                                inputField: {
                                    fontSize: '15px',
                                    fontWeight: '400',
                                    color: '#333',
                                },
                                chips: {
                                    background: '#6d5eac',
                                    color: '#fff',
                                },
                                option: {
                                    color: '#333',
                                },
                                groupHeading: {
                                    color: '#6d5eac',
                                    background: '#6d5eac',
                                },
                                dropdownList: {
                                    background: '#6d5eac',  // Change this to the desired background color
                                    '&:hover': {
                                        background: '#6d5eac',  // Change this to the desired hover background color
                                    },
                                },
                            }}
                        />
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default DateFilter
