import { SET_FOLLOWER_FILTER } from "../ActionTypes";

const FollowersReducer = (state = null, action) => {
    switch (action.type) {
      case SET_FOLLOWER_FILTER:
        return action.payload;
      default:
        return state;
    }
  };

  export default FollowersReducer