import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { BsPeopleFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setAudienceType, setEthnicityFilter, setLastPost } from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const AudienceType = ({ hoverStates, handleMouseOver, selectedPlatform }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const audienceTypeUrl = searchParams.get("audienceType")
  const audienceTypeArray = audienceTypeUrl ? audienceTypeUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const selected_audience_type = useSelector((state) => state.audienceType);
  const [selectedAudienceType, setSelectedAudienceType] = useState(
    selected_audience_type || []
  );
  const [selectedAudienceTypeId, setSelectedAudienceTypeId] = useState([]);
  // const [showDropdown, setShowDropdown] = useState(false);

  const instaOptions = [
    { id: 1, name: "Any" },
    { id: 2, name: "Likers" },
    { id: 3, name: "Followers" },
  ];

  const youtubeOptions = [
    { id: 1, name: "Any" },
    { id: 2, name: "Followers" },
    { id: 3, name: "Commenters" },
  ];

  // const toggleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };

  const handleCheckboxChange = (id, name) => {
    setSelectedAudienceType([name]);
    setSelectedAudienceTypeId(id);

    dispatch(setAudienceType([name]));

    if ([name]) {
      setSearchParam((searchParams) => {
        searchParams.set("audienceType", [name]);
        return searchParams;
      });
    }
    // const newSelectedEthnicities = selectedAudienceType.includes(id)
    //   ? selectedAudienceType.filter((eth) => eth !== id)
    //   : [...selectedAudienceType, id];

    // setSelectedAudienceType(newSelectedEthnicities);
  };

  const renderCheckbox = (option) => (
    <label key={option.id} className="checkbox-label flex items-center gap-1">
      <input
        type="checkbox"
        checked={selectedAudienceType.includes(option.id)}
        onChange={() => handleCheckboxChange(option.id)}
        className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
      />
      {option.name}
    </label>
  );

  useEffect(() => {
    if(audienceTypeArray.length === 0){
      setSelectedAudienceTypeId();
    }
  }, [audienceTypeUrl]);

  return (
    <div ref={buttonRef} className="Ethnicity dropdown">
      <div className="relative w-full">
      <button
        className={`filterTextColor ${
          isDropdownOpen ? "font-semibold" : ""
        } flex items-center`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        Audience Type
        {isDropdownOpen ? (
          <RxCross2 className="ml-2 text-lg" />
        ) : (
          <IoChevronDownSharp className="ml-2 text-lg" />
        )}
      </button>
      
        <ul 
          ref={dropdownRef}
          className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="dropdown-content flex flex-col gap-2">
            <h2 className="text-[#3D3D3D] flex items-center gap-1 text-lg">
              <BsPeopleFill /> Influencer
            </h2>
            {selectedPlatform === "YouTube"
              ? youtubeOptions?.map((option) => {
                  return (
                    <>
                      <label
                        key={option.id}
                        className="checkbox-label flex items-center gap-1"
                      >
                        <input
                          type="checkbox"
                          checked={selectedAudienceTypeId === option.id}
                          onChange={() =>
                            handleCheckboxChange(option.id, option.name)
                          }
                          className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                        />
                        {option.name}
                      </label>
                    </>
                  );
                })
              : instaOptions?.map((option) => {
                  return (
                    <>
                      <label
                        key={option.id}
                        className="checkbox-label flex items-center gap-1"
                      >
                        <input
                          type="checkbox"
                          checked={selectedAudienceTypeId === option.id}
                          onChange={() =>
                            handleCheckboxChange(option.id, option.name)
                          }
                          className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                        />
                        {option.name}
                      </label>
                    </>
                  );
                })}

            {/* <button onClick={toggleDropdown}>Apply</button> */}
          </div>
        </ul>
        </div>
    </div>
  );
};

export default AudienceType;
