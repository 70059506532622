import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setHasAudienceData } from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { useLocation, useSearchParams } from "react-router-dom";

const HasAudienceDataFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [searchParam, setSearchParam] = useSearchParams()

  const hasAudienceDataUrl = searchParams.get("hasAudienceData")
  const hasAudienceDataArray = hasAudienceDataUrl ? true : false; 

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
        
    dispatch(setHasAudienceData(isChecked)); // Toggle the checkbox state
    if(isChecked){
        setSearchParam((searchParams) => {
            searchParams.set("hasAudienceData", true)
            return searchParams;
        })
    }else{
        setSearchParam((searchParams) => {
            searchParams.delete("hasAudienceData")
            return searchParams;
        })
    }
  };

  const handleButtonClick = () => {
    handleMouseOver("isHasAudienceData");
  };

  return (
    <>
      <div className="isVerified flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleButtonClick}
            className="flex items-center bg-white w-full justify-between"
          >
            <span
              className={`text-[#3D3D3D] flex items-center`}
            >
              <input
                id="checkid"
                type="checkbox"
                className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
                checked={hasAudienceDataArray}
                onChange={handleCheckboxChange}
              />
              Has Audience Data
            </span>
              <InfoIconTooltip infoContent={"Refine search results with only influencers that we have Audience Data report."}/>
          </button>
          {/* <ul
            className={`w-full z-50 px-2 py-1 pl-0 bg-white rounded-md ${
              hoverStates.isHasAudienceData ? "block" : "hidden"
            }`}
          >
            <div className="flex items-center gap-2 w-full">
              <input
                id="checkid"
                type="checkbox"
                className="rounded-md border-[0.6px] border-[#D9D9D9]"
                checked={selected_hasAudienceData}
                onChange={handleCheckboxChange}
              />
              <label>Show only audience data</label>
            </div>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default HasAudienceDataFilter;
