import React from 'react'
import MyTable from './skeletonComponents/MyTable'

const PlanSkeleton = () => {
    return (
        <div>
            {/* <div className='flex justify-between'>
                <div className="h-5 mt-[5px] w-[50px] mx-2 animate-pulse bg-gray-200 shadow-lg "></div>
                <div className="h-5 mt-[5px] w-[100px] mx-2 animate-pulse bg-gray-200 shadow-lg "></div>
            </div> */}

            {/* <div className="h-3 mx-2 my-14 w-[180px]  animate-pulse bg-gray-200 shadow-lg "></div> */}

            <div className='mt-8'>
                <MyTable />
            </div>
        </div>
    )
}

export default PlanSkeleton