import React from "react";
import { useEffect, useState } from "react"
import { Navigate, Outlet } from 'react-router-dom'


const PrivateRoutes = () => {
  const [isLogin, setIsLogin] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token_data");
        if (!token) {
            setIsLogin(false);
        }
    }, []);
    
    return (
      isLogin ? <Outlet/> : <Navigate to='/login'/>
    )

}


export default PrivateRoutes;