import React from 'react'

const ListsListSkeleton = () => {
    return (

        <>
            <div className="flex flex-col justify-center gap-2 mt-4 p-2 pb-4 border border-gray-200 shadow-sm rounded-lg w-11/12">
                <div className='h-5 mt-[5px] w-[80px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                <div className='flex justify-between mt-3'>
                    <div className='h-3 mt-[5px] w-[150px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-[20px] mt-[5px] w-[20px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                </div>
                <div className='h-3 mt-[1px] w-[140px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>

            </div>
            <div className="flex flex-col justify-center gap-2 mt-4 p-2 pb-4 border border-gray-200 shadow-sm rounded-lg w-11/12">
                <div className='h-5 mt-[5px] w-[80px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                <div className='flex justify-between mt-3'>
                    <div className='h-3 mt-[5px] w-[150px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                    <div className='h-[20px] mt-[5px] w-[20px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>
                </div>
                <div className='h-3 mt-[1px] w-[140px] mx-2 animate-pulse bg-gray-200 shadow-lg'></div>

            </div>
        </>



    )
}

export default ListsListSkeleton