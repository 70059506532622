import React from 'react'
import { Table } from "flowbite-react";

const CostTable = () => {
    return (
        <Table className='origin-top custom-table '>
            <Table.Head className='border-solid border-2 border-indigo-200'>
                <Table.HeadCell >
                    Deliverables
                </Table.HeadCell>
                <Table.HeadCell>
                    Cost
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y ">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 border-solid border-2 border-indigo-200">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        Apple MacBook Pro 17"
                    </Table.Cell>
                    <Table.Cell>
                        Sliver
                    </Table.Cell>

                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 border-solid border-2 border-indigo-200">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        Microsoft Surface Pro
                    </Table.Cell>
                    <Table.Cell>
                        White
                    </Table.Cell>

                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 border-solid border-2 border-indigo-200">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        Magic Mouse 2
                    </Table.Cell>
                    <Table.Cell>
                        Black
                    </Table.Cell>

                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export default CostTable