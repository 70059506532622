import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setExcludeOfficialArtists } from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { useLocation, useSearchParams } from "react-router-dom";

const ExcludeofficialArtistsfilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [searchParam, setSearchParam] = useSearchParams()

  const excludeOfficialArtistsUrl = searchParams.get("excludeOfficialArtists")
  const excludeOfficialArtistsArray = excludeOfficialArtistsUrl ? true : false; 

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
        
    dispatch(setExcludeOfficialArtists(isChecked)); // Toggle the checkbox state
    if(isChecked){
        setSearchParam((searchParams) => {
            searchParams.set("excludeOfficialArtists", true)
            return searchParams;
        })
    }else{
        setSearchParam((searchParams) => {
            searchParams.delete("excludeOfficialArtists")
            return searchParams;
        })
    }
  };

  const handleButtonClick = () => {
    handleMouseOver("isExcludeofficialArtists");
  };

  return (
    <>
      <div className="isVerified flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleButtonClick}
            className="flex items-center w-full justify-between bg-white"
          >
            <span
              className={`text-[#3D3D3D] ${
                hoverStates.isExcludeofficialArtists ? "" : ""
              } flex items-center whitespace-nowrap`}
            >
              <input
                id="checkid"
                type="checkbox"
                className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
                checked={excludeOfficialArtistsArray}
                onChange={handleCheckboxChange}
              />
              Exclude official Artists Channels
              
            </span>
              <InfoIconTooltip infoContent={"Influencers that have official artist label in their Youtube channel will be excluded from the search results"}/>
          </button>
          <ul
            className={`w-full z-50 px-2 py-1 pl-0 bg-white rounded-md ${
              hoverStates.isExcludeofficialArtists ? "block" : "hidden"
            }`}
          >
          </ul>
        </div>
      </div>
    </>
  );
};

export default ExcludeofficialArtistsfilter;
