import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setAccountTypeFilter } from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import InfoIconTooltip from "./InfoIconTooltip";
import useDropdownPosition from "./useDropdownPosition";

const AccountType = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const accountTypeUrl = searchParams.get("accountType");
  const accountTypeArray = accountTypeUrl ? accountTypeUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [selectedIds, setSelectedIds] = useState(accountTypeArray);

  const handleAccountType = (id, name) => {
    let updatedSelectedIds;

    if (name === "Any") {
      // If "Any" is selected, uncheck all other options
      updatedSelectedIds = ["Any"];
      dispatch(setAccountTypeFilter([]));
      setSearchParam((searchParams) => {
        searchParams.delete("accountType");
        return searchParams;
      });
    } else {
      // If any other option is selected, remove "Any" from the selection
      if (selectedIds.includes(name)) {
        updatedSelectedIds = selectedIds.filter((item) => item !== name);
      } else {
        updatedSelectedIds = selectedIds.filter((item) => item !== "Any");
        updatedSelectedIds.push(name);
      }
    }

    setSelectedIds(updatedSelectedIds);

    if (updatedSelectedIds.length === 0 || updatedSelectedIds.includes("Any")) {
      dispatch(setAccountTypeFilter([]));
      setSearchParam((searchParams) => {
        searchParams.delete("accountType");
        return searchParams;
      });
    } else {
      dispatch(setAccountTypeFilter(updatedSelectedIds));
      setSearchParam((searchParams) => {
        searchParams.set("accountType", updatedSelectedIds.join(","));
        return searchParams;
      });
    }
  };

  const accountTypeOptions = [
    { id: 1, name: "Any" },
    { id: 2, name: "Business" },
    { id: 3, name: "Creator" },
    { id: 4, name: "Regular" },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={buttonRef} className="AccountType flex justify-start">
      <div className="relative w-full">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center bg-white filterTextColor"
        >
          <span
            className={`${
              isDropdownOpen ? "font-semibold" : ""
            } flex items-center`}
          >
            Account Type
            {isDropdownOpen ? (
              <RxCross2 className="ml-2 text-lg" />
            ) : (
              <IoChevronDownSharp className="ml-2 text-lg" />
            )}
          </span>
        </button>
        <ul
          ref={dropdownRef}
          className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <MdCampaign className="text-2xl" /> Influencer{" "}
              <InfoIconTooltip
                infoContent={`Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations.`}
              />
            </div>
            {accountTypeOptions.map((option) => (
              <label
                key={option.id}
                className="checkbox-label flex items-center gap-1"
              >
                <input
                  type="checkbox"
                  checked={selectedIds.includes(option.name)}
                  onChange={() => handleAccountType(option.id, option.name)}
                  className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                />
                {option.name}
              </label>
            ))}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default AccountType;