import React, { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setSaves } from "../../redux/filters/actions";
import InfoIconTooltip from "./InfoIconTooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";

const SavesFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();

  const savesUrl = searchParam.get("saves");
  const savesArray = savesUrl ? savesUrl.split(",") : [];

  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const convertToNumeric = (value) => {
    const conversions = {
      "1k": 1000,
      "5k": 5000,
      "10k": 10000,
      "25k": 25000,
      "50k": 50000,
      "100k": 100000,
      "250k": 250000,
      "500k": 500000,
      ">1m": 1000000,
      "<1m": 1000000,
      Clear: null,
    };

    return conversions[value] !== undefined ? conversions[value] : parseInt(value);
  };

  const updateFilterAndUrl = (newRangeValues) => {
    const sanitizedValues = newRangeValues.filter(value => value !== null && value !== undefined && value !== "");

    dispatch(setSaves(sanitizedValues));

    if (sanitizedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("saves", sanitizedValues.join(","));
        return searchParams;
      });
    } else {
      setSearchParam((searchParams) => {
        searchParams.delete("saves");
        return searchParams;
      });
    }
  };

  const handleFromChange = (value) => {
    const newFromValue = value === "Clear" ? null : convertToNumeric(value);
    const newRangeValues = [newFromValue, savesArray[1]];
    updateFilterAndUrl(newRangeValues);
    setFromDropdownOpen(false);
  };

  const handleToChange = (value) => {
    const newToValue = value === "Clear" ? null : convertToNumeric(value);
    const newRangeValues = [savesArray[0], newToValue];
    updateFilterAndUrl(newRangeValues);
    setToDropdownOpen(false);
  };

  useEffect(() => {
    const updatedRangeValues = [
      savesArray[0] ? parseInt(savesArray[0]) : null,
      savesArray[1] ? parseInt(savesArray[1]) : null,
    ];
    dispatch(setSaves(updatedRangeValues.filter(Boolean)));
  }, [location.search, dispatch]);

  const fromOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];
  const toOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    "<1m",
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} onClick={() => {setFromDropdownOpen(false); setToDropdownOpen(false)}} className="Saves flex justify-start">
      <div className="relative w-full">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="filterTextColor flex items-center bg-white"
        >
          <span
            className={`flex items-center ${
              isDropdownOpen ? "font-semibold" : ""
            }`}
          >
            Saves
            <span className={`flex items-center`}>
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2 text-lg" />
              )}
            </span>
          </span>
        </button>
        <ul
          className={`absolute w-[250px] z-50 p-5 bg-white rounded-lg border shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <MdCampaign className="text-2xl" /> Influencer
              <InfoIconTooltip infoContent="Identify influencers by their saves. You can filter by the number of saves using predefined options or clear the filters." />
            </div>
            <div className="flex gap-2 w-full">
              <div className="relative">
                <input
                  type="text"
                  className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                  placeholder="From"
                  value={savesArray[0] !== undefined ? savesArray[0] : ""}
                  onClick={(e) => {e.stopPropagation(); setFromDropdownOpen(!fromDropdownOpen); setToDropdownOpen(false)}}
                  readOnly
                />
                {fromDropdownOpen && (
                  <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                    {fromOptions.map((option) => (
                      <div
                        key={option}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleFromChange(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {savesArray[0] !== "1000000" && (
                <div className="relative">
                  <input
                    type="text"
                    className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                    placeholder="To"
                    value={savesArray[1] !== undefined ? savesArray[1] : ""}
                    onClick={(e) => {e.stopPropagation(); setToDropdownOpen(!toDropdownOpen); setFromDropdownOpen(false)}}
                    readOnly
                  />
                  {toDropdownOpen && (
                    <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                      {toOptions.map((option) => (
                        <div
                          key={option}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleToChange(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SavesFilter;