import React from 'react'

const MySmallCard = () => {
    return (
        <div className='h-[70px] w-[200px] animate-pulse border-l-4 border-l-gray-200  rounded-md my-2 pl-5 pr-20'>
            <div className="h-3 mt-[5px] w-[50px] mx-2 bg-gray-200 "></div>
            <div className="h-2 mt-[5px] w-[100px] mx-2 bg-gray-200 "></div>
            <div className="h-2 mt-[5px] w-[100px] mx-2 bg-gray-200 "></div>
            <div className="h-2 mt-[5px] w-[100px] mx-2 bg-gray-200 "></div>
        </div>
    )
}

export default MySmallCard