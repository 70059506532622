import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InfoIconTooltip from "../../components/SearchResultFilters/InfoIconTooltip";
// import AddCostModal from "../../components/Report/AddCostModal";
import TableSkeleton from "../hashtagReport/TableSkeleton";
import { HiBadgeCheck, HiTrash } from "react-icons/hi";
import axiosInstance from "../../interseptor";

const GET_CATEGORY_LIST_URL = "/livesearch/category";
const UPDATE_BRAND_LIST_URL = "/livesearch/viral_brand_update";

const ViralInfluencer = ({ reportData, setSelectedTab }) => {
    // const [showBox, setShowBox] = useState(false);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);

    const [countryList, setCountryList] = useState([])
    const [categoryList, setCategoryList] = useState([])

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedType, setSelectedType] = useState('');
    // const [action, setAction] = useState('update');

    const storedUsername = localStorage.getItem('userdata');

    const username = JSON.parse(storedUsername);

    useEffect(() => {
        if (reportData) {
            setLoading(false);
        }
    }, [reportData]);

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }
    };

    // const handleBox = () => {
    //     setShowBox(!showBox);
    // };

    const getUsername = (link) => {
        let username = link?.split("/").pop();
        return username
    }

    const getCategoryList = async () => {

        try {
            const data = new FormData();
            data.append("logintype", "category");
            data.append("device_id", "search");

            const response = await axiosInstance.post(GET_CATEGORY_LIST_URL, data);
            console.log('checking category', response?.data?.list)
            setCategoryList(response?.data?.list)
            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };


    const fetchCountryList = async () => {
        try {
            setLoading(true);

            const formdata = new FormData();
            formdata.append("logintype", "location");
            formdata.append("device_id", "search");

            const result = await axiosInstance.post("livesearch/location", formdata);
            console.log('result 11', result)
            if (result?.data?.status === 1) {
                const sortedData = result?.data?.list.sort((a, b) => a.name.localeCompare(b.name));
                console.log('sorted data country', sortedData)
                setCountryList(sortedData);
            } else {
                console.error('Error:', result.msg);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleSelectedCountry = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleSelectedCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSelectedType = (event) => {
        setSelectedType(event.target.value);
    };

    const updateViralBrandList = async (selectedCountry, selectedCategory, selectedType, username, action) => {
        // console.log('checking username', username)
        try {
            const data = new FormData();
            data.append("logintype", "viral_brand_update");
            data.append("device_id", "search");
            data.append("update_country", selectedCountry.toLowerCase() || '');
            data.append("update_category", selectedCategory.toLowerCase() || '');
            data.append("update_type", selectedType.toLowerCase() || '');
            data.append("username", username.toLowerCase() || '');
            data.append("type", action);

            const response = await axiosInstance.post(UPDATE_BRAND_LIST_URL, data);
            console.log(response?.data?.d)

            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };

    const type = ["Profile", "Channel", "Fanpage", "Community", "Brand"]

    useEffect(() => {
        getCategoryList()
        fetchCountryList()
    }, [])

    return (
        <>
            <div className="w-full flex items-center justify-between">
                <p className="font-semibold text-gray-600">{reportData?.profile?.length} Influencers</p>
                {/* <AddCostModal setSelectedTab={setSelectedTab} /> */}
            </div>
            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                    <tr className="h-12">
                        <th className="text-sm font-medium capitalize pl-2">Name</th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_posts')}
                                className='flex items-center cursor-pointer'
                            >
                                Total Post
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('follower')}
                                className='flex items-center cursor-pointer'
                            >
                                Followers
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_like')}
                                className='flex items-center cursor-pointer'
                            >
                                Likes
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_comment')}
                                className='flex items-center cursor-pointer'
                            >
                                Comments
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_view')}
                                className='flex items-center cursor-pointer'
                            >
                                Avg. Views
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        {
                            reportData?.platform[0] === 1 &&
                            <th className="text-sm font-medium capitalize">
                                <div
                                    onClick={() => handleSort('avg_share')}
                                    className='flex items-center cursor-pointer'
                                >
                                    Share
                                    {sortOrder === 'asc' ? <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                    </span> : <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                    </span>}
                                </div>
                            </th>
                        }
                        {
                            reportData?.platform[0] === 1 &&
                            <th className="text-sm font-medium capitalize">
                                <div
                                    onClick={() => handleSort('avg_save')}
                                    className='flex items-center cursor-pointer'
                                >
                                    Save
                                    {sortOrder === 'asc' ? <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                    </span> : <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                    </span>}
                                </div>
                            </th>
                        }
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('total_eng')}
                                className='flex items-center cursor-pointer'
                            >
                                Total Eng
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_eng_rate')}
                                className='flex items-center cursor-pointer'
                            >
                                Eng Rate
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        {/* <th className="text-sm font-medium capitalize">CPE</th> */}
                        {/* <th className="text-sm font-medium capitalize">CPV</th> */}

                        {/* <th className="text-sm font-medium capitalize">Live On</th> */}
                        {
                            username?.name === 'Aakash Dubey' ? <></>
                                :
                                <th className="text-sm font-medium capitalize"></th>
                        }

                        <th className="text-sm font-medium capitalize"></th>
                    </tr>
                </thead>
                {
                    loading ?
                        <>
                            <TableSkeleton />
                        </>
                        : <tbody className="divide-y">
                            {
                                reportData &&
                                reportData?.profile.sort((a, b) => {
                                    if (sortColumn) {
                                        if (sortOrder === 'asc') {
                                            return a[sortColumn] > b[sortColumn] ? 1 : -1;
                                        } else {
                                            return a[sortColumn] < b[sortColumn] ? 1 : -1;
                                        }
                                    }
                                    return 0; // No sorting
                                }).map((item, index) => {

                                    return (
                                        <tr key={index} className="py-2 even:bg-gray-100 my-auto" >
                                            <td className="pl-2 flex items-center py-2">
                                                <div className="flex gap-2 items-center">

                                                    {getUsername(item?.username) !== undefined && (
                                                        <div className="w-14 h-14 rounded-full shadow-md border overflow-hidden">
                                                            <img
                                                                className="w-full h-full object-cover"
                                                                src={item?.profile_image || '/asset/onErrorImage.jpg'}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null || undefined; // prevents looping
                                                                    currentTarget.src = "/asset/onErrorImage.jpg";
                                                                }}
                                                                alt="img"
                                                            />
                                                        </div>
                                                    )}

                                                    {getUsername(item?.username) !== undefined && (
                                                        <div className="flex flex-col">
                                                            <div className="flex items-center gap-0.5">
                                                                <Link
                                                                    to={item?.profile_link}
                                                                    target="_blank"
                                                                    className="hover:text-blue-500 font-semibold"  // Use a suitable hover class here
                                                                >
                                                                    {getUsername(item?.username)}
                                                                </Link>
                                                                {
                                                                    item?.is_verified && <HiBadgeCheck className='text-primary-500' size={16} />
                                                                }
                                                            </div>

                                                            {
                                                                username?.name === 'Aakash Dubey' ? <></>
                                                                    :
                                                                    <div className="flex flex-col gap-2 mt-1">
                                                                        <div className="flex gap-2">
                                                                            <select onChange={handleSelectedCountry} className="text-sm h-10 rounded-sm border-gray-300">
                                                                                <option>Country</option>
                                                                                {
                                                                                    countryList.map((data, index) => (
                                                                                        <option key={index}>{data?.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>

                                                                            <select onChange={handleSelectedType} className="text-sm h-10 rounded-sm border-gray-300">
                                                                                <option>Type</option>
                                                                                {
                                                                                    type.map((data, index) => (
                                                                                        <option key={index}>{data}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>


                                                                        <select onChange={handleSelectedCategory} className="text-sm h-10 rounded-sm border-gray-300">
                                                                            <option>Category</option>
                                                                            {
                                                                                categoryList?.map((category, index) => (
                                                                                    <option key={index}>
                                                                                        {category?.name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        {/* <span className="px-1 py-0.5 rounded-sm text-xs bg-blue-100 text-gray-600 flex items-center"><IoLocation /> United States of America</span> */}
                                                                    </div>
                                                            }

                                                        </div>
                                                    )}

                                                </div>
                                            </td>
                                            <td className="pl-2">
                                                {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                    item?.user_posts
                                                )}
                                            </td>
                                            <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                item?.follower
                                            )}</td>
                                            <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                item?.user_avg_like
                                            )}</td>
                                            <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                item?.user_avg_comment
                                            )}</td>
                                            <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                item?.user_avg_view
                                            )}</td>
                                            {
                                                reportData?.platform[0] === 1 &&
                                                <td className="pl-2">{item?.avg_share}</td>
                                            }

                                            {
                                                reportData?.platform[0] === 1 &&
                                                <td className="pl-2">{item?.avg_save}</td>
                                            }
                                            <td className="pl-2">
                                                {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                    item?.total_eng
                                                )}
                                            </td>
                                            <td className="pl-2">
                                                {item?.user_eng_rate.toFixed(2)}%
                                                {/* {(item?.user_eng_rate.toString()).length <= 4 ? `${(item?.eng_rate.toString())}%` : `${(item?.eng_rate.toString()).slice(0, 4)}%`} */}
                                            </td>

                                            {
                                                username?.name === 'Aakash Dubey' ? <></>
                                                    :
                                                    <td className="pl-2">
                                                        <HiTrash onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, item?.username, 'archive')} className="text-xl cursor-pointer" />
                                                    </td>
                                            }

                                            {/* <td className="pl-2">{item?.cpe}</td> */}
                                            {/* <td className="pl-2">{item?.cpv}</td> */}

                                            {/* <td className="pl-2">
                                    {item?.post_time !== 0 && datefunc(item?.post_time)}
                                </td> */}
                                            {
                                                username?.name === 'Aakash Dubey' ? <></>
                                                    :
                                                    <td className="pl-2">
                                                        <button onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, item?.username, 'update')} className="px-2 py-1 border rounded-lg shadow-md bg-[#6d5eac] text-white">
                                                            Update Profile
                                                        </button>
                                                        {
                                                            item.is_live === 0 && <InfoIconTooltip bgColor="text-red-600" infoContent={"Post is not live/Invalid Post Link"} />
                                                        }
                                                    </td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody >
                }

            </table >
        </>
    );
};

export default ViralInfluencer;