import { SET_AUDIENCE_TYPE } from "../ActionTypes";

const initialState = [];

const AudienceTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUDIENCE_TYPE:
            return action.payload;
        default:
            return state;
    }
};

export default AudienceTypeReducer