"use client";

// import { Tooltip } from 'flowbite-react';
// import React, { useState, useEffect, useRef } from "react";
import React, { useState, useEffect } from "react";

import axiosInstance from "../../interseptor";
import FormData from "form-data";
import { Table, Badge, Dropdown, Button } from "flowbite-react";
import { HiBadgeCheck } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { createPortal } from "react-dom";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import TableRowSK from "../../components/skeleton/tableRowSK";
import { Router, useLocation, useNavigate, useParams } from "react-router-dom";
import SearchResultFilters from "../../components/SearchResultFilters/SearchResultFilters";
import PlanComponent from "../../components/PlanComponent/PlanComponent";
import constant from "../../constants/constant";
import ProfilePopup from "../../components/profile/ProfilePopup";
import "./resultPage.css";
import { Link, useSearchParams } from "react-router-dom";
import AddToList from "./AddtoList";
// import { Button } from "flowbite-react/lib/esm";
import {
  AiFillDelete,
  AiFillPlusCircle,
  AiOutlineContacts,
  AiOutlineDelete,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import {
  BsFillArrowLeftCircleFill,
  BsPlusCircle,
  BsTextIndentLeft,
} from "react-icons/bs";
import { RiGalleryFill, RiPriceTag3Line } from "react-icons/ri";
import DismissableModal from "./DismissableModal";
import LoadingSpinner from "./LoadingSpinner";
import {
  setAccountTypeFilter,
  setAgeFilter,
  setAllFilterOpen,
  setAudienceAgeFilter,
  setAudienceBrandFilter,
  setAudienceGenderFilter,
  setAudienceInterestFilter,
  setAudienceLanguageFilter,
  setAudienceLocationFilter,
  setAudienceLookALikeFilter,
  setAverageLikesFilter,
  setBioPhraseFilter,
  setContactFilter,
  setCreatorBrandFilter,
  setCreatorInterestFilter,
  setCreatorLanguageFilter,
  setCreatorLocationFilter,
  setCreatorLookALikeFilter,
  setCredibleAccountFilter,
  setEngAndEngRate,
  setEngRateFilter,
  setEthnicityFilter,
  setExcludeOfficialArtists,
  setExcludePrivateAccount,
  setFolloweGrowthFilter,
  setFollowerFilter,
  setGenderFilter,
  setHasAudienceData,
  setHasContactDetailsFilter,
  setHashTagFilter,
  setIsVerifiedFilter,
  setIsVpResigtered,
  setKeywordsFilter,
  setMentionsFilter,
  setMostRecentPostFilter,
  setNumberOfContentFilter,
  setPreviousBrandSponcorFilter,
  setPreviouslyExported,
  setReelViewFilter,
  setSponcoredPostFilter,
  setTopicsFilter,
} from "../../redux/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import SelectedFiltersValueShowing from "./SelectedFiltersValueShowing";
import { ToastContainer, toast } from "react-toastify";
import ErrorMsgModal from "../ErrorMsgModal";
import { decryptData } from "../../components/Auth/Encryption";
import { FaCloudDownloadAlt } from "react-icons/fa";
const GET_PLANS_URL = "/livesearch/planlist";
const VIEW_PLAN_URL = "/livesearch/viewplan";
// const ADD_TO_LIST_URL = "/livesearch/addtolist";

function ResultPage({selectedPlatform}) {
  // const [showMore, setShowMore] = useState(0);
  const location = useLocation();
  const [searchParam, setSearchParam] = useSearchParams();
  const [data, setData] = useState([]);

  const quaryPlatform = searchParam.get("platform");
  const quaryFollowers = searchParam.get("followers");
  const quaryAudienceGender = searchParam.get("audienceGender");
  const quaryCreatorGender = searchParam.get("creatorGender");
  const quaryCreatorAge = searchParam.get("creatorAge");
  const quaryCreatorLocation = searchParam.get("creatorLocation");
  const quaryKeywords = searchParam.get("keywords");
  const quaryTopics = searchParam.get("topicsNew");
  const quaryCreatorBrand = searchParam.get("creatorBrand");
  const quaryNumberOfContent = searchParam.get("numberOfContent");
  const quaryIsVpRegistered = searchParam.get("isVpResigtered");
  const quaryIsVerified = searchParam.get("isVerified");
  const quaryHasContentDetails = searchParam.get("hasContactDetails");
  const quaryMostRecentPost = searchParam.get("mostRecentPost");
  const quaryCreatorLanguage = searchParam.get("creatorLanguage");
  const quaryCreatorInterest = searchParam.get("creatorInterest");
  const quaryBioPhrase = searchParam.get("bioPhrase");
  const quaryAccountType = searchParam.get("accountType");
  const quaryHashtag = searchParam.get("hashtag");
  const quaryMentions = searchParam.get("mentions");
  const quaryAudienceAge = searchParam.get("audienceAge");
  const quaryAudienceLocation = searchParam.get("audienceLocation");
  const quaryAudienceLanguage = searchParam.get("audienceLanguage");
  const quaryAudienceInterest = searchParam.get("audienceInterest");
  const quaryAudienceBrand = searchParam.get("audienceBrand");
  const quaryAverageLikes = searchParam.get("averageLikes");
  const quaryEngRate = searchParam.get("engagementRate");
  const quaryEngagements = searchParam.get("engagements");
  const quaryEng_rate = searchParam.get("eng_rate");
  const quaryIsSponcoredPost = searchParam.get("isSponcoredPost");
  const quaryPreviousBrandSponcored = searchParam.get("previousBrandSponcored");
  const quaryReelViewsUrl = searchParam.get("reelViews");
  const quaryFollowerGrowth = searchParam.get("followerGrowth");
  const quaryAudienceLookALike = searchParam.get("audienceLookALike");
  const quaryCreatorLookALike = searchParam.get("creatorLookALike");
  const quaryCredibleAccount = searchParam.get("credibleAccount");
  const quaryPreviouslyExported = searchParam.get("previousExported");
  const quaryExcludePrivateAccounts = searchParam.get("excludePrivateAccounts");
  const quaryReelPlays = searchParam.get("reel_plays");
  const quaryAudienceType = searchParam.get("audienceType");
  const quaryContact = searchParam.get("contact");
  const quaryPartnership = searchParam.get("partnership");
  
  const queryParams = new URLSearchParams(location.search);
  const selectedPlatformUrl = queryParams.get("platform");

  const dispatch = useDispatch();

  const selectedData = useSelector((state) => ({
    followerAllFilters: state.follower,
    creatorGender: state.creatorGender,
    creatorAge: state.age,
    creatorLocation: state.creatorLocation,
    keywords: state.keywords,
    topicsNew: state.topics,
    creatorBrand: state.creatorBrand,
    numberOfContent: state.numberOfContent,
    isVpResigtered: state.isVpResigtered,
    isVerified: state.isVerified,
    hasContactDetails: state.hasContactDetails,
    mostRecentPost: state.mostRecentPost,
    creatorLanguage: state.creatorLanguage,
    creatorInterest: state.creatorInterest,
    bioPhrase: state.bioPhrase,
    accountType: state.accountType,
    hashtag: state.hashtag,
    mentions: state.mentions,
    audienceGenderRedux: state.audienceGender,
    audienceAge: state.audienceAge,
    audienceLocation: state.audienceLocation,
    audienceLanguage: state.audienceLanguage,
    audienceInterest: state.audienceInterest,
    audienceBrand: state.audienceBrand,
    averageLikes: state.averageLikes,
    engagementRateFilter: state.engagementRate,
    isSponcoredPost: state.isSponcoredPost,
    previousBrandSponcored: state.previousBrandSponcored,
    reelViews: state.reelViews,
    followerGrowth: state.followerGrowth,
    audienceLookALike: state.audienceLookALike,
    creatorLookALike: state.creatorLookALike,
    allFilterOpen: state.allFilterOpen,
    ethnicity: state.ethnicity,
    contact: state.contact,
    credibleAccount: state.credibleAccount,
    previousExported: state.previouslyExported,
    excludePrivateAccounts: state.excludePrivateAccounts,
    hasAudienceData: state.hasAudienceData,
    excludeOfficialArtists: state.excludeOfficialArtists,
    engAndEngrate: state.engAndEngrate,
    saves: state.saves,
    shares: state.shares,
    partnership: state.partnership,
  }));

  const {
    followerAllFilters,
    creatorGender,
    creatorAge,
    creatorLocation,
    keywords,
    topicsNew,
    creatorBrand,
    numberOfContent,
    isVpResigtered,
    isVerified,
    hasContactDetails,
    mostRecentPost,
    creatorLanguage,
    creatorInterest,
    bioPhrase,
    accountType,
    hashtag,
    mentions,
    audienceGenderRedux,
    audienceAge,
    audienceLocation,
    audienceLanguage,
    audienceInterest,
    audienceBrand,
    averageLikes,
    engagementRateFilter,
    isSponcoredPost,
    previousBrandSponcored,
    reelViews,
    followerGrowth,
    audienceLookALike,
    creatorLookALike,
    allFilterOpen,
    ethnicity,
    contact,
    credibleAccount,
    previousExported,
    excludePrivateAccounts,
    hasAudienceData,
    excludeOfficialArtists,
    engAndEngrate,
    saves,
    shares,
    partnership,
  } = selectedData;

  const objectToQueryString = (obj) => {
    return Object.keys(obj)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      )
      .join("&");
  };

  const queryStringData = objectToQueryString(selectedData);

  // console.log('audience gender checking', audienceGenderRedux)

  const topics = queryParams.get("filter") ? queryParams.get("filter") : "";

  // console.log('topics from home', topics)
  // let decodedTopics=topics
  // if(topics && topics!= undefined && topics.length>0){
  //   let decodeduri = decodeURIComponent(topics)
  //   if(decodeduri){
  //     decodedTopics =JSON.parse(decodeduri)
  //   }
  //   //  (decodeURIComponent(topics))
  // }

  let decodedTopics;
  if (topics && topics.length > 0) {
    let decodedUri = decodeURIComponent(topics);

    if (decodedUri) {
      try {
        decodedTopics = JSON.parse(decodedUri);
        // console.log('from url',decodedTopics)
        // dispatch(setGenderFilter(decodedTopics[0].CreatorGender));
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error appropriately
      }
    }
  }
  // console.log('1111111',decodedTopics)

  // Parse the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);

  const convertUrlParamToArray = (param) => {
    return param ? param.split(",") : [];
  };

  // Retrieve the individual parameters

  const platformUrl = convertUrlParamToArray(searchParams.get("platform"));
  const followersUrl = convertUrlParamToArray(searchParams.get("followers"));
  const creatorGenderUrl = convertUrlParamToArray(searchParams.get("creatorGender"));
  const creatorAgeUrl = convertUrlParamToArray(searchParams.get("creatorAge"));
  const creatorLocationUrl = convertUrlParamToArray(searchParams.get("creatorLocation"));
  const keywordsUrl = convertUrlParamToArray(searchParams.get("keywords"));
  const topicsNewUrl = convertUrlParamToArray(searchParams.get("topicsNew"));
  const creatorBrandUrl = convertUrlParamToArray(searchParams.get("creatorBrand"));
  const numberOfContentUrl = convertUrlParamToArray(searchParams.get("numberOfContent"));
  const mostRecentPostUrl = convertUrlParamToArray(searchParams.get("mostRecentPost"));
  const creatorLanguageUrl = convertUrlParamToArray(searchParams.get("creatorLanguage"));
  const creatorInterestUrl = convertUrlParamToArray(searchParams.get("creatorInterest"));
  const bioPhraseUrl = convertUrlParamToArray(searchParams.get("bioPhrase"));
  const accountTypeUrl = convertUrlParamToArray(searchParams.get("accountType"));
  const hashtagUrl = convertUrlParamToArray(searchParams.get("hashtag"));
  const mentionsUrl = convertUrlParamToArray(searchParams.get("mentions"));
  const audienceGenderReduxUrl = convertUrlParamToArray(searchParams.get("audienceGender"));
  const audienceAgeUrl = convertUrlParamToArray(searchParams.get("audienceAge"));
  const audienceLocationUrl = convertUrlParamToArray(searchParams.get("audienceLocation"));
  const audienceLanguageUrl = convertUrlParamToArray(searchParams.get("audienceLanguage"));
  const audienceInterestUrl = convertUrlParamToArray(searchParams.get("audienceInterest"));
  const audienceBrandUrl = convertUrlParamToArray(searchParams.get("audienceBrand"));
  const averageLikesUrl = convertUrlParamToArray(searchParams.get("averageLikes"));
  const previousBrandSponcoredUrl = convertUrlParamToArray(searchParams.get("previousBrandSponcored"));
  const reelViewsUrl = convertUrlParamToArray(searchParams.get("reelViews"));
  const reelPlaysUrl = convertUrlParamToArray(searchParams.get("reel_plays"));
  const savesUrl = convertUrlParamToArray(searchParams.get("saves"));
  const sharesUrl = convertUrlParamToArray(searchParams.get("shares"));
  const followerGrowthUrl = convertUrlParamToArray(searchParams.get("followerGrowth"));
  const audienceLookALikeUrl = convertUrlParamToArray(searchParams.get("audienceLookALike"));
  const creatorLookALikeUrl = convertUrlParamToArray(searchParams.get("creatorLookALike"));
  const isVpResigteredUrl = searchParams.get("isVpResigtered") === "true";
  const isVerifiedUrl = searchParams.get("isVerified");
  const isCredibleAccountUrl = searchParams.get("credibleAccount");
  const isPreviousExportedUrl = searchParams.get("previousExported");
  const isExcludePrivateAccounts = searchParams.get("excludePrivateAccounts");
  const isExcludeOfficialArtistsUrl = searchParams.get("excludeOfficialArtists");
  const isAudienceData = searchParams.get("hasAudienceData");
  const isEthnicityUrl = searchParams.get("ethnicity");
  const isContactUrl = searchParams.get("contact");
  const isLastPostUrl = searchParams.get("lastPost");
  const isAudienceTypeUrl = searchParams.get("audienceType");
  // const engagementRateUrl = convertUrlParamToArray(searchParams.get("engagementRate"));
  const engAndEngrateUrl = searchParams.get("eng_rate");
  const engagementsUrl = searchParams.get("engagements");
  const partnershipUrl = searchParams.get("partnership");
  const hasContactDetailsUrl = searchParams.get("hasContactDetails") === "true";
  const isSponcoredPostUrl = searchParams.get("isSponcoredPost") === "true";

  const mapUrlParamsToNames = (urlParams) => {
    return urlParams.map((item) => {
      const parts = item.split("-k-");
      return parts.length === 2 ? parts[1] : null;
    });
  };

  const creatorLocationNames = mapUrlParamsToNames(creatorLocationUrl);
  const audienceLocationNames = mapUrlParamsToNames(audienceLocationUrl);
  const creatorBrandNames = mapUrlParamsToNames(creatorBrandUrl);
  const audienceBrandNames = mapUrlParamsToNames(audienceBrandUrl);
  const creatorLanguageNames = mapUrlParamsToNames(creatorLanguageUrl);
  const audienceLanguageNames = mapUrlParamsToNames(audienceLanguageUrl);
  const previousBrandSponsorNames = mapUrlParamsToNames(
    previousBrandSponcoredUrl
  );

  // const encodedArray = encodeURIComponent(JSON.stringify(creatorLocationUrl));

  const [filters, setFilters] = useState(decodedTopics);
  const [isHide, setIsHide] = useState({});
  const categories = constant.catagories;
  const [show, setShow] = useState(false);
  const categoryMapping = {};
  const [final, setFinal] = useState([]);
  const [noDataFound, setNoDataFound] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  categories?.forEach((category) => {
    categoryMapping[category.id] = category.name;
  });

  const categoriesIcon = constant.catagories;
  const categoryMappingIcon = {};

  categoriesIcon.forEach((category) => {
    categoryMappingIcon[category.id] = category.icon;
  });

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [planList, setPlanList] = useState(false);
  const [lists, setLists] = useState();

  // const [sorting, setSorting] = useState({
  //     'followers': true,
  //     'likes': true,
  //     'views': true,
  //     'eng': true,
  // })
  const [sorting, setSorting] = useState({
    followers: "",
    likes: "",
    views: "",
    eng: "",
    engrate: "",
  });

  const [priorityCategory, setPriorityCategory] = useState([]);

  const searchkey = queryParams.get("s") ? queryParams.get("s") : "";

  const platform = queryParams.get("platform")
    ? parseInt(queryParams.get("platform"))
    : 1;
  const p = queryParams.get("p") ? parseInt(queryParams.get("p")) : "0";

  let category = queryParams.get("categories[]")
    ? queryParams.get("categories[]")?.split(",") || []
    : 0;
  console.log("checking url category", typeof category);
  const genderUrl = queryParams.get("gender");

  const [selectedGender, setselectedGender] = useState("");
  const [minFollowers, setminFollowers] = useState("");
  const [maxFollowers, setmaxFollowers] = useState("");
  const [cities, setCities] = useState([]);
  const [advanced, setAdvanced] = useState({});
  const [filterData, setFilterData] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const [plans, setPlans] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [openNewPlanInput, setOpenNewPlanInput] = useState(false);
  const [isPlanmodalopen, setIsPlanmodalopen] = useState(false);
  const [curListId, setCurListId] = useState();

  const [infName, setInfName] = useState("");
  const [socialName, setSocialName] = useState("");
  const [sidebar, setSidebar] = useState(true);
  const [selectCheckbox, setSelectCheckbox] = useState(0);
  const [selectedInfluencers, setSelectedInfluencers] = useState([]);
  const [dataFromChild, setDataFromChild] = useState("");
  const [clearChildData, setClearChildDada] = useState(null);
  const [selectedChildFilter, setSelectedChildFilter] = useState([]);
  const [isProfileDataAvailable, setIsProfileDataAvailable] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsgFromAPI, setErrorMsgFromAPI] = useState("");
  const contactIcon = <AiOutlineContacts size="28" />;
  const priceIcon = <RiPriceTag3Line size="24" />;

  const currentUrl = location.pathname + location.search;

  // const location = useLocation()

  // Callback function to receive data from the child
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  
  const handleCheckBox = (social_name) => {
    if (
      selectedInfluencers.some(
        (influencer) => influencer.social_name === social_name.social_name
      )
    ) {
      // If the social name already exists in the array, remove it
      setSelectedInfluencers((prev) =>
        prev.filter(
          (influencer) => influencer.social_name !== social_name.social_name
        )
      );
    } else {
      // If the social name doesn't exist in the array, add it
      const influencers = [...selectedInfluencers];
      influencers.push(social_name);
      setSelectedInfluencers(influencers);
    }
  };

  const handleMore = (social_name) => {
    setIsHide((prevState) => ({
      ...prevState,
      [social_name]: !prevState[social_name],
    }));
  };

  const fetchData1 = () => {
    // alert()
    // navigate(`${currentUrl}&filter1=abc`)
    fetchData(page);
  };

  const handleSort = (e) => {
    // alert("sadasd")
    const sort_name = e.target.id;

    if (
      sort_name !== "followers" &&
      sort_name !== "likes" &&
      sort_name !== "views" &&
      sort_name !== "eng" &&
      sort_name !== "engrate"
    ) {
      return;
    }

    setSorting((prev) => {
      const updatedSorting = {
        followers: "",
        likes: "",
        views: "",
        eng: "",
        engrate: "",
      };
      updatedSorting[sort_name] = prev[sort_name] === "ASC" ? "DESC" : "ASC";

      return updatedSorting;
    });
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   // getPlans();

  //   let isFiltered = 0;
  //   if (filtered) {
  //     isFiltered = 1;
  //   }

  //   if (filters != null) {
  //     // console.log("isFiltered not null" ,filterData)
  //     fetchData(
  //       0,
  //       filterData?.selectedGender?.id,
  //       filterData?.selectedFollowerRange?.minFollowers,
  //       filterData?.selectedFollowerRange?.maxFollowers,
  //       isFiltered,
  //       filterData?.selectCatagory?.checkedValues,
  //       filterData?.selectedAgeRange
  //     )
  //       .then((responseData) => {
  //         setIsLoading(false);
  //         setData(responseData.data);
  //       })
  //       .catch((error) => {
  //         console.log(`Error loading data: ${error.message}`);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     fetchData(0) // Fetch data for the initial page (page 0)
  //       .then((responseData) => {
  //         setPage(1);
  //         setIsLoading(false);
  //         setData(responseData.data);
  //       })
  //       .catch((error) => {
  //         console.log(`Error loading data: ${error.message}`);
  //         setIsLoading(false);
  //       });
  //   }
  // }, [filterData, sorting]);

  const getPlans = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "planlist");
      data.append("device_id", "search");
      data.append("status", "1");

      const response = await axiosInstance.post(GET_PLANS_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          return false;
        }

        setPlans(response.data.list);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getList = async (val) => {
    // const plan_id = val
    setPlanId(val);
    setOpenNewPlanInput(false);
    // setCurplanname(val)
    try {
      let data = new FormData();
      data.append("logintype", "viewplan");
      data.append("device_id", "search");
      data.append("planid", val);
      data.append("status", "1");

      const response = await axiosInstance.post(VIEW_PLAN_URL, data);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          return false;
        }

        setLists(response.data.list.list_details);
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCurList = (list_id) => {
    // setCurlistname(list_name)
    setCurListId(list_id);
  };

  const HandelFilter = (data) => {
    setFilterData(data);
    setminFollowers(filterData?.selectedFollowerRange?.minFollowers);
    setmaxFollowers(filterData?.selectedFollowerRange?.maxFollowers);
    setselectedGender(filterData?.selectedGender);
    setCities(filterData?.cities);
    setAdvanced(data);
    setFiltered(true);
  };

  const removeFinalFilters = () => {
    setFilterData(null);
    setminFollowers(null);
    setmaxFollowers(null);
    setselectedGender(null);
    setCities(null);
    setAdvanced(null);
    setFiltered(false);
    var queryParams = new URLSearchParams(location.pathname + location.search);
    // navigate('/')
  };
  
  const fetchData = async (page, selectedGender, minFollowers, maxFollowers, filter = 0, catog = category, age = { minAge: "", maxAge: "" }) => {
   
    var formData = new FormData();

    formData.append("s", searchkey);
    formData.append("page", page);
    formData.append("f", filter);
    formData.append("logintype", "searchlist");
    formData.append("device_id", "search");
    formData.append("count", "0");
    // formData.append("age_from", age?.minAge);
    // formData.append("age_to", age?.maxAge);
    formData.append("platform", platformUrl || 'Instagram');
    formData.append("vpverified", "0");
    formData.append("p", p);
    formData.append("sortbysubs", sorting?.followers);
    formData.append("minlike", advanced?.MinAvgLikes || "");
    formData.append("maxlike", advanced?.MaxAvgLikes || "");
    formData.append("mineng", advanced?.MinEngRate || "");
    formData.append("maxeng", advanced?.MaxEngRate || "");
    formData.append("minviews_long", advanced?.MinAvgViews || "");
    formData.append("maxviews_long", advanced?.MaxAvgViews || "");
    formData.append("sortbyavgview_long", sorting?.views);
    formData.append("sortby_eng", sorting?.eng);
    formData.append("sortby_engrate", sorting?.engrate);
    formData.append("sortby_like", sorting?.likes);
    formData.append("verifiedig", "");
    formData.append("unknownage", "");
    formData.append("category", catog);

    formData.append("followers", followersUrl || "");
    formData.append("creator_language", creatorLanguageUrl || "");
    formData.append("audience_language", audienceLanguageUrl || "");
    formData.append("topic", topicsNewUrl || "");
    formData.append("creator_brand_affinity", creatorBrandUrl || "");
    formData.append("number_of_content", numberOfContentUrl || "");
    formData.append("partnership", partnershipUrl || "");
    formData.append("creator_gender", creatorGenderUrl || "");
    formData.append("creator_age", creatorAgeUrl || "");
    formData.append("audience_age", audienceAgeUrl || "");
    formData.append("is_Vp_Verified", isVpResigteredUrl || false);
    formData.append("is_verified", isVerifiedUrl || false);
    formData.append("credible_account", isCredibleAccountUrl || false);
    formData.append("exclude_private_accounts", isExcludePrivateAccounts || false);
    formData.append("exclude_official_artists", isExcludeOfficialArtistsUrl || false);
    formData.append("has_audience_data", isAudienceData || false);
    formData.append("previous_exported", isPreviousExportedUrl || false);
    formData.append("has_contact_details", hasContactDetailsUrl || false);
    formData.append("most_recent_post", mostRecentPostUrl || "");
    formData.append("ethnicity", isEthnicityUrl || "");
    formData.append("contact", isContactUrl || "");
    formData.append("audience_type", isAudienceTypeUrl || "");
    formData.append("last_post", isLastPostUrl || "");
    formData.append("saves", savesUrl || "");
    formData.append("shares", sharesUrl || "");
    formData.append("creator_interest", creatorInterestUrl || "");
    formData.append("creator_location", creatorLocationUrl || "");
    formData.append("audience_location", audienceLocationUrl || "");
    formData.append("bio_phrase", bioPhraseUrl || "");
    formData.append("account_type", accountTypeUrl || "");
    formData.append("keyword", keywordsUrl || "");
    formData.append("mentions", mentionsUrl || "");
    formData.append("hashtags", hashtagUrl || "");
    formData.append("audience_gender", audienceGenderReduxUrl || "");
    formData.append("audience_brand_affinity", audienceBrandUrl || "");
    formData.append("audience_interest_affinity", audienceInterestUrl || "");
    formData.append("average_likes", averageLikesUrl || "");
    formData.append("engagement_rate", engAndEngrateUrl || "");
    // formData.append("engagement_rate", engagementRateUrl || "");
    formData.append("engagements", engagementsUrl || "");
    formData.append("has_sponsored_posts", isSponcoredPostUrl || false);
    formData.append("previous_brand_sponsors", previousBrandSponcoredUrl || "");
    formData.append("reel_view", reelViewsUrl || "");
    formData.append("reel_plays", reelPlaysUrl || "");
    formData.append("followers_growth", followerGrowthUrl || "");
    formData.append("audience_lookalike", audienceLookALikeUrl || "");
    formData.append("creator_lookalike", creatorLookALikeUrl || "");

    console.log("fetch hit")
    try {
      const response = await axiosInstance.post(`livesearch/searchlist`, formData);
      
      if (response?.data?.list?.data) {
        setTotalData(response?.data?.list?.data[0]?.totalprofile);
      }

      console.log(response?.data?.list?.data, 'response?.data?.list?.data')

      if (response?.data?.status === 0) {
        setShowErrorMsg(true);
        setErrorMsgFromAPI(response?.data?.msg);
        // toast.error(response?.data?.msg, {
        //   pauseOnHover: false,
        //   closeOnClick: false  // Set closeOnClick to false
        // });
        return;
      }

      if (response?.data?.list?.data?.length === 0) {
        setIsProfileDataAvailable(true);
      }

      // setTotalData(response?.data?.list?.totalprofile);
      if (response?.data?.list?.data?.length < 10) {
        setIsEnd(true);
      } else setIsEnd(false);
      return { page: page + 1, data: response?.data?.list?.data }; // Return the updated page value and fetched data
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleScroll = function () {
    setLoadingMore(true);

    let isFiltered = 0;
    if (filtered) {
      isFiltered = 1;
    }

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = document.documentElement.clientHeight;
    const documentHeight = document.documentElement.offsetHeight;
    const top_cl = Math.ceil(scrollTop + windowHeight);
    if (top_cl >= documentHeight && isEnd === false) {
      // alert()
      // User has scrolled to the bottom, load next data
      fetchData(
        page,
        filterData?.selectedGender?.id,
        filterData?.selectedFollowerRange?.minFollowers,
        filterData?.selectedFollowerRange?.maxFollowers,
        isFiltered,
        filterData?.selectCatagory?.checkedValues,
        filterData?.selectedAgeRange
      )
        .then(({ page: nextPage, data: responseData }) => {
          setPage(nextPage);
          // const existingKeys = data?.map((item) => item.social_name);  //use this if different page may have previos page data (duplicate data)
          // const newData = responseData.filter(
          //   (item) => !existingKeys.includes(item.social_name)
          // );
          const newData = responseData;

          setData((prevData) => [...prevData, ...newData]);
          setLoadingMore((prev) => {
            if (prev == 10) {
              return false;
            }
          });
        })
        .catch((error) => {
          console.log(`Error loading data for page ${page}: ${error.message}`);
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);

    fetchData(0) // Fetch data for the initial page (page 0)
      .then((responseData) => {
        console.log(responseData.data, 'updated data checking')
        setPage(1);
        setIsLoading(false);
        setData(responseData?.data);
      })
      .catch((error) => {
        console.log(`Error loading data: ${error.message}`);
        setIsLoading(false);
      });
  }, [
    quaryPlatform,
    quaryFollowers,
    quaryAudienceGender,
    quaryAudienceLanguage,
    quaryCreatorLanguage,
    quaryCreatorGender,
    quaryCreatorAge,
    quaryCreatorLocation,
    quaryKeywords,
    quaryTopics,
    quaryCreatorBrand,
    quaryNumberOfContent,
    quaryHasContentDetails,
    quaryMostRecentPost,
    quaryCreatorInterest,
    quaryBioPhrase,
    // quaryAccountType,
    quaryHashtag,
    quaryMentions,
    quaryAudienceAge,
    quaryAudienceLocation,
    // quaryAudienceLanguage,
    quaryAudienceInterest,
    quaryAudienceBrand,
    quaryAverageLikes,
    quaryEngRate,
    quaryIsSponcoredPost,
    quaryPreviousBrandSponcored,
    quaryReelViewsUrl,
    quaryFollowerGrowth,
    quaryAudienceLookALike,
    quaryCreatorLookALike,
    quaryCredibleAccount,
    quaryIsVerified,
    quaryPreviouslyExported,
    quaryExcludePrivateAccounts,
    hasAudienceData,
    excludeOfficialArtists,
    ethnicity,
    // engAndEngrate,
    quaryEng_rate,
    quaryEngagements,
    sorting,
    // partnershipUrl
    quaryReelPlays,
    quaryAccountType,
    quaryAudienceType,
    quaryContact,
    quaryPartnership
  ]);

  useEffect(() => {
    rendertable(data);
    console.log("called new data")
  }, [data]);

  useEffect(() => {
    rendertable(data);
  }, [selectedInfluencers]);

  useEffect(() => {
    rendertable(data);
  }, [isHide]);

  const clearCheckedInfluencer = () => {
    setSelectedInfluencers([]);
  };

  const closeprofilepopup = () => {
    setShow(false);
  };

  const openPlanModal = (social_name) => {
    getPlans();
    if (social_name) {
      Array.isArray(social_name)
        ? setSelectedInfluencers([...social_name])
        : setSelectedInfluencers([social_name]);
    }
    setIsPlanmodalopen(true);
  };

  const closePlanModal = () => {
    setIsPlanmodalopen(false);
    setPlanId("");
    setCurListId("");
  };

  const openPreview = (social_name) => {
    setSocialName(social_name);
    setShow(true);
  };

  const handleExportSearchResult = () => {
    const filteredData = data.map((item) => ({
      social_name: item.social_name,
      social_user_name: item.social_user_name,
      followers: item.followers,
      avg_like: item.avg_like,
      avg_views: item.avg_views,
      engagement_rate: item.engagement_rate,
      age: item.age,
      avg_comment: item.avg_comment,
      gender: item.gender,
      is_verified: item.is_verified,
    }));

    // Convert to CSV format
    const csvHeader = "Social Name,Social User Name,Followers,Avg Like,Avg Views,Engagement Rate,Age,Avg Comment,Gender,Verified Account\n";
    const csvRows = filteredData
      .map((item) =>
        `${item.social_name},${item.social_user_name},${item.followers},${item.avg_like},${item.avg_views},${item.engagement_rate},${item.age},${item.avg_comment},${item.gender},${item.is_verified}`
      )
      .join("\n");

    const csvData = csvHeader + csvRows;

    // Create a blob and trigger download
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "search_results.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  const rendertable = function (data) {
    if (!Array.isArray(data)) {
      setFinal(
        <tr>
          <td colSpan={5} className="text-center py-5">No Data Found</td>
        </tr>
      );
      setNoDataFound(false)
      return null; // Return early if data is not an array
    }else{
      setNoDataFound(true)
    }

    const updatedFinal = data?.map((filteredinfluencer, index) => (
      <>
        <tr
          key={`${filteredinfluencer?.social_name}`}
          className=" hover:bg-[#EEF5FF] text-sm h-24 border text-black"
        >
          <td className="py-2">
            <div className="flex items-center whitespace-nowrap font-bold text-align-left">
              {JSON.parse(decryptData(localStorage.getItem("rights_action")))[
                "73"
              ].includes("119") && (
                <label className="container1 flex items-center pl-1">
                  <input
                    type="checkbox"
                    key={filteredinfluencer?.social_name}
                    checked={selectedInfluencers.some(
                      (influencer) =>
                        influencer.social_user_name ===
                        filteredinfluencer?.social_user_name
                    )}
                    onChange={() => handleCheckBox(filteredinfluencer)}
                    className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                  />
                  <div className="checkmark"></div>
                </label>
              )}
              <div className="w-12 h-12 ml-2">
                <img
                  alt=""
                  className="!rounded-full w-full h-full"
                  // src={`https://storage.googleapis.com/viralimage/${filteredinfluencer.im}`}
                  src={`${filteredinfluencer?.im}`}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null || undefined; // prevents looping
                    currentTarget.src = "/asset/onErrorImage.jpg";
                  }}
                />
              </div>

              <div className="pl-2 font-medium">
                <div className="flex items-center ">
                  {filteredinfluencer?.social_user_name?.slice(0, 20)}
                  {filteredinfluencer?.is_verified === true && (
                    <span>
                      <HiBadgeCheck className="text-primary-500" size={18} />
                    </span>
                  )}
                </div>
                <a
                  href={
                    selectedPlatformUrl === "TikTok"
                      ? "https://tiikok.com/@" + filteredinfluencer?.social_name
                      : selectedPlatformUrl === "Youtube"
                      ? "https://www.youtube.com/channel/" +
                        filteredinfluencer?.social_name
                      : "https://instagram.com/" +
                        filteredinfluencer?.social_name
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-sm ">
                    @{filteredinfluencer?.social_name}
                  </div>
                </a>
              </div>
            </div>
          </td>
          <td className=" px-4 py-2">
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(filteredinfluencer?.followers)}
          </td>
          {/* {filteredinfluencer.age == 0 ? (
              <td>-</td>
            ) : (
              <td>{filteredinfluencer.age}</td>
            )}
            {filteredinfluencer.gender == 0 ? (
              <td>-</td>
            ) : (
              <td>{filteredinfluencer.gender}</td>
            )} */}

          <td className=" px-4 py-2">
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(parseInt(filteredinfluencer?.avg_like))}
          </td>

          {/* <td className=" px-4 py-2">
              {Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1,
              }).format(parseInt(filteredinfluencer.avg_views))}
            </td> */}

          <td className=" px-4 py-2">
            {Math.round(filteredinfluencer?.engagement_rate * 100) / 100}%
          </td>

          {reelViewsUrl?.length > 0 && (
            <td className=" px-4 py-2">
              {Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 1,
              }).format(filteredinfluencer?.avg_views)}
            </td>
          )}

          {/* <td className="px-4 py-2">
              {isHide[filteredinfluencer.social_name] == undefined || isHide[filteredinfluencer.social_name] == false ? (
                <>
                  {filteredinfluencer?.profile_category && filteredinfluencer.profile_category.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {filteredinfluencer.profile_category.length > 2 ? (
                        <>
                          {uniq(filteredinfluencer.profile_category)
                            .slice(0, 2)
                            ?.map((category, index) => (
                              categoryMapping[category] && (
                                <div className='flex' key={index}>
                                  <Badge
                                    color="info"
                                    className="capitalize whitespace-nowrap"
                                  >
                                    {categoryMappingIcon[category]}
                                    {categoryMapping[category]}
                                  </Badge>
                                </div>
                              )
                            ))}
                          <Badge
                            color="info"
                            className="capitalize whitespace-nowrap cursor-pointer"
                            onClick={() =>
                              handleMore(filteredinfluencer.social_name)
                            }
                            title='Check More Categories'
                          >
                            +{filteredinfluencer.profile_category.length - 2} More
                          </Badge>
                        </>
                      ) : (
                        <>
                          {uniq(filteredinfluencer.profile_category)
                            ?.map((category, index) => (
                              categoryMapping[category] && (
                                <div className='flex' key={index}>
                                  <Badge
                                    color="info"
                                    className="capitalize whitespace-nowrap"
                                  >
                                    {categoryMappingIcon[category]}
                                    {categoryMapping[category]}
                                  </Badge>
                                </div>
                              )
                            ))}
                        </>
                      )}
                    </div>
                  ) : (
                    <span>Uncategorized</span>
                  )}
                </>
              ) : (
                <>
                  {filteredinfluencer?.profile_category && filteredinfluencer.profile_category.length > 0 ? (
                    <div className="flex flex-wrap gap-2">
                      {filteredinfluencer.profile_category.length > 2 ? (
                        <>
                          {uniq(filteredinfluencer.profile_category)
                            ?.map((category, index) => (
                              categoryMapping[category] && (
                                <div className='flex' key={index}>
                                  <Badge
                                    color="info"
                                    className="capitalize whitespace-nowrap"
                                  >
                                    {categoryMappingIcon[category]}
                                    {categoryMapping[category]}
                                  </Badge>
                                </div>
                              )
                            ))}
                          <Badge
                            color="info"
                            className="capitalize whitespace-nowrap cursor-pointer"
                            onClick={() => handleMore(filteredinfluencer.social_name)}
                            title='Show Less Categories'
                          >
                            Show Less
                          </Badge>
                        </>
                      ) : (
                        <>
                          {uniq(filteredinfluencer.profile_category)
                            ?.map((category, index) => (
                              categoryMapping[category] && (
                                <div className='flex' key={index}>
                                  <Badge
                                    color="info"
                                    className="capitalize whitespace-nowrap"
                                  >
                                    {categoryMappingIcon[category]}
                                    {categoryMapping[category]}
                                  </Badge>
                                </div>
                              )
                            ))}
                        </>
                      )}
                    </div>
                  ) : (
                    <span>Uncategorized</span>
                  )}
                </>
              )}
            </td> */}

          <td className="py-2 flex justify-center">
            <div className="flex text-xl gap-1 p-2">
              <div className="flex flex-col">
                <div className=" py-1 flex gap-0 rounded-md">
                  {JSON.parse(
                    decryptData(localStorage.getItem("rights_action"))
                  )["73"].includes("120") && (
                    <div
                      onClick={() => openPlanModal(filteredinfluencer)}
                      className="group relative px-2 cursor-pointer"
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                        <BsPlusCircle size="24" />
                      </div>
                      <span className="w-[90px] absolute -top-8 left-[50%] -translate-x-[50%] whitespace-nowrap z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                        Add to List
                      </span>
                    </div>
                  )}

                  {JSON.parse(
                    decryptData(localStorage.getItem("rights_action"))
                  )["73"].includes("119") && (
                    <Link
                      to={`/profile/?id=${
                        filteredinfluencer?.social_name
                      }&platformUrl=${encodeURIComponent(selectedPlatformUrl)}`}
                    >
                      <div className="group relative px-2 cursor-pointer">
                        <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            height="24"
                            width="24"
                          >
                            <path
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                            ></path>
                          </svg>
                        </div>
                        <span className="absolute -top-8 left-[50%] -translate-x-[50%] z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                          Profile
                        </span>
                      </div>
                    </Link>
                  )}
                  {/* <a
                      href={"https://instagram.com/" + filteredinfluencer.social_name}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="group relative px-2 cursor-pointer">
                        <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24">
                            <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="currentColor" d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"></path>
                          </svg>
                        </div>
                        <span className="absolute -top-8 left-[50%] -translate-x-[50%] z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                          Messages
                        </span>
                      </div>
                    </a> */}

                  {/* <div onClick={() => openPlanModal(filteredinfluencer.social_name)} className="group relative px-2 cursor-pointer">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full hover:text-blue-500">
                        <RiPriceTag3Line size="24" />
                      </div>
                      <span className="w-[100px] absolute -top-8 left-[50%] -translate-x-[50%] whitespace-nowrap z-20 origin-left scale-0 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium shadow-md transition-all duration-300 ease-in-out group-hover:scale-100">
                        View Pricing
                      </span>
                    </div> */}

                  {/* <DismissableModal name="View Pricing" logo={priceIcon} title="Pricing Details" contactdetail="No Pricing Details Found" heading="We apologize, but there are no pricing details available at the moment." /> */}

                  {/* <DismissableModal name="View Contact" logo={contactIcon} title="Contact Details" contactdetail="No Contact Details Found" heading="We&apos;re sorry, but there are no contact details available at the moment." /> */}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </>
    ));
    setFinal(updatedFinal);
  };

  return (
    <>
      {/* <ToastContainer autoClose={60000} /> */}
      {showErrorMsg && <ErrorMsgModal errorMsgFromAPI={errorMsgFromAPI} />}
      <section
        onClick={() => {
          allFilterOpen && dispatch(setAllFilterOpen(false));
        }}
        className={`flex ${show == true ? "fixed" : "relative"}`}
      >
        <div className={`${sidebar ? "w-[100%]" : "w-[100%]"} min-h-screen`}>
          <div className="flex flex-col fixed w-full z-10 bg-white pb-2 top-0 justify-between ">
            <div className="flex">
              {/* <SearchResultFilters
                sendToResultPage={sendToResultPage}
                FinalfilterData={HandelFilter}
                filterData={filterData}
                checkSelectedFilter={setSelectedChildFilter}
                onDataFromChild={handleDataFromChild}
                removeFinalFilters={removeFinalFilters}
                fetchData1={fetchData1}
                selectedPlatform={selectedPlatformUrl}
                // category={category}
                // clearAge={clearAge}
              /> */}

              {Object.keys(selectedInfluencers).length > 0 && (
                <div className="w-[350px] h-[38px] mt-3.5 rounded-lg shadow-md bg-[#6d5eac] text-slate-50 px-1.5 z-0 flex items-center justify-between">
                  <div>
                    <h1 className="text-sm">
                      {" "}
                      <span className="text-base text-[#f7cf5f] font-extrabold px-1">
                        {selectedInfluencers?.length}
                      </span>{" "}
                      influencer selected
                    </h1>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={clearCheckedInfluencer}
                      className="border shadow-sm hover:scale-105 duration-100 py-0.5 px-1 rounded-md text-sm"
                    >
                      Deselect All
                    </button>
                    <button
                      onClick={() => openPlanModal(selectedInfluencers)}
                      className="border shadow-sm hover:scale-105 duration-100 py-0.5 px-1 rounded-md text-sm border-[#f7cf5f] bg-[#f7cf5f] text-slate-900"
                    >
                      Add to list
                    </button>
                  </div>
                </div>
              )}
            </div>

          </div>

          {isLoading ? (
            <TableRowSK total={10} /> // Display loading state while data is being fetched
          ) : (
            <section
              className={`antialiased overflow-y-hidden w-full`}
            >
              
              <div className="flex items-center justify-between mb-2">
              <h5 className="text-sm pl-3 pb-2">
                  Showing 1-{data?.length} of {totalData} results
              </h5>

              <button 
                onClick={handleExportSearchResult}
                className="border flex items-center gap-1 rounded-lg px-3 py-2 bg-black text-white text-sm">
                <FaCloudDownloadAlt /> Export search results
              </button>

              </div>
              <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="w-full">
                  <div className="overflow-x-auto">
                    <table className=" w-full rounded-[8px] table-auto border-collapse">
                      <thead
                        className="static top-0 border  bg-[#EEF5FF]  text-[#828282] whitespace-nowrap"
                        onClick={handleSort}
                      >
                        <tr className="h-14">
                          <th className="capitalize font-medium text-sm p-3">
                            <div id="insta" className="flex">
                              {selectedPlatform === "Instagram" &&
                                "Instagram Profiles"}
                              {selectedPlatform === "Youtube" &&
                                "Youtube Profiles"}
                              {selectedPlatform === "TikTok" &&
                                "TikTok Profiles"}
                            </div>
                          </th>

                          <th className="cursor-pointer capitalize font-medium text-sm p-3">
                            <div id="followers" className="flex">
                              Followers
                              {sorting?.followers === "ASC" ? (
                                <BiSortDown size={17} id="followers" />
                              ) : (
                                <BiSortUp size={17} id="followers" />
                              )}
                            </div>
                          </th>

                          {/* <th className='cursor-pointer capitalize font-medium text-sm p-3'>
                            <div id="age" className="flex">
                              Age
                            </div>
                          </th>

                          <th className='cursor-pointer capitalize font-medium text-sm p-3'>
                            <div id="gender" className="flex">
                              Gender
                            </div>
                          </th> */}

                          <th className="cursor-pointer capitalize font-medium text-sm p-3">
                            <div id="eng" className="flex">
                              Engagement
                              {sorting?.eng === "ASC" ? (
                                <BiSortDown size={17} id="eng" />
                              ) : (
                                <BiSortUp size={17} id="eng" />
                              )}
                            </div>
                          </th>

                          {/* <th className='cursor-pointer capitalize font-medium text-sm p-3'>
                            <div id="views" className="flex">
                              Avg Views
                              {sorting?.views === "ASC" ? (
                                <BiSortDown size={17} id="views" />
                              ) : (
                                <BiSortUp size={17} id="views" />
                              )}
                            </div>
                          </th> */}

                          <th className="cursor-pointer capitalize font-medium text-sm p-3">
                            <div id="engrate" className="flex">
                              Engagement Rate
                              {sorting?.engrate === "ASC" ? (
                                <BiSortDown size={17} id="engrate" />
                              ) : (
                                <BiSortUp size={17} id="engrate" />
                              )}
                            </div>
                          </th>

                          {reelViewsUrl?.length > 0 &&
                            (selectedPlatformUrl === "Instagram" ? (
                              <th className="cursor-pointer capitalize font-medium text-sm p-3">
                                <div id="eng" className="flex">
                                  Reel Views
                                </div>
                              </th>
                            ) : selectedPlatformUrl === "Youtube" ? (
                              <th className="cursor-pointer capitalize font-medium text-sm p-3">
                                <div id="eng" className="flex">
                                  Views
                                </div>
                              </th>
                            ) : (
                              <th className="cursor-pointer capitalize font-medium text-sm p-3">
                                <div id="eng" className="flex">
                                  Average Views
                                </div>
                              </th>
                            ))}

                          {/* <th className='cursor-pointer capitalize font-medium text-sm p-3'>
                            Category
                          </th> */}

                          <th className="cursor-pointer capitalize font-medium text-sm p-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>{final}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          )}

          {isProfileDataAvailable ? (
            <div className="text-center flex justify-center">
              <p className="px-6 py-1 rounded-md text-black bg-[#f7cf5f]">
                No results found. Try adjusting search parameters.
              </p>
            </div>
          ) : isEnd ? (
            <div className="text-center flex justify-center">
              <p className="px-6 py-1 rounded-md text-black bg-[#f7cf5f]">
                End Result
              </p>
            </div>
          ) : noDataFound && (
            <div className="flex justify-center my-5">
              {loadingMore ? (
                <button className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                  <LoadingSpinner /> Loading
                </button>
              ) : (
                <button
                  className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg"
                  onClick={handleScroll}
                >
                  Load more
                </button>
              )}
            </div>
          )}
          
        </div>

        <div
          className={`${sidebar ? "relative" : "w-[400px] relative mt-6"}`}
          style={{ minHeight: "100lvh" }}
        >
          {sidebar ? (
            <>
              {" "}
              <div
                onClick={() => setSidebar(!sidebar)}
                className="fixed bottom-5 right-[-95px] translate-x-[-100%] cursor-pointer bg-black text-slate-50 z-40 hover:bg-[#1dbb99] p-1 w-32 h-10 rounded-lg flex items-center justify-center text-center text-sm"
              >
                <BsFillArrowLeftCircleFill className="mr-2 text-lg" /> Check Plans
              </div>
              {/* <div className='fixed z-40 top-[52px] right-[-130px] translate-x-[-100%]'>
                <button onClick={() => setSidebar(!sidebar)} className='planButton'>
                  <span className='text-white'>Check Plan</span>
                  <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="37" cy="37" r="35.5" stroke="white" stroke-width="3"></circle>
                    <path d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z" fill="white"></path>
                  </svg>
                </button>
              </div> */}
            </>
          ) : (
            <div className="fixed z-50">
              {" "}
              <div
                onClick={() => setSidebar(!sidebar)}
                className="absolute top-14 left-0 translate-x-[-50%] cursor-pointer hover:scale-110 duration-200 bg-slate-900 text-slate-50 z-50 border border-gray-300 p-1 rounded-full"
              >
                <FiChevronRight className="text-xl" />
              </div>
            </div>
          )}

          {/* <React.Fragment >
            <div className="min-h-screen sticky p-1 top-0 border-s-2 text-center overflow-scroll">
              <h5 className="text-gray-600 dark:text-gray-600 ml-1 py-1 position-sticky">All Plans</h5>
              <hr />
              {!planList ? (
                <div className="flex flex-col content-center h-[500px] justify-start">
                  <div className="text-center py-2 text-sm whitespace-normal">Kindly select a plan from the list below to start adding influencers!</div>
                  <PlanComponent colors={'light'} />
                </div>
              ) : (
                <div>false</div>
              )}
            </div>
          </React.Fragment> */}

          <React.Fragment>
            <div
              className={`${sidebar ? "hidden" : ""} border sticky p-1 top-0 z-40 bg-white text-start overflow-y-scroll scrollbar-w-0`}
              style={{ minHeight: "100lvh" }}
            >
              {/* <hr /> */}
              {sidebar == false && (
                <div className="flex flex-col content-center justify-start">
                  <PlanComponent
                    colors={"light"}
                    temp={isPlanmodalopen}
                    refreshPlans={plans}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
      </section>

      {/* {show && (
        <div
          className=" h-[100vh] absolute right-0 top-0 z-10 animate-slide-in-right ease-in duration-300"
          style={{ position: "fixed" }}
        >
          <ProfilePopup
            show={show}
            closeprofilepopup={closeprofilepopup}
            filterData={filterData}
            socialName={socialName}
          />
          
        </div>
      )} */}

      {/* add influencer to list */}

      <AddToList
        plans={plans}
        getPlans={getPlans}
        lists={lists}
        curListId={curListId}
        handleCurList={handleCurList}
        planId={planId}
        selectedInfluencers={selectedInfluencers}
        closePlanModal={closePlanModal}
        isPlanmodalopen={isPlanmodalopen}
        getList={getList}
        openNewPlanInput={openNewPlanInput}
        setOpenNewPlanInput={setOpenNewPlanInput}
        clearCheckedInfluencer={clearCheckedInfluencer}
        setSelectedInfluencers={setSelectedInfluencers}
      />

      {document.getElementById("filters_portal") &&
        createPortal(
          <>
            {category?.length > 0 && (
              <div className="flex items-center gap-1">
                <p className="text-sm text-[#6d5eac]">Topic</p>
                {category?.map((category) => (
                  <p
                    key={category}
                    className="bg-blue-100 hover:bg-blue-200 shadow-md flex items-center gap-1 capitalize text-blue-800 rounded-md pl-1.5 pr-0.5 py-0.5 text-xs"
                  >
                    {category}
                    <RxCross2 className="cursor-pointer hover:scale-110 text-xs hover:text-red-600" />
                  </p>
                ))}
              </div>
            )}
          </>,
          document.getElementById("filters_portal")
        )}
    </>
  );
}

export default ResultPage;
