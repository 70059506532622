import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgeFilter,
  setAudienceAgeFilter,
} from "../../redux/filters/actions";
import { useLocation, useSearchParams } from "react-router-dom";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import InfoIconTooltip from "./InfoIconTooltip";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = [
  "1",
  "5",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
  "95",
];

const Age1 = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParam, setSearchParam] = useSearchParams();

  const creatorAgeURL = searchParam.get("creatorAge");
  const audienceAgeURL = searchParam.get("audienceAge");
  const creatorAgeArray = creatorAgeURL ? creatorAgeURL.split(",") : [];
  const audienceAgeArray = audienceAgeURL ? audienceAgeURL.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [influencerRangeValues, setInfluencerRangeValues] = useState([
    creatorAgeArray[0] ? parseInt(creatorAgeArray[0]) : null,
    creatorAgeArray[1] ? parseInt(creatorAgeArray[1]) : null,
  ]);

  const [fromInfluencerDropdownOpen, setFromInfluencerDropdownOpen] =
    useState(false);
  const [toInfluencerDropdownOpen, setInfluencerToDropdownOpen] =
    useState(false);

  const [rangeValues, setRangeValues] = useState([
    audienceAgeArray[0] ? parseInt(audienceAgeArray[0]) : null,
    audienceAgeArray[1] ? parseInt(audienceAgeArray[1]) : null,
  ]);


  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);

  const [showPercentage, setShowPercentage] = useState(true)
  const [selectedPercentage, setSelectedPercentage] = useState("1")

  const updateFilterAndUrl = (newRangeValues, type) => {
    const sanitizedValues = newRangeValues.filter(
      (value) => value !== null && value !== undefined && value !== ""
    );
  
    let formattedValues = sanitizedValues.map((value, index) => 
      index === sanitizedValues.length - 1
        ? `${value}-k-${selectedPercentage}` // Use the latest percentage
        : value
    );
  
    if (type === "audience") {
      dispatch(setAudienceAgeFilter(formattedValues));
  
      if (sanitizedValues.length > 0) {
        const audienceAge = `${sanitizedValues.join(",")}-k-${selectedPercentage}`;
        setSearchParam((searchParams) => {
          searchParams.set("audienceAge", audienceAge);
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceAge");
          return searchParams;
        });
      }
    } else if (type === "creator") {
      dispatch(setAgeFilter(sanitizedValues));
  
      if (sanitizedValues.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorAge", sanitizedValues.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorAge");
          return searchParams;
        });
      }
    }
  };

  const handleInfluencerFromChange = (value) => {
    const newFromValue = value === "Clear" ? null : parseInt(value);
    const newRangeValues = [newFromValue, influencerRangeValues[1]];
    setInfluencerRangeValues(newRangeValues);
    setFromInfluencerDropdownOpen(false);
    updateFilterAndUrl(newRangeValues, "creator");
  };

  const handleInfluencerToChange = (value) => {
    const newToValue = value === "Clear" ? null : parseInt(value);
    const newRangeValues = [influencerRangeValues[0], newToValue];
    setInfluencerRangeValues(newRangeValues);
    setInfluencerToDropdownOpen(false);
    updateFilterAndUrl(newRangeValues, "creator");
  };

  const handleFromChange = (value) => {
    const newFromValue = value === "Clear" ? null : parseInt(value);
    const newRangeValues = [newFromValue, rangeValues[1]];
    setRangeValues(newRangeValues);
    setFromDropdownOpen(false);
    updateFilterAndUrl(newRangeValues, "audience");

    if(value === "Clear"){
      setShowPercentage(true)
    }else{
      setShowPercentage(false)
    }
  };

  const handleToChange = (value) => {
    const newToValue = value === "Clear" ? null : parseInt(value);
    const newRangeValues = [rangeValues[0], newToValue];
    setRangeValues(newRangeValues);
    setToDropdownOpen(false);
    updateFilterAndUrl(newRangeValues, "audience");

    if(value === "Clear"){
      setShowPercentage(true)
    }else{
      setShowPercentage(false)
    }
  };

  useEffect(() => {
    const updatedInfluencerRangeValues = [
      creatorAgeArray[0] ? parseInt(creatorAgeArray[0]) : null,
      creatorAgeArray[1] ? parseInt(creatorAgeArray[1]) : null,
    ];
    setInfluencerRangeValues(updatedInfluencerRangeValues);
    dispatch(setAgeFilter(updatedInfluencerRangeValues.filter(Boolean)));

    const updatedRangeValues = [
      audienceAgeArray[0] ? parseInt(audienceAgeArray[0]) : null,
      audienceAgeArray[1] ? parseInt(audienceAgeArray[1]) : null,
    ];
    
    setRangeValues(updatedRangeValues);
    dispatch(setAudienceAgeFilter(updatedRangeValues.filter(Boolean)));

    if(updatedRangeValues[0] === null && updatedRangeValues[1] === null){
      setShowPercentage(true)
    }
  }, [location.search, dispatch]);

  const fromOptions = ["Clear", "13", "18", "25", "35", "45", "65"];
  const toOptions = ["Clear", "17", "24", "34", "44", "64"];

  const handlePercentageChange = (newPercentage) => {
    setSelectedPercentage(newPercentage);
  
    // Use a useEffect to update URL after state is updated
    updateFilterAndUrl(rangeValues, "audience");
  };

  useEffect(() => {
    updateFilterAndUrl(rangeValues, "audience");
  }, [selectedPercentage, rangeValues]);

  return (
    <>
      <div ref={buttonRef} className="CreatorAge flex justify-start">
        <div onClick={() => {setFromDropdownOpen(false); setToDropdownOpen(false); setFromInfluencerDropdownOpen(false); setInfluencerToDropdownOpen(false)}} className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center bg-white filterTextColor"
          >
            <span
              className={`${
                isDropdownOpen ? "font-semibold" : ""
              } ${
                location.pathname.includes("result") ? "h-[40px]" : ""
              } whitespace-nowrap flex items-center`}
            >
              Age{" "}
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2 text-lg" />
              )}
            </span>
          </button>

          <ul
            ref={dropdownRef}
            className={`absolute ${
              location.pathname.includes("result") ? "" : ""
            } z-40 p-5 bg-white shadow-lg rounded-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-2 w-[250px]">
              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdPeopleAlt className="text-2xl" /> Audience
                  <InfoIconTooltip
                    infoContent={"Identify influencers by their audience age."}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex gap-2 w-full">
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                        placeholder="From"
                        value={rangeValues[0] !== null ? rangeValues[0] : ""}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          setFromDropdownOpen(!fromDropdownOpen); 
                          setToDropdownOpen(false);
                          setFromInfluencerDropdownOpen(false);
                          setInfluencerToDropdownOpen(false);
                        }}
                        readOnly
                      />
                      {fromDropdownOpen && (
                        <ul className="absolute z-10 bg-white border border-gray-300 rounded-lg p-2 mt-1 max-h-60 overflow-auto">
                          {fromOptions.map((option) => (
                            <li
                              key={option}
                              onClick={() => handleFromChange(option)}
                              className="cursor-pointer p-2 hover:bg-gray-200"
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                        placeholder="To"
                        value={rangeValues[1] !== null ? rangeValues[1] : ""}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          setToDropdownOpen(!toDropdownOpen); 
                          setFromDropdownOpen(false);
                          setFromInfluencerDropdownOpen(false);
                          setInfluencerToDropdownOpen(false)
                        }}
                        readOnly
                      />
                      {toDropdownOpen && (
                        <ul className="absolute z-10 bg-white border border-gray-300 rounded-lg p-2 mt-1 max-h-60 overflow-auto">
                          {toOptions.map((option) => (
                            <li
                              key={option}
                              onClick={() => handleToChange(option)}
                              className="cursor-pointer p-2 hover:bg-gray-200"
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flexw-full">
                <div className="relative">
                  <select
                    disabled={showPercentage}
                    value={selectedPercentage}
                    onChange={(e) => handlePercentageChange(e.target.value)}
                    className="w-full rounded-md border-[0.6px] border-[#D9D9D9]"
                  >
                    {percentageArr.map((percentage) => (
                      <option key={percentage} value={percentage}>
                        {percentage}%
                      </option>
                    ))}
                  </select>
                </div>
              </div>
                

              <div className="flex flex-col gap-2 w-full">
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Influencer
                  <InfoIconTooltip
                    infoContent={"Identify influencers by their age."}
                  />
                </div>
                <div className="flex gap-2">
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                      placeholder="From"
                      value={
                        influencerRangeValues[0] !== null
                          ? influencerRangeValues[0]
                          : ""
                      }
                      onClick={(e) =>{
                        e.stopPropagation();
                        setFromInfluencerDropdownOpen(!fromInfluencerDropdownOpen);
                        setInfluencerToDropdownOpen(false);
                        setToDropdownOpen(false);
                        setFromDropdownOpen(false);
                      }}
                      readOnly
                    />
                    {fromInfluencerDropdownOpen && (
                      <ul className="absolute z-10 bg-white border border-gray-300 rounded-lg p-2 mt-1 max-h-60 overflow-auto">
                        {fromOptions.map((option) => (
                          <li
                            key={option}
                            onClick={() => handleInfluencerFromChange(option)}
                            className="cursor-pointer p-2 hover:bg-gray-200"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      className="w-full py-3.5 rounded-md border-[0.6px] border-[#D9D9D9]"
                      placeholder="To"
                      value={
                        influencerRangeValues[1] !== null
                          ? influencerRangeValues[1]
                          : ""
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setInfluencerToDropdownOpen(!toInfluencerDropdownOpen)
                        setFromInfluencerDropdownOpen(false);
                        setToDropdownOpen(false);
                        setFromDropdownOpen(false);
                      }}
                      readOnly
                    />
                    {toInfluencerDropdownOpen && (
                      <ul className="absolute z-10 bg-white border border-gray-300 rounded-lg p-2 mt-1 max-h-60 overflow-auto">
                        {toOptions.map((option) => (
                          <li
                            key={option}
                            onClick={() => handleInfluencerToChange(option)}
                            className="cursor-pointer p-2 hover:bg-gray-200"
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Age1;