import React from 'react';
import ReactApexChart from 'react-apexcharts';

const FollowerDistributionGraph = ({ graphData }) => {
    // Check if the data is available
    if (!graphData) {
        // You can choose to render some loading indicator or return null
        return null;
    }

    // Extracting values from the graphData
    const giga_influencer = graphData.giga_influencer || 0;
    const macro_influencer = graphData.macro_influencer || 0;
    const mega_influencer = graphData.mega_influencer || 0;
    const micro_influencer = graphData.micro_influencer || 0;
    const nano_influencer = graphData.nano_influencer || 0;
    const tera_influencer = graphData.tera_influencer || 0;

    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Giga Influencer', 'Macro Influencer', 'Mega Influencer', 'Micro Influencer', 'Nano Influencer', 'Tera Influencer'],
        colors: ['#fc8181', '#f7cf5f', '#323237', '#6d5eac', '#1dbb99', '#38a169'], // Change colors as needed
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 600,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    const series = [giga_influencer, macro_influencer, mega_influencer, micro_influencer, nano_influencer, tera_influencer];

    return (
        <div id="chart" className="w-[500px]">
            <ReactApexChart options={options} series={series} type="donut" />
        </div>
    );
};

export default FollowerDistributionGraph;