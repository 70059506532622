import { SET_SAVES } from "../ActionTypes";

const initialState = [];

const SavesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SAVES:
            return action.payload;
        default:
            return state;
    }
};

export default SavesReducer
