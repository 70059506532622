import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useDispatch, useSelector } from "react-redux";
import {
setAudienceBrandFilter,
setCreatorBrandFilter,
} from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];

const CreatorBrand = ({ hoverStates, handleMouseOver, selectedPlatform }) => {
const dispatch = useDispatch();
const location = useLocation();
const searchParams = new URLSearchParams(location.search);

const creatorBrandUrl = searchParams.get("creatorBrand");
const audienceBrandUrl = searchParams.get("audienceBrand");

const audienceBrandArray = audienceBrandUrl
  ? audienceBrandUrl.split(",").map((value) => {
      const parts = value.split("-k-");
      return {
        id: parts[0],
        name: parts[1],
        percentage: parts[2],
      };
    })
  : [];
const creatorBrandArray = creatorBrandUrl
  ? creatorBrandUrl.split(",")
  : [];


const [creatorBrandData, setCreatorBrandData] = useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState(null);
const [searchParam, setSearchParam] = useSearchParams();
const [selectedOption, setSelectedOption] = useState({});
const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

const fetchCreatorBrandData = async () => {
  setCreatorBrandData([])
  try {
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("filter", "brands");
    formData.append("logintype", "getfilters");
    formData.append("device_id", "search");
    formData.append("platform", selectedPlatform);

    const response = await axiosInstance.post(
      "livesearch/getfilters",
      formData
    );
    const result = response.data;

    if (result.status === 1) {
      setCreatorBrandData(result.response.data.brands);
      console.log("Checking brand", result.response.data.brands);
    } else {
      setError(result.msg);
      console.error("Error:", result.msg);
    }
  } catch (error) {
    setError("An error occurred while fetching data.");
    console.error("Error:", error);
  } finally {
    setLoading(false);
  }
};

const handleSearchCreatorBrand = () => {
  fetchCreatorBrandData();
  setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown
};

const handleCreatorBrand = (selectedList) => {
  const selectedValues = selectedList.map(
    (item) => item.id + "-k-" + item.name
  );
  dispatch(setCreatorBrandFilter(selectedValues));

  if (selectedValues.length > 0) {
    setSearchParam((searchParams) => {
      searchParams.set("creatorBrand", selectedValues.join(","));
      return searchParams;
    });
  } else {
    setSearchParam((searchParams) => {
      searchParams.delete("creatorBrand");
      return searchParams;
    });
  }
};

const handleAudienceBrand = (selectedList) => {
  const selectedValues = selectedList.map(
    (item) =>
      `${item.id}-k-${item.name}-k-${selectedOption[item.id] || "5"}` // Use the selected option for each item
  );
  dispatch(setAudienceBrandFilter(selectedValues));

  if (selectedValues.length > 0) {
    setSearchParam((searchParams) => {
      searchParams.set("audienceBrand", selectedValues.join(","));
      return searchParams;
    });
  } else {
    setSearchParam((searchParams) => {
      searchParams.delete("audienceBrand");
      return searchParams;
    });
  }

  setIsDropdownOpen(false);
};

const handlePercentageChange = (id, value) => {
  setSelectedOption((prev) => {
    const updatedOptions = {
      ...prev,
      [id]: value,
    };
    const updatedValues = audienceBrandArray.map(
      (item) =>
        `${item.id}-k-${item.name}-k-${updatedOptions[item.id] || "5"}`
    );

    dispatch(setAudienceBrandFilter(updatedValues));
    setSearchParam((searchParams) => {
      if (updatedValues.length > 0) {
        searchParams.set("audienceBrand", updatedValues.join(","));
      } else {
        searchParams.delete("audienceBrand");
      }
      return searchParams;
    });

    return updatedOptions;
  });

  setIsDropdownOpen(false);
};

const audienceBrandIdAndName = audienceBrandArray.map((value) => ({
  id: value.id,
  name: value.name,
}));

const creatorBrandIdAndName = creatorBrandArray.map((value) => {
  const [id, name] = value.split("-k-");
  return { id, name };
});

return (
  <>
    <div ref={buttonRef} className="CreatorBrand flex justify-start">
      <div className="relative w-full">
        <button
          onClick={handleSearchCreatorBrand}
          className="flex items-center bg-white"
        >
          <span
            className={`filterTextColor ${
              isDropdownOpen ? "font-semibold" : ""
            } flex items-center`}
          >
            Brands
            {isDropdownOpen ? (
              <RxCross2 className="ml-2 text-lg" />
            ) : (
              <IoChevronDownSharp className="ml-2  text-lg" />
            )}
          </span>
        </button>
        <ul
          ref={dropdownRef}
          className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex gap-5">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdPeopleAlt className="text-2xl" /> Audience
                <InfoIconTooltip
                  infoContent={
                    "Identify influencers by their brand affinities. We determine brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands."
                  }
                />
              </div>
              <MultiSelectDropdown
                options={creatorBrandData}
                onSelect={handleAudienceBrand}
                onRemove={handleAudienceBrand}
                placeholder={"Select Brand"}
                selectedValues={audienceBrandIdAndName}
                loading={loading}
              />
              {audienceBrandIdAndName.map((item) => (
                <div key={item.id} className="flex items-center justify-between">
                  <span>{item.name}</span>
                  <select
                    value={selectedOption[item.id] || "5"}
                    onChange={(e) => handlePercentageChange(item.id, e.target.value)}
                    className="cursor-pointer hover:bg-gray-200 rounded-lg shadow-md"
                    style={{ border: "0.6px solid #D9D9D9", maxHeight: "400px", overflowY: "auto" }}
                  >
                    {percentageArr.map((option) => (
                      <option key={option} value={option}>
                        {`>${option}%`}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Influencer
                <InfoIconTooltip
                  infoContent={
                    "Identify influencers by their brand affinities. We determine brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands."
                  }
                />
              </div>
              <MultiSelectDropdown
                options={creatorBrandData}
                onSelect={handleCreatorBrand}
                onRemove={handleCreatorBrand}
                placeholder={"Select Brand"}
                selectedValues={creatorBrandIdAndName}
                loading={loading}
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
  </>
);
};

export default CreatorBrand;