import React from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { debounce } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTopicsFilter } from "../../redux/filters/actions";
import { Tooltip } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";

const Topics = ({
  hoverStates,
  handleMouseOver,
  sendToSearchResultFilter,
  selectedPlatform,
}) => {
  const dispatch = useDispatch();
  const selected_topic = useSelector((state) => state.topics);
  const [topicsData, setTopicsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState(selected_topic || []);

  const fetchTopics = async (value) => {
    try {
      setLoading(true);
      setError(null);

      const formdata = new FormData();
      formdata.append("topic", value);
      formdata.append("logintype", "searchtopic");
      formdata.append("device_id", "seacrh");
      formdata.append("platform", selectedPlatform);

      const response = await axiosInstance.post(
        "livesearch/searchtopic",
        formdata
      );
      console.log(response, "responseresponse");

      if (response?.data?.status === 1) {
        setTopicsData(response?.data?.response?.data);
        console.log("checking topics", response?.data?.response?.data);
      } else {
        setError(response?.data?.msg);
        console.error("Error:", response?.data?.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchTopics = () => {
    // handleMouseOver('isTopics');
    // Fetch topics for the initially selected value
    fetchTopics(selectedTopics);
  };

  const handleTopicsChange = (value) => {
    setSelectedTopics(value);
  };

  // Use debounce to limit API calls when the user is typing
  const debouncedFetchTopics = debounce(fetchTopics, 300);

  const handleTopicsSearch = (value) => {
    // Fetch topics based on user input
    debouncedFetchTopics(value);
  };

  useEffect(() => {
    // Send selected topics to the parent component
    dispatch(setTopicsFilter(selectedTopics));
    // sendToSearchResultFilter({ Topics: selectedTopics });
  }, [dispatch, selectedTopics, sendToSearchResultFilter]);

  return (
    <>
      <div className="Topics flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleSearchTopics}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            className="flex items-center bg-white"
          >
            <span
              className={`text-[#3D3D3D] ${
                isHover ? "" : ""
              } flex items-center`}
            >
              {/* {hoverStates.isTopics ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
              Topics
              {isHover ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2  text-lg" />
              )}
              {/* <InfoIconTooltip infoContent={`It is much more bigger than 'influencers who uses these tags'. Each tag represents some topic among all topics in the social network. Combination of tags represents combination of specified topics, like "german" + "cars" will give you german cars in the beginning, then mixed solely about cars or Germany.`} /> */}
            </span>
          </button>
          <ul
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
            className={`absolute z-50 p-5 w-[200px] bg-white shadow-lg rounded-lg ${
              isHover ? "block" : "hidden"
            }`}
          >
            <div
              onClick={() => fetchTopics(selectedTopics)}
              className="flex gap-2 w-full"
            >
              <Select
                mode="multiple"
                bordered={false}
                className="rounded-md w-full py-3 border-[0.6px] border-[#D9D9D9] text-[#3D3D3D]"
                placeholder="Search Topics"
                loading={loading}
                value={selectedTopics}
                onChange={handleTopicsChange}
                onSearch={handleTopicsSearch}
                notFoundContent={
                  <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                    Start Typing...
                  </span>
                }
              >
                {topicsData?.map((data) => (
                  <Select.Option key={data.id} value={data.name}>
                    <div className="flex gap-2 items-center">{data.name}</div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Topics;
