import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { HiInformationCircle } from 'react-icons/hi'
import { IoInformationCircleOutline } from "react-icons/io5";
const InfoIconTooltip = ({ infoContent, bgColor }) => {
    return (
        <Tooltip title={infoContent}>
            <IconButton style={{ padding: '0px' }}>
                <IoInformationCircleOutline  className={`${bgColor ? `${bgColor} text-xl` : 'bg-white text-xl'}`} />
            </IconButton>
        </Tooltip>
    )
}

export default InfoIconTooltip
