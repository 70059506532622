import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Label, Select, TextInput } from "flowbite-react/lib/esm";
import { AiOutlinePlus } from "react-icons/ai";
import * as BSIcon from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { HiPlus } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import axiosInstance from "../../interseptor";
import { createPortal } from "react-dom";

const CREATE_LIST_URL = "/livesearch/createlist";
const REGEX = /^[a-zA-Z.\- ?]+$/;

const CreateNewList = ({ color, size, planId, getList,setType }) => {
  const [deliverablesList, setDeliverablesList] = useState({});
  const [isOpen, setisOpen] = useState(false);
  const [showPlatform, setShowPlatform] = useState(false);
  const [allplatforms, setAllPlatforms] = useState();
  const [platform, setPlatform] = useState();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [currentPlatformName, setCurrentPlatformName] = useState("");
  const [isDeliverableChecked, setIsDeliverableChecked] = useState(false);
  const [deliverables, setDeliverables] = useState([
    {
      platform: "Instagram",
      deliverables: ["Instagram Posts", "Reels"],
    },
    {
      platform: "Youtube",
      deliverables: ["Youtube Video", "Shorts"],
    },
    {
      platform: "Facebook",
      deliverables: ["Facebook Posts", "Videos"],
    },
  ]);
  const [deliverableType, setDeliverableType] = useState();
  const [addListDeliverables, setAddListDeliverables] = useState([]);
  const [listCreated, setListCreated] = useState(false);

  // const notify = (msg) => toast.success(msg);

  const [listName, setListName] = useState("");
  const [posts, setPosts] = useState("");
  const [cost, setCost] = useState("");

  const handleDeliverableCheck = () => {
    if (isDeliverableChecked) {
      setIsDeliverableChecked(false);
      setDeliverablesList({});
    } else {
      setIsDeliverableChecked(true);

      const new_del = {
        id: "",
        name: "",
        deliverables: [{ id: uuidv4(), type: "", posts: "", cost: "" }],
        platforms: ["", ""],
      };

      console.log("new_del0", new_del);

      setDeliverablesList(new_del);
    }
  };

  const openModal = () => {
    setisOpen(!isOpen);

    const arr = [];

    setDeliverables([
      {
        platform: "Instagram",
        deliverables: ["Instagram Posts", "Reels"],
      },
      {
        platform: "Youtube",
        deliverables: ["Youtube Video", "Shorts"],
      },
      {
        platform: "Facebook",
        deliverables: ["Facebook Posts", "Videos"],
      },
    ]);
  };

  const closeModal = () => {
    // setisOpen(!isOpen);
    setType('influencerList')
    setListCreated(false);
  };

  const handleDeliverablesChange = async () => {
    setShowPlatform(!showPlatform);
  };

  const getDeliverables = async () => {
    // const selectedplatform=selectedplatform.toLowerCase()

    const formData = new FormData();
    formData.append("logintype", "getdeliverables");
    formData.append("device_id", "seacrh");
    formData.append("platform[]", "instagram");
    formData.append("platform[]", "youtube");

    const response = await axiosInstance.post(
      "/livesearch/getdeliverables",
      formData
    );

    return response.data.list;
  };

  // useEffect(() => {

  //     getDeliverables()
  //     console.log('list', deliverables)
  //     // const arr = list && list.filter((obj,index) => {
  //     //     let platforms = []
  //     //     if(platforms.indexOf(obj.platform) == index){

  //     //     }
  //     // }
  //     //     )
  //     // const allplatforms_ = removeDuplicates(arr)
  //     // console.log('all', allplatforms_)
  //     // console.log(platforms)
  //     // setAllPlatforms(allplatforms_)
  // }, [])

  let currentPlatform_;

  const handlePlatform = (platform) => {
    // console.log("passed platform", platform);
    setCurrentPlatformName(platform);
    // const filtered = deliverables.reduce((acc, curr) => {
    //     const { platform, deliverable } = curr;
    //     if (!acc[platform]) {
    //         acc[platform] = [deliverable];
    //     } else {
    //         if (!acc[platform].includes(deliverable)) {
    //             acc[platform].push(deliverable);
    //         }
    //     }
    //     return acc;
    // }, {});

    // currentPlatform_ = filtered[platform]
    // console.log('selectedplatform',()=>{[prev,platform]})
    if (!selectedPlatforms.includes(platform)) {
      // console.log("not includes");
      setSelectedPlatforms(platform);
    } else {
      // console.log("includes", platform, selectedPlatforms);
      const updated = selectedPlatforms.filter((platform_) => {
        return platform_ != platform;
      });
      // console.log("updated", updated);
      setSelectedPlatforms(updated);
    }
  };

  const handleInputChange = (index, id_, value) => {
    setDeliverablesList((prevState) => {
      const updatedDeliverables = [...prevState.deliverables];
      updatedDeliverables[index] = {
        ...updatedDeliverables[index],
        [id_]: value,
      };

      return { ...prevState, deliverables: updatedDeliverables };
    });
    // setDeliverables(prev=>{
    //   return {...prev,[prev.deliverables]:updatedDeliverables}
    // });
  };

  const handleDeliveryDelete = (index_) => {
    const filtered = deliverablesList.deliverables.filter((item, index) => {
      // console.log(index,index_)

      return index != index_;
    });

    setDeliverablesList((prev) => {
      // console.log('1111111111111',{...prev,deliverables:filtered})
      return { ...prev, deliverables: filtered };
    });
    // console.log('filtered delete',filtered)
  };

  const obj_ =
    deliverables &&
    deliverables.filter((del) => {
      return selectedPlatforms.includes(del.platform);
    });

  // console.log("obj_", obj_);

  let deliverablesType =
    obj_ &&
    obj_.map((each) => {
      return each.deliverables;
    });

  deliverablesType = deliverablesType && deliverablesType.flat(1);

  //it is actually deliverables
  const selectDeliverableChange = async (deliverableType) => {
    // console.log('deliverable',deliverable)
    setDeliverableType(deliverableType);
    // setSelectedPlatform(item);
    setShowPlatform(false);
  };

  // const arr = []
  // deliverables && deliverables.map(deliverable => {
  //     arr.push(deliverable.platform)
  // })

  const handleAddMore = () => {
    const new_del = { id: uuidv4(), type: "", posts: "", cost: "" };

    console.log("new del", new_del);
    // prev_dels.push(new_del)
    setDeliverablesList((prev) => {
      const updatedDeliverables = [...prev.deliverables, new_del];
      // console.log('updted',updatedDeliverables)
      // console.log('prev',prev)
      return { ...prev, deliverables: updatedDeliverables };
    });
    // postsRef.current.push(React.createRef());
    // costRef.current.push(React.createRef());
  };

  // console.log("del111111111", selectedPlatforms);
  const createList = async () => {
    let del = "";
    if (deliverablesList.deliverables) {
      del = JSON.stringify(deliverablesList.deliverables);
      del = del.toString();
    }
    // console.log(del);
    try {
      let data = new FormData();
      data.append("logintype", "createlist");
      data.append("device_id", "seacrh");
      data.append("plan_id", planId);
      data.append("list_name", listName);
      data.append("deliverable", del);
      data.append("platform", selectedPlatforms);

      console.log("passing planid", planId);

      const response = await axiosInstance.post(CREATE_LIST_URL, data);

      let validated = false;
      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status_code === 200) {
          if (response.data.status == 1) {
            validated = REGEX.test(response?.data?.msg);
            if (validated) {
              setListCreated(true);
              getList(planId);
              setSelectedPlatforms([]);
              setDeliverablesList({});
              setListName("");
              closeModal();

              toast.success(response?.data?.msg, { pauseOnHover: false });
            }
          } else if (response.data.status == 0) {
            closeModal();
            toast.error(response?.data?.msg, { pauseOnHover: false });
          }

          return false;
        } else if (response && response?.data && response?.data?.status === 0) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  // const platforms = removeDuplicates(arr)
  // console.log(platforms)
  // const del=deliverables[0].deliverable
  const del = "hi";

  return (
    <>
      {/* <div className="flex justify-center">
        <button
          onClick={openModal}
          className="w-[90%] bg-[#323237] border border-[#323237] flex items-center justify-center py-2 rounded-lg text-white hover:opacity-90 -ml-[1px]"
          disabled={planId == null}
        > 
          <HiPlus className="mr-0.5"/>
          <span className="whitespace-nowrap">Create List</span>
        </button>
      </div> */}
      <div>
        <h1 className="text-gray-600 dark:text-gray-600 text-lg font-semibold mb-4 text">
          Create New Lists
        </h1>

        <div className="">
          <div className="flex flex-col mt-6">
            <Label
              className="!text-gray-600 mb-1"
              htmlFor="planname"
              value="Name of the List"
            />
            <TextInput
              id="listName"
              type="text"
              placeholder="Enter List Name"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              required={true}
              shadow={true}
            />
          </div>

          <div className="mt-6">
            <Label value="Choose Platform" />
            <div className="flex justify-left flex-wrap gap-2 mt-1">
              {deliverables &&
                deliverables.map((del) => {
                  const Icon = BSIcon[`Bs${del.platform}`];
                  return (
                    <Button
                      color="gray"
                      className="w-28 "
                      onClick={() => handlePlatform(del.platform)}
                      style={{
                        backgroundColor: `${
                          selectedPlatforms.includes(del.platform)
                            ? "#6d5eac"
                            : ""
                        }`,
                        outline: "none",
                        ":hover": {
                          boxShadow: "0 0 5px #00aaff",
                          border: "1px solid #00aaff",
                        },
                        color: `${
                          selectedPlatforms.includes(del.platform)
                            ? "white"
                            : "gray"
                        }`,
                      }}
                    >
                      <Icon className="mr-2 h-5 w-5" />
                      {del.platform}
                    </Button>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="flex gap-5 mt-6">
          <Button
            size="sm"
            onClick={createList}
            className="bg-slate-900 hover:bg-slate-900/90"
            disabled={listName.length == 0 || selectedPlatforms.length == 0}
          >
            <span className="whitespace-nowrap">Create List</span>
          </Button>
          <Button
            onClick={closeModal}
            size="sm"
            className="bg-gray-600 hover:bg-gray-700"
          >
            Cancel
          </Button>
        </div>

        {listCreated && listCreated == true && <></>}
      </div>

      {/* {isOpen &&
        createPortal(
          <div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
            <div className="fixed overflow-y-scroll max-h-[600px] left-1/2 -translate-x-[50%] top-[50%]  -translate-y-1/2 z-50 bg-white border-2 border-gray-500 p-8 w-2/5 rounded-xl shadow-md">
              <h1 className="text-gray-600 dark:text-gray-600 text-lg font-semibold mb-4 text">
                Create New Lists
              </h1>

              <div className="">
                <div className="flex flex-col mt-6">
                  <Label
                    className="!text-gray-600 mb-1"
                    htmlFor="planname"
                    value="Name of the List"
                  />
                  <TextInput
                    id="listName"
                    type="text"
                    placeholder="Enter List Name"
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    required={true}
                    shadow={true}
                  />
                </div>

                <div className="mt-6">
                  <Label value="Choose Platform" />
                  <div className="flex justify-left flex-wrap gap-2 mt-1">
                    {deliverables &&
                      deliverables.map((del) => {
                        const Icon = BSIcon[`Bs${del.platform}`];
                        return (
                          <Button
                            color="gray"
                            className="w-28 "
                            onClick={() => handlePlatform(del.platform)}
                            style={{
                              backgroundColor: `${selectedPlatforms.includes(del.platform)
                                  ? "#6d5eac"
                                  : ""
                                }`,
                              outline: "none",
                              ":hover": {
                                boxShadow: "0 0 5px #00aaff",
                                border: "1px solid #00aaff",
                              },
                              color: `${selectedPlatforms.includes(del.platform)
                                  ? "white"
                                  : "gray"
                                }`,
                            }}
                          >
                            <Icon className="mr-2 h-5 w-5" />
                            {del.platform}
                          </Button>
                        );
                      })}
                  </div>
                </div>

              </div>

              <div className="flex gap-5 mt-6">
                <Button
                  size="sm"
                  onClick={createList}
                  className="bg-slate-900 hover:bg-slate-900/90"
                  disabled={
                    listName.length == 0 || selectedPlatforms.length == 0
                  }
                >
                  <span className="whitespace-nowrap">Create List</span>
                </Button>
                <Button
                  onClick={closeModal}
                  size="sm"
                  className="bg-gray-600 hover:bg-gray-700"
                >
                  Cancel
                </Button>
              </div>

              {listCreated && listCreated == true && <></>}
            </div>
          </div>,
          document.body
        )} */}
    </>
  );
};

export default CreateNewList;
