import { SET_CREATOR_LOOKALIKE_FILTER } from "../ActionTypes";

const initialState = [];

const CreatorLookALikeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATOR_LOOKALIKE_FILTER:
            const previousValue = state.value;
            console.log('state in SET_CREATOR_LOOKALIKE_FILTER', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default CreatorLookALikeReducer