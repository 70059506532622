import { Table } from "flowbite-react";

export default function TableRowSkeleton({ total }) {
    return new Array(total).fill(0).map((item, idx) => {
        return <Table>
            <Table.Row key={idx} className="bg-white dark:border-gray-700 dark:bg-gray-800 animate-pulse">
            {new Array(total).fill(0).map((col, colIdx) => {
                return <Table.Cell key={colIdx} className="w-[50px] m-2 bg-gray-200 animate-pulse">
                </Table.Cell>
            })}
        </Table.Row>
        </Table>
    })
}