'use client';

import { useState } from 'react';
import { Button, Modal } from 'flowbite-react';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import md5 from "md5";

const ViralRepeatedBrandModal = ({ brand, brandIndex }) => {
    const [openModal, setOpenModal] = useState(false);

    // const { id } = useParams();

    const closeModal = () => {
        setOpenModal(false);
    };

    // const GravatarImage = ({ brand, brandIndex }) => {
    //     const hashedBrand = md5(brand);
    //     const gravatarURL = `https://storage.googleapis.com/viralimage/${hashedBrand}.jpg`;

    //     return (
    //         <img
    //             src={gravatarURL}
    //             alt={`Gravatar for ${brand}`}
    //             className="w-10 h-10 rounded-full shadow-md border overflow-hidden"
    //         />
    //     );
    // };

    // const getUsername = (link) => {
    //     let username = link?.split("/").pop();
    //     return username
    // }

    return (
        <>
            <ToastContainer autoClose={1500} />
            <button onClick={() => setOpenModal(true)} className="">
                {`@${brand?.key}`}
            </button>
            <Modal show={openModal} onClose={closeModal}>
                <Modal.Header>{`@${brand?.key}`}</Modal.Header>
                <Modal.Body>

                    <div className="flex flex-wrap gap-3 cursor-pointer">
                        {
                            brand?.value.map((url) => (
                                <Link key={brandIndex} target="/blank" to={`https://www.instagram.com/p/${url}`}>
                                    <div className='w-32 h-44 bg-black rounded-lg shadow-md border overflow-hidden'>
                                        <img
                                            src={`https://viralpitch.co/i3.php?id=${url}&s=m`}
                                            alt={`Gravatar for ${brand}`}
                                            className="w-[100%] h-[100%] object-contain"
                                        />
                                    </div>
                                </Link>
                            ))
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: '#6d5eac', color: 'white' }} color="gray" onClick={() => setOpenModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ViralRepeatedBrandModal
