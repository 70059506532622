"use client";
import React, { useEffect } from "react";
import SearchComponent from "../../components/Search/SearchComponent";
import Typewriter from "typewriter-effect";
import { useLocation, useSearchParams } from "react-router-dom";
import dashboardImage from "./influencer_19.png";
import ResultPage from "../ResultPage/ResultPage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectedFiltersValueShowing from "../ResultPage/SelectedFiltersValueShowing";
import {
  setAccountTypeFilter,
  setAgeFilter,
  setAllFilterOpen,
  setAudienceAgeFilter,
  setAudienceBrandFilter,
  setAudienceGenderFilter,
  setAudienceInterestFilter,
  setAudienceLanguageFilter,
  setAudienceLocationFilter,
  setAudienceLookALikeFilter,
  setAudienceType,
  setAverageLikesFilter,
  setBioPhraseFilter,
  setContactFilter,
  setCreatorBrandFilter,
  setCreatorInterestFilter,
  setCreatorLanguageFilter,
  setCreatorLocationFilter,
  setCreatorLookALikeFilter,
  setCredibleAccountFilter,
  setEngAndEngRate,
  setEngRateFilter,
  setEthnicityFilter,
  setExcludeOfficialArtists,
  setExcludePrivateAccount,
  setFolloweGrowthFilter,
  setFollowerFilter,
  setGenderFilter,
  setHasAudienceData,
  setHasContactDetailsFilter,
  setHashTagFilter,
  setIsVerifiedFilter,
  setIsVpResigtered,
  setKeywordsFilter,
  setLastPost,
  setMentionsFilter,
  setMostRecentPostFilter,
  setNumberOfContentFilter,
  setPartnership,
  setPlatformFilter,
  setPreviousBrandSponcorFilter,
  setPreviouslyExported,
  setReelPlaysFilter,
  setReelViewFilter,
  setSaves,
  setShares,
  setSponcoredPostFilter,
  setTopicsFilter,
} from "../../redux/filters/actions";
import FollowersAllFilter from "../../components/SearchResultFilters/FollowersAllFilter";
import MostRecentPost from "../../components/SearchResultFilters/MostRecentPost";
import Age1 from "../../components/SearchResultFilters/Age1";
import SharesFilter from "../../components/SearchResultFilters/SharesFilter";
import SavesFilter from "../../components/SearchResultFilters/SavesFilter";
import AccountType from "../../components/SearchResultFilters/AccountType";
import BioPhrase from "../../components/SearchResultFilters/BioPhrase";
import CreatorLocation from "../../components/SearchResultFilters/CreatorLocation";
import CreatorInterest from "../../components/SearchResultFilters/CreatorInterest";
import CreatorLanguage from "../../components/SearchResultFilters/CreatorLanguage";
import CreatorGender from "../../components/SearchResultFilters/CreatorGender";
import CreatorBrand from "../../components/SearchResultFilters/CreatorBrand";
import Keyword from "../../components/SearchResultFilters/Keyword";
import Partnership from "../../components/SearchResultFilters/Partnership";
import EthnicityFilter from "../../components/SearchResultFilters/EthnicityFilter";
import CreatorLookALike from "../../components/SearchResultFilters/CreatorLookALike";
import EngagementFilter from "../../components/SearchResultFilters/EngagementFilter";
import ReelView from "../../components/SearchResultFilters/ReelView";
import ReelPlays from "../../components/SearchResultFilters/ReelPlays";
import ContactFilter from "../../components/SearchResultFilters/ContactFilter";
import FollowersGrowth from "../../components/SearchResultFilters/FollowersGrowth";
import AudienceType from "../../components/SearchResultFilters/AudienceType";
import SelectedFiltersValuePercentage from "../ResultPage/SelectedFiltersValuePercentage";
import AllFiltersDropdown from "./AllFiltersDropdown";
// import Card from '../contentInspiration/Card';

function HomePage() {
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const platformUrl = searchParams.get("platform");

  const [filters, setFilters] = useState(null);
  const [lookalikeDropdown, setLookalikeDropdown] = useState(false);
  const dispatch = useDispatch();



  const elementHoverState = {
    // allFilterOpen: false,
    isFollowerOpen: false,
    isTopics: false,
    Keyword: false,
    CreatorBrand: false,
    NumberofContent: false,
    CreatorGender: false,
    Gender: false,
    CreatorAge: false,
    isVpVerified: false,
    isVerified: false,
    isContactDetails: false,
    RecentPost: false,
    CreatorLanguage: false,
    CreatorInterest: false,
    CreatorLocation: false,
    BioPhrase: false,
    AccountType: false,
    Hashtags: false,
    Mentions: false,
    isAdvanceOpen: false,
    isAudienceOpen: false,
    isAverageLikes: false,
    AudiencerLocation: false,
    AudienceAge: false,
    AudienceGender: false,
    AudienceLanguage: false,
    AudienceBrand: false,
    AudienceInterest: false,
    AverageLikes: false,
    EngagementRate: false,
    SponsoredPost: false,
    PreviousBrand: false,
    ReelViews: false,
    ReelPlays: false,
    FollowerGrowth: false,
    AiduenceLookalike: false,
    CreatorLookalike: false,
  };

  const [hoverStates, setHoverStates] = useState(elementHoverState);

  const handleMouseOver = (element) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [element]: !prevState[element],
    }));
  };

  const selectedData = useSelector((state) => ({
    followerAllFilters: state.follower,
    creatorGender: state.creatorGender,
    creatorAge: state.age,
    creatorLocation: state.creatorLocation,
    keywords: state.keywords,
    topicsNew: state.topics,
    creatorBrand: state.creatorBrand,
    numberOfContent: state.numberOfContent,
    isVpResigtered: state.isVpResigtered,
    isVerified: state.isVerified,
    credibleAccount: state.credibleAccount,
    hasContactDetails: state.hasContactDetails,
    mostRecentPost: state.mostRecentPost,
    creatorLanguage: state.creatorLanguage,
    creatorInterest: state.creatorInterest,
    bioPhrase: state.bioPhrase,
    accountType: state.accountType,
    hashtag: state.hashtag,
    mentions: state.mentions,
    audienceGenderRedux: state.audienceGender,
    audienceAge: state.audienceAge,
    audienceLocation: state.audienceLocation,
    audienceLanguage: state.audienceLanguage,
    audienceInterest: state.audienceInterest,
    audienceBrand: state.audienceBrand,
    averageLikes: state.averageLikes,
    engagementRate: state.engagementRate,
    isSponcoredPost: state.isSponcoredPost,
    previousBrandSponcored: state.previousBrandSponcored,
    reelViews: state.reelViews,
    reelPlays: state.reelPlays,
    followerGrowth: state.followerGrowth,
    audienceLookALike: state.audienceLookALike,
    creatorLookALike: state.creatorLookALike,
    selectedPlatform: state.platform,
    allFilterOpen: state.allFilterOpen,
    ethnicity: state.ethnicity,
    contact: state.contact,
    credibleAccount: state.credibleAccount,
    previousExported: state.previouslyExported,
    excludePrivateAccounts: state.excludePrivateAccounts,
    hasAudienceData: state.hasAudienceData,
    excludeOfficialArtists: state.excludeOfficialArtists,
    engAndEngrate: state.engAndEngrate,
    saves: state.saves,
    shares: state.shares,
    partnership: state.partnership,
    lastPost: state.lastPost,
    audienceType: state.audienceType,
  }));

  const {
    followerAllFilters,
    creatorGender,
    creatorAge,
    creatorLocation,
    keywords,
    topicsNew,
    creatorBrand,
    numberOfContent,
    isVpResigtered,
    isVerified,
    credibleAccount,
    hasContactDetails,
    mostRecentPost,
    creatorLanguage,
    creatorInterest,
    bioPhrase,
    accountType,
    hashtag,
    mentions,
    audienceGenderRedux,
    audienceAge,
    audienceLocation,
    audienceLanguage,
    audienceInterest,
    audienceBrand,
    averageLikes,
    engagementRate,
    isSponcoredPost,
    previousBrandSponcored,
    reelViews,
    reelPlays,
    followerGrowth,
    audienceLookALike,
    creatorLookALike,
    selectedPlatform,
    allFilterOpen,
    ethnicity,
    contact,
    lastPost,
    previousExported,
    excludePrivateAccounts,
    hasAudienceData,
    excludeOfficialArtists,
    engAndEngrate,
    saves,
    shares,
    partnership,
    audienceType,
  } = selectedData;

  const mapUrlParamsToNames = (urlParams) => {
    console.log(urlParams, "urlParams");
    return urlParams.map((item) => {
      if (item !== null) {
        const parts = item.split("-k-");
        return parts.length === 2 ? parts[1] : null;
      }
    });
  };

  const audienceMapUrlParamsToNames = (urlParams) => {
    return urlParams.map((item) => {
      if (item !== null) {
        const parts = item.split("-k-");
        return parts.length >= 2 ? parts[1] : null;  // Ensure to return the second part (name)
      }
      return null;
    });
  };

  const withoutIdNameAndPercentage = (urlParams) => {
    return urlParams.map((item) => {
      if (item !== null) {
        const parts = item.split("-k-");
        return {
          name: parts[0],          // Extract the name (second part)
          percentage: parts[1]     // Extract the percentage (third part)
        };
      }
      return null;
    });
  };

  const mapUrlParamsToNameAndPercentage = (urlParams) => {
    return urlParams.map((item) => {
      if (item !== null) {
        const parts = item.split("-k-");
        return {
          name: parts[1],          // Extract the name (second part)
          percentage: parts[2]     // Extract the percentage (third part)
        };
      }
      return null;
    });
  };

  const audienceMapUrlParamsToCombined = (urlParams) => {
    let ages = [];
    let percentage = null;
  
    urlParams.forEach((item) => {
      if (typeof item === 'string') {
        const parts = item.split("-k-");
        if (parts.length === 2) {
          const agePart = parts[0];
          const percentPart = parts[1];
  
          // Split multiple ages if present
          const ageValues = agePart.split(",");
          ages.push(...ageValues);
          percentage = percentPart; // Last percentage is used
        } else {
          ages.push(item); // Add age value without percentage
        }
      } else if (typeof item === 'number') {
        // Handle numeric values directly
        ages.push(item.toString());
      }
    });
  
    // Remove duplicate ages and format as needed
    const uniqueAges = [...new Set(ages)].join(", ");
    
    return [uniqueAges, percentage];
  };

  const audienceLocationNameWithPercentage = mapUrlParamsToNameAndPercentage(audienceLocation);
  const audienceLanguageNameWithPercentage = mapUrlParamsToNameAndPercentage(audienceLanguage);
  const audienceBrandNameWithPercentage = mapUrlParamsToNameAndPercentage(audienceBrand);
  const audienceInterestNames = withoutIdNameAndPercentage(audienceInterest);
  const audienceGenderNames = withoutIdNameAndPercentage(audienceGenderRedux);
  
  const audienceAgeNames = audienceMapUrlParamsToCombined(audienceAge);
  

  const creatorLanguageNames = mapUrlParamsToNames(creatorLanguage);
  const audienceLanguageNames = mapUrlParamsToNames(audienceLanguage);
  const creatorLocationNames = mapUrlParamsToNames(creatorLocation);
  const audienceLocationNames = audienceMapUrlParamsToNames(audienceLocation);
  const creatorBrandNames = mapUrlParamsToNames(creatorBrand);
  const audienceBrandNames = mapUrlParamsToNames(audienceBrand);
  const partnershipNames = mapUrlParamsToNames(partnership);


  const removeSelectedFilter = (categoryName, filterName, idToRemove) => {
    if (filterName === "Creator Location") {
      const removedCategory = creatorLocation?.filter(
        (item) => item !== idToRemove
      );
      dispatch(setCreatorLocationFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorLocation", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorLocation");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Audience Location") {
      
      const removedCategory = audienceLocation?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setAudienceLocationFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceLocation", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceLocation");
          return searchParams; // Return a new instance
        });
      }
    } else if (filterName === "Creator Language") {
      const removedCategory = creatorLanguage?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setCreatorLanguageFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorLanguage", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorLanguage");
          return searchParams;
        });
      }
    } else if (filterName === "Audience Language") {
      const removedCategory = audienceLanguage?.filter(
        (item) => item !== idToRemove
      );
      console.log(removedCategory, 'removedCaremovedCategorytegory')
      
      dispatch(setAudienceLanguageFilter(removedCategory));
      // console.log(removedCategory, 'removedCaremovedCategorytegory')

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceLanguage", removedCategory.join(","));
          return searchParams;
        });
      }else{
        setSearchParam((searchParams) => {
          searchParams.delete("audienceLanguage");
          return searchParams;
        });
      }
    } else if (filterName === "Creator Brand") {
      const removedCategory = creatorBrand?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setCreatorBrandFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorBrand", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorBrand");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Audience Brand") {
      const removedCategory = audienceBrand?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setAudienceBrandFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceBrand", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceBrand");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Partnerships") {
      const removedCategory = partnership?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setPartnership(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("partnership", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("partnership");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Creator Interest") {
      const removedCategory = creatorInterest?.filter(
        (item) => item !== categoryName
      );

      dispatch(setCreatorInterestFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorInterest", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorInterest");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Audience Interest") {
      const removedCategory = audienceInterest?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setAudienceInterestFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceInterest", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceInterest");
          return new URLSearchParams(searchParams); // Return a new instance
        });
      }
    } else if (filterName === "Creator Gender") {
      const removedCategory = creatorGender?.filter(
        (item) => item !== categoryName
      );
      dispatch(setGenderFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorGender", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorGender");
          return searchParams;
        });
      }
    } else if (filterName === "Audience Gender") {
      const removedCategory = audienceGenderRedux?.filter(
        (item) => item !== idToRemove
      );

      dispatch(setAudienceGenderFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceGender", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceGender");
          return searchParams;
        });
      }
    } else if (filterName === "Ethnicity") {
      dispatch(setEthnicityFilter([]));

      setSearchParam((searchParams) => {
        searchParams.delete("ethnicity");
        return new URLSearchParams(searchParams); // Return a new instance
      });
    } else if (filterName === "Creator Age") {
      const removedCategory = creatorAge?.filter(
        (item) => item !== categoryName
      );

      dispatch(setAgeFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorAge", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorAge");
          return searchParams;
        });
      }
    } else if (filterName === "Audience Age") {
      const removedCategory = audienceAge?.filter(
        (item) => item !== categoryName
      );

      dispatch(setAudienceAgeFilter([]));

      // if (removedCategory.length > 0) {
      //   setSearchParam((searchParams) => {
      //     searchParams.set("audienceAge", removedCategory.join(","));
      //     return searchParams;
      //   });
      // } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceAge");
          return searchParams;
        });
      // }
    } else if (filterName === "Audience Type") {
      dispatch(setAudienceType([]));

      setSearchParam((searchParams) => {
        searchParams.delete("audienceType");
        return searchParams;
      });

    } else if (filterName === "Followers") {
      const removedCategory = followerAllFilters?.filter(
        (item) => item !== categoryName
      );
      dispatch(setFollowerFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("followers", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("followers");
          return searchParams;
        });
      }
    } else if (filterName === "Topics") {
      const removedCategory = topicsNew?.filter(
        (item) => item !== categoryName
      );

      dispatch(setTopicsFilter(removedCategory));
      if(removedCategory.length > 0){
        setSearchParam((searchParams) => {
          searchParams.set("topicsNew", removedCategory.join(","));
          return searchParams;
        });
      }else{
        setSearchParam((searchParams) => {
          searchParams.delete("topicsNew");
          return searchParams;
        });
      }
      
    } else if (filterName === "Contact") {
      console.log(contact, "before:");
      const removedCategory = contact?.filter((item) => item !== categoryName);
      console.log(removedCategory, "after:");

      dispatch(setContactFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("contact", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("contact");
          return new URLSearchParams(searchParams);
        });
      }
    } else if (filterName === "Last Post") {
      dispatch(setLastPost([]));

      setSearchParam((searchParams) => {
        searchParams.delete("lastPost");
        return new URLSearchParams(searchParams);
      });
    } else if (filterName === "Is Credible account?") {
      dispatch(setCredibleAccountFilter(false));
      setSearchParam((searchParams) => {
        searchParams.delete("credibleAccount");
        return searchParams;
      });
    } else if (filterName === "Is Previous Exported?") {
      dispatch(setPreviouslyExported(false));
      setSearchParam((searchParams) => {
        searchParams.delete("previousExported");
        return searchParams;
      });
    } else if (filterName === "Exclude official artist channels?") {
      dispatch(setExcludeOfficialArtists(false));
      setSearchParam((searchParams) => {
        searchParams.delete("excludeOfficialArtists");
        return searchParams;
      });
    } else if (filterName === "Has Audience Data") {
      dispatch(setHasAudienceData(false));
      setSearchParam((searchParams) => {
        searchParams.delete("hasAudienceData");
        return searchParams;
      });
    } else if (filterName === "Exclude Private Acc.?") {
      dispatch(setExcludePrivateAccount(false));
      setSearchParam((searchParams) => {
        searchParams.delete("excludePrivateAccounts");
        return searchParams;
      });
    } else if (filterName === "Number of Content") {
      const removedCategory = numberOfContent?.filter(
        (item) => item !== categoryName
      );

      dispatch(setNumberOfContentFilter(removedCategory));
    } else if (filterName === "Viral Pitch Verified?") {
      const removedCategory = isVpResigtered?.filter(
        (item) => item !== categoryName
      );

      dispatch(setIsVpResigtered(removedCategory));
    } else if (filterName === "Is Verified?") {
      dispatch(setIsVerifiedFilter(false));
      setSearchParam((searchParams) => {
        searchParams.delete("isVerified");
        return searchParams;
      });
    } else if (filterName === "Contact Info?") {
      const removedCategory = hasContactDetails?.filter(
        (item) => item !== categoryName
      );

      dispatch(setHasContactDetailsFilter(removedCategory));
    } else if (filterName === "Recent Post") {
      const removedCategory = mostRecentPost?.filter(
        (item) => item !== categoryName
      );

      dispatch(setMostRecentPostFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("mostRecentPost", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("mostRecentPost");
          return searchParams;
        });
      }
    } else if (filterName === "Bio Phrase") {
      const removedCategory = bioPhrase?.filter(
        (item) => item !== categoryName
      );

      dispatch(setBioPhraseFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("bioPhrase", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("bioPhrase");
          return searchParams;
        });
      }
    } else if (filterName === "Account Type") {
      dispatch(setAccountTypeFilter([]));
      
      setSearchParam((searchParams) => {
        searchParams.delete("accountType");
        return searchParams;
      });
      
    } else if (filterName === "Keywords") {
      const removedCategory = keywords?.filter((item) => item !== categoryName);
      dispatch(setKeywordsFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("keywords", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("keywords");
          return searchParams;
        });
      }
    } else if (filterName === "Hashtag") {
      const removedCategory = hashtag?.filter((item) => item !== categoryName);

      dispatch(setHashTagFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("hashtag", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("hashtag");
          return searchParams;
        });
      }
    } else if (filterName === "Mentions") {
      const removedCategory = mentions?.filter((item) => item !== categoryName);

      dispatch(setMentionsFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("mentions", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("mentions");
          return searchParams;
        });
      }
    } else if (filterName === "Average Likes") {
      const removedCategory = averageLikes?.filter(
        (item) => item !== categoryName
      );

      dispatch(setAverageLikesFilter(removedCategory));
      //   setSearchParam(searchParams => {
      //     searchParams.set("averageLikes", removedCategory.join(','))
      //     return searchParams;
      //   });
    } else if (filterName === "Eng. Rate") {
      dispatch(setEngRateFilter([]));
      setSearchParam((searchParams) => {
        searchParams.delete("eng_rate");
        return searchParams;
      });
    } else if (filterName === "Engagement") {
      dispatch(setEngAndEngRate([]));

      setSearchParam((searchParams) => {
        searchParams.delete("engagements");
        return searchParams;
      });
    } else if (filterName === "Sponcor Post?") {
      const removedCategory = isSponcoredPost?.filter(
        (item) => item !== categoryName
      );

      dispatch(setSponcoredPostFilter(removedCategory));
      //   setSearchParam(searchParams => {
      //     searchParams.set("isSponcoredPost", removedCategory.join(','))
      //     return searchParams;
      //   });
    } else if (filterName === "Previous Brand Sponcored") {
      const removedCategory = previousBrandSponcored?.filter(
        (item) => item !== idToRemove
      );
      // If id is an array and you want to remove all matching items, use some() to check for equality
      // const removedCategory = previousBrandSponcored?.filter((item) => !idToRemove.some((value) => value === item));

      dispatch(setPreviousBrandSponcorFilter(removedCategory));

      setSearchParam((searchParams) => {
        searchParams.set("previousBrandSponcored", removedCategory.join(","));
        return searchParams;
      });
    } else if (filterName === "Reel Views") {
      const removedCategory = reelViews?.filter(
        (item) => item !== categoryName
      );

      dispatch(setReelViewFilter(removedCategory));
      setSearchParam((searchParams) => {
        searchParams.delete("reelViews");
        return searchParams;
      });
    } else if (filterName === "Reel Plays") {
      const removedCategory = reelPlays?.filter(
        (item) => item !== categoryName
      );

      dispatch(setReelPlaysFilter(removedCategory));
      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("reel_plays", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("reel_plays");
          return searchParams;
        });
      }
    } else if (filterName === "Saves") {
      const removedCategory = saves?.filter((item) => item !== categoryName);

      dispatch(setSaves(removedCategory));
      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("saves", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("saves");
          return searchParams;
        });
      }
    } else if (filterName === "Shares") {
      const removedCategory = shares?.filter((item) => item !== categoryName);

      console.log(removedCategory, "lerremovedCategory");

      dispatch(setShares(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("shares", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("shares");
          return searchParams;
        });
      }
    } else if (filterName === "Follower Growth") {
      const removedCategory = followerGrowth?.filter(
        (item) => item !== categoryName
      );

      dispatch(setFolloweGrowthFilter(removedCategory));
      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("followerGrowth", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("followerGrowth");
          return searchParams;
        });
      }
    } else if (filterName === "Audience Looks a Like") {
      const removedCategory = audienceLookALike?.filter(
        (item) => item !== categoryName
      );

      dispatch(setAudienceLookALikeFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("audienceLookALike", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("audienceLookALike");
          return searchParams;
        });
      }
    } else if (filterName === "Creator Looks a Like") {
      const removedCategory = creatorLookALike?.filter(
        (item) => item !== categoryName
      );

      dispatch(setCreatorLookALikeFilter(removedCategory));

      if (removedCategory.length > 0) {
        setSearchParam((searchParams) => {
          searchParams.set("creatorLookALike", removedCategory.join(","));
          return searchParams;
        });
      } else {
        setSearchParam((searchParams) => {
          searchParams.delete("creatorLookALike");
          return searchParams;
        });
      }
    }
  };

  useEffect(() => {
    // Check if selectedPlatform is 'Instagram'
    if (platformUrl === null) {
      dispatch(setPlatformFilter('Instagram'));
      dispatch(setCreatorLocationFilter(["304716-k-India"]));
      dispatch(setMostRecentPostFilter([1]));
      dispatch(setEngRateFilter([0.01]));   
  
      setSearchParam((searchParams) => {
        searchParams.set("platform", 'Instagram');
        return searchParams;
      });

      setSearchParam((searchParams) => {
        searchParams.set("mostRecentPost", 1);
        return searchParams;
      });
  
      setSearchParam((searchParams) => {
        searchParams.set("eng_rate", 0.01);
        return searchParams;
      });
      
      setSearchParam((searchParams) => {
        searchParams.set("creatorLocation", "304716-k-India");
        return searchParams;
      });
    }
  }, [selectedPlatform]);

  return (
    <>
      <div
        onClick={() => {
          allFilterOpen && dispatch(setAllFilterOpen(false));
          setLookalikeDropdown(false);
        }}
        className="w-full justify-center  overflow-hidden relative pt-5 pb-0 flex flex-col items-start"
      >
        <div className="absolute top-0 bg-[#EEF5FF] h-[400px] w-full z-[-1]"></div>
        <div className=" w-full ml-[5%] mb-3 capitalize rounded-lg">
          {/* <div className="absolute h-80 w-16 top-0 left-10 translate-x-[-10] rotate-45 bg-slate-200/80"></div> */}
          <div className="w-full flex flex-col items-start justify-start">
            <p className="text-3xl mb-1 font-[600] ">
              Search and analyze from
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(to right, #7279FD, #7279FD, #BF00DB)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                  fontWeight: "600",
                }}
              >
                {" "}
                largest influencer
              </span>{" "}
              database
            </p>
            <p className="text-[#828282] text-lg ">
              Access over 200M+ global influencers from 100+ categories and
              topics
            </p>
          </div>

          <SearchComponent filtersProp={filters} />

          {/* {
                    platformUrl === 'Instagram' && <>
                        <div className='w-full flex justify-center gap-2'>
                            <SearchResultFilters
                                selectedPlatform={selectedPlatform}
                                isHomePage={true}
                            />
                        </div>
                    </>
                } */}

          <div className="px-4 pb-6 bg-white shadow-md w-[90%] flex gap-2">
            {/* <SearchResultFilters
              selectedPlatform={platformUrl}
              // sendToHomePage={sendToHomePage}
              isHomePage={true}
            /> */}

            <div className=" flex flex-wrap gap-3 my-3">
              <CreatorLocation
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
              />

              <CreatorLanguage
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
                />
              
              {platformUrl === "Instagram" && (
                <CreatorBrand
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
                />
              )}

              {platformUrl === "Instagram" && (
                <Partnership
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                  selectedPlatform={platformUrl}
                />
              )}

              {platformUrl === "Instagram" && (
                <CreatorInterest
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                  selectedPlatform={platformUrl}
                />
              )}

              <CreatorGender
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={selectedPlatform}
              />

              {platformUrl === "Instagram" && (
                <EthnicityFilter
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                  selectedPlatform={selectedPlatform}
                />
              )}

              <Age1
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                // fetchData1={fetchData1}
                // isHomePage={isHomePage}
              />

              {(platformUrl === "Instagram" ||
                platformUrl === "YouTube") && (
                <AudienceType
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                  selectedPlatform={platformUrl}
                />
              )}

              <CreatorLookALike
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
                lookalikeDropdown={lookalikeDropdown}
                setLookalikeDropdown={setLookalikeDropdown}
              />

              {platformUrl === "TikTok" && (
                <SavesFilter
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                />
              )}

              {platformUrl === "TikTok" && (
                <SharesFilter
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                />
              )}

              {(platformUrl === "Instagram" ||
                platformUrl === "TikTok") && (
                <BioPhrase
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                />
              )}

              <Keyword
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
              />

              <FollowersAllFilter
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
              />

              <EngagementFilter
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
              />

              {(platformUrl === "YouTube" ||
                platformUrl === "TikTok") && (
                <ReelView
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                  selectedPlatform={platformUrl}
                />
              )}

              {platformUrl === "Instagram" && (
                <ReelPlays
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                />
              )}

              <ContactFilter
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
              />

              {/* <LastPost
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
              /> */}

              {platformUrl === "Instagram" && (
                <AccountType
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}
                />
              )}

              <FollowersGrowth
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={platformUrl}
              />

              <MostRecentPost
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
              />

              {/* <Topics
                hoverStates={hoverStates}
                handleMouseOver={handleMouseOver}
                selectedPlatform={selectedPlatform}
              /> */}

              {/* {platformUrl === "Instagram" && (
                <NumberOfContent
                  hoverStates={hoverStates}
                  handleMouseOver={handleMouseOver}

                />
              )} */}
              
              <AllFiltersDropdown 
                selectedPlatform={selectedPlatform} 
                hoverStates={hoverStates} 
                handleMouseOver={handleMouseOver}
              />

            </div>
          </div>

          <div className="px-4 flex-wrap rounded-b-lg pb-6  bg-white shadow-md w-[90%] flex justify-start gap-2">
            <SelectedFiltersValueShowing
              filterState={creatorLocationNames}
              id={creatorLocation}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Location"}
            />
            <SelectedFiltersValuePercentage
              filterState={audienceLocationNameWithPercentage}
              id={audienceLocation}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Location"}
            />
            <SelectedFiltersValueShowing
              filterState={creatorLanguageNames}
              id={creatorLanguage}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Language"}
            />
            <SelectedFiltersValuePercentage
              filterState={audienceLanguageNameWithPercentage}
              id={audienceLanguage}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Language"}
            />
            <SelectedFiltersValueShowing
              filterState={creatorGender}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Gender"}
            />
            <SelectedFiltersValuePercentage
              filterState={audienceGenderNames}
              id={audienceGenderRedux}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Gender"}
            />
            <SelectedFiltersValueShowing
              filterState={followerAllFilters}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Followers"}
            />

            <SelectedFiltersValueShowing
              filterState={creatorAge}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Age"}
            />

            <SelectedFiltersValueShowing
              filterState={audienceType}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Type"}
            />

            <SelectedFiltersValueShowing
              filterState={keywords}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Keywords"}
            />
            <SelectedFiltersValueShowing
              filterState={topicsNew}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Topics"}
            />

            <SelectedFiltersValueShowing
              filterState={creatorBrandNames}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Brand"}
              id={creatorBrand}
            />

            <SelectedFiltersValuePercentage
              filterState={audienceBrandNameWithPercentage}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Brand"}
              id={audienceBrand}
            />

            <SelectedFiltersValueShowing
              filterState={partnershipNames}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Partnerships"}
              id={partnership}
            />
            <SelectedFiltersValueShowing
              filterState={saves}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Saves"}
              id={saves}
            />
            <SelectedFiltersValueShowing
              filterState={shares}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Shares"}
              id={shares}
            />

            <SelectedFiltersValueShowing
              filterState={numberOfContent}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Number of Content"}
            />
            <SelectedFiltersValueShowing
              filterState={isVpResigtered}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Viral Pitch Verified?"}
            />
            <SelectedFiltersValueShowing
              filterState={isVerified}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Is Verified?"}
            />
            <SelectedFiltersValueShowing
              filterState={contact}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Contact"}
            />
            <SelectedFiltersValueShowing
              filterState={lastPost}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Last Post"}
              id={lastPost}
            />
            <SelectedFiltersValueShowing
              filterState={ethnicity}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Ethnicity"}
            />
            <SelectedFiltersValueShowing
              filterState={credibleAccount}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Is Credible account?"}
            />
            <SelectedFiltersValueShowing
              filterState={excludePrivateAccounts}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Exclude Private Acc.?"}
            />
            <SelectedFiltersValueShowing
              filterState={hasAudienceData}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Has Audience Data"}
            />
            <SelectedFiltersValueShowing
              filterState={previousExported}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Is Previous Exported?"}
            />
            <SelectedFiltersValueShowing
              filterState={excludeOfficialArtists}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Exclude official artist channels?"}
            />
            <SelectedFiltersValueShowing
              filterState={hasContactDetails}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Contact Info?"}
            />
            <SelectedFiltersValueShowing
              filterState={mostRecentPost}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Recent Post"}
            />

            <SelectedFiltersValueShowing
              filterState={creatorInterest}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Interest"}
            />
            <SelectedFiltersValueShowing
              filterState={bioPhrase}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Bio Phrase"}
            />
            <SelectedFiltersValueShowing
              filterState={accountType}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Account Type"}
            />
            <SelectedFiltersValueShowing
              filterState={hashtag}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Hashtag"}
            />
            <SelectedFiltersValueShowing
              filterState={mentions}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Mentions"}
            />

            {
              // console.log(audienceAgeNames[0], 'asfd')
              audienceAgeNames[0] !== "" &&
              <SelectedFiltersValueShowing
                filterState={audienceAgeNames}
                id={audienceAge}
                removeSelectedFilter={removeSelectedFilter}
                filterName={"Audience Age"}
              />
            }

            <SelectedFiltersValuePercentage
              filterState={audienceInterestNames}
              id={audienceInterest}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Interest"}
            />

            <SelectedFiltersValueShowing
              filterState={averageLikes}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Average Likes"}
            />
            <SelectedFiltersValueShowing
              filterState={engagementRate}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Eng. Rate"}
            />
            <SelectedFiltersValueShowing
              filterState={engAndEngrate}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Engagement"}
            />
            <SelectedFiltersValueShowing
              filterState={isSponcoredPost}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Sponcor Post?"}
            />
            <SelectedFiltersValueShowing
              filterState={previousBrandSponcored}
              id={previousBrandSponcored}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Previous Brand Sponcored"}
            />

            <SelectedFiltersValueShowing
              filterState={reelViews}
              removeSelectedFilter={removeSelectedFilter}
              filterName={
                platformUrl === "Instagram"
                  ? "Reel Views"
                  : platformUrl === "Youtube"
                  ? "Views"
                  : "Avg. Views"
              }
            />

            <SelectedFiltersValueShowing
              filterState={reelPlays}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Reel Plays"}
            />

            <SelectedFiltersValueShowing
              filterState={followerGrowth}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Follower Growth"}
            />
            <SelectedFiltersValueShowing
              filterState={audienceLookALike}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Audience Looks a Like"}
            />
            <SelectedFiltersValueShowing
              filterState={creatorLookALike}
              removeSelectedFilter={removeSelectedFilter}
              filterName={"Creator Looks a Like"}
            />
          </div>
        </div>

        <div className="w-[90%] ml-[5%]">
          <ResultPage selectedPlatform={platformUrl} />
        </div>

        <div className="ml-[5%] relative overflow-hidden  flex flex-col w-[90%] items-center justify-center my-2  rounded-lg bg-[#6d5eac]">
          <img
            className="absolute hidden md:block z-10 right-10"
            src={dashboardImage}
            alt="img"
          ></img>
          <div className="absolute z-0 top-0 left-0 translate-x-[-40%] translate-y-[-40%] w-60 h-60 lg:w-96 lg:h-96 rounded-full border-[60px] md:border-[80px] lg:border-[80px] border-gray-700/30"></div>
          <div className="absolute z-0 bottom-0 right-0 translate-x-[40%] translate-y-[40%] w-60 h-60 lg:w-96 lg:h-96 rounded-full border-[60px] md:border-[80px] lg:border-[80px] border-gray-700/30"></div>
          <div className="flex flex-col items-center md:items-start w-[95%] md:w-[95%] py-3 md:py-8">
            <div className="w-full z-10">
              <h1 className="text-xl md:text-3xl text-center md:text-left text-slate-50 font-extrabold">
                Queries, feedback or just plain curious? We welcome them all!
              </h1>
            </div>

            <span className="z-10 text-xl text-center md:text-left md:text-3xl lg:text-3xl pt-3 font-semibold text-[#f7cf5f]">
              <Typewriter
                options={{
                  strings: [
                    "Contact us for details.",
                    "Reach out with your questions.",
                    "Our team is here to assist you.",
                    "Feel free to inquire for further information.",
                    "Contact us for specific needs.",
                    "We're available to help.",
                    "Reach out for additional insights.",
                    "Get in touch for details on our products/services.",
                  ],
                  autoStart: true,
                  loop: true,
                  typeSpeed: 10,
                }}
              />
            </span>

            <div className="md:w-[70%] text-center md:text-left text-base text-slate-50 z-10 pt-5">
              Whether you're on a quest for the perfect influencer partnership
              or just want to chat about the mysteries of the social media
              cosmos, drop us a line! Our communication channels are like
              wormholes—connecting us instantly.
            </div>
            <div className="mt-4 z-10">
              <a href="mailto:contact@viralpitch.co">
                <button className="text-slate-900 py-2 px-6 bg-[#f7cf5f] hover:bg-[#323237] hover:text-slate-50 duration-200 rounded-lg">
                  Write to us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
