import React, { useRef } from "react";
import { Select } from "antd";
import "antd"
import {
    Label,
    TextInput,
    Tooltip,
} from "flowbite-react";
import axiosInstance from "../../interseptor";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
    HiEye,
    HiPlus,
    HiRefresh,
    HiTrash,
} from "react-icons/hi";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCloudDownloadAlt, FaFacebook, FaInstagram, FaRegEdit, FaTiktok, FaYoutube } from "react-icons/fa";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import { MdAdd, MdDeleteForever } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import EditReportNameModal from "../ReportPage/EditReportNameModal";
import { BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import ViralTableSkelaton from "../viralbrandreport/ViralTableSkelaton";
import { decryptData } from "../../components/Auth/Encryption";


const GET_REPORT_LIST_URL = "/livesearch/reportlist";
const UPLOAD_HASHTAG_REPORT_URL = "/livesearch/create_hashtagreport";

const CSVFILE_URL = `https://viralpitch.co/d2/dashboard/sample-report-csv.csv`

const HashtagReport = () => {
    const { Option } = Select;
    const [reportList, setReportList] = useState([]);

    const [reportName, setReportName] = useState("");
    const [brandName, setBrandName] = useState("");
    const [brandHandle, setBrandHandle] = useState("");
    const [competitorNames, setCompetitorNames] = useState([""]);
    const [competitorHandles, setCompetitorHandles] = useState([""]);


    const [loading, setLoading] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [selectPlatform, setSelectPlatform] = useState('instagram')
    const [editReportName, setEditReportName] = useState(false)
    const [typedKeywords, setTypedKeywords] = useState([]);
    const [competitorTypedKeywords, setCompetitorTypedKeywords] = useState([]);
    const [competitorTypedKeywords1, setCompetitorTypedKeywords1] = useState([]);
    const [searchValue, setSearchValue] = useState([])
    const [competitorSearchValue, setCompetitorSearchValue] = useState([])
    const [competitorSearchValue1, setCompetitorSearchValue1] = useState([])
    const [selectedPostTime, setSelectedPostTime] = useState('');
    const [selectedReportTime, setSelectedReportTime] = useState('');
    const [isError, setIsError] = useState('')
    const [sortedPlatform, setSortedPlatform] = useState("")

    const [addMoreCompetitor, setAddMoreCompetitor] = useState(false);
    const containerRef = useRef(null);


    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc')


    const handleSort = (column) => {
        // setLoading(true)
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }

    };


    const [competitorData, setCompetitorData] = useState([
        {
            name: "",
            handle: "",
            hashtags: []
        }
    ]);


    const handleCompetitorNameChange = (e, index) => {
        const updatedCompetitorData = [...competitorData];
        updatedCompetitorData[index].name = e.target.value;
        setCompetitorData(updatedCompetitorData);
    };

    const handleCompetitorHandleChange = (e, index) => {
        const updatedCompetitorData = [...competitorData];
        updatedCompetitorData[index].handle = e.target.value;
        setCompetitorData(updatedCompetitorData);
    };

    const handleCompetitorHashtagChange = (value, index) => {
        const updatedCompetitorData = [...competitorData];
        updatedCompetitorData[index].hashtags = value;
        setCompetitorData(updatedCompetitorData);
    };

    const handleAddMoreCompetitor = () => {
        setAddMoreCompetitor(true)
        setCompetitorData([
            ...competitorData,
            {
                name: "",
                handle: "",
                hashtags: []
            }
        ]);
    };

    const handleDeleteCompetitor = (index) => {
        const updatedCompetitorData = competitorData.filter((_, i) => i !== index);
        setCompetitorData(updatedCompetitorData);
    };


    // const handleCompetitorNameChange = (e, index) => {
    //     const updatedCompetitorNames = [...competitorNames];
    //     updatedCompetitorNames[index] = e.target.value;
    //     setCompetitorNames(updatedCompetitorNames);
    // };

    // const handleCompetitorHandleChange = (e, index) => {
    //     const updatedCompetitorHandles = [...competitorHandles];
    //     updatedCompetitorHandles[index] = e.target.value;
    //     setCompetitorHandles(updatedCompetitorHandles);
    // };

    // const handleAddMoreCompetitor = () => {
    //     setAddMoreCompetitor(true)
    //     setCompetitorNames([...competitorNames, ""]);
    //     setCompetitorHandles([...competitorHandles, ""]);
    // };


    const handlePostDateChange = (event) => {
        const selectedValue = event.target.value;
        // Extract numeric value from the selected option
        const numericValue = parseInt(selectedValue, 10);
        setSelectedPostTime(numericValue);
    };

    const handleReportDateChange = (event) => {
        const selectedValue = event.target.value;
        // Extract numeric value from the selected option
        const numericValue = parseInt(selectedValue, 10);
        setSelectedReportTime(numericValue);
    };

    const datefunc = (datestring) => {
        const createdOn = new Date(datestring);
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC", // Assuming your date string is in UTC
        };
        const formattedDate = createdOn.toLocaleString("en-US", options);
        return formattedDate;
    };


    function handleChange(value) {
        setSearchValue(value);
    }

    // function handleCompetitorHashtagChange(value) {
    //     setCompetitorSearchValue(value);
    // }

    function handleCompetitorHashtagChange1(value) {
        setCompetitorSearchValue1(value);
    }

    function handleSearch(value) {
        // Implement your search logic here if needed
        // For now, we'll set the typedKeywords to selected_keywords
        setTypedKeywords(searchValue || []);
    }

    function handleCompetitorHashtagSearch(value) {
        // Implement your search logic here if needed
        // For now, we'll set the typedKeywords to selected_keywords
        setCompetitorTypedKeywords(competitorSearchValue || []);
    }

    function handleCompetitorHashtagSearch1(value) {
        // Implement your search logic here if needed
        // For now, we'll set the typedKeywords to selected_keywords
        setCompetitorTypedKeywords1(competitorSearchValue1 || []);
    }

    useEffect(() => {
        setTypedKeywords(searchValue || []);
        setCompetitorTypedKeywords(competitorSearchValue || [])
        setCompetitorTypedKeywords1(competitorSearchValue1 || [])
    }, [searchValue, competitorSearchValue, competitorSearchValue1]);

    const handleFileInputChange = (event) => {
        console.log("file", event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    const createReport = async (platform) => {
        setLoading(false);
        setLoading(true)
        let data = new FormData();
        data.append("logintype", "create_hashtagreport");
        data.append("device_id", "seacrh");
        data.append("platform", (platform === 'instagram') ? 1 : (platform === 'youtube') ? 2 : 3);
        data.append("reportname", reportName);
        data.append("brand_name", brandName);
        data.append("brand_handle", brandHandle);
        data.append("brand_hashtag", typedKeywords);
        // data.append("competitor_name", competitorData.map((competitor) => competitor.name));
        // data.append("competitor_handle", competitorData.map((competitor) => competitor.handle));
        // data.append("competitor_hashtag", competitorData.map((competitor) => competitor.hashtags));
        const competitorNamesArray = [];
        const competitorHandlesArray = [];
        const competitorHashtagsArray = [];

        // Process competitor data
        competitorData.forEach((competitor, index) => {
            competitorNamesArray.push(competitor.name);
            competitorHandlesArray.push(competitor.handle);
            competitorHashtagsArray.push(competitor.hashtags);
        });

        // Append arrays to FormData
        data.append("competitor_name", JSON.stringify(competitorNamesArray));
        data.append("competitor_handle", JSON.stringify(competitorHandlesArray));
        data.append("competitor_hashtag", JSON.stringify(competitorHashtagsArray));

        data.append("post_time", selectedPostTime);
        data.append("report_time", selectedReportTime);

        try {
            const response = await axiosInstance.post(UPLOAD_HASHTAG_REPORT_URL, data);
            let validated = false;

            console.log('upl response', response)
            if (response?.data?.status_code == 200) {
                console.log('200 ok')
                if (response?.data?.status == 1) {
                    // console.log('1 ok')
                    closeModal();
                    toast.success(response?.data?.msg, { pauseOnHover: false });
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                } else {
                    closeModal();
                    toast.error(response?.data?.msg, { pauseOnHover: false });
                }
            } else if (response && response?.data && response?.data?.status == 0) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            } else if (response && response?.data && response?.data?.status == 3) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            }

            return response?.data.list;

            console.log("upload res", response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const closeModal = () => {
        setIsCreateOpen(false);
        setReportName("");
        setSelectedFile(null);

        setBrandName("");
        setBrandHandle("");
        setCompetitorNames([""]);
        setCompetitorHandles([""]);
        setTypedKeywords([])
        setCompetitorTypedKeywords([])
        setCompetitorTypedKeywords1([])
        setLoading(false)
    };

    // const handleAddMoreCompetitor = () => {
    //     setAddMoreCompetitor(true)
    // }

    const getReportList = async (sortedPlatform) => {
        try {
            let data = new FormData();
            data.append("logintype", "reportlist");
            data.append("device_id", "seacrh");
            data.append("hashtag_report", 1);
            data.append("platform", sortedPlatform.toLowerCase());

            const response = await axiosInstance.post(GET_REPORT_LIST_URL, data);

            if (response?.data?.status === 0) {
                setIsLoading(false)
                setIsError(response?.data?.msg)
                return
            }

            if (response && response?.status === 200) {
                if (response && response?.data && response?.data?.status === 201) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 2
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 3
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                }

                setReportList(response.data.list.report_list);
                setIsLoading(false)
                console.log("report response", response.data);
                setLoading(false);
                setIsCreateOpen(false);
                return response?.data.list;
            }
        } catch (error) { }
    };


    useEffect(() => {
        // Auto-scroll to the bottom when addMoreCompetitor changes
        if (containerRef.current && addMoreCompetitor) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [addMoreCompetitor]);


    useEffect(() => {
        getReportList(sortedPlatform);
    }, [sortedPlatform]);

    const archievePlan = async (id) => {
        alert(id)
    };

    const handleRefresh = () => {
        window.location.reload();
    }

    const sortByPlatform = ['Filter by Platform', 'Instagram', 'YouTube', 'TikTok'];


    return (
        <div className="p-3 mx-5">
            <ToastContainer autoClose={1500} />
            <div className="flex justify-between items-baseline">
                <div className="flex justify-between mb-2">
                    <h1 className="text-lg font-semibold text-gray-700">Hashtag Reports</h1>
                </div>

                <div className="flex gap-2">
                    {JSON.parse(decryptData(localStorage.getItem('rights_action')))["76"]?.includes("130") && <div>
                        <select
                            className="flex whitespace-nowrap w-[90%] nowrap items-center rounded-[4px] hover:opacity-90 h-[53px] w-auto text-black  px-[24px] border-[0.8px] mr-3 border-[#D9D9D9]"
                            name="date"
                            id="date"
                            onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate === 'Filter by Platform') {
                                    setSortedPlatform("")
                                } else if (selectedDate) {
                                    setSortedPlatform(selectedDate)
                                }
                            }}
                        >
                            {sortByPlatform.map((data, index) => (
                                <option className="cursor-pointer my-4" key={index} value={data}>
                                    {data}
                                </option>
                            ))}
                        </select>
                    </div>}

                   {JSON.parse(decryptData(localStorage.getItem('rights_action')))["76"]?.includes("131") &&  <button
                        className="flex whitespace-nowrap w-[90%] nowrap items-center rounded-[4px] hover:opacity-90 h-[53px] w-auto text-black  px-[24px] border-[0.8px] mr-3 border-[#D9D9D9]"
                        onClick={() => setIsCreateOpen(true)}
                    >
                        <HiPlus className="mr-0.5" />
                        Create Report
                    </button>}
                </div>


                {isCreateOpen && (
                    <div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
                        <div className="fixed top-16 left-1/2 -translate-x-[50%] z-50 bg-white border-2 border-gray-500 p-8 w-2/5 rounded-xl shadow-md">

                            <div className="flex justify-end">
                                <AiOutlineClose
                                    onClick={closeModal}
                                    className="cursor-pointer"
                                />
                            </div>

                            <div className="w-full flex gap-2 py-2">
                                <button onClick={() => setSelectPlatform('instagram')} className={`${selectPlatform === 'instagram' ? 'bg-[#6d5eac] text-white' : ''} min-w-[120px] w-full border p-2 rounded-lg flex gap-1 justify-center items-center`}>
                                    <FaInstagram className="" /> Instagram
                                </button>
                                <button onClick={() => setSelectPlatform('youtube')} className={`${selectPlatform === 'youtube' ? 'bg-[#6d5eac] text-white' : ''} min-w-[120px] w-full border p-2 rounded-lg flex gap-1 justify-center items-center`}>
                                    <FaYoutube className="text-lg" /> YouTube
                                </button>
                                <button onClick={() => setSelectPlatform('tiktok')} className={`${selectPlatform === 'tiktok' ? 'bg-[#6d5eac] text-white' : ''} min-w-[120px] w-full border p-2 rounded-lg flex gap-1 justify-center items-center`}>
                                    <FaTiktok /> TikTok
                                </button>
                            </div>


                            <div ref={containerRef} className={`h-[250px] overflow-${addMoreCompetitor ? 'auto pr-2' : 'auto'}`}>
                                <TextInput
                                    id="reportname"
                                    type="text"
                                    placeholder="Enter Report Name"
                                    style={{ backgroundColor: 'white' }}
                                    value={reportName}
                                    onChange={(e) => setReportName(e.target.value)}
                                    required={true}
                                    shadow={true}
                                />

                                <div className="flex gap-2 w-full">

                                    <TextInput
                                        id="brandname"
                                        className="mt-2 w-full"
                                        type="text"
                                        placeholder="Brand Name"
                                        style={{ backgroundColor: 'white' }}
                                        value={brandName}
                                        onChange={(e) => setBrandName(e.target.value)}
                                        required={true}
                                        shadow={true}
                                    />

                                    <TextInput
                                        id="brandhandle"
                                        className="mt-2 w-full"
                                        type="text"
                                        placeholder="Brand Handle"
                                        style={{ backgroundColor: 'white' }}
                                        value={brandHandle}
                                        onChange={(e) => setBrandHandle(e.target.value)}
                                        required={true}
                                        shadow={true}
                                    />

                                </div>

                                <div className="mt-2">
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        onChange={handleChange}
                                        onSearch={handleSearch}
                                        tokenSeparators={[',']}
                                        className="w-[100px] rounded-md py-1.5 border border-gray-300 shadow-sm text-gray-600"
                                        placeholder={<span style={{ color: 'your-desired-color' }}>Brand Hashtag</span>}
                                        notFoundContent={<span className="ant-empty-description pl-2 py-5 text-gray-500">Write Hashtags</span>}
                                        value={typedKeywords}
                                    >
                                        {typedKeywords.map((keyword) => (
                                            <Option key={keyword}>{keyword}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className="flex gap-2 w-full">
                                    <TextInput
                                        id="competitorname"
                                        className="mt-2 w-full"
                                        type="text"
                                        placeholder="Competitor Name"
                                        style={{ backgroundColor: 'white' }}
                                        value={competitorData[0].name}
                                        onChange={(e) => handleCompetitorNameChange(e, 0)}
                                        required={true}
                                        shadow={true}
                                    />
                                    <TextInput
                                        id="competitorhandle"
                                        className="mt-2 w-full"
                                        type="text"
                                        placeholder="Competitor Handle"
                                        style={{ backgroundColor: 'white' }}
                                        value={competitorData[0].handle}
                                        onChange={(e) => handleCompetitorHandleChange(e, 0)}
                                        required={true}
                                        shadow={true}
                                    />
                                </div>

                                <div className="mt-2 flex gap-2 w-full">
                                    <div className="w-full">
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleCompetitorHashtagChange(value, 0)}
                                            onSearch={(value) => handleCompetitorHashtagSearch(value, 0)}
                                            tokenSeparators={[',']}
                                            className="w-[100px] rounded-md py-1.5 border border-gray-300 shadow-sm text-gray-600"
                                            placeholder={<span style={{ color: 'your-desired-color' }}>Competitor Hashtag</span>}
                                            notFoundContent={<span className="ant-empty-description pl-2 py-5 text-gray-500">Write Hashtags</span>}
                                            value={competitorData[0].hashtags}
                                        >
                                            {competitorTypedKeywords.map((keyword) => (
                                                <Option key={keyword}>{keyword}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div
                                        onClick={handleAddMoreCompetitor}
                                        title="Add More Competitor"
                                        className="border hover:border-2 border-[#1dbb99] flex items-center cursor-pointer justify-center w-14 rounded-lg">
                                        <MdAdd className='text-[#1dbb99] text-2xl' />
                                    </div>
                                </div>

                                {
                                    addMoreCompetitor && competitorData.slice(1).map((competitor, index) => (
                                        <>
                                            <div className="flex gap-2 w-full">
                                                <TextInput
                                                    id={`competitorname${index + 1}`}
                                                    className="mt-2 w-full"
                                                    type="text"
                                                    placeholder={`Competitor ${index + 2} Name`}
                                                    style={{ backgroundColor: 'white' }}
                                                    value={competitor.name}
                                                    onChange={(e) => handleCompetitorNameChange(e, index + 1)}
                                                    required={true}
                                                    shadow={true}
                                                />
                                                <TextInput
                                                    id={`competitorhandle${index + 1}`}
                                                    className="mt-2 w-full"
                                                    type="text"
                                                    placeholder={`Competitor ${index + 2} Handle`}
                                                    style={{ backgroundColor: 'white' }}
                                                    value={competitor.handle}
                                                    onChange={(e) => handleCompetitorHandleChange(e, index + 1)}
                                                    required={true}
                                                    shadow={true}
                                                />
                                            </div>

                                            <div className="mt-2 flex gap-2 w-full">
                                                <div className="w-full">
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => handleCompetitorHashtagChange(value, index + 1)}
                                                        onSearch={(value) => handleCompetitorHashtagSearch(value, index + 1)}
                                                        tokenSeparators={[',']}
                                                        className="w-[100px] rounded-md py-1.5 border border-gray-300 shadow-sm text-gray-600"
                                                        placeholder={<span style={{ color: 'your-desired-color' }}>2nd Competitor Hashtag</span>}
                                                        notFoundContent={<span className="ant-empty-description pl-2 py-5 text-gray-500">Write Hashtags</span>}
                                                        value={competitor.hashtags}
                                                    >
                                                        {competitor.hashtags.map((keyword) => (
                                                            <Option key={keyword}>{keyword}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div
                                                    onClick={() => handleDeleteCompetitor(index + 1)}
                                                    title="Delete Competitor"
                                                    className="flex items-center cursor-pointer justify-center w-14 rounded-lg">
                                                    <MdDeleteForever className='text-red-600 text-2xl' />
                                                </div>
                                            </div>
                                        </>
                                    ))}

                            </div>

                            <div className="mt-2 w-full">
                                <div className="flex justify-between gap-2">
                                    <div className="w-full">
                                        <select
                                            className="w-full rounded-lg border border-gray-300 text-gray-600 text-sm"
                                            name="pets1"
                                            id="pet-select1"
                                            value={selectedPostTime}
                                            onChange={handlePostDateChange}
                                        >
                                            <option disabled value="">Post Within</option>
                                            <option value="1">1 Day</option>
                                            <option value="7">7 Days</option>
                                            <option value="15">15 Days</option>
                                            <option value="30">1 Month</option>
                                            <option value="90">3 Months</option>
                                            <option value="180">6 Months</option>
                                        </select>
                                    </div>
                                    {/* <div className="w-full">
                                        <select
                                            className="w-full rounded-lg border border-gray-300 text-gray-600 text-sm"
                                            name="pets2"
                                            id="pet-select2"
                                            value={selectedReportTime}
                                            onChange={handleReportDateChange}
                                        >
                                            <option disabled value="">Report Update Till</option>
                                            <option value="1">1 Day</option>
                                            <option value="7">7 Days</option>
                                            <option value="15">15 Days</option>
                                            <option value="30">1 Month</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>


                            <div className="flex gap-2">
                                {
                                    loading ?
                                        <button className="mt-5 bg-[#1dbb99] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                                            <LoadingSpinner /> Creating
                                        </button>
                                        :
                                        <button
                                            disabled={reportName === '' || brandName === '' || typedKeywords.length === 0}
                                            className="mt-5 min-w-[130px] w-full bg-[#1dbb99] text-white p-2 rounded-lg shadow-md"
                                            onClick={() => createReport(selectPlatform)}
                                        >
                                            Create Report
                                        </button>
                                }
                                <button
                                    className="mt-5 min-w-[130px] w-full bg-[#323237] text-white p-2 rounded-lg shadow-md"
                                    onClick={closeModal}
                                >
                                    Clear
                                </button>
                            </div>

                        </div>
                    </div>
                )}
            </div>


            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="static top-0 border  bg-[#EEF5FF]  text-[#828282] whitespace-nowrap">
                    <tr className="h-14 text-black">
                        <th className="text-sm font-medium capitalize pl-2">Report Name</th>
                        <th onClick={() => handleSort('platform')} className="text-sm font-medium capitalize">
                            <div className="cursor-pointer flex items-center gap-0.5">
                                Platform
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        {/* <th className="text-sm font-medium capitalize">Total Post</th> */}
                        <th onClick={() => handleSort('added_on')} className="text-sm font-medium capitalize">
                            <div className="cursor-pointer flex items-center gap-0.5">
                                Added On
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th onClick={() => handleSort('last_update')} className="text-sm font-medium capitalize">
                            <div className="cursor-pointer flex items-center gap-0.5">
                                Last Updated
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize"></th>
                        <th className="text-sm font-medium capitalize"></th>
                    </tr>
                </thead>
                <tbody className="divide-y text-black">
                    {isLoading ? <>
                        <ViralTableSkelaton />
                    </> : reportList &&
                    reportList.sort((a, b) => {
                        if (sortColumn) {
                            if (sortOrder === 'asc') {
                                return a[sortColumn] > b[sortColumn] ? 1 : -1;
                            } else {
                                return a[sortColumn] < b[sortColumn] ? 1 : -1;
                            }
                        }
                        return 0; // No sorting
                    }).map((report) => (
                        <tr className="h-14 border bg-white " key={report.report_id}>
                            <td className="pl-2 flex items-center font-semibold gap-1">
                                {report.report_name}
                           {JSON.parse(decryptData(localStorage.getItem('rights_action')))["76"]?.includes("132") &&     <EditReportNameModal uniqueId={report.report_id} />}
                            </td>
                            <td>
                                {report.platform === 'Instagram' ? <BsInstagram className="text-rose-600 text-lg" /> :
                                    report.platform === 'Tiktok' ? <BsTiktok className="text-black text-lg" /> :
                                        report.platform === 'Youtube' ? <BsYoutube className="text-red-600 text-lg" /> : ""}
                            </td>
                            {/* <td> <span className="py-1 px-2 rounded-md shadow-lg bg-[#1dbb99] text-white">{report.total_posts}</span></td> */}
                            <td>{datefunc(report.added_on)}</td>
                            <td>{datefunc(report.last_update)}</td>

                            <td className="">
                                <div className="flex items-center gap-2">
                                    <Tooltip content="Refresh Report">
                                        <button
                                            onClick={() => handleRefresh()}
                                            className="flex items-center text-[#234E70] p-2 text-xl rounded-md hover:bg-[#323237] hover:text-white"
                                        >
                                            <HiRefresh className="text-xl hover:scale-110 duration-100" />
                                        </button>
                                    </Tooltip>
                                    {/* <Tooltip content="Archieve Report">
                    <button
                      onClick={() => archievePlan(report.report_id)}
                      className="flex items-center text-[#234E70] p-2 text-xl rounded-md hover:bg-[#323237] hover:text-white"
                    >
                      <HiTrash className="text-xl hover:scale-110 duration-100" />
                    </button>
                  </Tooltip> */}
                                </div>
                            </td>

                            <td className="flex items-center justify-center h-14">
                              {JSON.parse(decryptData(localStorage.getItem('rights_action')))["76"]?.includes("132") &&  <Link
                                    to={`/hashtagreport/${report.report_id}`}
                                    className="flex items-center px-2 py-1.5 rounded-md bg-[#1dbb99] hover:opacity-90 text-sm text-white"
                                >
                                    <HiEye />
                                    &nbsp;View Report
                                </Link>}
                            </td>
                        </tr>
                    ))}
                    {isError !== '' && <>
                        <tr className="h-14 relative even:bg-gray-100">
                            <td className="w-full absolute p-5 text-center">
                                <p>{isError}</p>
                            </td>
                        </tr>
                    </>}
                </tbody>
            </table>
        </div>
    )
}

export default HashtagReport
