import React, { useRef } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons"; // Import DownOutlined
import "antd";
import { debounce } from "lodash";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAudienceLookALikeFilter, setCreatorLookALikeFilter } from "../../redux/filters/actions";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const MAX_COUNT = 1;

const CreatorLookALike = ({hoverStates, handleMouseOver, selectedPlatform, lookalikeDropdown, setLookalikeDropdown }) => {
  const dispatch = useDispatch();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const creatorLookALikeUrl = searchParams.get("creatorLookALike")
  const audienceLookALikeUrl = searchParams.get("audienceLookALike")
  
  const creatorLookALikeArray = creatorLookALikeUrl ? creatorLookALikeUrl.split(",") : [];
  const audienceLookALikeArray = audienceLookALikeUrl ? audienceLookALikeUrl.split(",") : [];

  const [searchParam, setSearchParam] = useSearchParams();
  const selected_creator_lookalike = useSelector((state) => state.creatorLookALike);
  const [topicsData, setTopicsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState(
    selected_creator_lookalike?.map((data) => data?.username) || []
  );

  const selected_audience_lookalike = useSelector((state) => state.audienceLookALike);
  const [topicsAudienceData, setTopicsAudienceData] = useState([]);
  const [selectedAudienceTopics, setSelectedAudienceTopics] = useState(selected_audience_lookalike?.map((data) => data?.username) || []);

  const handleAudienceTopicsChange = (value) => {
    console.log(value, 'selectedAudienceTopics')
    setSelectedAudienceTopics(value);
    dispatch(setAudienceLookALikeFilter(value))
    if(value.length > 0){
      setSearchParam(searchParams => {
          searchParams.set("audienceLookALike", value.join(','))
          return searchParams;
      });
    }else{
      setSearchParam(searchParams => {
        searchParams.delete("audienceLookALike")
        return searchParams;
      });
    }
  };

  const fetchTopics = async (value) => {
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "lookalike");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append("platform", selectedPlatform.toLocaleLowerCase());
      formData.append("search_string", value || "");

      // Using axiosInstance to make the POST request
      const response = await axiosInstance.post(
        "livesearch/getfilters",
        formData
      );
      const result = response.data;

      if (result.status === 1) {
        setTopicsData(result.response.data.data);
        setTopicsAudienceData(result.response.data.data)
        // console.log("lookalike data", result.response.data.data);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchTopics = (e) => {
    // handleMouseOver("isCreatorLookALike");
    // Fetch topics for the initially selected value
    fetchTopics(selectedTopics);
    e.stopPropagation();
    setLookalikeDropdown(!lookalikeDropdown); // Toggle the dropdown
  };

  const handleTopicsChange = (value) => {
    // console.log(value, 'selectedAudienceTopics')
    setSelectedTopics(value);
    dispatch(setCreatorLookALikeFilter(value));
    if(value.length > 0){
      setSearchParam(searchParams => {
          searchParams.set("creatorLookALike", value.join(','))
          return searchParams;
        });
    }else{
      setSearchParam(searchParams => {
        searchParams.delete("creatorLookALike")
        return searchParams;
        });
    }
  };

  // Use debounce to limit API calls when the user is typing
  const debouncedFetchTopics = debounce(fetchTopics, 300);

  const handleTopicsSearch = (value) => {
    // Fetch topics based on user input
    debouncedFetchTopics(value);
  };

  const suffix = (
    <>
      <span>
        {selectedTopics.length} / {MAX_COUNT}
      </span>
      <DownOutlined />
    </>
  );

  return (
    <>
      <div className="Topics flex justify-start">
        <div className="relative w-full">
          <button
            onClick={(e) => handleSearchTopics(e)}
            className="filterTextColor flex items-center bg-white"
          >
            <span
              className={` ${
                lookalikeDropdown ? "font-semibold" : ""
              } flex items-center`}
            >
              {/* {isDropdownOpen ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
              Lookalike
              <span
                className={` ${
                  lookalikeDropdown ? "" : ""
                } flex items-center`}
              >
                {lookalikeDropdown ? (
                  <RxCross2 onClick={(e) => {e.stopPropagation(); setLookalikeDropdown(false)}} className="ml-2 text-lg" />
                ) : (
                  <IoChevronDownSharp className="ml-2 text-lg" />
                )}
              </span>
            </span>
          </button>
          <ul
            className={`absolute w-[220px] z-50 p-5 bg-white rounded-lg shadow-lg ${
              lookalikeDropdown ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-5">
              <div
                // onClick={() => fetchTopics(selectedAudienceTopics)}
                onClick={handleSearchTopics}
                className="flex flex-col gap-2 w-full"
              >
                <div className={`flex items-center gap-2`}>
                  <MdPeopleAlt className="text-2xl" /> Audience
                  <InfoIconTooltip
                    infoContent={`Audience lookalikes help you find influencers that have similar audience.`}
                  />
                </div>
                <Select
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  suffixIcon={suffix}
                  bordered={false}
                  className="w-full rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-[#3D3D3D]"
                  placeholder="Search LookALikes"
                  loading={loading}
                  value={audienceLookALikeArray}
                  onChange={handleAudienceTopicsChange}
                  onSearch={handleTopicsSearch}
                  notFoundContent={
                    <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                      Start Typing...
                    </span>
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {topicsAudienceData?.map((data) => (
                    <Select.Option key={data?.user_id} value={data?.username}>
                      <div className="flex gap-2 items-center">
                        {data?.username}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div
                onClick={() => fetchTopics(selectedTopics)}
                className="flex flex-col gap-2 w-full"
              >
                <div className="flex items-center gap-2">
                  <MdCampaign className="text-2xl" /> Influencer
                  <InfoIconTooltip
                    infoContent={`We help you find influencers that post similar content.`}
                  />
                </div>
                <Select
                  mode="multiple"
                  maxCount={MAX_COUNT}
                  suffixIcon={suffix}
                  bordered={false}
                  className="w-full rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-[#3D3D3D]"
                  placeholder="Search LookALikes"
                  loading={loading}
                  value={creatorLookALikeArray}
                  onChange={handleTopicsChange}
                  onSearch={handleTopicsSearch}
                  notFoundContent={
                    <span className="ant-empty-description text-[#3D3D3D]">
                      Start Typing...
                    </span>
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {topicsData?.map((data) => (
                    <Select.Option key={data?.user_id} value={data?.username}>
                      <div className="flex gap-2 items-center">
                        {data?.username}
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CreatorLookALike;
