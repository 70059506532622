import React from "react";
import { useState } from 'react';

export default function useToken() {

  const getToken = () => {
    
    const tokenString = localStorage.getItem('token_data');
    const isauthenticated = localStorage.getItem('authenticated');
    const userToken = tokenString;
    
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
  
    if(userToken !== false){
      // console.log('%c saveToken token started', 'background: #222; color: #bada55');
      localStorage.setItem('token_data', JSON.stringify(userToken?.token_data));
      // console.log('token_data', userToken.token_data);
      localStorage.setItem('userdata', JSON.stringify(userToken?.data));
      // console.log('userdata', userToken.data);
      localStorage.setItem("authenticated", true);
      // console.log(userToken)
      setToken(userToken);
      // console.log('%c localStorage saveToken set', 'background: #222; color: #ff0000');
    } else{
      console.log('localStorage saveToken undefined')
    }
    
  };

  return {
    setToken: saveToken,
    token
  }
}