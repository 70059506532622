import React, { useEffect, useRef, useState } from "react";
import { setFollowerFilter } from "../../redux/filters/actions";
import { useDispatch } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { IoChevronDownSharp } from "react-icons/io5";
import { useLocation, useSearchParams } from "react-router-dom";
import { MdCampaign } from "react-icons/md";
import InfoIconTooltip from "./InfoIconTooltip";
import useDropdownPosition from "./useDropdownPosition";

const FollowersAllFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParam, setSearchParam] = useSearchParams();

  const followersUrl = searchParam.get("followers");
  const followersArray = followersUrl ? followersUrl.split(",").map(Number) : [null, null];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);
  const [customFromValue, setCustomFromValue] = useState("");
  const [customToValue, setCustomToValue] = useState("");

  const convertToNumeric = (value) => {
    if (value === "1k") return 1000;
    if (value === "5k") return 5000;
    if (value === "10k") return 10000;
    if (value === "25k") return 25000;
    if (value === "50k") return 50000;
    if (value === "100k") return 100000;
    if (value === "250k") return 250000;
    if (value === "500k") return 500000;
    if (value === ">1m") return 1000000;
    if (value === "<1m") return 1000000;
    return parseInt(value);
  };

  const updateFilters = (newFollowersArray) => {
    const sanitizedValues = newFollowersArray.filter((value) => value !== null);
    dispatch(setFollowerFilter(sanitizedValues));

    if (sanitizedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("followers", sanitizedValues.join(","));
        return searchParams;
      });
    } else {
      searchParam.delete("followers");
      setSearchParam(searchParam);
    }
  };

  const handleFromChange = (value) => {
    const newFollowersArray = value === "Clear" ? [null, followersArray[1]] : [convertToNumeric(value), followersArray[1]];
    updateFilters(newFollowersArray);
    setFromDropdownOpen(false);
  };

  const handleToChange = (value) => {
    const newFollowersArray = value === "Clear" ? [followersArray[0], null] : [followersArray[0], convertToNumeric(value)];
    updateFilters(newFollowersArray);
    setToDropdownOpen(false);
  };

  const handleCustomFromChange = () => {
    if (customFromValue !== "") {
      const newFollowersArray = [parseInt(customFromValue), followersArray[1]];
      updateFilters(newFollowersArray);
    }
    setFromDropdownOpen(false);
    setCustomFromValue("");
  };

  const handleCustomToChange = () => {
    if (customToValue !== "") {
      const newFollowersArray = [followersArray[0], parseInt(customToValue)];
      updateFilters(newFollowersArray);
    }
    setToDropdownOpen(false);
    setCustomToValue("");
  };

  const fromOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];
  const toOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];

  return (
    <div ref={buttonRef} onClick={() => {setFromDropdownOpen(false); setToDropdownOpen(false)}} className="Followers flex justify-start">
      <div className="relative w-full">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex filterTextColor items-center bg-white"
        >
          <span
            className={`${isDropdownOpen ? "font-semibold" : ""} ${
              location.pathname.includes("result")
                ? "ml-5 h-[40px] whitespace-nowrap"
                : ""
            }  flex items-center`}
          >
            Followers{" "}
            {isDropdownOpen ? (
              <RxCross2 className="ml-2 text-lg" />
            ) : (
              <IoChevronDownSharp className="ml-2  text-lg" />
            )}
          </span>
        </button>
        <ul
          ref={dropdownRef}
          className={`w-[230px] absolute ${
            location.pathname.includes("result") ? "left-[20px]" : ""
          }  z-40 p-5 bg-white shadow-lg rounded-lg ${
            isDropdownOpen ? "block" : "hidden"
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <MdCampaign className="text-2xl" /> Influencer
              <InfoIconTooltip infoContent="Identify influencers by the keywords they use in their posts texts." />
            </div>
            <div
              className={`flex gap-2 ${
                followersArray[0] !== 1000000 ? "w-full" : "w-[110px]"
              }`}
            >
              <div className="relative">
                <input
                  type="text"
                  className="w-full py-2 rounded-[4px] border-[0.6px] border-[#D9D9D9]"
                  placeholder="From"
                  value={followersArray[0] !== null ? followersArray[0] : ""}
                  onClick={(e) => {e.stopPropagation(); setFromDropdownOpen(!fromDropdownOpen); setToDropdownOpen(false)}}
                  readOnly
                />
                {fromDropdownOpen && (
                  <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                    {fromOptions.map((option) => (
                      <div
                        key={option}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleFromChange(option)}
                      >
                        {option}
                      </div>
                    ))}

                    {/* <div className="p-1 flex flex-col">
                      <input
                        type="number"
                        className="w-full py-1.5 rounded-md border-[0.6px] border-[##D9D9D9] mb-1 text-xs"
                        placeholder="Custom"
                        value={customFromValue}
                        onChange={(e) => setCustomFromValue(e.target.value)}
                      />
                      <button
                        className="px-2 py-1.5 bg-[##D9D9D9] text-white rounded-md cursor-pointer"
                        onClick={handleCustomFromChange}
                      >
                        Add
                      </button>
                    </div> */}
                  </div>
                )}
              </div>

              {followersArray[0] !== 1000000 && (
                <div className="relative">
                  <input
                    type="text"
                    className="w-full py-2 rounded-[4px] border-[0.6px] border-[#D9D9D9]"
                    placeholder="To"
                    value={followersArray[1] !== null ? followersArray[1] : ""}
                    onClick={(e) => {e.stopPropagation(); setToDropdownOpen(!toDropdownOpen); setFromDropdownOpen(false)}}
                    readOnly
                  />
                  {toDropdownOpen && (
                    <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                      {toOptions.map((option) => (
                        <div
                          key={option}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleToChange(option)}
                        >
                          {option}
                        </div>
                      ))}

                      {/* <div className="p-1 flex flex-col">
                        <input
                          type="number"
                          className="w-full py-1.5 rounded-md border-[0.6px] border-[#D9D9D9] mb-1 text-xs"
                          placeholder="Custom"
                          value={customToValue}
                          onChange={(e) => setCustomToValue(e.target.value)}
                        />
                        <button
                          className="px-2 py-1.5 bg-[#D9D9D9] text-white rounded-md cursor-pointer"
                          onClick={handleCustomToChange}
                        >
                          Add
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default FollowersAllFilter;