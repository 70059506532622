import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { BsPeopleFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setEthnicityFilter } from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import InfoIconTooltip from "./InfoIconTooltip";
import { useLocation, useSearchParams } from "react-router-dom";

const EthnicityFilter = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const ethnicityURL = searchParams.get("ethnicity")
  const ethnicityArray = ethnicityURL ? ethnicityURL.split(",") : []

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedEthnicity, setSelectedEthnicity] = useState(1); // Single selection state
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(">30%");
  const dispatch = useDispatch();

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);

    handleRadioChange(selectedEthnicity, e.target.value)
  };

  const options = [
    { id: 1, name: "Any" },
    { id: 2, name: "African Descent" },
    { id: 3, name: "Asian" },
    { id: 4, name: "White / Caucasion" },
    { id: 5, name: "Hispanic American" },
  ];

  let percentage = [
    1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95,
  ];

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleRadioChange = (id, percentage) => {
    setSelectedEthnicity(id);

    let filterValue;
    if (id === 1) {
      filterValue = ["Any"];
      dispatch(setEthnicityFilter([]));
      setSearchParam((searchParams) => {
        searchParams.delete("ethnicity");
        return searchParams;
      });
    } else {
      const option = options.find((opt) => opt.id === id);
      filterValue = option ? [option.name] : [];
    }
    if(filterValue[0] !== 'Any'){
      dispatch(setEthnicityFilter([filterValue + "-k-" + (percentage || selectedValue)]));
      
      setSearchParam((searchParams) => {
        searchParams.set("ethnicity", [filterValue + "-k-" + (percentage || selectedValue)]);
        return searchParams;
      });
    }
  };

  const ethnicityIdAndName = ethnicityArray.map((value) => {
    const [id, name] = value.split("-k-"); // Assuming the format is "id-k-name"
    return { id, name };
  });
  
  
  useEffect(() => {
    if(ethnicityArray.length === 0){
      setSelectedEthnicity(1)
    }
  },[ethnicityURL])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="Ethnicity dropdown">
      <button
        className={`filterTextColor flex items-center`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {/* {showDropdown ? (
          <RxCross2 className="mr-1 text-lg" />
        ) : (
          <BiPlus className="mr-1 text-lg" />
        )} */}
        <span
              className={`filterTextColor ${
                isDropdownOpen ? "font-semibold" : ""
              } flex items-center`}
              >
              Ethnicity
              {isDropdownOpen ? (
                <RxCross2 className="ml-2 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-2 text-lg" />
              )}
              
            </span>
      </button>
      {isDropdownOpen && (
        <div
          className={`absolute w-fit border-[0.6px] border-[#D9D9D9] z-50 p-5 bg-white rounded-lg shadow-lg`}
        >
          <div className="dropdown-content flex flex-col gap-2">
            <h2 className="text-[#3D3D3D] flex items-center gap-1 text-lg">
              <BsPeopleFill /> Audience
              <InfoIconTooltip infoContent={'Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts.'} />
            </h2>
            {options.map((option) => (
              <label
                key={option.id}
                className="radio-label flex items-center gap-1"
              >
                <input
                  type="radio"
                  name="ethnicity"
                  checked={selectedEthnicity === option.id}
                  onChange={() => handleRadioChange(option.id)}
                  className="left-2 rounded-[4px] bg-gray-100 border border-gray-300 w-3.5 h-3.5"
                />
                {option.name}
              </label>
            ))}

            {selectedEthnicity !== 1 && (
              <div className="border rounded-lg">
                <select
                  className="w-full"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {percentage.map((item, index) => (
                    <option key={index} value={`>${item}%`}>
                      {`>${item}%`}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EthnicityFilter;