'use client';

import { useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'flowbite-react';
import { FaRegEdit } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from '../../interseptor';

const EditReportNameModal = ({ uniqueId }) => {
    const [openModal, setOpenModal] = useState(false);
    const [edittedName, setEdittedName] = useState('')


    const handleSetReportName = async (correctName, id) => {
        // var myHeaders = new Headers();
        // myHeaders.append("Authorization", "Bearer mR2n1pdAkva6RWmorAchWgnzaabsdohj5fmRgcdXEz8PRFunBU");
        // myHeaders.append("Cookie", "PHPSESSID=p26ob1kfmis8aqv94vr8oud5nr");

        // var formdata = new FormData();
        // formdata.append("logintype", "editreport");
        // formdata.append("device_id", "seacrh");
        // formdata.append("reportid", id);
        // formdata.append("report_name", correctName); // Use the variable directly here

        // var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata,
        //     redirect: 'follow'
        // };

        try {
            const formdata = new FormData();
            formdata.append('logintype', 'editreport');
            formdata.append('device_id', 'seacrh');
            formdata.append('reportid', id);
            formdata.append('report_name', correctName);

            const response = await axiosInstance.post('/livesearch/editreport', formdata);
            console.log(response.data); // Log the response if needed

            if (response?.data?.status === 1) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setOpenModal(false);
            } else {
                setOpenModal(false);
                toast.error(response?.data?.msg, { pauseOnHover: false });
            }

        } catch (error) {
            console.error('Error editing report name:', error);
        }
        // fetch("https://test.viralpitch.co/apis/livesearch/editreport", requestOptions)
        //     .then(response => response.text())
        //     .then(result => console.log(result))
        //     .catch(error => console.log('error', error));

        // setTimeout(() => {
        //     window.location.reload();
        // }, 1000);

        // setOpenModal(false);
    };

    return (
        <>
            {/* <Button onClick={() => setOpenModal(true)}>Toggle modal</Button> */}
            <ToastContainer theme="colored" autoClose={1500} />
            <Tooltip content="Edit Name">
                <CiEdit
                    onClick={() => setOpenModal(true)}
                    className="cursor-pointer"
                />
            </Tooltip>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Edit Report Name</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <input
                            type='text'
                            value={edittedName}
                            onChange={(e) => setEdittedName(e.target.value)}
                            placeholder='Edit Name'
                            className='border-[0.6px] border-[#D9D9D9] rounded-lg w-[50%]'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        bg="#6d5eac"
                        style={{ backgroundColor: '#6d5eac' }}
                        onClick={() => handleSetReportName(edittedName, uniqueId)}
                    >
                        Set Name
                    </Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditReportNameModal
