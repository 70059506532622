import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate that an error has occurred
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error:', error);
    console.error('Error Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI when an error occurs
      return <h1 className='p-5'>Something went wrong. <Link to='/home'><span className='text-blue-600'>Go To Home</span></Link> </h1>;
    }

    // Render the child components if no error occurred
    return this.props.children;
  }
}

export default Error;