import { SET_AGE_FILTER } from "../ActionTypes";

const ageReducer = (state = null, action) => {
    switch (action.type) {
      case SET_AGE_FILTER:
        return action.payload;
      default:
        return state;
    }
  };

  export default ageReducer