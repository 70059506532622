import { SET_MENTIONS_FILTER } from "../ActionTypes";

const initialState = [];

const MentionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENTIONS_FILTER:
            const previousValue = state.value;
            console.log('state in MENTIONS', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default MentionsReducer