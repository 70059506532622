import { Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import {IoIosWarning} from 'react-icons/io'

const ErrorMsgModal = ({errorMsgFromAPI}) => {
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (errorMsgFromAPI !== '') {
            setOpenModal(true);
        } else {
            setOpenModal(false);
        }
    }, [errorMsgFromAPI]);

    const handleRedirect = () => {
        window.location.href = 'mailto:contact@viralpitch.co';
      };

    return (
        <Modal show={openModal} onClose={() => setOpenModal(false)}>
            <Modal.Header>Error</Modal.Header>
            <Modal.Body>
                <div className="space-y-2">
                    <div>
                        <IoIosWarning className='text-7xl text-red-600'/>
                    </div>
                    <p className='text-red-600'>{errorMsgFromAPI}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleRedirect} className='p-2 px-4 rounded-lg bg-[#6d5eac] text-white'>Contact Us</button>
                <button onClick={() => setOpenModal(false)} className='p-2 px-4 rounded-lg border bg-gray-100'>Dismiss</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ErrorMsgModal
