import React from 'react'
import { useEffect } from 'react';
import anychart from 'anychart';


const WordCloud = ({title,data}) => {
  // return (
  //   <div className='w-full mt-4'>
  //       <img src="/asset/wordcloud1.jpg" alt="" className='w-[100%] mx-auto' />
  //   </div>
  // )
  
  console.log('data in cloud',data)

  useEffect(() => {
    anychart.onDocumentReady(function () {
        const customcolor = anychart.scales.linearColor();
        customcolor.colors(['#45b6fe', '#f94449', '#DE73FF']);

        const chart = anychart.tagCloud(data);
        chart.colorScale(customcolor);
        chart.title(title);
        chart.angles([0, 45, -45]);
        // chart.colorRange(true);
        // chart.colorRange().length('90%');

        chart.container('word');
        chart.draw();
    });
}, []);

return <div id="word" className="w-full h-[600px]"></div>;
}

export default WordCloud