import React from "react";
import EngagementInsights from "../../components/Report/EngagementInsights";
import Influencers from "../../components/Report/Influencers";
import Reels from "../../components/Report/Reels";
import WordCloud from "../../components/Report/WordCloud";
import { Tabs } from "flowbite-react";
import ContentInsights from "../../components/Report/ContentInsights";
import reeljson from '../../samplesjons/reelsreportjson'


function ReelReport() {
  const samplereeljson = reeljson;

  return (
    <>
      <div className="flex items-center gap-3 border-b-2 border-b-gray-500 pt-1 pb-3">
        <a href="/">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 20 20"
            aria-hidden="true"
            height="25"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
        <div
          className="flex justify-center items-center space-x-4"
          data-testid="flowbite-avatar"
        >
          <div className="relative">
            <img
              alt="avatar of Jese"
              className="!rounded-full w-10 h-10"
              data-testid="flowbite-avatar-img"
              src="https://images.unsplash.com/photo-1687076613219-fb4b3d94e75a?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=387&amp;q=80"
            />
          </div>
        </div>
        <div>
          <p className="text-2xl text-gray-600 font-semibold"> My Reel Report</p>
          <p>Engagement Last update on 1May</p>
        </div>
      </div>

      <Tabs.Group aria-label="Tabs with underline" style="underline">
        <Tabs.Item active title="Dashboard">
          {/* <StaticPost reportData={reportData} /> */}
          <Reels reportData={samplereeljson} />
          <EngagementInsights />
          <ContentInsights />
          <WordCloud />
        </Tabs.Item>
        <Tabs.Item title="Influencers">
         
            <Influencers reportData={samplereeljson} />
   
        </Tabs.Item>
        {/* <Tabs.Item title="Content">
          <Content reportData={reportData} />
        </Tabs.Item> */}
      </Tabs.Group>

      {/* {samplereeljson &
        samplereeljson.map((item, index) => (
          <div className="gap-4 bg-white p-2" key={index}>
            <p className="text-lg text-gray-500 font-semibold p-2">{item.Save}</p>
            <div className="flex justify-between gap-3 p-4">
              <div className=" flex flex-col justify-center items-center">
                <p className="text-md text-gray-700 font-semibold">
                  Total Static Posts
                </p>
                <p className="text-4xl text-orange-500 font-semibold">27</p>
              </div>
            </div>
          </div>
        ))} */}
    </>
  );
}

export default ReelReport;
