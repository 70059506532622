import React from 'react'
import { Button } from 'flowbite-react'
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const PageNotFound = () => {

    const goBack = () => {
        window.history.back();
    }

    return (
        <div className='flex items-center'>
            <div>
                <h1 className='text-5xl font-bold p-4'>Page Not Found</h1>
                <p className='text-gray-600 p-4'>Oops! Looks like you followed a bad link. If you think this is a problem with us, please contact us.
                </p>

                <Button onClick={goBack} >
                    <BiArrowBack />
                    Go back
                </Button>

            </div>
            <div>
                <img src="https://images.unsplash.com/photo-1633078654544-61b3455b9161?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8ZXJyb3J8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=600&q=60" alt="" />
            </div>

        </div>
    )
}

export default PageNotFound