import CryptoJS from "crypto-js";

const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
};


const updateData = (key,newData) => {
    // Encrypt data and save to session storage
    const encryptedData = encryptData(newData);
    const val = localStorage.setItem(key, encryptedData);
    return val
};
const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, process.env.REACT_APP_KEY).toString();
};

export { decryptData, updateData }