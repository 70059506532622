import React from "react";
import { Label, TextInput } from "flowbite-react";
import axiosInstance from "../../interseptor";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { HiEye, HiTrash } from "react-icons/hi";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCloudDownloadAlt, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import LoadingSpinner from "../ResultPage/LoadingSpinner";
import md5 from "md5";
import { IoLocation } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import ViralTableSkelaton from "../viralbrandreport/ViralTableSkelaton";


const GET_REPORT_LIST_URL = "/livesearch/viral_brand_report";
const UPDATE_BRAND_LIST_URL = "/livesearch/viral_brand_update";
const GET_CATEGORY_LIST_URL = "/livesearch/category";
const UPLOAD_REPORT_URL = "/livesearch/uploadreport";

const CSVFILE_URL = `https://viralpitch.co/d2/dashboard/sample-report-csv.csv`

const ITEMS_PER_PAGE = 50;

const ViralReportUpdateSheet = () => {
    const [reportList, setReportList] = useState([]);
    const [reportName, setReportName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [selectPlatform, setSelectPlatform] = useState('instagram')
    const [currentPage, setCurrentPage] = useState(1);
    const [brandInputKeyword, setBrandInputkeyword] = useState('')
    const [brandInput, setBrandInput] = useState('')
    const [countryList, setCountryList] = useState([])
    const [categoryList, setCategoryList] = useState([])


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [action, setAction] = useState('update');

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortDate, setSortDate] = useState('')

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [isArchive, setIsArchive] = useState()

    const [apiSortOrLoadMore, setApiSortOrLoadMore] = useState(0)

    const [selectedUsername, setSelectedUsername] = useState()

    const [currentTab, setCurrentTab] = useState(0)

    const handleSort = (date, type) => {

        setSortDate(date)
        setCurrentPage(1)
        setApiSortOrLoadMore(0)
        // If the same column is clicked, toggle the sorting order
        if (type === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(type);
            setSortOrder('asc'); // Default to ascending order
        }

        console.log('checking sortinggg1', sortColumn, sortOrder, date)
        // getReportList()
    };

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const createReport = async (platform) => {
        setLoading(true);
        let data = new FormData();
        data.append("logintype", "uploadreport");
        data.append("device_id", "seacrh");
        data.append("file", selectedFile);
        data.append("platform", (platform === 'instagram') ? 1 : (platform === 'youtube') ? 2 : 3);
        data.append("reportname", reportName);

        try {
            const response = await axiosInstance.post(UPLOAD_REPORT_URL, data);
            if (response?.data?.status_code == 200) {
                if (response?.data?.status == 1) {
                    closeModal();
                    toast.success(response?.data?.msg, { pauseOnHover: false });
                    // Delay the reload by 2 seconds
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    closeModal();
                    toast.error(response?.data?.msg, { pauseOnHover: false });
                    setLoading(false);
                }
            } else if (response && response?.data && response?.data?.status == 0) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            } else if (response && response?.data && response?.data?.status == 3) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            }
            return response?.data.list;
        } catch (error) {
            console.log(error);
        }
    };

    const closeModal = () => {
        setIsCreateOpen(false);
        setReportName("");
        setSelectedFile(null);
    };

    const downloadCsv = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    };

    const GravatarImage = ({ brand }) => {
        console.log('brand', brand) 
        const hashedBrand = md5(brand);
        const gravatarURL = `https://storage.googleapis.com/viralimage/${hashedBrand}.jpg`;

        return (
            <img
                src={gravatarURL}
                alt={`Gravatar for ${brand}`}
                onError={({ currentTarget, error }) => {
                    console.error('Image failed to load:', error);
                    currentTarget.onerror = null; // Remove the event listener to prevent looping
                    currentTarget.src = "/asset/onErrorImage.jpg";
                }}
                className="w-14 h-14 rounded-full shadow-md border overflow-hidden"
            />
        );
    };

    const getReportList = async (brandInput,index) => {
        setCurrentTab(index ||0)
        try {
            const data = new FormData();
            data.append("logintype", "viral_brand_report");
            data.append("device_id", "search");
            data.append("is_updated", 1);
            data.append("brand_name", brandInput);
            data.append("type", index);

            const response = await axiosInstance.post(GET_REPORT_LIST_URL, data);
            console.log('checking reportlist', response?.data?.d)
            if (response?.data?.status === 0) {
                setIsLoading(false);
                setReportList([]);
            } else {
                setIsLoading(false);
                setReportList(response?.data?.d);
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
            setIsLoading(false);
        }
    };

    const updateViralBrandList = async (selectedCountry, selectedCategory, selectedType, username, action) => {
        console.log('checking username', username)
        try {
            const data = new FormData();
            data.append("logintype", "viral_brand_update");
            data.append("device_id", "search");
            data.append("update_country", selectedCountry.toLowerCase() || '');
            data.append("update_category", selectedCategory.toLowerCase() || '');
            data.append("update_type", selectedType.toLowerCase() || '');
            data.append("username", username.toLowerCase() || '');
            data.append("type", action);

            const response = await axiosInstance.post(UPDATE_BRAND_LIST_URL, data);
            setSelectedUsername(username)
            setIsArchive(response?.data?.sucess)
            console.log('response archive', response)
            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };

    const getCategoryList = async () => {

        try {
            const data = new FormData();
            data.append("logintype", "category");
            data.append("device_id", "search");

            const response = await axiosInstance.post(GET_CATEGORY_LIST_URL, data);
            console.log('checking category', response?.data?.list)
            setCategoryList(response?.data?.list)
            if (response?.data?.status === 0) {
            } else {
            }
        } catch (error) {
            console.error("Error fetching report list:", error);
        }
    };

    const fetchCountryList = async () => {
        try {
            setLoading(true);

            const formdata = new FormData();
            formdata.append("logintype", "location");
            formdata.append("device_id", "search");

            const result = await axiosInstance.post("livesearch/location", formdata);
            console.log('result 11', result)
            if (result?.data?.status === 1) {
                const sortedData = result?.data?.list.sort((a, b) => a.name.localeCompare(b.name));
                console.log('sorted data country', sortedData)
                setCountryList(sortedData);
            } else {
                console.error('Error:', result.msg);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        setCurrentPage(prevPage => prevPage + 1); // Increment current page
    };

    // Function to handle key down event
    const handleKeyDown = () => {
        // Clear previous typing timeout
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
    };

    // Function to handle key up event
    const handleKeyUp = (event) => {
        // Set new typing timeout
        const value = event.target.value;
        setTypingTimeout(setTimeout(() => {
            setBrandInput(value);
        }, 500));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setBrandInput(brandInputKeyword)
        // Perform API call here
    };

    const handleSelectedCountry = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleSelectedCategory = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSelectedType = (event) => {
        setSelectedType(event.target.value);
    };

    useEffect(() => {
        getCategoryList()
        fetchCountryList()
    }, [])

    useEffect(() => {
        getReportList(brandInput,0) // Fetch data for the current page
    }, [brandInput]); // Trigger the effect when currentPage changes

    const type = ["Profile", "Channel", "Fanpage", "Community", "Brand"]

    const handleArchive = (selectedCountry, selectedCategory, selectedType, username) => {
        // Show confirmation dialog
        const confirmArchive = window.confirm("Are you sure you want to Archive?");
        // alert()
        // If user confirms, call the API
        if (confirmArchive) {
            updateViralBrandList(selectedCountry, selectedCategory, selectedType, username, 'archive');
        }
    };

    const paginatedReportList = reportList.slice(0, currentPage * ITEMS_PER_PAGE);

    return (
        <div className="p-3">
            <ToastContainer autoClose={1500} />
            <div className="flex justify-between">
                <div className="w-full flex items-center justify-between">
                    <div className="ml-3">
                        <h1 className="text-2xl font-semibold text-[#323237]">Update Viral Brand Reports</h1>
                        <div className="mt-2 flex gap-2">
                            <button onClick={()=>getReportList(brandInput,0)} className={`border w-40 py-2 px-2 ${currentTab === 0 ? "bg-[#1dbb99]" : "bg-[#323237]"} text-white rounded-md`}>Report</button>
                            <button onClick={() => getReportList(brandInput,1)} className={`border w-40 py-2 px-2 ${currentTab === 1 ? "bg-[#1dbb99]" : "bg-[#323237]"} text-white rounded-md`}>Archived</button>
                            <button onClick={() => getReportList(brandInput,2)} className={`border w-40 py-2 px-2 ${currentTab === 2 ? "bg-[#1dbb99]" : "bg-[#323237]"} text-white rounded-md`}>Updated</button>
                        </div>
                        <p className="mt-3 text-gray-500">Showing {paginatedReportList.length} / {reportList.length}</p>
                    </div>

                    <div className="flex gap-2">

                        <form onSubmit={handleSearch}>
                            <div className="relative">
                                <input
                                    onChange={(event) => setBrandInputkeyword(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    className="border-[0.6px] border-[#D9D9D9] rounded-[0.375rem] w-[200px] py-3.5"
                                    type="text"
                                    placeholder="Search by Brand"
                                    value={brandInputKeyword}
                                />
                            </div>
                        </form>
                    </div>
                </div>

                {isCreateOpen && (
                    <div className="bg-gray-700/75 w-full h-[100vh] fixed left-0 top-0 z-40 ">
                        <div className="fixed top-32 left-1/2 -translate-x-[50%] z-50 bg-white border-2 border-gray-500 p-8 w-2/5 rounded-xl shadow-md">
                            <div className="flex justify-end">
                                <AiOutlineClose
                                    onClick={closeModal}
                                    className="cursor-pointer"
                                />
                            </div>
                            <Label
                                className="!text-gray-600 mb-1"
                                htmlFor="reportname"
                                value="Name of the Report"
                            />
                            <TextInput
                                id="reportname"
                                type="text"
                                placeholder="Enter Report Name"
                                value={reportName}
                                onChange={(e) => setReportName(e.target.value)}
                                required={true}
                                shadow={true}
                            />

                            <div className="flex gap-2 mt-5 py-2">
                                <button onClick={() => setSelectPlatform('instagram')} className={`${selectPlatform === 'instagram' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaInstagram className="" /> Instagram
                                </button>
                                <button onClick={() => setSelectPlatform('youtube')} className={`${selectPlatform === 'youtube' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaYoutube className="text-lg" /> YouTube
                                </button>
                                <button onClick={() => setSelectPlatform('tiktok')} className={`${selectPlatform === 'tiktok' ? 'bg-[#6d5eac] text-white' : ''} w-[120px] border p-2 rounded-lg shadow-md flex gap-1 justify-center items-center`}>
                                    <FaTiktok /> TikTok
                                </button>
                            </div>

                            <div className="mt-5">
                                <Label
                                    className="!text-gray-600 mb-1 mr-1"
                                    htmlFor="reportname"
                                    value="Upload a File"
                                />
                                <br />
                                <input
                                    className="rounded-lg"
                                    type="file"
                                    accept=".csv"
                                    name="file"
                                    id=""
                                    onChange={handleFileInputChange}
                                />
                                {/* <p className=" mt-1 text-xs text-gray-600">
                  Suggested file type: .csv
                </p> */}
                                <button onClick={() => { downloadCsv(CSVFILE_URL) }} className='flex items-center gap-1 text-xs mt-2'>
                                    <FaCloudDownloadAlt /> Download Sample CSV
                                </button>
                            </div>

                            {
                                loading ? <button className="mt-5 bg-[#1dbb99] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                                    <LoadingSpinner /> Creating
                                </button> : <button
                                    disabled={reportName == "" || !selectedFile}
                                    className="mt-5 w-[120px] bg-[#1dbb99] text-white p-2 rounded-lg shadow-md"
                                    onClick={() => createReport(selectPlatform)}
                                >
                                    Create
                                </button>
                            }

                        </div>
                    </div>
                )}
            </div>


            <table className="mt-5 w-full rounded-[8px] border text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="sticky top-0 z-0 bg-[#EEF5FF]  text-[#828282] whitespace-nowrap">
                    {
                        isLoading ?
                            <tr className="h-14 text-black">
                                <th className="text-sm font-medium capitalize pl-2">Brand Name</th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                            </tr>
                            :
                            <tr className="h-14">
                                <th className="text-sm font-medium capitalize pl-2">Brand Name</th>
                                <th className="text-sm font-medium capitalize">Country</th>
                                <th className="text-sm font-medium capitalize">Category</th>
                                <th className="text-sm font-medium capitalize">Type</th>
                                <th className="text-sm font-medium capitalize"></th>
                                <th className="text-sm font-medium capitalize"></th>
                            </tr>
                    }

                </thead>
                <tbody className="divide-y">
                    {isLoading ? <ViralTableSkelaton />
                        : paginatedReportList.map((report, index) => (
                            console.log({paginatedReportList}),
                            <tr className="h-20 border bg-white" key={index}>
                                <td className="pl-2 font-semibold">

                                    <div className="pr-2 text-black py-2 flex items-center">
                                        <Link target="/blank" to={`https://www.instagram.com/${report?.username}/`}>
                                            <span className="mr-2 flex items-center gap-1">
                                                <GravatarImage brand={report?.username} />
                                            </span>
                                        </Link>
                                        <div className="flex flex-col">
                                            <div>
                                                <span>{report?.username}</span>
                                            </div>
                                            <div className="flex gap-2 mt-1">

                                            </div>
                                        </div>
                                    </div>

                                </td>

                                <td>
                                    <select onChange={handleSelectedCountry} className="text-sm h-10 rounded-sm border-gray-300">
                                        <option>{report?.country || 'Select'}</option>
                                        {
                                            countryList.map((data, index) => (
                                                <option key={index}>{data?.name}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select onChange={handleSelectedCategory} className="text-sm h-10 rounded-sm border-gray-300">
                                        <option>{report?.category || 'Select'}</option>
                                        {
                                            categoryList?.map((category, index) => (
                                                <option key={index}>
                                                    {category?.name}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </td>
                                <td>
                                    <select onChange={handleSelectedType} className="text-sm h-10 rounded-sm border-gray-300">
                                        <option>{report?.type || 'Select'}</option>
                                        {
                                            type.map((data, index) => (
                                                <option key={index}>{data}</option>
                                            ))
                                        }
                                    </select>
                                </td>

                                <td>
                                    <HiTrash
                                        onClick={() => handleArchive(selectedCountry, selectedCategory, selectedType, report?.username)}
                                        title="Archive" className={`text-xl cursor-pointer ${(isArchive === 0 && selectedUsername === report?.username) && "text-red-600"}`} />
                                </td>

                                <td className="">
                                    <div className="flex items-center justify-center">
                                        <button
                                            onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, report?.username, action)}
                                            className="flex items-center px-2 py-1.5 rounded-md bg-[#1dbb99] hover:opacity-90 text-sm text-white"
                                        >
                                            Update Report
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

            {
                (reportList.length > currentPage * ITEMS_PER_PAGE) &&
                <div className="flex justify-center my-5">
                    <button
                        className="px-4 py-2 bg-[#1dbb99] text-white rounded-lg shadow-md"
                        onClick={handleLoadMore}
                    >
                        {loading ? <LoadingSpinner /> : "Load More"}
                    </button>
                </div>
            }

        </div>
    );
};

export default ViralReportUpdateSheet;