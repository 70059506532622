import React from 'react'

const MyAvatar = () => {
  return (
    <>
      <div className='h-8 w-8 rounded-full animate-pulse bg-gray-200 shadow-lg'></div>
    </>
  )
}

export default MyAvatar