import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ShareOfGraph = ({ graphData }) => {
    // Check if the data is available
    // Extracting values from the graphData
    
    const seriesData = graphData.map((data) => (Number(data.preng.toFixed(2))));
    const labels = graphData.map((data) => data.name);

    // const totalSum = seriesData.reduce((sum, value) => sum + value, 0);
    const totalSum = Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
    }).format(graphData[0].totaleng);

    // console.log({totalSum})
    // console.log({totalSum})

    const options = {
        chart: {
            type: 'donut',
        },
        labels: labels,
        colors: ['#6d5eac', '#38a169', '#323237', '#fc8181', '#6d5eac', '#f7cf5f', '#1dbb99'], // Update colors as needed
        legend: {
            show: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            label: "Total Engagements",
                            fontSize: '14',
                            fontWeight: 'bold',
                            formatter: function () {
                                return totalSum;
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <div id="chart" className="w-[350px]">
            <ReactApexChart options={options} series={seriesData} type="donut" />
        </div>
    );
};

export default ShareOfGraph;