import React from 'react'
import { HiEye } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const ViralTableSkelaton = () => {
    return (
        <>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
            <tr className="h-20 text-white border even:bg-gray-100">
                <td className="pl-2 font-semibold">
                    <Link target="/blank" to={`https://www.instagram.com/`}>
                        <div className="pr-2 flex gap-1 items-center">
                            <span className="mr-2 flex items-center gap-1 w-12 h-12 rounded-full bg-gray-500 animate-pulse">
                                {/* <GravatarImage brand={"csass"} /> */}
                            </span>
                            <h1 className='bg-gray-500 text-gray-500 animate-pulse'>
                                sdasdsadsad
                            </h1>
                        </div>
                    </Link>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="font-medium text-white">
                    <div className="flex px-2 justify-center items-center">
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(54357)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center border-l border-r border-gray-400">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(3433)}
                            </span>
                        </div>
                        <div className="w-full flex justify-center">
                            <span className="bg-gray-500 text-gray-500 animate-pulse">
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(13123)}
                            </span>
                        </div>
                    </div>
                </td>
                <td>

                </td>

                <td className="">
                    <div className="flex items-center justify-center">
                        <Link
                            to={`/ViralBrandReport/`}
                            className="flex items-center px-2 py-1.5 rounded-md bg-gray-500 animate-pulse hover:opacity-90 text-sm text-gray-500"
                        >
                            <HiEye />
                            &nbsp;View Report
                        </Link>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ViralTableSkelaton
