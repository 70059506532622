import React from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {

    const domainName = window.location.hostname;

    return (

        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

                {
                    domainName === 'coke.viralpitch.co' ?
                        <div className='w-[100px] mb-2'>
                            <img className="w-[100%] h-[100%]" src="https://viralpitch.co/public/cococola-1.png" alt="logo" />
                        </div>
                        :
                        domainName === 'scarlett.viralpitch.co' ?
                            <div className='w-[100px] mb-2'>
                                <img className="w-[100%] h-[100%]" src="https://viralpitch.co/public/scralett.jpeg" alt="logo" />
                            </div>
                            :
                            domainName === 'skii.viralpitch.co' ?
                                <div className='w-[100px] mb-2'>
                                    <img className="w-[100%] h-[100%]" src="https://viralpitch.co/public/sk2.jpeg" alt="logo" />
                                </div>
                                :
                                domainName === 'airtel.viralpitch.co' ?
                                    <div className='w-[100px] mb-2'>
                                        <img className="w-[100%] h-[100%]" src="https://viralpitch.co/public/airtel_new.png" alt="logo" />
                                    </div>
                                    :
                                    <img className="w-100 h-8 mb-2" src="https://viralpitch.co/demoadmin/Infls/assets/img/logo.png" alt="logo" />
                }


                <Outlet />

            </div>
        </section>
    );
}


export default AuthLayout;