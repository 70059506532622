import constants from "../../constants/constant";

export default function TableRowSK({ total =1 }) {
    return new Array(total).fill(0).map((item, idx) => {
        return (
        <div key={idx} className="pt-2 mt-3  w-full flex  mr-3 mb-3 p-2 rounded-lg flex-none cursor-pointer animate-pulse" style={constants.searchBoxStyle}>
            <div className="h-[60px] w-full animate-pulse rounded-lg">
                <div className="flex gap-1">
                    <div className="h-7 w-1/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-5/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-5/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-1/12 animate-pulse bg-gray-200 rounded-full"></div>
                </div>
                <div className="flex gap-1 mt-1">
                    <div className="h-7 w-1/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-5/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-5/12 animate-pulse bg-gray-200 rounded-full"></div>
                    <div className="h-7 w-1/12 animate-pulse bg-gray-200 rounded-full"></div>
                </div>
            </div>
        </div>
        )
    })
}