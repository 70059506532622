import React from 'react';
import WordCloud from 'react-wordcloud';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const HorizontalWordCloud = ({ hashtags }) => {
  // Transform the hashtags data into the format expected by WordCloud
  const words = hashtags.map((hashtag, index) => ({
    text: hashtag,
    value: index + 1, // You can adjust the value as needed
  }));

  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: false,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [5, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 2,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <WordCloud words={words} options={options} />
    </div>
  );
};

export default HorizontalWordCloud;