import { SET_PARTNERSHIP } from "../ActionTypes";

const initialState = [];

const PartnershipReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARTNERSHIP:
            return action.payload;
        default:
            return state;
    }
};

export default PartnershipReducer;