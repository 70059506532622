import axios from "axios";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

axiosInstance.interceptors.request.use(
  
  function (config) {
    const token = JSON.parse(localStorage.getItem("token_data"));
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['Authorization'] = `Bearer ${token?.token}`; 
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if(response.data.status === 401){
      localStorage.clear()
      document.cookie = "emails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      window.location.href = "/login"
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 422) {
      return error.response;
    }
    return error;
  }
);

export default axiosInstance;
