import { SET_SPONCORED_POST_FILTER } from "../ActionTypes";

const initialState = false;

const SponcoredPostReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SPONCORED_POST_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default SponcoredPostReducer