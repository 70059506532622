import React, { useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Select } from "antd";
import "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBioPhraseFilter } from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const BioPhrase = ({hoverStates, handleMouseOver, sendToSearchResultFilter, }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const bioPhraseUrl = searchParams.get("bioPhrase")
  const bioPhraseArray = bioPhraseUrl ? bioPhraseUrl.split(",") : [];
  
  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();
  const { Option } = Select;
  const [typedKeywords, setTypedKeywords] = useState([]);

  function handleChange(value) {
    dispatch(setBioPhraseFilter(value));
    if (value.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("bioPhrase", value.join(","));
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("bioPhrase");
        return searchParams;
      });
    }
  }

  function handleSearch(value) {
    // Filter the predefined data based on the user input
    const filteredData = predefinedData.filter((item) =>
      item.toLowerCase().includes(value.toLowerCase())
    );
    setTypedKeywords(filteredData);
  }

  return (
    <>
      <div ref={buttonRef} className="BioPhrase flex justify-start">
        <div className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={`filterTextColor flex items-center bg-white ${isDropdownOpen ? 'font-semibold' : ''}`}
          >
            Bio
            <span
              className={`flex items-center`}
            >
              {isDropdownOpen ? (
                <RxCross2 className="ml-1 text-lg" />
              ) : (
                <IoChevronDownSharp className="ml-1 text-lg" />
              )}
              </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-2 w-[200px]">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Bio{" "}
                <InfoIconTooltip
                  infoContent={`Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations.`}
                />
              </div>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                onChange={handleChange}
                onSearch={handleSearch}
                tokenSeparators={[","]}
                value={bioPhraseArray}
                className="w-full rounded-md py-3 border-[0.6px] border-[#D9D9D9] text-[#3D3D3D]"
                placeholder="Search Keywords in bio"
                notFoundContent={
                  <span className="ant-empty-description pl-2 py-5 text-[#3D3D3D]">
                    Start Typing...
                  </span>
                }
              >
                {typedKeywords.map((keyword) => (
                  <Option key={keyword}>{keyword}</Option>
                ))}
              </Select>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

const predefinedData = [];

export default BioPhrase;
