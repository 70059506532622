import React, { useEffect } from 'react'
import FormData from "form-data";
import { useState } from 'react'
import Papa from 'papaparse';
import { FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { AiOutlineDownload } from 'react-icons/ai';
import LoadingSpinner from '../ResultPage/LoadingSpinner';
import SearchedHistory from '../SearchedHistory';
import axiosInstance from '../../interseptor';
import instagram from '../../components/asset/instagram.png'
import tiktok from '../../components/asset/tiktok.png'
import youtube from '../../components/asset/youtube1.png'
import { IoIosSearch } from 'react-icons/io';
import { LuUpload } from 'react-icons/lu';
import { Badge } from 'flowbite-react';
import { HiBadgeCheck } from 'react-icons/hi';
import constant from '../../constants/constant';
import { RiCloseFill } from 'react-icons/ri';
// const CSVFILE_URL = `http://localhost:3000/sample.csv`
const CSVFILE_URL = 'https://viralpitch.co/d2/dashboard/similar_profile_sample.csv';

const SimilarProfile = () => {
    const platforms = [
        { id: 1, label: "Instagram", Activeicon: instagram, icon: <FaInstagram /> },
        {
            id: 2, label: "YouTube", Activeicon: youtube, icon: <FaYoutube />
        },
        { id: 3, label: "TikTok", Activeicon: tiktok, icon: <FaTiktok /> },

    ];
    const [jsonResult, setJsonResult] = useState([]);
    const [csvData, setCsvData] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [userData, setUserData] = useState('');
    const [realUserData, setRealUserData] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [platform, setPlatform] = useState(platforms[0]);
    const [platformDropdown, setPlatformDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportId, setReportId] = useState();
    const [shouldContinueInterval, setShouldContinueInterval] = useState(true);
    const [showDownload, setShowDownload] = useState(false)
    const [selectedPlatform, setSelectedPlatform] = useState(constant.platforms[0]);
    const [searchUsername, setSearchUsername] = useState([])
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [loadingTopics, setLoadingTopics] = useState(false);
    const [username, setAllusername] = useState([])
    const [searchInput, setsearchInput] = useState('');
    const [searchTopic, setSearchTopic] = useState([]);
    const [dataFetched, setDataFetched] = useState(false)

    const downloadCsv = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click()
        aTag.remove();
    }

    const handlePlatform = (platform) => {
        setPlatform(platform)
    }

    const handleCSVFileChange = (event) => {
        setSearchValue("")
        setSearchResult([])
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setCsvData(e.target.result);
                Papa.parse(e.target.result, {
                    header: true, // Treat the first row as headers
                    dynamicTyping: true, // Automatically detect data types
                    skipEmptyLines: true, // Skip empty lines
                    complete: (result) => {
                        setJsonResult(result.data);
                    },
                    error: (error) => {
                        console.error('Error converting CSV to JSON: ', error.message);
                    },
                });
            };

            reader.readAsText(file);
        } else {
            // Handle the case where no file was selected
            setCsvData('');
            setJsonResult([]);
        }
    }

    const handleSearchValue = (e) => {
        if (e.target.value.length === 0) {
            setShowSuggestion(false)
            setDataFetched(false)
        }
        else {
            setShowSuggestion(true)
        }
        setSearchValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchResult(searchValue.split(", "));
        setRealUserData([])
        setShowSuggestion(false)
        setDataFetched(true)
    };

    useEffect(() => {
        setLoading(true); // Start loading

        let userDataUpdated = false;

        const fetchInitialData = async () => {
            setShowDownload(false)
            try {
                var formdata = new FormData();
                formdata.append("logintype", "calculate-engagement");
                formdata.append("device_id", "seacrh");
                formdata.append("platform", platform.label);
                formdata.append("csvfile", new Blob([csvData], { type: 'text/csv' }), "username.csv");
                formdata.append("uploadfile", searchValue === "" ? "1" : "0");
                console.log(username, "checknig", searchResult)
                formdata.append("username", (username.length > 0) ? username : searchResult);
                formdata.append("similarsearch", "1");

                const response = await axiosInstance.post("livesearch/calculate-engagement", formdata)
                console.log('response first', response?.data)

                userDataUpdated = true;
                setUserData(response?.data);
                setLoading(false); // Stop loading
                if (response?.data.list.length > 0) {
                    setReportId(response?.data.report_id)
                    setShowDownload(true)
                }

            } catch (error) {
                console.log('error', error);
                setLoading(false);
            }

            return () => {
                if (!userDataUpdated) {
                    setLoading(false); // Stop loading if userData is not updated
                }
            }
        }

        fetchInitialData()

    }, [csvData, searchResult])


    const convertToCsv = () => {
        const csvHeaders = ["Username", "Followers", "Eng. Rate", "Avg. Likes", "Avg. Comments", "Avg. Plays", "Media Count", "Phone Number", "Email ID", "Status"];

        const csvData = [csvHeaders];
        console.log(userDataFn())
        userDataFn().list?.forEach((item) => {
            const row = [
                item.username,
                item.follower,
                item.engagement,
                item.avg_like_count,
                item.avg_comment,
                item.avg_play_count,
                item.media_count,
                item.phone,
                item.email,
                item.scrap_status,
            ];
            csvData.push(row);
        });

        return Papa.unparse(csvData);
    };

    const handleDownload = () => {
        const csvData = convertToCsv();

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'userData.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    };


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer auZKqWbYZYJFL5qBuAU00brgrQwlqkUGqCJMjn9DQPUZL4I5Mv");
    myHeaders.append("Cookie", "PHPSESSID=4r0t2656f9id1236bs23psr2rv");


    var formdata = new FormData();
    formdata.append("logintype", "get_eng_report");
    formdata.append("device_id", "seacrh");
    formdata.append("report_id", reportId);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const fetchData = async (val) => {
        console.log("help")
        try {
            let formdata = new FormData();
            formdata.append("logintype", "get_eng_report");
            formdata.append("device_id", "seacrh");
            formdata.append("report_id", val || reportId);

            // Only fetch if shouldContinueInterval is true
            // if (shouldContinueInterval && userData && userData?.list?.length > 0 && userData?.list !== ' ') {
            const response = await axiosInstance.post("livesearch/get_eng_report", formdata);

            console.log('data', response.data)

            setRealUserData(response.data);

            // Additional logic or processing with the fetched data can be added here

            setLoading(false);

            // Check if there are no items with scrap_status === 0
            const noItemsWithScrapStatusZero = response?.data?.list.every(item => item?.scrap_status !== "0");

            // console.log("scrap status =", noItemsWithScrapStatusZero);

            // If no items have scrap_status === 0, stop the interval
            if (noItemsWithScrapStatusZero) {
                setShouldContinueInterval(false);
            }
            // } else {
            // Set loading to false when userData.length is 0 or is ' ' or undefined
            setLoading(false);
            setShowDownload(true)
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {

        // Set up an interval to fetch data every 5 seconds
        const newIntervalId = setInterval(() => {

            // Fetch data only when shouldContinueInterval is true
            if (shouldContinueInterval && userData && userData?.list?.length > 0 && userData?.list !== ' ') {
                // console.log({ TEST: "ADFASF", userData })
                fetchData();
            }
        }, 3000);

        // Cleanup the interval when the component unmounts or shouldContinueInterval is false
        return () => {
            clearInterval(newIntervalId);
            if (!shouldContinueInterval) {
                setShouldContinueInterval(true); // Reset the state for future use
            }
        };

    }, [userData, shouldContinueInterval]);  // Dependencies array should include userData and shouldContinueInterval

    const fetchSimilarUsername = async (value) => {

        // console.log(value, 'value')
        try {

            const formdata = new FormData();
            formdata.append("filter", "lookalike");
            formdata.append("logintype", "getfilters");
            formdata.append("device_id", "search");
            formdata.append("platform", platform.label.toLowerCase());
            formdata.append("search_string", value || '');

            const response = await axiosInstance.post("livesearch/getfilters", formdata);

            console.log("result of similar user", response?.data)
            if (response?.data?.status === 1) {
                setSearchUsername(response?.data?.response?.data?.data);
                console.log('lookalike data', response.data.response.data);
            } else {
                // setError(response.msg);
                console.error('Error:', response?.data?.msg);
            }
        } catch (error) {
            // setError('An error occurred while fetching data.');
            console.error('Error:', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchSimilarUsername(searchValue)
    }, [searchValue])

    const userDataFn = () => {
        return realUserData?.list?.length > 0 ? realUserData : userData
    }

    function reportIdNew(val) {
        console.log({ val })
        fetchData(val)
        setShowDownload(true)
    }

    function usernamhHandler(val) {
        const allusernmae = [...username]
        const findUsername = allusernmae.find((name) => {
            return name === val.username
        })

        if (findUsername === undefined) {
            setAllusername(prev => [...prev, val.username])
        }
        console.log(findUsername)
    }

    const removeFromSearch = (index) => {
        const updatedSearches = [...username];
        updatedSearches.splice(index, 1);
        setAllusername(updatedSearches);
        const updatedSearchNames = [...username];
        updatedSearchNames.splice(index, 1);
        setAllusername(updatedSearchNames);
    };

    useEffect(() => {
        const fetchData = async (value) => {
            setLoadingTopics(true);

            try {
                const formdata = new FormData();
                formdata.append("logintype", "searchtopic");
                formdata.append("device_id", "seacrh");
                formdata.append("platform", selectedPlatform.label);
                formdata.append("filter", "lookalike");
                formdata.append("search_string", value.substring(1)); // Remove '@' from the username


                const response = await axiosInstance.post("livesearch/searchtopic", formdata);

                setSearchTopic(response?.data?.response?.data);
                setSearchUsername([])
                setLoadingTopics(false);

                if (searchInput.length === 0) {
                    setSearchTopic([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };


        if (searchValue.length > 1) {
            fetchData(searchValue);
        }

    }, [searchValue, selectedPlatform.label]);

    return (
        <div className='mt-2 w-full flex flex-col items-center h-screen bg-[#FAFAFA]'>
            <div className="w-[95%] z-40 flex flex-col items-center justify-center rounded-lg  py-9">
                <p className='text-3xl mb-1 font-[600] '>                     Effortlessly Discover Similar Influencers Using Built-In Tools and Recommendation Algorithms.

                    <span style={{
                        backgroundImage: 'linear-gradient(to right, #7279FD, #7279FD, #BF00DB)',
                        WebkitBackgroundClip: 'text',
                        backgroundClip: 'text',
                        color: 'transparent',
                        fontWeight: '600',
                    }} >                     Search for Similar Influencer Profiles
                        .</span> </p>




                <div className='w-[900px] xl:w-[95%] justify-center flex flex-col my-4 m-auto mt-10'>
                    <div className="flex  w-full overflow-x-auto  ">
                        {platforms.map((socialplatform) => (
                            <button
                                key={socialplatform.id}
                                className={`flex-shrink-0 shadow-lg inline-flex justify-center font items-center h-14 w-[160px] py-3 px-4 text-base font-medium text-center
                                ${platform.id + 1 === socialplatform.id && 'rounded-bl-[8px]'}
                                ${socialplatform.id === 1 && 'rounded-tl-[8px]'}
                                ${socialplatform.id === 3 && 'rounded-tr-[8px]'}
                                ${platform.id === socialplatform.id ? "bg-white slide-animation" : "bg-black text-white"}
                                ${platform.id - 1 === socialplatform.id && 'rounded-br-[8px]'}
                                focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 relative`}
                                onClick={() => handlePlatform(socialplatform)}
                                type="button"
                                style={{
                                    transition: 'background-color 0.3s, color 0.3s, border-radius 0.3s'
                                }}
                            >
                                <div className="flex gap-1 items-center justify-center">
                                    {/* Use appropriate icon based on the selected platform */}
                                    {socialplatform.id === platform.id ?
                                        <img src={socialplatform.Activeicon} className="w-[20px]" /> : socialplatform.icon}
                                    {socialplatform.label}
                                </div>
                            </button>
                        ))}

                    </div>

                    <div className='shadow-md '>
                    <div className="relative py-6 px-6 rounded-tr-[8px] w-full bg-white ">
                    <span className="w-full pl-12 flex overflow-auto min-w-[300px] items-center border bg-gray-50 border-gray-300 p-0 gap-[5px]">
                            <div className="max-w-auto flex overflow-auto">
                                {username && username?.map((name, index) => (
                                    <button
                                        key={index}
                                        className="my-1 mx-1 px-2 py-1 flex whitespace-nowrap items-center capitalize bg-blue-100 text-blue-800 rounded-lg text-sm"
                                        onClick={() => removeFromSearch(index)}
                                    >
                                        {name}
                                        <RiCloseFill />
                                    </button>
                                ))}
                            </div>
                            <div className="absolute z-[24] w-[20px] left-[50px] top-[43%]"> <IoIosSearch /></div>
                            <input
                                onChange={handleSearchValue}
                                // onFocus={() => setShowSearchSuggestion(true)}
                                // onClick={handleShowSuggest}
                                value={searchValue}
                                type="search"
                                className={`p-3 ${searchValue.length > 0 ? "w-fit min-w-[50px]" : "w-full"} pl-0 border-none z-20 text-base text-black bg-gray-50 border-0 focus:ring-transparent`}
                                placeholder='Find 🔍 Similar Profiles 👯'
                                required
                                autoComplete="false"
                            />
                            <div className={`absolute left-0 bg-white z-50 h-[300px] w-[80%] border overflow-auto  ${loadingTopics || !showSuggestion ? 'bottom-0 top-[100%]' : "bottom-0 top-[100%]"} rounded-lg  ${showSuggestion ? "" : "hidden"}`}>
                                    {
                                        loadingTopics || !showSuggestion ? (
                                            // Render loading indicator while data is being fetched
                                            <div className="text-lg font-semibold pb-5 p-5">Loading...</div>
                                        ) : searchUsername?.length > 0 ? (
                                            <div className="pl-5 pt-5 w-full">
                                                <div className="text-gray-500 text-lg font-normal">Similer Username</div>
                                                <div className="flex flex-wrap">
                                                    {searchUsername
                                                        ?.filter((category) => category?.username) // Filter out items without a username
                                                        .slice(0, 20) // Limit the display to the first 20 items
                                                        .map((category) => (

                                                            <button key={category?.user_id} onClick={() => { usernamhHandler(category) }}>
                                                                <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                                                                    <div className="flex items-center gap-1">
                                                                        {
                                                                            category?.picture &&
                                                                            <div className="w-8 h-8 rounded-full overflow-hidden">
                                                                                <img className="w-full h-full object-cover" src={category?.picture} alt="img" />
                                                                            </div>
                                                                        }

                                                                        <div className="flex items-center gap-1">
                                                                            <div className="flex flex-col justify-start items-start">
                                                                                <div className="flex gap-1">
                                                                                    <p>
                                                                                        {category?.username}
                                                                                    </p>
                                                                                    <div>
                                                                                        {
                                                                                            category?.is_verified && <HiBadgeCheck className='text-primary-500' size={16} />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <p className="text-gray-600 text-xs font-thin">
                                                                                    {Intl.NumberFormat("en-US", {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1,
                                                                                    }).format(category?.followers)}
                                                                                    &nbsp; Followers
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Badge>
                                                            </button>

                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )
                                            : (searchValue !== "" && searchValue.length > 1) &&
                                            <>
                                                <button >
                                                    <Badge className="my-2 rounded-full mr-2 p-1 pr-3 cursor-pointer bg-indigo-100 text-indigo capitalize">
                                                        <div className="flex items-center gap-1">
                                                            {
                                                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                                                    <img className="w-full h-full object-cover" src={"/avatar.png"} alt="img" />
                                                                </div>
                                                            }

                                                            <div className="flex items-center gap-1">
                                                                <div className="flex flex-col justify-start border items-start">
                                                                    <div className="flex gap-1">
                                                                        <p onClick={() => { setAllusername(prev => [...prev, searchValue]) }}>
                                                                            {searchValue}
                                                                        </p>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Badge>
                                                </button>
                                            </>
                                    }




                                </div>
                        </span>
                        </div>
                        <div className='pl-6 rounded-lg flex justify-between bg-white'>
                            <div>
                                <SearchedHistory sendReportId={reportIdNew} reportType={1} />
                            </div>
                            <div className='w-auto'>
                                <label htmlFor="csvFileInput" className='w-auto rounded-md px-3  bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer' >
                                    <LuUpload className='text-md' />
                                    Upload CSV
                                </label>
                                <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                            </div>
                            <div onClick={() => { downloadCsv(CSVFILE_URL) }} >
                                <div className="w-auto rounded-md px-3 ml-2 bg-white py-3 flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer">
                                    <AiOutlineDownload className="text-md" />
                                    Sample CSV
                                </div>
                            </div>

                            <div className='w-full items-center  flex justify-end'>
                                <button
                                    onClick={handleSubmit}
                                    type='submit'
                                    className='rounded-[4px] mb-6 mx-6  bg-[#686FFD] text-white py-3 px-4'
                                > Check Value
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <div className='rounded-xl bg-[#6d5eac] shadow-lg text-white flex items-center box-border mr-2'>
                        <SearchedHistory sendReportId={reportIdNew} reportType={2} />
                    </div>

                    <div className='rounded-xl shadow-lg text-white flex items-center box-border ml-2'>
                        <label htmlFor="csvFileInput" className='w-full rounded-xl px-3 py-3 flex items-center gap-1 text-white text-sm whitespace-nowrap cursor-pointer' style={{ backgroundColor: "#6d5eac" }}>
                            <FcExport className="text-2xl" />
                            Upload CSV
                        </label>
                        <input type="file" id="csvFileInput" accept=".csv" style={{ display: 'none' }} onChange={handleCSVFileChange} />
                    </div>

                    <div onClick={() => { downloadCsv(CSVFILE_URL) }} className='rounded-xl bg-[#6d5eac] shadow-lg text-white flex items-center box-border ml-2'>
                        <div className="w-full rounded-xl px-3 py-3 flex items-center gap-1 text-white text-sm whitespace-nowrap cursor-pointer">
                            <AiOutlineDownload className="text-2xl" />
                            Sample CSV
                        </div>
                    </div> */}
                </div>

            </div>

            {showDownload && (
                <div className="w-[95%] sticky top-0 bg-white z-20 py-5 flex gap-2 justify-end">

                    {/* <ToggleSearchedView /> */}

                    <div>
                        <button
                            className="shadow-md px-3 py-2 flex gap-1 items-center rounded-lg bg-[#323237] text-white"
                            onClick={handleDownload}>
                            <AiOutlineDownload className="text-2xl" /> Download
                        </button>
                    </div>
                </div>
            )}

            {
                loading ? <>
                    <div className="mb-10 mt-5 relative w-[95%] flex gap-5">
                        <div className="w-full flex justify-center my-5">
                            <button className="bg-[#201E20] duration-200 transition-all hover:opacity-90 text-slate-50 px-4 py-2 rounded-lg">
                                <LoadingSpinner />  Loading
                            </button>
                        </div>
                    </div>
                </>
                    :
                    (userData?.list?.length > 0 || (Array.isArray(userDataFn()?.list) && userDataFn()?.list.length > 0)) > 0 ? <>
                        <table className="min-w-[95%] w-[95%] mb-10 mt-5 relative container mx-auto overflow-x-auto">
                            <thead className='sticky top-0 z-30'>
                                <tr className=''>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Username
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Followers
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Eng. Rate
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Avg. Likes
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Avg. Comments
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Avg. Plays
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Media Count
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Phone Number
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Email ID
                                    </th>
                                    <th className="px-6 py-4 font-medium bg-[#6d5eac] text-left text-base leading-4 text-white tracking-wider">
                                        Status
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {userData?.list?.length === 0 && searchValue.length > 0 ? (
                                    <tr>
                                        <td colSpan="5" className="text-center py-10 text-gray-500">
                                            <h1 className='text-xl'>No data found</h1>
                                        </td>
                                    </tr>
                                ) : (

                                    userDataFn()?.list.map((item, index) => (
                                        <tr key={index} className='border even:bg-blue-50'>
                                            <td className="py-5 flex items-center gap-2 pl-2 whitespace-no-wrap">

                                                <div className="min-w-[55px] w-[55px] min-h-[55px] h-[55px] rounded-full overflow-hidden">
                                                    <img className="object-cover h-[100%] w-[100%]" src={item?.img || "/avatar.png"} alt="img" />
                                                </div>

                                                <div className="font-medium text-sm dark:text-white">
                                                    <div>
                                                        {item?.username}
                                                    </div>
                                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                                        {item?.name}
                                                    </div>
                                                </div>

                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.follower))}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {(item?.engagement.toString()).length <= 4 ? `${(item?.engagement.toString())}%` : `${(item?.engagement.toString()).slice(0, 4)}%`}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_like_count))}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_comment))}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.avg_play_count))}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {Intl.NumberFormat("en-US", {
                                                    notation: "compact",
                                                    maximumFractionDigits: 1,
                                                }).format(Math.floor(item?.media_count))}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.phone && `${item?.phone.charAt(0).toUpperCase()}${item?.phone.slice(1)}`}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.email && `${item?.email.charAt(0).toUpperCase()}${item?.email.slice(1)}`}
                                            </td>

                                            <td className="px-6 py-5 text-sm whitespace-no-wrap border-b border-gray-300">
                                                {item?.scrap_status == 0 && <button className="w-[100px] flex gap-1 justify-center items-center bg-[#f7cf5f] duration-200 transition-all hover:opacity-90 text-black px-2 py-2 rounded-lg">
                                                    <LoadingSpinner className="text-xs" />  Loading
                                                </button>}
                                                {item?.scrap_status == 1 && <button className="w-[100px] flex justify-center items-center bg-[#1dbb99] duration-200 transition-all hover:opacity-90 text-slate-50 px-2 py-2 rounded-lg">
                                                    Valid User
                                                </button>}
                                                {item?.scrap_status == 2 && <button className="w-[100px] flex justify-center items-center bg-[#6d5eac] duration-200 transition-all hover:opacity-90 text-slate-50 px-2 py-2 rounded-lg">
                                                    Invalid User
                                                </button>}
                                            </td>

                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </>
                    : dataFetched ? "No result found" : ""
            }
        </div>
    )
}

export default SimilarProfile
