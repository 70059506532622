import { SET_EXCLUDE_OFFICIAL_ARTISTS } from "../ActionTypes";

const initialState = false;

const ExcludeofficialArtistsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXCLUDE_OFFICIAL_ARTISTS:
            return action.payload;
        default:
            return state;
    }
};

export default ExcludeofficialArtistsReducer