import { SET_PREVIOUS_EXPORTED_FILTER } from "../ActionTypes";


const initialState = false;

const PreviousExportedReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREVIOUS_EXPORTED_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default PreviousExportedReducer