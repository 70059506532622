import React from 'react';
import ReactApexChart from 'react-apexcharts';

const InfluencerAgeGraph = ({ graphData }) => {
    const colors = ['#323237', '#1dbb99', '#6d5eac', '#f7cf5f', '#6d5eac'];

    const chartData = {
        series: [{
            name: "",
            data: Object.values(graphData),
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log('aaaaaaaa23', chart, w, e)
                    },
                },
            },
            colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories: Object.keys(graphData),
                labels: {
                    style: {
                        colors: colors,
                        fontSize: '12px',
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        console.log(val)
                        return val; // Display the count as it is
                    },
                },
            },
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData?.options} series={chartData?.series} type="bar" height={350} />
        </div>
    );
}

export default InfluencerAgeGraph;