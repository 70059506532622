import React from 'react'
import { useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { HiEye, HiPlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'


const Campaign = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState("ALL")

    return (
        <>
            <div className='w-full'>
                <div className='absolute right-10 bottom-5'>
                    <button className='flex items-center border rounded-lg hover:scale-105 duration-100 shadow-md text-white px-4 py-2 bg-[#6d5eac]'>
                        <HiPlus className='mr-1' /> New Campaign
                    </button>
                </div>

                <div className='w-[98%] mx-auto flex flex-col items-center mt-5'>
                    <div className='w-full flex justify-between'>
                        <div>
                            <h1 className='text-lg font-semibold'>Campaign</h1>
                        </div>
                        <div className='w-[40%] relative'>
                            <input
                                className='rounded-3xl w-full pl-4 border-2 mr-2 border-[#6d5eac]'
                                placeholder='Search Campaign' type='search' />
                            <CiSearch className='absolute top-0 translate-y-[70%] right-4 text-xl' />
                        </div>
                    </div>

                    <div className='w-full shadow-lg rounded-lg bg-[#323237] text-white py-2 mt-5'>
                        <ul className='list-none flex w-full justify-around'>
                            <li onClick={() => setActive("ALL")} className={`${active == "ALL" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>ALL (3)</li>
                            <li onClick={() => setActive("ACTIVE")} className={`${active == "ACTIVE" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>ACTIVE</li>
                            <li onClick={() => setActive("DRAFT")} className={`${active == "DRAFT" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>DRAFT (3)</li>
                            <li onClick={() => setActive("UNDER_REVIEW")} className={`${active == "UNDER_REVIEW" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>UNDER REVIEW</li>
                            <li onClick={() => setActive("ENDED")} className={`${active == "ENDED" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>ENDED</li>
                            <li onClick={() => setActive("ARCHIVED")} className={`${active == "ARCHIVED" && "text-[#f7cf5f]"} cursor-pointer hover:text-[#f7cf5f] text-sm`}>ARCHIVED</li>
                        </ul>
                    </div>
                </div>

                {
                    active == 'ALL' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-blue-50 hover:bg-blue-50">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-blue-50 hover:bg-blue-50">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-blue-50 hover:bg-blue-50">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {
                    active == 'ACTIVE' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            WhatsApp
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {
                    active == 'DRAFT' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            WhatsApp
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {
                    active == 'UNDER_REVIEW' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Campaign
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {
                    active == 'ENDED' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            WhatsApp
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            Whizard API
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }

                {
                    active == 'ARCHIVED' && <>
                        <div className='w-[98%] mx-auto'>
                            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                                    <tr className="h-12">
                                        <th className="text-sm font-medium capitalize pl-2">Brand</th>
                                        <th className="text-sm font-medium capitalize">Campaign Name</th>
                                        <th className="text-sm font-medium capitalize">Platform</th>
                                        <th className="text-sm font-medium capitalize">Status</th>
                                        <th className="text-sm font-medium capitalize">Created On</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y">
                                    <tr className="h-14 border even:bg-gray-100">
                                        <td className="pl-2 flex items-center">
                                            <div className='w-12 h-12 mr-2 my-2 rounded-full border shadow-md overflow-hidden'>
                                                <img className='w-[100%] h-[100%] object-cover' src='https://wallpapers.com/images/hd/profile-picture-background-t2h20vgsbbth5dxe.jpg' />
                                            </div>
                                            WhatsApp
                                        </td>
                                        <td>WhatsApp Automation</td>
                                        <td>Instagram</td>
                                        <td><span className='text-xs px-2 py-1 bg-gray-500 text-white rounded-md shadow-md'>DRAFT</span></td>
                                        <td>31/08/2023</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                }   

            </div>
        </>
    )
}

export default Campaign
