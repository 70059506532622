import React from "react";
import { HiHeart, HiUserCircle } from "react-icons/hi";

const EngagementInsights = () => {
  return (
    <div className=" bg-white mt-4 p-2 text-gray-500">
      <h1 className="font-semibold text-lg p-2">Engagement Insights</h1>
      <div className="grid grid-cols-3 gap-4 h-72 p-3">
        <div className="col-span-1 gap-2 border-2 border-gray-100 p-4">
          <h1>Engagements</h1>
          <div className="">
            <div className="flex gap-4 items-center my-3">
              <HiUserCircle size={50} />
              <p className="text-2xl border-r-2 border-r-gray-500 pr-3 font-semibold">2.48</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
            </div>
            <div className="flex flex-col gap-3 pt-2">
              <div className="flex justify-between ">
                <div className="flex gap-2 items-center">
                  <HiHeart />
                  Likes
                </div>
                <p>2,280,163</p>
              </div>
              <div className="flex justify-between ">
                <div className="flex gap-2 items-center">
                  <HiHeart />
                  Comments
                </div>
                <p>2,163</p>
              </div>
              <div className="flex justify-between ">
                <div className="flex gap-2 items-center">
                  <HiHeart />
                  Engagement rate
                </div>
                <p>5.2%</p>
              </div>
              <div className="flex justify-between ">
                <div className="flex gap-2 items-center">
                  <HiHeart />
                  CPE
                </div>
                <p>1.29</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 gap-2 border-2 border-gray-100 p-4">Daily Engagements</div>
        <div className="col-span-1 gap-2 border-2 border-gray-100 p-4">Historical vs Actual Engagements</div>
      </div>
    </div>
  );
};

export default EngagementInsights;
