import React from 'react'

const TableSkeleton = () => {
    return (
        <tbody className='text-white border animate-pulse'>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
            <tr className="even:bg-[#d4d4d4] odd:bg-[#f3f3f3] border">
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
                <td className="px-4 py-5 text-center">
                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                </td>
            </tr>
        </tbody>
    )
}

export default TableSkeleton
