import React from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import IsVerified from "../../components/SearchResultFilters/IsVerified";
import CredibleAccountFilter from "../../components/SearchResultFilters/CredibleAccountFilter";
import PreviouslyExportedFilter from "../../components/SearchResultFilters/PreviouslyExportedFilter";
import ExcludePrivateAccountsFilter from "../../components/SearchResultFilters/ExcludePrivateAccountsFilter";
import HasAudienceDataFilter from "../../components/SearchResultFilters/HasAudienceDataFilter";
import ExcludeofficialArtistsfilter from "../../components/SearchResultFilters/ExcludeofficialArtistsfilter";
import useDropdownPosition from "../../components/SearchResultFilters/useDropdownPosition";
import { useLocation } from "react-router-dom";

const AllFiltersDropdown = ({ selectedPlatform, hoverStates, handleMouseOver }) => {
    const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const platformUrl = searchParams.get("platform");

  return (
    <div ref={buttonRef} className="relative">
      <div
        onClick={() => setIsDropdownOpen((prev) => !prev)}
        className={`cursor-pointer p-2 h-8 rounded-md ${
          isDropdownOpen ? "bg-gray-300" : "bg-gray-100"
        } flex items-center justify-center`}
      >
        <button className="flex items-center">
          <HiDotsHorizontal className="text-lg" />
        </button>
      </div>
      <div
        ref={dropdownRef}
        className={`absolute w-[320px] z-50 p-5 bg-white rounded-lg shadow-lg ${
          isDropdownOpen ? "block" : "hidden"
        }`}
      >
        <div className="flex flex-col gap-2">
          <IsVerified
            hoverStates={hoverStates}
            handleMouseOver={handleMouseOver}
          />

          {platformUrl === "Instagram" && (
            <CredibleAccountFilter
              hoverStates={hoverStates}
              handleMouseOver={handleMouseOver}
            />
          )}

          <PreviouslyExportedFilter
            hoverStates={hoverStates}
            handleMouseOver={handleMouseOver}
          />
        {console.log(platformUrl, "nksadkasd")}
          {(platformUrl === "Instagram" || platformUrl === "TikTok") && (
            <ExcludePrivateAccountsFilter
              hoverStates={hoverStates}
              handleMouseOver={handleMouseOver}
            />
          )}

          <HasAudienceDataFilter
            hoverStates={hoverStates}
            handleMouseOver={handleMouseOver}
          />

          {platformUrl === "YouTube" && (
            <ExcludeofficialArtistsfilter
              hoverStates={hoverStates}
              handleMouseOver={handleMouseOver}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllFiltersDropdown;