import React, { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoIconTooltip from "./InfoIconTooltip";
import { setContactFilter } from "../../redux/filters/actions";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const contactList = [
  "Has Email",
  "Has Phone",
  "Has Snapchat",
  "Has Facebook",
  "Has YouTube",
  "Has TikTok",
  "Has Twitter",
  "Has Telegram",
  "Has WhatsApp",
  "Has LINE ID",
  "Has VK",
  "Has BBM",
  "Has Kik",
  "Has WeChat",
  "Has Viber",
  "Has Skype",
  "Has Tumblr",
  "Has Twitch",
  "Has KaKao",
  "Has Pinterest",
  "Has Linktree",
  "Has Sarahah",
  "Has Sayat.me",
  "Has Apple Music Account",
  "Has Weibo",
];

// Transform the contact list into an array of objects with a 'name' property
const transformedContactList = contactList.map((item) => ({ name: item }));

const ContactFilter = ({ hoverStates, handleMouseOver }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const contactUrl = searchParams.get("contact")
  const contactArray = contactUrl ? contactUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();
  
  const handleSearchCreatorInterest = () => {
    // handleMouseOver("Contact");
    setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown
  };

  const handleContactChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.name);
    // setSelectedContact(selectedValues);

    dispatch(setContactFilter(selectedValues));

    if(selectedValues.length > 0){
      setSearchParam((searchParams) => {
        searchParams.set('contact', selectedValues.join(","))
        return searchParams;
      })
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete('contact')
        return searchParams;
      })
    }
  };

  return (
    <>
      <div ref={buttonRef} className="ContactFilter flex justify-start">
        <div className="relative w-full">
          <button
            onClick={handleSearchCreatorInterest}
            className="filterTextColor flex items-center bg-white"
          >
            <span className={`flex items-center ${isDropdownOpen ? 'font-semibold' : ''}`}>
              Contact
              <span
                className={`${
                  isDropdownOpen ? "" : ""
                } flex items-center`}
              >
                {isDropdownOpen ? (
                  <RxCross2 className="ml-2 text-lg" />
                ) : (
                  <IoChevronDownSharp className="ml-2 text-lg" />
                )}
              </span>
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-fit z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Influencer{" "}
                <InfoIconTooltip
                  infoContent={`Identify influencers by their interests. We determine interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests.`}
                />
              </div>
              <MultiSelectDropdown
                options={transformedContactList}
                onSelect={handleContactChange}
                onRemove={handleContactChange}
                placeholder={"Add Contact"}
                selectedValues={contactArray.map((value) => ({
                  name: value,
                }))}
              />
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContactFilter;
