import { useState, useEffect } from "react";
import { Tooltip } from "flowbite-react";
import axiosInstance from "../../interseptor";
import { HiArrowLeft, HiRefresh } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import ViralContentInsights from "./ViralContentInsights";
import ViralInfluencer from "./ViralInfluencer";
import ViralContent from "./ViralContent";
import { MdOutlineDashboardCustomize, MdOutlinePeopleAlt } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
import ViralOverlappingInfluencer from "./ViralOverlappingInfluencer";
const VIEW_REPORT = "/livesearch/viral_report_detail";

const ViralBrandSingleReport = () => {
    const [selectedTab, setSelectedTab] = useState("Dashboard");
    const [selectedPlatform, setSelectedPlatform] = useState("Instagram");
    const [reportData, setReportData] = useState();
    const [reportName, setReportName] = useState("");
    const [competitorName, setCompetitorName] = useState("");
    const [selectedPostedDate, setSelectedPostedDate] = useState("");
    const [noDataFound, setNoDataFound] = useState(false)
    const [listNull, setListNull] = useState(false)
    const { id } = useParams();
    // console.log("report id", id);


    function convertUnixTimestampToDateTime(timestamp) {
        console.log(timestamp)
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Get individual components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted string
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

        return formattedDate;
    }

    useEffect(() => {

        const getReportData = async () => {
            try {
                let data = new FormData();
                data.append("logintype", "viral_report_detail");
                data.append("device_id", "seacrh");
                data.append("brandname", id);
                data.append("last_posted", selectedPostedDate);

                const response = await axiosInstance.post(VIEW_REPORT, data);
                // console.log("report response", response.data.list);

                if (response && response?.status === 200) {

                    if (response?.data?.status === 0) {
                        setNoDataFound(true)
                        return;
                    }

                    if (response?.data?.list === null) {
                        setListNull(true)
                        return;
                    }

                    if (response && response?.data && response?.data?.status === 201) {
                        console.log({ message: response?.data?.msg, code: 401 });
                        return false;
                    } else if (
                        response &&
                        response?.data &&
                        response?.data?.status === 2
                    ) {
                        console.log({ message: response?.data?.msg, code: 401 });
                        return false;
                    } else if (
                        response &&
                        response?.data &&
                        response?.data?.status === 3
                    ) {
                        console.log({ message: response?.data?.msg, code: 401 });
                        return false;
                    }
                    console.log(response?.data?.list)
                    setReportData(response?.data?.list);
                    setListNull(false);
                    setReportName(response?.data?.report_name);
                    console.log(response?.data?.competitor)
                    setCompetitorName(response?.data?.competitor?.split(','));
                    return response?.data.list;
                }
            } catch (err) {
                console.log(err);
            }
        };

        if (id) { // Add a check for id
            getReportData();
        }

    }, [id, selectedPostedDate]);


    const wordcloudData_ = reportData?.hashtags_used?.reduce((acc, item) => {
        if (item) {
            if (acc[item]) {
                acc[item]++;
            } else {
                acc[item] = 1;
            }
        }
        return acc;
    }, {});


    let wordcloudData = []
    if (wordcloudData_) {
        wordcloudData = Object.entries(wordcloudData_)?.slice(0, 10).map(([x, count]) => ({ x, value: count }));

    }

    const handleRefresh = () => {
        window.location.reload();
    }

    const lastPostedDate = ['Check Last Posted', 7, 15, 30, 60, 90];

    const sortedData = reportData?.post_list.slice().sort((a, b) => b.views - a.views);

    const postByUsername = sortedData?.reduce((acc, post) => {
        const username = post?.username;
        if (!acc[username]) {
            acc[username] = [];
        }
        acc[username].push(post);
        return acc;
    }, {});

    useEffect(()=>{
        renderTabContent()
    },[reportData])

    const renderTabContent = () => {
        switch (selectedTab) {
            case "Dashboard":
                return (
                    <>
                        {/* <StaticPost reportData={reportData} /> */}
                        {/* <Reels reportData={reportData} /> */}
                        {/* <EngagementInsights /> */}
                        {reportData ? <ViralContentInsights reportData={reportData} height={300} />
                            :
                            <>
                                <div className="w-full h-[300px] mt-10 flex gap-5 justify-between text-gray-500">

                                    <div className="w-2/4 bg-gray-200 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden">

                                    </div>


                                    <div className="w-2/4 bg-gray-200 animate-pulse flex justify-between border rounded-lg shadow-lg p-5">

                                    </div>

                                </div>
                                <div className="w-full h-[100px] mt-10 flex gap-5 justify-between text-gray-500">

                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>

                                </div>
                            </>
                        }
                    </>
                );
            case "Influencers":
                return (
                    <div className="">
                        <ViralInfluencer reportData={reportData} setSelectedTab={setSelectedTab} />
                    </div>
                );
            case "Content":
                return <>{postByUsername && <ViralContent reportData={postByUsername} height={500} />}</>;
            case "repeatInfluencer":
                return (
                    <div className="">
                        <ViralOverlappingInfluencer reportData={reportData} setSelectedTab={setSelectedTab} />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {
                noDataFound ?
                    <div className="">
                        <img
                            src="/asset/no-data.png"
                            alt="no-dataImage"
                            className="bg-cover h-96 mx-auto"
                        />
                        <p className="text-lg text-gray-600 text-center p-2">
                            No Data Found
                        </p>
                        <div className="flex justify-center text-md mt-5">
                            <Link to="/ViralBrandReport" className="mx-auto">
                                <span className="text-indigo-500">View</span> Another Profile
                            </Link>
                        </div>
                    </div> : (
                        <div className="p-2 px-8 h-full">
                            <div className="sticky top-0 z-40 bg-white flex items-center gap-3 border-b-2 border-b-gray-500 pt-1 pb-3">
                                <div className="w-full flex justify-between items-center">
                                    <div className="flex items-center gap-5">
                                        <Link to="/ViralBrandReport">
                                            <HiArrowLeft size={25} />
                                        </Link>
                                        <div>
                                            <p className="text-2xl text-[#323237] font-semibold flex items-center gap-1">
                                                {id}
                                                <Tooltip content="Refresh Report">
                                                    <button
                                                        onClick={() => handleRefresh()}
                                                        className="flex items-center p-2 text-xl rounded-md hover:bg-[#323237] hover:text-white"
                                                    >
                                                        <HiRefresh className="text-xl hover:scale-110 duration-100" />
                                                    </button>
                                                </Tooltip>
                                            </p>
                                            <p className="text-sm flex items-center">
                                                {console.log(reportData)}
                                                Report Last updated on {reportData?.last_updated ? convertUnixTimestampToDateTime(reportData?.last_updated) : <div className="w-32 h-6 ml-2 rounded-lg bg-gray-200 animate-pulse"></div>}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="font-medium text-sm">
                                        {
                                            <div>
                                                <select
                                                    className="rounded-lg border-2 border-[#6d6eac] py-3"
                                                    name="date"
                                                    id="date"
                                                    onChange={(e) => {
                                                        const selectedDate = e.target.value;
                                                        console.log(selectedDate)
                                                        if (selectedDate === 'Check Last Posted') {
                                                            setSelectedPostedDate(""); setReportData()
                                                        } else if (selectedDate) {
                                                            setSelectedPostedDate(selectedDate) ; setReportData()
                                                        }
                                                    }}
                                                >
                                                    {lastPostedDate.map((data, index) => (
                                                        <option className="cursor-pointer py-4" key={index} value={data}>
                                                            {data === 'Check Last Posted' ? data : `Last ${data} Days`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        }

                                        {
                                            reportData?.report_msg !== "" && reportData?.report_msg
                                        }
                                    </div>

                                </div>
                            </div>

                            {
                                listNull ?
                                    <div className="">
                                        <img
                                            src="/asset/no-data.png"
                                            alt="no-dataImage"
                                            className="bg-cover h-96 mx-auto"
                                        />
                                        <p className="text-lg text-gray-600 text-center p-2">
                                            No Data Found
                                        </p>
                                        <div className="flex justify-center text-md mt-5">
                                            <Link to="/ViralBrandReport" className="mx-auto">
                                                <span className="text-indigo-500">View</span> Another Profile
                                            </Link>
                                        </div>
                                    </div>
                                    :
                                    <> <div className="w-full flex items-center justify-between gap-4 mt-5">
                                        <div className="flex gap-4">
                                            <button
                                                className={`tab-button flex items-center gap-0.5 ${selectedTab === "Dashboard" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                                                onClick={() => setSelectedTab("Dashboard")}
                                            >
                                                <MdOutlineDashboardCustomize /> Dashboard
                                            </button>
                                            <button
                                                className={`tab-button flex items-center gap-0.5 ${selectedTab === "Influencers" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                                                onClick={() => setSelectedTab("Influencers")}
                                            >
                                                <MdOutlinePeopleAlt /> Influencers
                                            </button>
                                            <button
                                                className={`tab-button flex items-center gap-0.5 ${selectedTab === "Content" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                                                onClick={() => setSelectedTab("Content")}
                                            >
                                                <BiBookContent /> Content
                                            </button>
                                            <button
                                                className={`tab-button flex items-center gap-0.5 ${selectedTab === "repeatInfluencer" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                                                onClick={() => setSelectedTab("repeatInfluencer")}
                                            >
                                                <BiBookContent /> Repeated Brand
                                            </button>
                                        </div>

                                        <div className="flex items-center gap-2">

                                            {
                                                reportData?.platform === 1 ?
                                                    <div
                                                        className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                                        onClick={() => setSelectedPlatform("Instagram")}
                                                    >
                                                        <BsInstagram />
                                                        Instagram Report
                                                    </div>
                                                    : reportData?.platform === 2 ?
                                                        <div
                                                            className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                                            onClick={() => setSelectedPlatform("YouTube")}
                                                        >
                                                            <BsYoutube />
                                                            YouTube Report
                                                        </div> : reportData?.platform === 3 ?
                                                            <div
                                                                className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                                                onClick={() => setSelectedPlatform("TikTok")}
                                                            >
                                                                <BsTiktok />
                                                                TikTok Report
                                                            </div>
                                                            : null
                                            }


                                        </div>
                                    </div>

                                        <div className="mt-4">
                                            {renderTabContent()}
                                        </div>

                                    </>
                            }

                        </div>
                    )
            }
        </>
    );
};

export default ViralBrandSingleReport;