import React, { useState } from "react";
import { Badge } from "flowbite-react";
import { FaComment, FaEye, FaImage } from "react-icons/fa";
import { IoIosSave, IoIosTrendingDown, IoIosTrendingUp, IoMdHeart, IoMdShareAlt } from "react-icons/io";
import { BsCameraReelsFill, BsChatFill, BsFillPostcardHeartFill, BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import { RiUserFollowFill, RiVideoFill } from "react-icons/ri";
import FollowerDistributionGraph from "./FollowerDistributionGraph";
import FollowerGenderGraph from "./FollowerGenderGraph";
import { HiEye } from "react-icons/hi";
import { report } from "process";
import HorizontalWordCloud from "../../pages/HorizontalWordCloud";
import { AiFillLike } from "react-icons/ai";
import { GiProgression } from "react-icons/gi";
import InfoIconTooltip from "../SearchResultFilters/InfoIconTooltip";

const ContentInsights = ({ reportData }) => {
  console.log('reportData**********', reportData)
  // Check if reportData is undefined or null
  // if (reportData === undefined || reportData === null){
  //   return null; // Return null or some default value
  // } 

  // Determine the initial state based on the value of showpostoverview
  const currState = reportData?.showpostoverview === 1 ? 'post' : 'reel';

  // useState hook to manage the 'tabs' state
  const [tabs, setTabs] = useState(currState);

  // Assuming reportData.post_list is an array
  const sortedPostList = reportData?.post_list.sort((a, b) => b.follower - a.follower);

  // Get the top 5 items
  const topPosts = sortedPostList?.filter((post, index, self) => {
    // Filter out items with duplicate usernames
    return index === self.findIndex((p) => p.username === post.username);
  })
    .slice(0, 10);

  const datefunc = (datestring) => {
    let createdOn = new Date(datestring);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const formattedDate = createdOn.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  console.log('top influencer', topPosts)

  const reelActualEngagement = parseInt(reportData?.reel_overview?.reel_actual_engagement, 10);
  const reelHistoricEngagement = parseInt(reportData?.reel_overview?.reel_historic_engagement, 10);
  const postActualEngagement = parseInt(reportData?.post_overview?.post_actual_engagement, 10);
  const postHistoricEngagement = parseInt(reportData?.post_overview?.post_historic_engagement, 10);
  const overviewActualEngagement = (parseInt(reportData?.reel_overview?.reel_actual_engagement, 10) || 0) + (parseInt(reportData?.post_overview?.post_actual_engagement, 10) || 0);
  const overviewHistoricEngagement = (parseInt(reportData?.reel_overview?.reel_historic_engagement, 10) || 0) + (parseInt(reportData?.post_overview?.post_historic_engagement, 10) || 0);

  // Find the higher value
  const reelHigherValue = Math.max(reelActualEngagement, reelHistoricEngagement);
  const postHigherValue = Math.max(postActualEngagement, postHistoricEngagement);
  const overviewHigherValue = Math.max(overviewActualEngagement, overviewHistoricEngagement);

  // Calculate the percentage difference
  const reelPercentageDifferenceActual = (reelActualEngagement / reelHigherValue) * 100;
  const reelPercentageDifferenceHistoric = (reelHistoricEngagement / reelHigherValue) * 100;
  const postPercentageDifferenceActual = (postActualEngagement / postHigherValue) * 100;
  const postPercentageDifferenceHistoric = (postHistoricEngagement / postHigherValue) * 100;
  const overviewPercentageDifferenceHistoric = (overviewHistoricEngagement / overviewHigherValue) * 100;
  const overviewPercentageDifferenceActual = (overviewActualEngagement / overviewHigherValue) * 100;

  // Log the results or use them as needed
  console.log(`reel Actual Engagement Width: ${reelPercentageDifferenceActual.toFixed(2)}%`);
  console.log(`reel Historic Engagement Width: ${reelPercentageDifferenceHistoric.toFixed(2)}%`);
  console.log(`post Actual Engagement Width: ${postPercentageDifferenceActual.toFixed(2)}%`);
  console.log(`post Historic Engagement Width: ${postPercentageDifferenceHistoric.toFixed(2)}%`);
  console.log(`overview Actual Engagement Width: ${overviewPercentageDifferenceActual.toFixed(2)}%`);
  console.log(`overview Historic Engagement Width: ${overviewPercentageDifferenceHistoric.toFixed(2)}%`);

  return (
    <>
      <div className="w-full">
        {
          reportData?.reel_overview ?
            <div className="w-full flex gap-4 mt-10">
              {
                reportData?.reel_overview?.total_reel &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Reels</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <BsCameraReelsFill className="text-[#1dbb99] text-xl" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.reel_overview?.total_reel_likes &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Reel Likes</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <AiFillLike className="text-[#6d5eac]" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel_likes)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.reel_overview?.total_reel_likes &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Avg. Reel Likes</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <AiFillLike className="text-[#6d5eac]" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel_avg_likes)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.reel_overview?.total_reel_comment &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Reel Comments</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <FaComment className="text-[#f7cf5f] text-xl" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel_comment)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.reel_overview?.total_reel_views &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Reel Views</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <FaEye className="text-black" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel_views)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.reel_overview?.total_reel_avg_views &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Average Reel Views</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <FaEye className="text-black" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.reel_overview?.total_reel_avg_views)}
                      </p>
                    </div>
                  </div>
                </div>
              }

            </div>
            : <div className="w-full flex gap-4">
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
            </div>
        }


        {
          reportData?.post_overview ?
            <div className="w-full flex gap-4 mt-5">
              {
                reportData?.post_overview?.total_posts &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Posts</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <BsFillPostcardHeartFill className="text-[#1dbb99]" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.post_overview?.total_posts)}
                      </p>
                    </div>
                  </div>
                </div>
              }


              {
                reportData?.post_overview?.total_post_likes &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Post Likes</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <AiFillLike className="text-[#6d5eac]" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.post_overview?.total_post_likes)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.post_overview?.total_post_avg_likes &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Avg. Post Likes</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <AiFillLike className="text-[#6d5eac]" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.post_overview?.total_post_avg_likes)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.post_overview?.total_post_comments &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Total Post Comments</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <FaComment className="text-[#f7cf5f] text-xl" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.post_overview?.total_post_comments)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.post_overview?.total_post_avg_comments &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Avg. Post Comments</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <FaComment className="text-[#f7cf5f] text-xl" />
                      <p className="font-bold text-black text-xl">
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(reportData?.post_overview?.total_post_avg_comments)}
                      </p>
                    </div>
                  </div>
                </div>
              }

              {
                reportData?.post_overview?.average_engangement_rate &&
                <div className="w-full border flex flex-col justify-around h-full rounded-lg shadow-lg p-3">
                  <div className="flex flex-col gap-2 text-2xl ml-2">
                    <h1 className="text-base">Average Eng. Rate</h1>
                    <div className="flex items-center gap-2 ml-2 mt-3">
                      <GiProgression className="text-[#6d5eac] text-xl" />
                      <p className="font-bold text-black text-xl">
                        {reportData?.post_overview?.average_engangement_rate}
                      </p>
                    </div>
                  </div>
                </div>
              }

            </div>
            : <div className="w-full flex gap-4">
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
              <div className="w-full rounded-md bg-gray-100 animate-pulse shadow-lg hover:scale-105 duration-100"></div>
            </div>
        }


        <div className="w-full my-10 flex gap-5 justify-between text-gray-500">
          <div className="w-full border rounded-lg shadow-lg p-5">
            <h1 className="text-black font-semibold">
              Followers Distribution
              <InfoIconTooltip infoContent={`Lorem Ipsum`} />
            </h1>
            <FollowerDistributionGraph graphData={reportData?.followers_distribution} />
          </div>
          <div className="w-full border rounded-lg shadow-lg p-5">
            <h1 className="text-black font-semibold">
              Influencer Gender
              <InfoIconTooltip infoContent={`Lorem Ipsum`} />
            </h1>
            <FollowerGenderGraph graphData={reportData?.gender_distribution} />
          </div>
        </div>



        <div className="w-full mt-10 border rounded-lg shadow-lg p-5">
          <div className="mb-5 flex gap-2 items-center">
            {
              reportData?.showoverview === 1 &&
              <button onClick={() => setTabs('overview')} className={`w-32 border ${tabs === 'overview' ? 'bg-[#1dbb99]' : 'bg-[#323237]'} text-white rounded-lg px-2 py-1.5`}>
                Overview
              </button>
            }


            {
              (reportData?.post_overview?.total_posts !== 0 && reportData?.post_overview?.total_posts !== undefined) && (
                <button
                  onClick={() => setTabs('post')}
                  className={`w-32 border ${tabs === 'post' ? 'bg-[#1dbb99]' : 'bg-[#323237]'} text-white rounded-lg px-2 py-1.5`}
                >
                  Post
                </button>
              )
            }

            {
              reportData?.showreeloverview === 1 &&
              <button onClick={() => setTabs('reel')} className={`w-32 border ${tabs === 'reel' ? 'bg-[#1dbb99]' : 'bg-[#323237]'} text-white rounded-lg px-2 py-1.5`}>
                Reel
              </button>
            }
          </div>

          {
            (tabs === 'post' && reportData?.showpostoverview === 1) &&
            <div className="w-full flex gap-5 justify-between text-gray-500">
              <div className="w-full border rounded-lg shadow-lg p-5">
                <h2 className="">POST OVERVIEW</h2>

                <div className="my-2">
                  <h2 className="font-semibold text-2xl text-black">
                    {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                      reportData?.post_overview?.total_post_likes + reportData?.post_overview?.total_post_comments + reportData?.post_overview?.total_post_saves + reportData?.post_overview?.total_post_shares
                    )}
                  </h2>
                  <p>No. of people engaged on influencer&apos;s posts</p>
                </div>
                <div className="w-[400px] mt-5 bg-blue-50 rounded-lg p-5">
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdHeart className="text-red-500 text-lg" /> Likes</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.total_post_likes
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><BsChatFill className="text-[#f7cf5f]" /> Comments</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.total_post_comments
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdShareAlt className="text-[#1dbb99] text-lg" /> Share</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.total_post_shares
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoIosSave className="text-[#323237] text-lg" /> Saves</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.total_post_saves
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full border rounded-lg shadow-lg p-5">

                <h2 className="">HISTORICAL V/S ACTUAL ENGAGEMENTS</h2>
                {
                  postPercentageDifferenceActual >= postPercentageDifferenceHistoric ?
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-[#1dbb99]"><IoIosTrendingUp /> PROGRESS</p>
                    </div>
                    :
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-red-800"><IoIosTrendingDown /> REGRESS</p>
                    </div>
                }


                <div className="w-[400px] mt-5 p-5">
                  <div className="flex items-center">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.post_historic_engagement
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Historical</p>
                      {/* <div className="w-[230px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${postPercentageDifferenceHistoric.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.post_overview?.post_actual_engagement
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Actual</p>
                      {/* <div className="w-[150px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${postPercentageDifferenceActual.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            (tabs === 'reel' && reportData?.showreeloverview === 1) &&
            <div className="w-full flex gap-5 justify-between text-gray-500">
              <div className="w-full border rounded-lg shadow-lg p-5">
                <h2 className="">REEL OVERVIEW</h2>

                <div className="my-2">
                  <h2 className="font-semibold text-2xl text-black">
                    {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                      reportData?.reel_overview?.total_reel_likes + reportData?.reel_overview?.total_reel_comment + reportData?.reel_overview?.total_reel_views + reportData?.reel_overview?.total_reel_saves + reportData?.reel_overview?.total_reel_shares
                    )}
                  </h2>
                  <p>No. of people engaged on influencer&apos;s posts</p>
                </div>
                <div className="w-[400px] mt-5 bg-blue-50 rounded-lg p-5">
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdHeart className="text-red-500 text-lg" /> Likes</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.total_reel_likes
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><BsChatFill className="text-[#f7cf5f]" /> Comments</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.total_reel_comment
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><HiEye className="" /> Views</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.total_reel_views
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdShareAlt className="text-[#1dbb99] text-lg" /> Share</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.total_reel_shares
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoIosSave className="text-[#323237] text-lg" /> Saves</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.total_reel_saves
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full border rounded-lg shadow-lg p-5">

                <h2 className="">HISTORICAL V/S ACTUAL ENGAGEMENTS</h2>

                {
                  reelPercentageDifferenceActual >= reelPercentageDifferenceHistoric ?
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-[#1dbb99]"><IoIosTrendingUp /> PROGRESS</p>
                    </div>
                    :
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-red-800"><IoIosTrendingDown /> REGRESS</p>
                    </div>
                }

                <div className="w-[400px] mt-5 p-5">
                  <div className="flex items-center">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.reel_historic_engagement
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Historical</p>
                      {/* <div className="w-[230px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${reelPercentageDifferenceHistoric.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        reportData?.reel_overview?.reel_actual_engagement
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Actual</p>
                      {/* <div className="w-[150px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${reelPercentageDifferenceActual.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {

            (tabs === 'overview' && reportData?.showoverview === 1) &&
            <div className="w-full flex gap-5 justify-between text-gray-500">
              <div className="w-full border rounded-lg shadow-lg p-5">
                <h2 className="">OVERVIEW</h2>

                <div className="my-2">
                  <h2 className="font-semibold text-2xl text-black">
                    {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                      (
                        (reportData?.post_overview?.total_post_likes || 0) +
                        (reportData?.reel_overview?.total_reel_likes || 0) +
                        (reportData?.post_overview?.total_post_comments || 0) +
                        (reportData?.reel_overview?.total_reel_comment || 0) +
                        (reportData?.reel_overview?.total_reel_shares || 0) +
                        (reportData?.post_overview?.total_post_shares || 0) +
                        (reportData?.reel_overview?.total_reel_saves || 0) +
                        (reportData?.post_overview?.total_post_saves || 0) +
                        (reportData?.reel_overview?.total_reel_views || 0)
                      ) || 0 // Ensure that the final result is not NaN
                    )}
                  </h2>
                  <p>No. of people engaged on influencer&apos;s posts</p>
                </div>
                <div className="w-[400px] mt-5 bg-blue-50 rounded-lg p-5">
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdHeart className="text-red-500 text-lg" /> Likes</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.post_overview?.total_post_likes || 0) + (reportData?.reel_overview?.total_reel_likes || 0)
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><BsChatFill className="text-[#f7cf5f]" /> Comments</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.post_overview?.total_post_comments || 0) + (reportData?.reel_overview?.total_reel_comment || 0)
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><HiEye className="" /> Views</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.reel_overview?.total_reel_views || 0)
                      )}
                    </p>
                  </div>

                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoMdShareAlt className="text-[#1dbb99] text-lg" /> Share</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.reel_overview?.total_reel_shares || 0) + (reportData?.post_overview?.total_post_shares || 0)
                      )}
                    </p>
                  </div>
                  <div className="w-full flex justify-between">
                    <div className="flex items-center gap-1"><IoIosSave className="text-[#323237] text-lg" /> Saves</div>
                    <p className="text-black font-semibold">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.post_overview?.total_post_saves || 0) + (reportData?.reel_overview?.total_reel_saves || 0)
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full border rounded-lg shadow-lg p-5">

                <h2 className="">HISTORICAL V/S ACTUAL ENGAGEMENTS</h2>

                {
                  overviewPercentageDifferenceActual >= overviewPercentageDifferenceHistoric ?
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-[#1dbb99]"><IoIosTrendingUp /> PROGRESS</p>
                    </div>
                    :
                    <div className="my-2">
                      <p className="flex items-center gap-1 text-red-800"><IoIosTrendingDown /> REGRESS</p>
                    </div>
                }
                <div className="w-[400px] mt-5 p-5">
                  <div className="flex items-center">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.reel_overview?.reel_historic_engagement || 0) + (reportData?.post_overview?.post_historic_engagement || 0)
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Historical</p>
                      {/* <div className="w-[230px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${overviewPercentageDifferenceHistoric.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-4">
                    <p className="font-semibold text-black w-20">
                      {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        (reportData?.reel_overview?.reel_actual_engagement || 0) + (reportData?.post_overview?.post_actual_engagement || 0)
                      )}
                    </p>
                    <div className="w-full">
                      <p className="pb-1 text-sm">Actual</p>
                      {/* <div className="w-[150px] h-[40px] bg-[#6d5eac] rounded-lg"></div> */}
                      <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700">
                        <div className="h-10 bg-[#6d5eac] rounded-lg dark:bg-[#6d5eac]" style={{ width: `${overviewPercentageDifferenceActual.toFixed(2) + '%'}` }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>


        {/* <div className="w-full mt-5 flex gap-5 justify-between text-gray-500">
          <div className="w-full border rounded-lg shadow-lg p-5">
            <h2 className="">REEL VIEWS</h2>

            <div className="my-2">
              <h2 className="font-semibold text-2xl text-black">18.92M  </h2>
              <p>No. of people viewed influencer&apos;s posts</p>
            </div>
            <div className="w-[400px] mt-5 bg-blue-50 rounded-lg p-5">
              <div className="w-full flex justify-between">
                <div className="flex items-center gap-1"><MdOutlineVideoCameraFront className="text-red-500 text-lg" /> Reel Views</div>
                <p className="text-black font-semibold">18,918,063</p>
              </div>
              <div className="w-full flex justify-between">
                <div className="flex items-center gap-1"><AiOutlinePercentage className="text-black" /> View Rate</div>
                <p className="text-black font-semibold">28.52%</p>
              </div>
              <div className="w-full flex justify-between">
                <div className="flex items-center gap-1"><MdCurrencyRupee className="text-[#1dbb99]" /> CPV</div>
                <p className="text-black font-semibold">₹ 0.17</p>
              </div>
            </div>
          </div>

          <div className="w-full border rounded-lg shadow-lg p-5">
            <h2 className="">HISTORICAL V/S ACTUAL VIEWS</h2>

            <div className="my-2">
              <p className="flex items-center gap-1 text-[#1dbb99]"><IoIosTrendingUp /> PROGRESS</p>
            </div>
            <div className="w-[400px] mt-5 p-5">
              <div className="flex items-center gap-5">
                <p className="font-semibold text-black">3.19M</p>
                <div>
                  <p className="pb-1 text-sm">Historical</p>
                  <div className="w-[230px] h-[40px] bg-[#6d5eac] rounded-lg"></div>
                </div>
              </div>
              <div className="flex items-center gap-5 mt-4">
                <p className="font-semibold text-black">2.48M</p>
                <div>
                  <p className="pb-1 text-sm">Actual</p>
                  <div className="w-[150px] h-[40px] bg-[#6d5eac] rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        {/* <div className="w-full border rounded-lg shadow-lg p-5 mt-5">
          <h2 className="">INFLUENCER CATEGORY PERFORMANCE</h2>

          <div className="flex gap-5 mt-5">
            <div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 ">Influencer Category</div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="flex items-center gap-1"><BsPeople className="text-lg" /> Influencers Live</div>
                <div className="flex items-center gap-1"><RiSendPlaneLine className="text-lg" />Posts Live</div>
                <div className="flex items-center gap-1"><MdOutlineCampaign className="text-lg" />Campaign Reach</div>
                <div className="flex items-center gap-1">Engagements</div>
                <div className="flex items-center gap-1">Engagement Rate</div>
                <div className="flex items-center gap-1">Reel Views</div>
                <div className="flex items-center gap-1">View Rate</div>
              </div>
            </div>
            <div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 text-center">NANO <span className="text-xs">(1K-10K)</span></div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="text-center font-medium">45</div>
                <div className="text-center font-medium">34M</div>
                <div className="text-center font-medium">22M</div>
                <div className="text-center font-medium">56K</div>
                <div className="text-center font-medium">23%</div>
                <div className="text-center font-medium">8M</div>
                <div className="text-center font-medium">98K</div>
              </div>
            </div>
            <div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 text-center">MICRO <span className="text-xs">(10K-100K)</span></div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="text-center font-medium">45</div>
                <div className="text-center font-medium">34M</div>
                <div className="text-center font-medium">22M</div>
                <div className="text-center font-medium">56K</div>
                <div className="text-center font-medium">23%</div>
                <div className="text-center font-medium">8M</div>
                <div className="text-center font-medium">98K</div>
              </div>
            </div><div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 text-center">MACRO <span className="text-xs">(100K-500K)</span></div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="text-center font-medium">45</div>
                <div className="text-center font-medium">34M</div>
                <div className="text-center font-medium">22M</div>
                <div className="text-center font-medium">56K</div>
                <div className="text-center font-medium">23%</div>
                <div className="text-center font-medium">8M</div>
                <div className="text-center font-medium">98K</div>
              </div>
            </div><div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 text-center">MEGA <span className="text-xs">(500K-1M)</span></div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="text-center font-medium">45</div>
                <div className="text-center font-medium">34M</div>
                <div className="text-center font-medium">22M</div>
                <div className="text-center font-medium">56K</div>
                <div className="text-center font-medium">23%</div>
                <div className="text-center font-medium">8M</div>
                <div className="text-center font-medium">98K</div>
              </div>
            </div><div className="w-full rounded-lg text-sm text-gray-600 font-semibold">
              <div className="bg-blue-50 shadow-md rounded-lg p-2 text-center">A-LISTERS <span className="text-xs">(1M+)</span></div>
              <div className="mt-3 bg-blue-50 shadow-md rounded-lg p-2 flex flex-col gap-5">
                <div className="text-center font-medium">45</div>
                <div className="text-center font-medium">34M</div>
                <div className="text-center font-medium">22M</div>
                <div className="text-center font-medium">56K</div>
                <div className="text-center font-medium">23%</div>
                <div className="text-center font-medium">8M</div>
                <div className="text-center font-medium">98K</div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="w-full bg-white mt-10">
          <div className="grid grid-cols-1 gap-4">
            {/* {console.log({ topPosts })} */}
            {
              (topPosts && topPosts[0].follower > 0) &&
              <div className="col-span-1 gap-2">
                <div className="flex justify-between">
                  <h1 className="text-black font-semibold">TOP INFLUENCERS</h1>
                </div>
                <div className="w-full flex overflow-auto gap-5 m-2">
                  {
                    topPosts?.map((item, index) => (

                      item?.follower > 0 &&
                      <div key={index} className="min-w-[280px] h-[300px] border rounded-lg overflow-hidden shadow-md my-5 hover:shadow-lg">
                        <a href={item?.profile_link} target="/blank">
                          <div className="w-full flex flex-col items-center justify-center mt-5">
                            <div className="w-20 h-20 overflow-hidden rounded-full shadow-md">
                              <img className="w-[100%] h-[100%] object-cover"
                                src={item?.profile_image || "/asset/No-Image-Found.png"}
                                alt="img"
                                onError={({ currentTarget, error }) => {
                                  console.error('Image failed to load:', error);
                                  currentTarget.onerror = null; // Remove the event listener to prevent looping
                                  currentTarget.src = "/asset/onErrorImage.jpg";
                                }}
                              />
                            </div>
                            {/* <h2 className="font-semibold">Shehnaz Gill</h2> */}
                            <p className="font-semibold pt-1 hover:text-primary-600">@{item?.username}</p>
                          </div>
                        </a>

                        <div className="flex mt-10 w-full">
                          <div className="w-full flex flex-col gap-4 border-r-2">
                            <div>
                              <p className="text-center font-semibold">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                item?.follower
                              )}</p>
                              <p className="text-xs text-center text-gray-500">Followers</p>
                            </div>
                            <div>
                              <p className="text-center font-semibold">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                item?.user_posts
                              )}</p>
                              <p className="text-xs text-center text-gray-500">Posts</p>
                            </div>
                          </div>
                          <div className="w-full flex flex-col gap-4">
                            <div>
                              <p className="text-center font-semibold">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                item?.user_avg_like
                              )}</p>
                              <p className="text-xs text-center text-gray-500">Avg. Likes</p>
                            </div>
                            <div>
                              <p className="text-center font-semibold">
                                {(item?.user_eng_rate.toString()).length <= 4 ? `${(item?.user_eng_rate.toString())}%` : `${(item?.user_eng_rate.toString()).slice(0, 4)}%`}
                              </p>
                              <p className="text-xs text-center text-gray-500">Engagements</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }

                </div>
              </div>
            }


            <div className="col-span-1 gap-2">
              <div className="flex justify-between">
                <h1 className="text-black font-semibold">TOP POST</h1>
              </div>
              <div className="flex overflow-auto gap-5 m-2">
                {reportData &&
                  reportData?.top_content?.map((item, index) => (
                    item?.username && (
                      <a
                        key={index}
                        href={item.post_link}
                        target="_blank"
                        className="border h-fit min-w-[280px] rounded-lg overflow-hidden my-5 shadow-md"
                        rel="noreferrer"
                      >
                        <div className="w-full flex items-center justify-between gap-1 p-2">
                          <div className="flex items-center gap-1">
                            <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                              <img
                                className="w-full h-full object-cover"
                                src={item.profile_image || '/asset/onErrorImage.jpg'}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null || undefined; // prevents looping
                                  currentTarget.src = "/asset/onErrorImage.jpg";
                                }}
                                alt="img"
                              />
                            </div>
                            <div className="">
                              <p className="">{item?.username}</p>
                              <p className="text-xs text-gray-600">{datefunc(item?.post_time)}</p>
                            </div>
                          </div>

                          <div className="flex flex-col items-center gap-1">
                            {
                              reportData?.platform[0] === 1 ?
                                <BsInstagram className="text-red-600 text-lg" />
                                : reportData?.platform[0] === 2 ?
                                  <BsYoutube className="text-rose-600 text-lg" />
                                  : reportData?.platform[0] === 3 ?
                                    <BsTiktok className="text-black text-lg" />
                                    : ""
                            }
                          </div>

                        </div>
                        <div
                          className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                        >
                          <img
                            src={item.post_image}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null || undefined; // prevents looping
                              currentTarget.src = "/asset/onErrorImage.jpg";
                            }}
                            alt=""
                            className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                          />
                        </div>
                        <div className="w-full flex gap-5 p-2 py-3">
                          <div className="flex items-center text-sm">
                            {
                              item?.post_type === "Video" ?
                                <RiVideoFill className="text-lg" />
                                : item?.post_type === "Reel" ?
                                  <FaImage className="text-lg" />
                                  : ""
                            }
                          </div>
                          {item?.views > 0 && (
                            <div className="flex items-center gap-1 text-sm">
                              <FaEye /> {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(item?.views)}
                            </div>
                          )}

                          {item?.likes > 0 && (
                            <div className="flex items-center gap-1 text-sm">
                              <IoMdHeart className="text-red-500 text-lg" /> {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(item?.likes)}
                            </div>
                          )}

                          {item?.comment > 0 && (
                            <div className="flex items-center gap-1 text-sm">
                              <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(item?.comment)}
                            </div>
                          )}
                        </div>
                      </a>
                    )
                  ))}
              </div>
            </div>


            {/* <div className="col-span-1 gap-2 border rounded-lg shadow-md">
              <div className="flex justify-between">
                <h1 className="my-5 pl-2">ALL POST</h1>
              </div>
              <div className="flex flex-wrap h-[800px] overflow-auto gap-5 m-2">
                {reportData?.post_list &&
                  reportData?.post_list?.map((item, index) => {
                    return (
                      <a
                        key={index}
                        href={item.post_link}
                        target="_blank"
                        className="border h-fit min-w-[280px] rounded-lg overflow-hidden  shadow-md" rel="noreferrer"
                      >
                        <div className="w-full flex items-center gap-1 p-2">
                          <div className="w-10 h-10 rounded-full shadow-md border overflow-hidden">
                            <img
                              className="w-full h-full object-cover"
                              src={item.profile_image || '/asset/onErrorImage.jpg'}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null || undefined; // prevents looping
                                currentTarget.src = "/asset/onErrorImage.jpg";
                              }}
                              alt="img"
                            />
                          </div>
                          <p>{item?.username}</p>
                        </div>
                        <div
                          className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                        >
                          <img
                            src={item.post_image}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null || undefined; // prevents looping
                              currentTarget.src = "/asset/onErrorImage.jpg";
                            }}
                            alt=""
                            className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                          />
                        </div>
                        <div className="w-full flex gap-5 p-2 py-3">
                          <div className="flex items-center gap-1 text-sm">
                            <FaEye /> {Intl.NumberFormat("en-US", {
                              notation: "compact",
                              maximumFractionDigits: 1,
                            }).format(item?.views)}
                          </div>
                          <div className="flex items-center gap-1 text-sm">
                            <IoMdHeart className="text-red-500 text-lg" /> {Intl.NumberFormat("en-US", {
                              notation: "compact",
                              maximumFractionDigits: 1,
                            }).format(item?.likes)}
                          </div>

                          {
                            item?.comment > 0 &&
                            <div className="flex items-center gap-1 text-sm">
                              <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(item?.comment)}
                            </div>
                          }


                          {
                            item?.follower > 0 &&
                            <div className="flex items-center gap-1 text-sm">
                              <RiUserFollowFill className="text-black text-base" /> {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(item?.follower)}
                            </div>
                          }

                        </div>
                      </a>
                    );
                  })}
              </div>
            </div> */}

            {/* <div className="col-span-1 gap-2 ">
              <div className="flex justify-between">
                <h1>TOP POSTS</h1>
              </div>
              <table className="min-w-full mt-2">
                <thead>
                  <tr className="border">
                    <th className="py-2 px-4 text-left font-medium text-white bg-[#6d5eac] border">Name</th>
                    <th className="py-2 px-4 text-left font-medium text-white bg-[#6d5eac] border">Followers</th>
                    <th className="py-2 px-4 text-left font-medium text-white bg-[#6d5eac] border">Likes</th>
                    <th className="py-2 px-4 text-left font-medium text-white bg-[#6d5eac] border">Comments</th>
                    <th className="py-2 px-4 text-left font-medium text-white bg-[#6d5eac] border">Views</th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {reportData?.top_content && (
                    <tr className="odd:bg-gray-50 border">
                      <td className="whitespace-nowrap py-2 px-4 border">
                        thatbohogirl
                      </td>
                      <td className="py-2 px-4 border">2.6m</td>
                      <td className="py-2 px-4 border">590</td>
                      <td className="py-2 px-4 border">2.8</td>
                      <td className="py-2 px-4 border">302</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div> */}
          </div>
        </div>


        {
          reportData?.hashtags_used?.length > 0 &&
          <div className="w-full flex gap-5 my-10">
            {
              reportData?.hashtags_used?.length > 0 &&
              <div className="w-[70%] flex items-center justify-center border p-5 rounded-lg shadow-lg bg-white">
                {/* <WordCloud1 hashtags={reportData?.hashtags_used} /> */}
                <HorizontalWordCloud hashtags={reportData?.hashtags_used} />
              </div>
            }

            {
              reportData?.hashtags_used?.length > 0 &&
              <div className="w-[30%] border p-5 rounded-lg shadow-lg bg-white">
                <h1 className="text-black font-semibold">Trending Topics</h1>

                <div className="w-full mt-5 p-2 max-h-[400px] rounded-md overflow-auto flex gap-4 flex-wrap">
                  {
                    reportData?.hashtags_used?.map((data, index) => {
                      return <Badge
                        key={index}
                        color="blue"
                        size="sm"
                        className="shadow-md rounded-lg"
                        style={{ color: "#6d5eac", fontWeight: "semibold" }}
                      >
                        #{data}
                      </Badge>
                    })
                  }
                </div>
              </div>
            }

          </div>
        }


      </div>
    </>
  );
};

export default ContentInsights;
