import { useEffect, useRef, useState } from "react";

const useDropdownPosition = () => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false); // Close the dropdown when clicking outside
    }
  };

  const adjustDropdownPosition = () => {
    if (dropdownRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdown = dropdownRef.current;

      const viewportWidth = window.innerWidth;
      const dropdownWidth = dropdown.offsetWidth;

      // Adjust horizontally
      if (buttonRect.left + dropdownWidth > viewportWidth) {
        dropdown.style.right = "0";
        dropdown.style.left = "auto";
      } else {
        dropdown.style.left = "0";
        dropdown.style.right = "auto";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isDropdownOpen) {
      adjustDropdownPosition();
    }
  }, [isDropdownOpen]);

  return { dropdownRef, buttonRef, isDropdownOpen, setIsDropdownOpen };
};

export default useDropdownPosition;