import { SET_ETHNICITY_FILTER } from "../ActionTypes"

const initialState = []

const EthnicityReducer = (state = initialState, action) => {
    switch (action.type){
        case SET_ETHNICITY_FILTER:
            const previousValue = state.value;
            console.log('state in Ethnicity', previousValue)
            return action.payload;
        default:
            return state;
    }
}

export default EthnicityReducer