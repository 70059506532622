import React, { useEffect, useRef, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setReelPlaysFilter } from "../../redux/filters/actions";
import InfoTooltip from "./InfoTooltip";
import InfoIconTooltip from "./InfoIconTooltip";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const ReelPlays = ({ hoverStates, handleMouseOver, selectedPlatform }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const selected_reelPlays = useSelector((state) => state.reelPlays);
  const [rangeValues, setRangeValues] = useState(selected_reelPlays || [null, null]); // Initialize with null instead of empty strings

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);
  const [customFromValue, setCustomFromValue] = useState("");
  const [customToValue, setCustomToValue] = useState("");

  const convertToNumeric = (value) => {
    // Handle custom values or any other logic based on your requirements
    if (value === "1k") return 1000;
    if (value === "5k") return 5000;
    if (value === "10k") return 10000;
    if (value === "25k") return 25000;
    if (value === "50k") return 50000;
    if (value === "100k") return 100000;
    if (value === "250k") return 250000;
    if (value === "500k") return 500000;
    if (value === ">1m") return 1000000;
    // Add more conversions as needed
    return parseInt(value);
  };

  const handleFromChange = (value) => {
    if (value === "Clear") {
      setRangeValues([null, rangeValues[1]]);
    } else {
      setRangeValues([convertToNumeric(value), rangeValues[1]]);
    }
    setFromDropdownOpen(false);
  };

  const handleToChange = (value) => {
    if (value === "Clear") {
      setRangeValues([rangeValues[0], null]);
    } else {
      setRangeValues([rangeValues[0], convertToNumeric(value)]);
    }
    setToDropdownOpen(false);
  };

  const handleCustomFromChange = () => {
    if (customFromValue !== "") {
      setRangeValues([customFromValue, rangeValues[1]]);
    }
    setFromDropdownOpen(false);
    setCustomFromValue("");
  };

  const handleCustomToChange = () => {
    if (customToValue !== "") {
      setRangeValues([rangeValues[0], customToValue]);
    }
    setToDropdownOpen(false);
    setCustomToValue("");
  };

  const fromOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    ">1m",
  ];
  const toOptions = [
    "Clear",
    "1k",
    "5k",
    "10k",
    "25k",
    "50k",
    "100k",
    "250k",
    "500k",
    "<1m",
  ];

  useEffect(() => {
    // Filter out null values if both "From" and "To" are null
    const sanitizedValues = rangeValues?.filter(
      (value) => value !== null && value !== undefined && value !== ""
    );

    dispatch(setReelPlaysFilter(sanitizedValues));

    if (sanitizedValues.length > 0) {
      setSearchParam((searchParams) => {
        searchParams.set("reel_plays", sanitizedValues.join(","));
        return searchParams;
      });
    }else{
      setSearchParam((searchParams) => {
        searchParams.delete("reel_plays");
        return searchParams;
      });
    }
  }, [dispatch, rangeValues]);

  return (
    <>
      <div ref={buttonRef} className="reel_plays flex justify-start">
        <div onClick={() => {setToDropdownOpen(false); setFromDropdownOpen(false)}} className="relative w-full">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="filterTextColor flex items-center bg-white "
          >
            <span className={`flex items-center ${isDropdownOpen ? 'font-semibold' : ''}`}>
              Reel Plays
              <span className={`flex items-center`}>
                {isDropdownOpen ? (
                  <RxCross2 className="ml-2 text-lg" />
                ) : (
                  <IoChevronDownSharp className="ml-2 text-lg" />
                )}
              </span>
            </span>
          </button>
          <ul
            ref={dropdownRef}
            className={`absolute w-[250px] z-50 p-5 bg-white rounded-lg shadow-lg ${
              isDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> 
                Influencer
                <InfoIconTooltip
                  infoContent={`Identify influencers by average number of plays in their recent reels.`}
                />
              </div>
              <div className="flex gap-2 w-full">
                <div className="relative">
                  <input
                    type="text"
                    className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                    placeholder="From"
                    value={rangeValues[0] !== null ? rangeValues[0] : ""}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFromDropdownOpen(!fromDropdownOpen)
                      setToDropdownOpen(false)
                    }}
                    readOnly
                  />
                  {fromDropdownOpen && (
                    <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                      {fromOptions.map((option) => (
                        <div
                          key={option}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleFromChange(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {rangeValues[0] !== 1000000 && (
                  <div className="relative">
                    <input
                      type="text"
                      className="w-full py-3 rounded-md border-[0.6px] border-[#D9D9D9]"
                      placeholder="To"
                      value={rangeValues[1] !== null ? rangeValues[1] : ""}
                      onClick={(e) => {
                        e.stopPropagation();
                        setToDropdownOpen(!toDropdownOpen);
                        setFromDropdownOpen(false)
                      }}
                      readOnly
                    />
                    {toDropdownOpen && (
                      <div className="absolute top-full left-0 w-full h-[200px] overflow-auto mt-1 bg-white rounded-md border-[0.6px] border-[#D9D9D9] z-10">
                        {toOptions.map((option) => (
                          <div
                            key={option}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleToChange(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ReelPlays;
