import React from 'react'

const Gallery = ({ images }) => {
    return (
        <div className='grid grid-cols-3 gap-2'>
            {images && images.length > 0
            ? <>
            {images && images.map(image => {
                return <div key={image.id} className='border border-gray-200 shadow-md rounded-md h-[200px]'>
                    <a href={images.url} target="_blank" rel="noopener noreferrer" style={{display:'block',height:'90%'}}>
                        <div className='w-[100%] h-[100%]'>
                        <img src={image.post_image} alt="" style={{width:'100%',height:'100%'}} />
                        </div>
                    </a>
                    <div className='flex justify-between text-[9px] p-1'>
                        <div>
                            {image.like}
                        </div>
                        <div>
                            {image.comment}
                        </div>
                        <div>
                            {image.eng}
                        </div>
                    </div>
                </div>
            })}
            </>
            : <p>No Data Found</p>

            }

            








        </div>
    )
}

export default Gallery