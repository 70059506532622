import React from 'react'
import { Select } from "antd";
import "antd"

const InputSearchBar = ({placeholder}) => {
    return (
        <>
            <Select
                mode="multiple"
                bordered={false}
                className="w-full rounded-md py-1 border-[0.6px] border-[#D9D9D9] text-[#3D3D3D]"
                placeholder={placeholder}
            >
                <Select.Option value="jack">Jack</Select.Option>
                <Select.Option value="lucy">Lucy</Select.Option>
                <Select.Option value="Yiminghe">yiminghe</Select.Option>
            </Select>
        </>
    )
}

export default InputSearchBar
