import React, { useRef, useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoChevronDownSharp } from "react-icons/io5";
import { MdCampaign, MdPeopleAlt } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setAudienceInterestFilter, setCreatorInterestFilter } from "../../redux/filters/actions";
import MultiSelectDropdown from "./MultiSelectDropdown";
import InfoIconTooltip from "./InfoIconTooltip";
import axiosInstance from "../../interseptor";
import { useLocation, useSearchParams } from "react-router-dom";
import useDropdownPosition from "./useDropdownPosition";

const percentageArr = ["1", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];

const CreatorInterest = ({ selectedPlatform }) => {
  const dispatch = useDispatch();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const creatorInterestUrl = searchParams.get("creatorInterest");
  const audienceInterestUrl = searchParams.get("audienceInterest");

  const creatorInterestArray = creatorInterestUrl ? creatorInterestUrl.split(",") : [];
  const audienceInterestArray = audienceInterestUrl ? audienceInterestUrl.split(",") : [];

  const {dropdownRef, buttonRef, setIsDropdownOpen, isDropdownOpen} = useDropdownPosition();

  const [creatorInterest, setCreatorInterest] = useState([]);
  const [audienceInterest, setAudienceInterest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  const fetchCreatorInterestData = async () => {
    setCreatorInterest([]);
    setAudienceInterest([]);
    try {
      setLoading(true);
      setError(null);

      const formData = new FormData();
      formData.append("filter", "interests");
      formData.append("logintype", "getfilters");
      formData.append("device_id", "search");
      formData.append("platform", selectedPlatform);

      const response = await axiosInstance.post("livesearch/getfilters", formData);
      const result = response.data;

      if (result.status === 1) {
        setCreatorInterest(result.response.data.interests);
        setAudienceInterest(result.response.data.interests);
      } else {
        setError(result.msg);
        console.error("Error:", result.msg);
      }
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchCreatorInterest = () => {
    fetchCreatorInterestData();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleInterestChange = (selectedList) => {
    const selectedValues = selectedList.map((item) => item.name);
    dispatch(setCreatorInterestFilter(selectedValues));

    setSearchParam((searchParams) => {
      if (selectedValues.length > 0) {
        searchParams.set("creatorInterest", selectedValues.join(","));
      } else {
        searchParams.delete("creatorInterest");
      }
      return searchParams;
    });
  };

  const handleAudienceInterestChange = (selectedList) => {
    const updatedValues = selectedList.map((item) => `${item.name}-k-${selectedOptions[item.name] || "5"}`);
    dispatch(setAudienceInterestFilter(updatedValues));

    setSearchParam((searchParams) => {
      if (updatedValues.length > 0) {
        searchParams.set("audienceInterest", updatedValues.join(","));
      } else {
        searchParams.delete("audienceInterest");
      }
      return searchParams;
    });

    setIsDropdownOpen(false);
  };

  const handlePercentageChange = (name, value) => {
    setSelectedOptions((prev) => {
      const updatedOptions = {
        ...prev,
        [name]: value,
      };

      const updatedValues = audienceInterestArray.map((item) => {
        const [interestName] = item.split('-k-');
        return `${interestName}-k-${updatedOptions[interestName] || "5"}`;
      });

      dispatch(setAudienceInterestFilter(updatedValues));
      setSearchParam((searchParams) => {
        if (updatedValues.length > 0) {
          searchParams.set("audienceInterest", updatedValues.join(","));
        } else {
          searchParams.delete("audienceInterest");
        }
        return searchParams;
      });

      return updatedOptions;
    });

    setIsDropdownOpen(false);
  };

  return (
    <div ref={buttonRef} className="CreatorInterest flex justify-start">
      <div className="relative w-full">
        <button
          onClick={handleSearchCreatorInterest}
          className={`flex items-center bg-white ${isDropdownOpen ? "font-semibold" : ""}`}
        >
          <span className="filterTextColor flex items-center">
            Interests
            {isDropdownOpen ? (
              <RxCross2 className="ml-2 text-lg" />
            ) : (
              <IoChevronDownSharp className="ml-2 text-lg" />
            )}
          </span>
        </button>
        <ul ref={dropdownRef} className={`absolute w-fit p-5 z-50 bg-white rounded-lg shadow-lg ${isDropdownOpen ? "block" : "hidden"}`}>
          <div className="flex gap-5">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdPeopleAlt className="text-2xl" /> Audience
                <InfoIconTooltip
                  infoContent={
                    "Identify influencers by their interests. We determine interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests."
                  }
                />
              </div>
              <MultiSelectDropdown
                options={audienceInterest}
                onSelect={handleAudienceInterestChange}
                onRemove={handleAudienceInterestChange}
                placeholder={"Select Interest"}
                selectedValues={audienceInterestArray.map((value) => ({
                  name: value.split('-k-')[0], // Extract interest name only
                }))}
                loading={loading}
              />
              {audienceInterestArray.map((item, index) => {
                const [interestName, percentage] = item.split('-k-');
                return (
                  <div key={index} className="flex items-center justify-between">
                    <span>{interestName}</span>
                    <select
                      value={selectedOptions[interestName] || percentage || "5"}
                      onChange={(e) => handlePercentageChange(interestName, e.target.value)}
                      className="cursor-pointer hover:bg-gray-200 rounded-lg shadow-md"
                      style={{ border: "0.6px solid #D9D9D9", maxHeight: "400px", overflowY: "auto" }}
                    >
                      {percentageArr.map((option) => (
                        <option key={option} value={option}>
                          {`>${option}%`}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="flex items-center gap-2">
                <MdCampaign className="text-2xl" /> Influencer
                <InfoIconTooltip
                  infoContent={
                    "Identify influencers by their interests. We determine interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests."
                  }
                />
              </div>
              <MultiSelectDropdown
                options={creatorInterest}
                onSelect={handleInterestChange}
                onRemove={handleInterestChange}
                placeholder={"Select Interest"}
                selectedValues={creatorInterestArray.map((value) => ({
                  name: value,
                }))}
                loading={loading}
              />
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CreatorInterest;