import { Button, Modal, Label, Textarea } from 'flowbite-react';
import React, { useState } from 'react';
import axiosInstance from '../../interseptor';
import { toast } from 'react-toastify';

const UPDATE_APPROVE_URL = "/livesearch/update_approve"

const ChangeStatusModal = ({ isStatus, setIsStatus, selectedInfluencer }) => {
  const [selectedStatus, setSelectedStatus] = useState(null); // Track if user selects 'Approve' or 'Reject'
  const [rejectReason, setRejectReason] = useState(''); // Store the reject reason
  console.log(selectedInfluencer, 'sdselectedInfluencer')
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    if (status === 'Approve') {
      setRejectReason(''); // Clear the reject reason if 'Approve' is selected
    }
  };

  const handleSubmit = async (planId) => {
    if (selectedStatus === 'Reject' && !rejectReason) {
      // Ensure that rejectReason is provided when rejecting
    //   alert('Please provide a reason for rejection');
      toast.warn('Please provide a reason for rejection')
      return;
    } else {
      try {
        const formData = new FormData();
        formData.append('logintype', 'update_approve');
        formData.append('device_id', 'search');
        formData.append('plan_id', planId); // Replace with actual plan ID
        formData.append('reject_resion', selectedStatus === 'Reject' ? rejectReason : '');
        formData.append('campaign_locked', selectedStatus === 'Approve' ? '1' : '2');
  
        const response = await axiosInstance.post(UPDATE_APPROVE_URL, formData, {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
            Authorization: 'Bearer p7ACXJ8Yd1UrzGAJoGjh40Ewrz9acBDhYciKCvRHdKXU334tTS',
            'Content-Type': 'multipart/form-data', // Important for FormData
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
            Cookie: 'PHPSESSID=phvn51mmgfc65n24kpkp1ops5i', // Use cookie if needed
          },
        });
  
        if(response?.data?.status == 1){
            toast.success(response?.data?.msg)
            window.location.reload()
        }else{
            toast.warn(response?.data?.msg)
        }
        // Handle the response
        console.log(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  
    // Close modal
    setIsStatus(false);
  };

  return (
    <Modal
      show={isStatus}
      onClose={() => setIsStatus(false)}
    >
      <Modal.Header>Set Status</Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-2">
          <div className="flex gap-4">
            <Button
              color={selectedStatus === 'Approve' ? 'success' : 'light'}
              onClick={() => handleStatusChange('Approve')}
            >
              Approve
            </Button>
            <Button
              color={selectedStatus === 'Reject' ? 'failure' : 'light'}
              onClick={() => handleStatusChange('Reject')}
            >
              Reject
            </Button>
          </div>

          {/* Show the reason input if "Reject" is selected */}
          {selectedStatus === 'Reject' && (
            <div className="mt-4">
              <Label htmlFor="rejectReason" value="Reason for Rejection" />
              <Textarea
                id="rejectReason"
                placeholder="Please provide a reason for rejection"
                required={true}
                value={rejectReason}
                onChange={(e) => setRejectReason(e.target.value)}
                rows={4}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleSubmit(selectedInfluencer?.plan_id)}
          disabled={!selectedStatus} // Disable if no status is selected
        >
          Set Status
        </Button>
        <Button
          color="gray"
          onClick={() => setIsStatus(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeStatusModal;