import { SET_HAS_CONTACT_DETAILS_FILTER } from "../ActionTypes";

const initialState = false;

const ContactDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HAS_CONTACT_DETAILS_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default ContactDetailReducer