import { SET_CREDIBLE_ACCOUNT_FILTER } from "../ActionTypes";

const initialState = false;

const CredibleAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREDIBLE_ACCOUNT_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default CredibleAccountReducer