import React from 'react'
import { Card, Avatar, Button, Table } from 'flowbite-react'

const MyTable = () => {
  return (
    <Table striped={true}>
                <Table.Head className='bg-gray-300 text-gray-400'>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                    <Table.HeadCell>
                    <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <div className="h-3 my-1 w-[120px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[60px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <div className="h-3 my-1 w-[110px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[50px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <div className="h-3 my-1 w-[120px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[60px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[80px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        
                    </Table.Row>

                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <div className="h-3 my-1 w-[110px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[50px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        <Table.Cell>
                        <div className="h-3 my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
                        </Table.Cell>
                        
                    </Table.Row>

                </Table.Body>
            </Table>
  )
}

export default MyTable