import React from "react";
import { RxCross2 } from "react-icons/rx";

const SelectedFiltersValuePercentage = ({ filterState, filterName, removeSelectedFilter, id, selectedPlatform, }) => {
  if (filterName === "Audience Location") {
    console.log(filterState, id, "12345");
  }
  const handleRemoveSelectedFilter = (categoryName, filterName, idToRemove) => {
    removeSelectedFilter(categoryName, filterName, idToRemove);
  };

  if (Array.isArray(filterState)) {
    return (
      <>
        {filterState && filterState?.length > 0 && (
          <div className="flex flex-wrap items-center gap-1  mr-3">
            <p className="text-md text-[#828282]">{filterName} - </p>
            {filterState?.map((category, index) => (
              <p
                key={index}
                className="bg-white text-black rounded-[41px] hover:bg-[#FAFAFA]  flex items-center gap-1 border-[0.6px] border-[#D9D9D9] px-auto py-[4px] px-[8px] text-md"
              >
                {category.name === "Any" ? `${category.name}` : `${category.name} ${category.percentage}%`}

                <RxCross2
                  onClick={() =>
                    handleRemoveSelectedFilter(
                      category,
                      filterName,
                      id?.[index]
                    )
                  }
                  className="cursor-pointer hover:scale-110 text-xs hover:text-red-600"
                />
              </p>
            ))}
          </div>
        )}
      </>
    );
  }
};

export default SelectedFiltersValuePercentage;
