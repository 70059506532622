import React from 'react'

const CampaignListSkeleton = () => {
  return (
    <>
    <tr>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-200 text-gray-200 animate-pulse">sumedha</td>
    </tr>
    <tr>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">testing new</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Viral pitch</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Social inter</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">BGMI-87678</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">1</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">PO</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">3,57,777</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Mon 09, 15:29</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">Pending</td>
      <td className="px-4 py-2 border bg-gray-100 text-gray-100 animate-pulse">sumedha</td>
    </tr>
    </>
  )
}

export default CampaignListSkeleton
