import React, { useEffect, useState } from "react";
import axiosInstance from "../../interseptor";
import CampaignListSkeleton from "./CampaignListSkeleton";
import ChangeStatusModal from "./ChangeStatusModal";
import { ToastContainer } from "react-toastify";

const CAMPAIGN_LIST_URL = "/livesearch/approvel_plan";

const CampaignList = () => {
  const [campaignList, setCampaignList] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(false);
  const [visibleCampaigns, setVisibleCampaigns] = useState(10);
  const [isStatus, setIsStatus] = useState(false)
  const [selectedInfluencer, setSelectedInfluencer] = useState({})

  const fetchCampaignList = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("logintype", "approvel_plan");
    formData.append("device_id", "seacrh");

    try {
      const response = await axiosInstance.post(CAMPAIGN_LIST_URL, formData);
      console.log(response?.data?.data, "response data");

      if (response?.data?.data) {
        // Convert the object into an array
        const campaignsArray = Object.values(response?.data?.data);
        setCampaignList(campaignsArray);
      } else {
        setCampaignList([]); // Ensure it defaults to an empty array if no data
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching the campaign list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaignList();
  }, []);

  const handleLoadMore = () => {
    setVisibleCampaigns((prevVisible) => prevVisible + 10); // Show 10 more campaigns when clicked
  };

  return (
    <>
    <ToastContainer autoClose={1500}/>
    <div className="w-full flex justify-center">
        <ChangeStatusModal
            isStatus={isStatus}
            setIsStatus={setIsStatus}
            selectedInfluencer={selectedInfluencer}        
        />
      <div className="w-[95%] mt-5">
        <h1 className="text-lg font-semibold text-gray-700">Campaign List</h1>

        <div className="mt-5">
          <table className="min-w-full bg-white overflow-x-auto">
            <thead>
              <tr className="bg-[#EEF5FF] text-sm">
                <th className="px-4 py-2 border font-normal">S.No.</th>
                <th className="px-4 py-2 border font-normal">Campaign</th>
                <th className="px-4 py-2 border font-normal">Payment By</th>
                <th className="px-4 py-2 border font-normal">Brand</th>
                <th className="px-4 py-2 border font-normal">Ro No.</th>
                <th className="px-4 py-2 border font-normal">Ro File</th>
                <th className="px-4 py-2 border font-normal">Invoice Type</th>
                <th className="px-4 py-2 border font-normal">Brand Charge</th>
                <th className="px-4 py-2 border font-normal">Invoice Amount</th>
                <th className="px-4 py-2 border font-normal">Expense</th>
                <th className="px-4 py-2 border font-normal">Margin</th>
                <th className="px-4 py-2 border font-normal">Created On</th>
                <th className="px-4 py-2 border font-normal">Status</th>
                <th className="px-4 py-2 border font-normal">Account Manager</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {loading ? (
                <CampaignListSkeleton />
              ) : campaignList.length > 0 ? (
                campaignList.slice(0, visibleCampaigns).map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border text-center">{index + 1}</td>
                    <td className="px-4 py-2 border">{item?.campaign_name}</td>
                    <td className="px-4 py-2 border">
                      {item?.companyname === "troupedigital"
                        ? "Troupe Digital"
                        : "Viral Pitch"}
                    </td>
                    <td className="px-4 py-2 border">{item?.brand_name}</td>
                    <td className="px-4 py-2 border">{item?.invoice_no || "N/A"}</td>
                    <td className="px-4 py-2 border">
                      {item?.ro_file ? (
                        <a href={item?.ro_file_new} target="_blank" rel="noopener noreferrer">
                          View File
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="px-4 py-2 border">{item?.invoice_type == '1' ? "RO" : item?.invoice_type == '2' ? "IO" : item?.invoice_type == '3' ? "PI" : item?.invoice_type == '4' ? "PO" : item?.invoice_type == '5' ? "Email Confirmation" : "N/A"}</td>
                    <td className="px-4 py-2 border">{item?.brand_charge}</td>
                    <td className="px-4 py-2 border">{item?.invoice_value}</td>
                    <td className="px-4 py-2 border">{item?.invoice_amount_with_margin || "N/A"}</td>
                    <td className="px-4 py-2 border">{item?.total_margin}</td>
                    <td className="px-4 py-2 border">
                      {new Date(item?.campaign_createdon * 1000).toLocaleString()}
                    </td>
                    <td className="px-4 py-2 border">
                      <span
                        title="Click here to change status"
                        onClick={() => {setSelectedInfluencer(item); setIsStatus(true)}}
                        className={`rounded-full px-3 py-0.5 capitalize cursor-pointer ${
                          item?.campaign_approved == 1
                            ? "bg-green-400 text-white" : item?.campaign_approved == 2 ? "bg-red-500 text-white"
                            : "bg-yellow-300 text-white"
                        }`}
                      >
                        {item?.campaign_approved == 2 ? "Reject" : item?.campaign_approved == 1 ? "Approved" : "Pending"}
                      </span>
                    </td>
                    <td className="px-4 py-2 border uppercase">{item?.created_by || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="px-4 py-2 text-center">
                    No campaigns available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center">
          {visibleCampaigns < campaignList.length && (
            <button
              onClick={handleLoadMore}
              className="border px-4 py-2 rounded-lg bg-black text-white mb-10 mt-5 mx-auto"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default CampaignList;