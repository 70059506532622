import { SET_KEYWORDS_FILTER } from "../ActionTypes";

const initialState = [];

const KeywordsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_KEYWORDS_FILTER:
            const previousValue = state.value;
            console.log('state in KEYWORD', previousValue)
            return action.payload;
        default:
            return state;
    }
}

export default KeywordsReducer
