import React from 'react'

const MyButton = () => {
  return (
    <div className='flex items-center '>
      <div className="h-[15px] my-1 w-[15px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>
      <div className="h-[15px] my-1 w-[70px] mx-1 animate-pulse bg-gray-200 shadow-lg "></div>

    </div>

  )
}

export default MyButton