import React, { useEffect, useState } from "react";
// import './profilepopup.css'
import {
  FaFacebook,
  FaInstagram,
  FaMap,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { AiOutlineClose, AiOutlineFileSearch } from "react-icons/ai";
import { HiLocationMarker, HiShoppingCart } from "react-icons/hi";
import { BsInstagram, BsTwitter, BsYoutube, BsFacebook } from "react-icons/bs";

import { Badge, Button, Tabs } from "flowbite-react";
import axiosInstance from "../../interseptor";
// import AddToList from "./CreateNewList";
import Gallery from "./Gallery";
import Cost from "./Cost";
import CostTable from "./CostTable";
import TableRowSK from "../skeleton/tableRowSK";
import { TableRow } from "flowbite-react/lib/esm/components/Table/TableRow";
import TableRowSkeleton from "../skeleton/tableRow";
import constant from "../../constants/constant";
import { Link } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import { BsArrowUpCircle } from "react-icons/bs";
import { AiOutlineTag } from "react-icons/ai";
import {toast} from 'react-toastify'
import AddToList from "../../pages/ResultPage/AddtoList";
const PROFILE_PREVIEW_URL = "/livesearch/profilepreview";

const ProfilePopup = ({show,  closeprofilepopup, filterData, socialName,children }) => {
  // console.log("inf name", socialName);

  let label = "Instagram";
  if (filterData?.selectedPlatform?.label) {
    label = filterData?.selectedPlatform?.label;
  }
  // const {selectedPlatform:{label}} = filterData || 'instagram'
  // console.log("filter in popup", label);
  // const [show, setShow] = useState(false)
  
  const [showCost, setShowCost] = useState(false);
  const [costLoading, setCostLoading] = useState(true);
  const [profilePreview, setProfilePreview] = useState();
  const [InfName, setInfName] = useState('');
  const [isPlanmodalopen,setIsPlanmodalopen] = useState(false)

  const categories = constant.catagories;
  const categoryMapping = {};

  categories.forEach((category) => {
    categoryMapping[category.id] = category.name;
  });

  function PlatformIcon(props) {
    const { platform } = props;
    let Icon;

    switch (platform) {
      case "instagram":
        Icon = BsInstagram;
        return <Icon color="#e1306c" />;
      case "twitter":
        Icon = BsTwitter;
        return <Icon color="#1da1f2" />;
      case "youtube":
        Icon = BsYoutube;
        return <Icon color="#ff0000" />;
      case "facebook":
        Icon = BsFacebook;
        return <Icon color="#1da1f2" />;
      default:
        return null;
    }
  }

  useEffect(() => {
      getProfilePreview();
  }, [show]);

  const getProfilePreview = async () => {
    try {
      let data = new FormData();
      data.append("logintype", "profilepreview");
      data.append("device_id", "seacrh");
      data.append("handle", socialName);
      data.append("platform", label);

      let response = await axiosInstance.post(PROFILE_PREVIEW_URL, data);
      // console.log("profile preview", response);

      if (response && response?.status === 200) {
        if (response && response?.data && response?.data?.status === 201) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 2) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        } else if (response && response?.data && response?.data?.status === 3) {
          console.log({ message: response?.data?.msg, code: 401 });
          return false;
        }

        if(!response?.data?.list?.profile && show==true){
          closeprofilepopup()
          toast.warning('no profile preview found', { pauseOnHover: false });      
        }
        if(response?.data?.list?.profile && show==true){
          setProfilePreview(response.data.list.profile);
        }
        return response?.data.list;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openPlanModal = (social_name) => {
    setInfName(social_name)
    setIsPlanmodalopen(true)
}

const closePlanModal = () => {
  setIsPlanmodalopen(false)

}

const uniq = (a)=> {
  var seen = {};
  return a.filter(function(item) {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

  setTimeout(() => {
    setCostLoading(false);
  }, 2000);

  return (
    <>
      {show && profilePreview && (
        <div className="relative">
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-75 "></div>
          <div
            className="absolute top-0 right-0 overflow-y-scroll w-[550px] z-40 flex flex-col justify-start gap-4 bg-white p-4"
            style={{ height: "100vh" }}
          >
            <div className="flex justify-between">
              <div className="flex gap-3">
                <div className="flex flex-col gap-3">
                  <img
                    src={profilePreview.profile_image}
                    alt=""
                    className="w-16 rounded-full"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <p>{profilePreview.name}</p>
                    <div className="flex gap-1">
                      {profilePreview.other_platform.map((obj, index) => (
                        <div key={index}>
                          {Object.entries(obj).map(([key, value]) => (
                            <a href={value} target="_blank">
                              <PlatformIcon platform={key} />
                            </a>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-[11px] -mt-2 font-semibold text-gray-500">
                    @{profilePreview.handle}
                  </div>
                  <div className="flex justify-start gap-3 items-center">
                    {profilePreview?.age > 0 && (
                      <div className="text-[9px]">
                        Age : <span className="font-semibold">34</span>
                      </div>
                    )}
                    {profilePreview?.gender && (
                      <div className="text-[9px]">
                        Gender :{" "}
                        <span className="font-semibold">
                          {profilePreview.gender}
                        </span>
                      </div>
                    )}

                    {profilePreview?.location && (
                      <div className="flex items-center">
                        <HiLocationMarker />
                        <p className="text-[9px] font-semibold">
                          {profilePreview?.location}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex gap-2 mt-1">
                    {profilePreview.categories && uniq(profilePreview.categories).slice(0, 5).map((category, index) => (
                      <Badge key={index} color="info" className="capitalize">
                        {categoryMapping[category]}
                      </Badge>
                    ))}
                  </div>
                </div>
              </div>
              <AiOutlineClose
                onClick={closeprofilepopup}
                className="cursor-pointer"
              />
            </div>

            <div className="flex gap-3 mt-2">
              {/* <Button onClick={() => openPlanModal(filteredinfluencer.social_name)}>Add to list</Button> */}
              {/* <AddToList plans={plans}  getPlans={getPlans} lists={lists} curListId={curListId} planId={planId} infName={infName} closePlanModal={closePlanModal} isPlanmodalopen={isPlanmodalopen} getList={getList} handleCurList={handleCurList} openNewPlanInput={openNewPlanInput} setOpenNewPlanInput={setOpenNewPlanInput} /> */}
              {/* <div>hi</div> */}
              {/* {console.log('children',children)}
              {children} */}

              <Link to={`/profile/?id=${profilePreview.handle}`}>
                <Button size="xs">
                  <AiOutlineFileSearch className="mr-1 h-4 w-4" />
                  <p>Analyse Profile</p>
                </Button>
              </Link>

              <Button
                size="xs"
                onClick={() => {
                  setShowCost(!showCost);
                }}
              >
                <AiOutlineFileSearch className="mr-1 h-4 w-4" />
                <p>View Cost</p>
              </Button>

              {/* <Cost /> */}
            </div>

            {showCost && !costLoading && (
              <div className="h-auto">
                <CostTable />
              </div>
            )}

            {
              showCost && costLoading && <TableRowSkeleton total={4} />
              // <p>loading</p>
            }

            <div className="flex justify-around py-2 mt-2 bg-blue-100">
              {profilePreview?.followers > 0 && (
                <div>
                  <p className="text-sm text-indigo-800">
                  {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                        profilePreview.followers
                    )}
                  </p>
                  <p className="text-xs text-gray-800">Followers</p>
                </div>
              )}

              {!profilePreview?.average_likes == 0 && (
                <div>
                  <p className="text-sm text-indigo-800">
                    {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                    Math.floor(profilePreview.average_likes)
                    )}
                  </p>
                  <p className="text-xs text-gray-800">Avg Likes</p>
                </div>
              )}

              {!profilePreview?.average_comments == 0 && (
                <div>
                  <p className="text-sm text-indigo-800">
                  {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                    Math.floor(profilePreview.average_comments))}
                  </p>
                  <p className="text-xs text-gray-800">Avg Comments</p>
                </div>
              )}

              {!profilePreview?.average_views == 0 && (
                <div>
                  <p className="text-sm text-indigo-800">
                  {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(Math.floor(profilePreview.average_views))}
                  </p>
                  <p className="text-xs text-gray-800">Avg Views</p>
                </div>
              )}
            </div>

            <div>
              <Tabs.Group
                aria-label="Tabs with underline"
                style="underline"
                className="mx-auto"
              >
                <Tabs.Item
                  title=" Recent Posts"
                  icon={BiTimeFive}
                  active={true}
                  className="p-1"
                >
                  <div className="w-full">
                    <Gallery images={profilePreview.recent_post} />
                  </div>
                </Tabs.Item>
                <Tabs.Item title="Top Posts" icon={BsArrowUpCircle}>
                  <Gallery images={profilePreview.top_post} />
                </Tabs.Item>
                <Tabs.Item title="Brand Posts" icon={AiOutlineTag} style="xs">
                  <Gallery images={profilePreview.brand_post} />
                </Tabs.Item>
              </Tabs.Group>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePopup;
