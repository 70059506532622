import { SET_TOPICS_FILTER } from "../ActionTypes";

const initialState = [];

const TopicsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOPICS_FILTER:
            const previousValue = state.value;
            console.log('state in TOPICS', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default TopicsReducer