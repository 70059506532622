module.exports = {
    searchBoxStyle: {
        "boxShadow": '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)', 
        'border': '1px solid #E5E7EB'
    },
    searchInputStyle: {
        "boxShadow": "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
        "border": "1px solid #E5E7EB"
    },
    platforms: [
        {
            id: 1,
            label: 'Instagram',
            key: 'instagram',
            icon: 'BsInstagram',
        },
        {
            id: 2,
            label: 'Youtube',
            key: 'youtube',
            icon: 'BsYoutube',
        },
        {
            id: 3,
            label: 'TikTok',
            key: 'tiktok',
            icon: 'BsTiktok',
        },
        // {
        //     id: 4,
        //     label: 'Facebook',
        //     key: 'facebook',
        //     icon: 'BsFacebook',
        // }
    ],
    genders:[
        {
            id: 0,
            label: 'Select Gender',
            value:'',
            icon:'BsGenderMale',
            key:'none'
        },
        {
            id: 1,
            label: 'Male',
            value:'1',
            icon:'👨',
            key:'male'
        },
        {
            id: 2,
            label: 'Female',
            value:'2',
            icon:'👧',
            key:'female'
        },
        {
            id: 3,
            label: 'Transgender',
            value:'3',
            icon:'⚧️',
            key:'transgender'
        },
        {
            id: 4,
            label: 'Fanpage',
            value:'4',
            icon:'❤️',
            key:'FanPage'
        }
    ],
    catagories:[
            {
                "id": "62",
                "name": "Artists",
                "icon": "🎨"
            },
            {
                "id": "59",
                "name": "Celebrity",
                "icon": "👧🏻"
            },
            {
                "id": "54",
                "name": "Education",
                "icon": "🏫"
            },
            {
                "id": "53",
                "name": "Infotainment",
                "icon": "📝"
            },
            {
                "id": "49",
                "name": "Entrepreneur",
                "icon": "🤵"
            },
            {
                "id": "48",
                "name": "Skin Care",
                "icon": "🧑🏻"
            },
            {
                "id": "47",
                "name": "Hair Care",
                "icon": "💇🏻"
            },
            {
                "id": "39",
                "name": "Fitness",
                "icon": "👨"
            },
            {
                "id": "38",
                "name": "Nutritionist",
                "icon": "🍛"
            },
            {
                "id": "37",
                "name": "Sports",
                "icon": "🏅"
            },
            {
                "id": "35",
                "name": "Pets",
                "icon": "🐾"
            },
            {
                "id": "34",
                "name": "Couple",
                "icon": "💑"
            },
            {
                "id": "33",
                "name": "Music",
                "icon": "🎵"
            },
            {
                "id": "31",
                "name": "Automobile",
                "icon": "🚗"
            },
            {
                "id": "30",
                "name": "Prankster",
                "icon": "😉"
            },
            {
                "id": "29",
                "name": "Kids",
                "icon": "😛"
            },
            {
                "id": "28",
                "name": "Dad",
                "icon": "👴"
            },
            {
                "id": "27",
                "name": "Mom",
                "icon": "👩‍👧‍👧"
            },
            {
                "id": "26",
                "name": "Home Decor",
                "icon": "🏠"
            },
            {
                "id": "25",
                "name": "News & Politics",
                "icon": "📰"
            },
            {
                "id": "24",
                "name": "Finance",
                "icon": "💰"
            },
            {
                "id": "20",
                "name": "photography",
                "icon": "📸"
            },
            {
                "id": "19",
                "name": "gaming",
                "icon": "🎮"
            },
            {
                "id": "18",
                "name": "entertainment",
                "icon": "🧑‍🎤"
            },
            {
                "id": "17",
                "name": "Craft/DIY",
                "icon": "🖍️"
            },
            {
                "id": "16",
                "name": "family",
                "icon": "👨‍👩‍👧"
            },
            {
                "id": "15",
                "name": "Lifestyle",
                "icon": "🧳"
            },
            {
                "id": "12",
                "name": "technology",
                "icon": "👨‍💻"
            },
            {
                "id": "8",
                "name": "food",
                "icon": "🍲"
            },
            {
                "id": "7",
                "name": "Health, Sports & Fitness",
                "icon": "👨‍⚕️"
            },
            {
                "id": "6",
                "name": "Beauty & Makeup",
                "icon": "🦋"
            },
            {
                "id": "4",
                "name": "fashion",
                "icon": "✨"
            },
            {
                "id": "3",
                "name": "travel",
                "icon": "✈️"
            }
     
    ]
}