import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { setCredibleAccountFilter } from '../../redux/filters/actions';
import InfoIconTooltip from './InfoIconTooltip';
import { useLocation, useSearchParams } from 'react-router-dom';

const CredibleAccountFilter = ({ hoverStates, handleMouseOver }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [searchParam, setSearchParam] = useSearchParams()
    const searchParams = new URLSearchParams(location.search)

    const isCredibleAcUrl = searchParams.get("credibleAccount");
    const isCredibleAcArray = isCredibleAcUrl ? true : false;

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        
        dispatch(setCredibleAccountFilter(isChecked)); // Toggle the checkbox state
        if(isChecked){
            setSearchParam((searchParams) => {
                searchParams.set("credibleAccount", true)
                return searchParams;
            })
        }else{
            setSearchParam((searchParams) => {
                searchParams.delete("credibleAccount")
                return searchParams;
            })
        }
    };

    const handleButtonClick = () => {
        handleMouseOver('isCredibleAccount');
    };

    return (
        <>
            <div className="credibleAccount flex justify-start">
                <div className="relative w-full">
                    <button
                        onClick={handleButtonClick}
                        className="flex items-center w-full justify-between bg-white"
                    >
                        <span className={`text-[#3D3D3D] flex items-center`}>
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
                                checked={isCredibleAcArray}
                                onChange={handleCheckboxChange}
                            />
                            Only Credible Accounts
                        </span>
                            <InfoIconTooltip infoContent={'Refine search results with the only influencers that have high-level audience credibility.'} />
                    </button>
                </div>
            </div>
        </>
    )
}

export default CredibleAccountFilter
