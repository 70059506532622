import React, { useEffect, useState } from 'react'
import  { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack, IoMdWallet } from 'react-icons/io'
import axiosInstance from '../interseptor';
import { ToastContainer, toast } from 'react-toastify';
import ErrorMsgModal from './ErrorMsgModal';
const GET_CREDIT_HISTORY = "/livesearch/credit_history";

const WalletHistory = () => {
    const [creditHistory, setCreditHistory] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [availablePoints, setAvailablePoints] = useState()
    const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [errorMsgFromAPI, setErrorMsgFromAPI] = useState('')
    const navigate = useNavigate()
    const getCreditHistory = async () => {

        let data = new FormData();
        data.append("logintype", "credit_history");
        data.append("device_id", "seacrh");

        try {
            const response = await axiosInstance.post(GET_CREDIT_HISTORY, data);

            if (response?.data?.status === 0) {
                setShowErrorMsg(true)
                setErrorMsgFromAPI(response?.data?.msg)
                setIsLoading(false);
                return;
            }

            if (response?.data?.status_code == 200) {
                setCreditHistory(response?.data?.list)

                setAvailablePoints(response?.data?.avaliable_points)
                setIsLoading(false)
                if (response?.data?.status == 1) {
                } else {

                }
            } else if (response && response?.data && response?.data?.status == 0) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            } else if (response && response?.data && response?.data?.status == 3) {
                console.log({ message: response?.data?.msg, code: 401 });
                return false;
            }

            return response?.data.list;
        } catch (error) {
            console.log(error);
        }
    }

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }
    };

    const datefunc = (datestring) => {
        const createdOn = new Date(datestring);
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC", // Assuming your date string is in UTC
        };
        const formattedDate = createdOn.toLocaleString("en-US", options);
        return formattedDate;
    };

    useEffect(() => {
        getCreditHistory()
    }, [])

    return (
        <div className='w-full mt-2 px-8'>
            {
                showErrorMsg && <ErrorMsgModal errorMsgFromAPI={errorMsgFromAPI}/>
            }
            <div className='flex gap-1 items-center' onClick={()=>navigate('/home')}>
                <IoMdArrowRoundBack className='text-lg cursor-pointer'  />
                <p className='font-semibold'>Wallet</p>
            </div>

            <div className='h-[150px] w-full flex items-center rounded-lg bg-[#EEF5FF] mt-5'>
                <div className='ml-5 text-black'>
                    <IoMdWallet className='text-2xl text-black-100' />
                    <p className='text-2xl text-black'>Total Balance</p>
                    <div className='flex items-end gap-5'>
                        <p className='text-4xl font-bold text-black-100'>{availablePoints || 0}</p>
                        <p className='text-black text-sm'>Last Transaction: {creditHistory?.length > 0 && datefunc(creditHistory?.reverse()[0]?.date)}</p>
                    </div>
                </div>
            </div>

            <h1 className='my-5 font-bold'>Transaction History</h1>

            <table className="my-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="sticky top-0 bg-[#EEF5FF] text-black">
                    <tr className="h-12">
                        <th className="text-sm font-medium capitalize pl-2">S. No.</th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('date')}
                                className='flex items-center cursor-pointer'
                            >
                                Date
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('points')}
                                className='flex items-center cursor-pointer'
                            >
                                Points
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('transaction_type')}
                                className='flex items-center cursor-pointer'
                            >
                                Transaction Type
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('status')}
                                className='flex items-center cursor-pointer'
                            >
                                Status
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y">
                    {
                        creditHistory?.length !== 0 && (
                            isLoading ? <>
                                <tr className="h-14 m-10">
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                </tr>
                                <tr className="h-14 m-10">
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                </tr>
                                <tr className="h-14 m-10">
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                </tr>
                                <tr className="h-14 m-10">
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                    <td className="animate-pulse rounded-2xl bg-gray-100 text-black-100">xxxxxxxxxxxxx</td>
                                </tr>
                            </> : creditHistory &&
                            creditHistory?.sort((a, b) => {
                                if (sortColumn) {
                                    if (sortOrder === 'asc') {
                                        return a[sortColumn] > b[sortColumn] ? 1 : -1;
                                    } else {
                                        return a[sortColumn] < b[sortColumn] ? 1 : -1;
                                    }
                                }
                                return 0; // No sorting
                            }).map((data, index) => (
                                <tr className="h-14 border even:bg-gray-100" key={index}>
                                    <td className='pl-2'>{index + 1}</td>
                                    <td className=''>{datefunc(data?.date)}</td>
                                    <td>{data?.points}</td>
                                    <td className="">{data?.transaction_type}</td>
                                    <td className="h-14">{data?.status}</td>
                                </tr>
                            )))
                    }
                </tbody>
            </table>

        </div>
    )
}

export default WalletHistory
