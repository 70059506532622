import { Button, Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { MdHistory } from 'react-icons/md';
import LoadingSpinner from './ResultPage/LoadingSpinner';
import axiosInstance from '../interseptor';
import EditReportNameModal from './ReportPage/EditReportNameModal';

const GET_REPORT_LIST_URL = "/livesearch/reportlist";

const SearchedHistory = ({ sendReportId, reportType }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportList, setReportList] = useState([])
    const [isError, setIsError] = useState()

    const closeModal = () => {
        setOpenModal(false);
    };

    const datefunc = (datestring) => {
        const createdOn = new Date(datestring);
        const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC", // Assuming your date string is in UTC
        };
        const formattedDate = createdOn.toLocaleString("en-US", options);
        return formattedDate;
    };

    const getReportList = async () => {

        try {
            let data = new FormData();
            data.append("logintype", "reportlist");
            data.append("device_id", "seacrh");
            data.append("report_type", reportType);

            const response = await axiosInstance.post(GET_REPORT_LIST_URL, data);
            console.log({ response })
            if (response?.data?.status === 0) {
                setLoading(false)
                setIsError(response?.data?.msg)
                return
            }

            if (response && response?.status === 200) {
                if (response && response?.data && response?.data?.status === 201) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 2
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 3
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                }

                setReportList(response.data.list.report_list);
                setLoading(false)
                console.log("report response", response.data);
                setLoading(false);
                return response?.data.list;
            }
        } catch (error) { }
    };

    useEffect(() => {
        getReportList()
    }, [])
    const reportHandler = (reportid) => {
        console.log("hep", reportid)
        sendReportId(reportid)
        closeModal()
    }
    return (
        <>
        
            <div onClick={() => setOpenModal(true)} className="w-auto mr-2 rounded-md px-3  bg-white py-3 h-[46px] flex items-center gap-1 text-black border-[0.6px] border-[#D9D9D9]   text-sm whitespace-nowrap cursor-pointer">
                <MdHistory className="text-ms" />
                Previous Searched
            </div>
            <Modal show={openModal} onClose={closeModal}>
                <Modal.Header>Previous Searched History</Modal.Header>
                <Modal.Body className='bg-white py-0'>
                    <table className="min-w-[100%] w-[100%] relative container mx-auto overflow-x-auto">
                        <thead className='sticky top-0 z-30 text-sm'>
                            <tr className=''>
                                <th className="px-4 py-3 font-medium bg-[#6d5eac] text-left leading-4 text-white tracking-wider">
                                    Platform
                                </th>
                                <th className="px-4 py-3 font-medium bg-[#6d5eac] text-left leading-4 text-white tracking-wider">
                                    Report Name
                                </th>
                                <th className="px-4 py-3 font-medium bg-[#6d5eac] text-left leading-4 text-white tracking-wider">
                                    Total Users
                                </th>
                                <th className="px-4 py-3 font-medium bg-[#6d5eac] text-left leading-4 text-white tracking-wider">
                                    Searched On
                                </th>
                                <th className="px-4 py-3 font-medium bg-[#6d5eac] text-left leading-4 text-white tracking-wider">

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <div className='w-full border text-center'>{isError}</div>
                            {reportList?.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center py-10 text-gray-500">
                                        <h1 className='text-xl'>No data found</h1>
                                    </td>
                                </tr>
                            ) : (
                                reportList.map((item, index) => (
                                    <tr key={index} className='border even:bg-blue-50'>
                                        <td className="py-3 pl-2 whitespace-no-wrap">
                                            {item?.platform}
                                        </td>

                                        <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                            <div className='flex items-center gap-0.5'>
                                                {item?.report_name || <span>Unknown</span>}
                                                <EditReportNameModal uniqueId={item?.report_id} />
                                            </div>
                                        </td>

                                        <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                            {Intl.NumberFormat("en-US", {
                                                notation: "compact",
                                                maximumFractionDigits: 1,
                                            }).format(Math.floor(item?.total_users))}
                                        </td>

                                        <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                            {datefunc(item?.added_on)}
                                        </td>
                                        <td className="px-4 py-3 text-sm whitespace-no-wrap border-b border-gray-300">
                                            <button onClick={() => reportHandler(item?.report_id)}
                                                className='border whitespace-nowrap rounded-md bg-[#6d6eac] text-white text-sm py-1 px-2'>
                                                View Report
                                            </button>
                                        </td>

                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    {/* {
                        loading ?
                            <Button
                                bg="#6d5eac"
                                style={{ backgroundColor: '#6d5eac' }}
                            >
                                <LoadingSpinner /> &nbsp; Uploading
                            </Button>
                            :
                            <Button
                                bg="#6d5eac"
                                style={{ backgroundColor: '#6d5eac' }}
                            >
                                Add
                            </Button>
                    } */}

                    <Button bg="#6d5eac" style={{ backgroundColor: '#6d5eac' }} onClick={() => setOpenModal(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SearchedHistory
