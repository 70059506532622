import { SET_EXCLUDE_PRIVATE_ACCOUNTES } from "../ActionTypes";

const initialState = false;

const ExcludePrivateAccountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXCLUDE_PRIVATE_ACCOUNTES:
            return action.payload;
        default:
            return state;
    }
};

export default ExcludePrivateAccountsReducer