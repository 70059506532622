import React from 'react';
import ReactApexChart from 'react-apexcharts';

const FollowerGenderGraph = ({ graphData }) => {
    // Check if the data is available
    if (!graphData) {
        // You can choose to render some loading indicator or return null
        return null;
    }

    // Extracting values from the graphData
    const female = graphData.female || 0;
    const male = graphData.male || 0;
    const unknown = graphData.unknown || 0;

    const options = {
        chart: {
            type: 'donut',
            height: 400, // Set the maximum height for the chart
        },
        labels: ['Female', 'Male'],
        colors: ['#1dbb99', '#6d5eac'], // Change colors as needed
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 600,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    const series = [female, male];

    return (
        <div id="chart" className="w-[450px]">
            <ReactApexChart options={options} series={series} type="donut" />
        </div>
    );
};

export default FollowerGenderGraph;