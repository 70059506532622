import React, { useEffect, useState } from "react";
import { Avatar, Card } from "flowbite-react";
import { HiEye } from "react-icons/hi";
import { FaEye, FaVideo } from "react-icons/fa";
import { IoMdHeart } from "react-icons/io";
import { BsChatFill, BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import { RiUserFollowFill, RiVideoFill } from "react-icons/ri";
import { BiSolidHide } from "react-icons/bi";

const HashtagContent = ({ reportData }) => {
    const [loading, setLoading] = useState(true);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc')

    const handleSort = (column) => {
        setLoading(true)
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }

    };


    useEffect(() => {
        // This effect will run whenever sortColumn or sortOrder changes
        if (reportData) {
            setLoading(false);
        }
    }, [sortColumn, sortOrder, reportData])

    function convertUnixTimestampToDateTime(timestamp) {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Get individual components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted string
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

        return formattedDate;
    }
    const uniqueItems = [];
    const uniqueUsernames = new Set();

    reportData?.post_list?.forEach((item) => {
        if (!uniqueUsernames.has(item?.shortcode)) {
            uniqueUsernames.add(item?.shortcode);
            uniqueItems.push(item);
        }
    });
    console.log(uniqueItems,reportData)
    return (
        <div>
            <div className="flex justify-between pb-4">
                <p className="font-semibold text-gray-600">{uniqueItems?.length} Posts</p>
                <ul className="flex items-center gap-4">
                    <li onClick={() => handleSort('post_time')} className="cursor-pointer flex items-center gap-0.5">
                        Date
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('views')} className="cursor-pointer flex items-center gap-0.5">
                        View
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('likes')} className="cursor-pointer flex items-center gap-0.5">
                        Likes
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                    <li onClick={() => handleSort('comment')} className="cursor-pointer flex items-center gap-0.5">
                        Comments
                        {sortOrder === 'asc' ? <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                        </span> : <span>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                        </span>}
                    </li>
                </ul>
            </div>

            <div className="col-span-1 gap-2">
                {/* <div className="flex justify-between">
          <h1 className="my-5 pl-2">ALL POST</h1>
        </div> */}
                {
                    loading ?
                        <>
                            <div className="w-full flex gap-5 m-2 pr-20">
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                            </div>
                            <div className="w-full flex gap-5 m-2 pr-20 mt-5">
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                                <div className="w-full h-[320px] rounded-lg animate-pulse bg-gray-200"></div>
                            </div>
                        </>
                        :
                        <div className="flex flex-wrap h-[800px] overflow-auto gap-5 m-2">
                            {reportData?.post_list &&
                                uniqueItems?.sort((a, b) => {
                                    if (sortColumn) {
                                        if (sortOrder === 'asc') {
                                            return a[sortColumn] > b[sortColumn] ? 1 : -1;
                                        } else {
                                            return a[sortColumn] < b[sortColumn] ? 1 : -1;
                                        }
                                    }
                                    return 0; // No sorting
                                }).map((item, index) => {
                                    return (
                                        item?.username !== undefined && item?.username !== "" && item?.username !== null &&
                                        <a
                                            key={index}
                                            href={item.post_link}
                                            target="_blank"
                                            className="border h-fit min-w-[280px] rounded-lg overflow-hidden  shadow-md" rel="noreferrer"
                                        >
                                            <div className="w-full flex items-center justify-between p-2">
                                                <div className="flex items-center gap-1">
                                                    <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                                        <img
                                                            className="w-full h-full object-cover"
                                                            src={item.profile_image || '/asset/onErrorImage.jpg'}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null || undefined; // prevents looping
                                                                currentTarget.src = "/asset/onErrorImage.jpg";
                                                            }}
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <p className="">{item?.username}</p>
                                                        <p className="text-xs text-gray-600">{convertUnixTimestampToDateTime(item?.post_time)}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-center gap-1">
                                                    {
                                                        reportData?.platform === 1 ?
                                                            <BsInstagram className="text-red-600 text-lg" />
                                                            : reportData?.platform === 2 ?
                                                                <BsYoutube className="text-rose-600 text-lg" />
                                                                : reportData?.platform === 3 ?
                                                                    <BsTiktok className="text-black text-lg" />
                                                                    : ""
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                                            >
                                                <img
                                                    src={item.post_image}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null || undefined; // prevents looping
                                                        currentTarget.src = "/asset/onErrorImage.jpg";
                                                    }}
                                                    alt=""
                                                    className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                                                />
                                            </div>
                                            <div className="w-full flex gap-4 p-2 py-3">
                                                <div className="flex items-center text-sm">
                                                    {
                                                        item?.post_type === "Video" &&
                                                        <RiVideoFill className="text-lg" />
                                                    }
                                                </div>
                                                <div className="flex items-center gap-1 text-sm">
                                                    <FaEye /> {Intl.NumberFormat("en-US", {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1,
                                                    }).format(item?.views)}
                                                </div>
                                                <div className="flex items-center gap-1 text-sm">
                                                    {
                                                        item?.likes === -1 ?
                                                            <>
                                                                <IoMdHeart className="text-red-500 text-lg" /> <BiSolidHide />
                                                            </>
                                                            :
                                                            <>
                                                                <IoMdHeart className="text-red-500 text-lg" /> {Intl.NumberFormat("en-US", {
                                                                    notation: "compact",
                                                                    maximumFractionDigits: 1,
                                                                }).format(item?.likes)}
                                                            </>
                                                    }
                                                </div>

                                                {
                                                    item?.comment > 0 &&
                                                    <div className="flex items-center gap-1 text-sm">
                                                        <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                                            notation: "compact",
                                                            maximumFractionDigits: 1,
                                                        }).format(item?.comment)}
                                                    </div>
                                                }


                                                {/* {
                      item?.follower > 0 &&
                      <div className="flex items-center gap-1 text-sm">
                        <RiUserFollowFill className="text-black text-base" /> {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(item?.follower)}
                      </div>
                    } */}

                                            </div>
                                        </a>
                                    );
                                })}
                        </div>
                }

            </div>
        </div>
    )
}

export default HashtagContent
