import { SET_REEL_VIEW_FILTER } from "../ActionTypes";

const initialState = [];

const ReelViewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REEL_VIEW_FILTER:
            return action.payload;
        default:
            return state;
    }
};

export default ReelViewsReducer