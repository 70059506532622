import React, { useEffect, useState } from "react";
// import { Table, Accordion, Button, Avatar } from "flowbite-react";
import { Link } from "react-router-dom";
// import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
// import InfoIconTooltip from "../../components/SearchResultFilters/InfoIconTooltip";
// import AddCostModal from "../../components/Report/AddCostModal";
import { HiBadgeCheck } from "react-icons/hi";
import md5 from "md5";
import TableSkeleton from "../hashtagReport/TableSkeleton";
import ViralRepeatedBrandModal from "./ViralRepeatedBrandModal";

const ViralOverlappingInfluencer = ({ reportData }) => {
    const [showBox, setShowBox] = useState(false);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (reportData) {
            setLoading(false);
        }
    }, [reportData]);

    const handleSort = (column) => {
        // If the same column is clicked, toggle the sorting order
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If a different column is clicked, set it as the new sorting column
            setSortColumn(column);
            setSortOrder('asc'); // Default to ascending order
        }
    };

    // const handleBox = () => {
    //     setShowBox(!showBox);
    // };

    const getUsername = (link) => {
        let username = link?.split("/").pop();
        return username
    }

    // const datefunc = (datestring) => {
    //     let createdOn = new Date(datestring);
    //     let options = {
    //         day: "numeric",
    //         month: "long",
    //         year: "numeric",
    //         hour: "numeric",
    //         minute: "numeric",
    //         // second: "numeric",
    //     };
    //     const formattedDate = createdOn.toLocaleDateString("en-US", options);
    //     return formattedDate;
    // };

    // const filteredProfiles = reportData?.profile?.filter((profile) =>
    //     Object.keys(reportData?.brandoverlap).includes(getUsername(profile?.username))
    // );

    const filteredProfiles = reportData?.profile?.filter((profile) => {
        // Check if reportData and reportData.brandoverlap are defined
        if (reportData && reportData.brandoverlap) {
            // Ensure that getUsername(profile?.username) is a valid function or method
            const username = getUsername(profile?.username);

            // Check if the username is included in the keys of reportData.brandoverlap
            return Object.keys(reportData.brandoverlap).includes(username);
        }

        // Return false if reportData or reportData.brandoverlap is undefined
        return false;
    });

    console.log({ filteredProfiles })

    const GravatarImage = ({ brand }) => {
        const hashedBrand = md5(brand);
        const gravatarURL = `https://storage.googleapis.com/viralimage/${hashedBrand}.jpg`;

        return (
            <img
                src={gravatarURL}
                alt={`Gravatar for ${brand}`}
                className="w-10 h-10 rounded-full shadow-md border overflow-hidden"
            />
        );
    };


    return (
        <>
            <div className="w-full flex items-center justify-between">
                <p className="font-semibold text-gray-600">{filteredProfiles?.length} Influencers</p>
                {/* <AddCostModal setSelectedTab={setSelectedTab} /> */}
            </div>
            <table className="mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="sticky top-0 bg-[#6d6eac] text-white">
                    <tr className="h-12">
                        <th className="text-sm font-medium capitalize pl-2">Name</th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_posts')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Total Post
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('follower')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Followers
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_like')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Likes
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_comment')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Comments
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_avg_view')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Avg. Views
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        {
                            reportData?.platform[0] === 1 &&
                            <th className="text-sm font-medium capitalize">
                                <div
                                    onClick={() => handleSort('avg_share')}
                                    className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                                >
                                    Share
                                    {sortOrder === 'asc' ? <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                    </span> : <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                    </span>}
                                </div>
                            </th>
                        }
                        {
                            reportData?.platform[0] === 1 &&
                            <th className="text-sm font-medium capitalize">
                                <div
                                    onClick={() => handleSort('avg_save')}
                                    className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                                >
                                    Save
                                    {sortOrder === 'asc' ? <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                    </span> : <span>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                    </span>}
                                </div>
                            </th>
                        }
                        {/* <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('total_eng')}
                                className='flex items-center cursor-pointer'
                            >
                                Total Eng
                                // {sortOrder === 'asc' ? <span>
                                //     <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                // </span> : <span>
                                //     <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                // </span>}
                            </div>
                        </th> */}
                        <th className="text-sm font-medium capitalize">
                            <div
                                onClick={() => handleSort('user_eng_rate')}
                                className='flex items-center cursor-pointer whitespace-nowrap mx-3'
                            >
                                Eng Rate
                                {sortOrder === 'asc' ? <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z"></path></svg>
                                </span> : <span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" id="followers" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M11 9h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5zm-6 3h2V8h3L6 4 2 8h3z"></path></svg>
                                </span>}
                            </div>
                        </th>
                        <th className="text-sm font-medium capitalize whitespace-nowrap mx-3">Overlap Influencers</th>
                        {/* <th className="text-sm font-medium capitalize">CPE</th> */}
                        {/* <th className="text-sm font-medium capitalize">CPV</th> */}

                        {/* <th className="text-sm font-medium capitalize">Live On</th> */}
                        {/* <th className="text-sm font-medium capitalize"></th> */}
                    </tr>
                </thead>
                {
                    loading ?
                        <>
                            <TableSkeleton />
                        </>
                        :
                        <tbody className="divide-y">
                            {
                                filteredProfiles.sort((a, b) => {
                                    if (sortColumn) {
                                        if (sortOrder === 'asc') {
                                            return a[sortColumn] > b[sortColumn] ? 1 : -1;
                                        } else {
                                            return a[sortColumn] < b[sortColumn] ? 1 : -1;
                                        }
                                    }
                                    return 0; // No sorting
                                }).map((item, index) => (
                                    <tr key={index} className="h-20 even:bg-gray-100" >
                                        <td className="pl-2">
                                            <div className="pr-2 flex gap-1 items-center">
                                                {getUsername(item?.username) !== undefined && (
                                                    <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                                        <img
                                                            className="w-full h-full object-cover"
                                                            src={item?.profile_image || '/asset/onErrorImage.jpg'}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null || undefined; // prevents looping
                                                                currentTarget.src = "/asset/onErrorImage.jpg";
                                                            }}
                                                            alt="img"
                                                        />
                                                    </div>
                                                )}
                                                {getUsername(item?.username) !== undefined && (
                                                    <div className="flex items-center gap-0.5">
                                                        <Link
                                                            to={item?.profile_link}
                                                            target="_blank"
                                                            className="hover:text-blue-500 font-semibold"  // Use a suitable hover class here
                                                        >
                                                            {getUsername(item?.username)}
                                                        </Link>
                                                        {
                                                            item?.is_verified && <HiBadgeCheck className='text-primary-500' size={16} />
                                                        }
                                                        {/* <p className="text-xs">
                                                    {item?.gender}
                                                </p> */}
                                                    </div>
                                                )}

                                            </div>
                                        </td>
                                        <td className="pl-2">
                                            {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                item?.user_posts
                                            )}
                                        </td>
                                        <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                            item?.follower
                                        )}</td>
                                        <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                            item?.user_avg_like
                                        )}</td>
                                        <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                            item?.user_avg_comment
                                        )}</td>
                                        <td className="pl-2">{Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                            item?.user_avg_view
                                        )}</td>
                                        {
                                            reportData?.platform[0] === 1 &&
                                            <td className="pl-2">{item?.avg_share}</td>
                                        }

                                        {
                                            reportData?.platform[0] === 1 &&
                                            <td className="pl-2">{item?.avg_save}</td>
                                        }
                                        {/* <td className="pl-2">
                                                {Intl.NumberFormat('en-US', { notation: 'compact', maximumFractionDigits: 1 }).format(
                                                    item?.total_eng
                                                )}
                                            </td> */}
                                        <td className="pl-2">
                                            {item?.user_eng_rate.toFixed(2)}%
                                            {/* {(item?.user_eng_rate.toString()).length <= 4 ? `${(item?.eng_rate.toString())}%` : `${(item?.eng_rate.toString()).slice(0, 4)}%`} */}
                                        </td>
                                        <td className="pl-2 w-full py-2">
                                            <div className="flex flex-wrap gap-1">
                                                {reportData?.brandoverlap[getUsername(item?.username)]?.map(
                                                    (brand, brandIndex) => (
                                                        brand?.value.length === 1 ?
                                                            <Link key={brandIndex} target="/blank" to={`https://www.instagram.com/p/${brand?.value}`}>
                                                                <span key={brandIndex} className="mr-2 flex items-center gap-1">
                                                                    <GravatarImage brand={brand?.key} />
                                                                    {`@${brand?.key}`}
                                                                </span>
                                                            </Link>
                                                            :
                                                            <span key={brandIndex} className="mr-2 flex items-center gap-1">
                                                                <GravatarImage brand={brand?.key} />
                                                                <ViralRepeatedBrandModal brand={brand} brandIndex={brandIndex} />
                                                            </span>
                                                    )
                                                )}
                                            </div>
                                        </td>
                                        {/* <td className="pl-2">{item?.cpe}</td> */}
                                        {/* <td className="pl-2">{item?.cpv}</td> */}

                                        {/* <td className="pl-2">
                                    {item?.post_time !== 0 && datefunc(item?.post_time)}
                                </td> */}
                                        {/* <td className="pl-2">
                                                <Link className="" to={item?.profile_link} target="_blank">
                                                    <button className="px-2 py-1 border rounded-lg shadow-md bg-[#6d5eac] text-white">
                                                        View Profile
                                                    </button>
                                                </Link>
                                                {
                                                    item.is_live === 0 && <InfoIconTooltip bgColor="text-red-600" infoContent={"Post is not live/Invalid Post Link"} />
                                                }
                                            </td> */}
                                    </tr>
                                )
                                )
                            }
                        </tbody >
                }

            </table >
        </>
    )
}

export default ViralOverlappingInfluencer
