import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { setExcludePrivateAccount } from '../../redux/filters/actions';
import InfoTooltip from './InfoTooltip';
import InfoIconTooltip from './InfoIconTooltip';
import { useLocation, useSearchParams } from 'react-router-dom';

const ExcludePrivateAccountsFilter = ({ hoverStates, handleMouseOver }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [searchParam, setSearchParam] = useSearchParams()

    const isExcludePrivateUrl = searchParams.get("excludePrivateAccounts")
    const isExcludePrivateArray = isExcludePrivateUrl ? true : false;

    const handleButtonClick = () => {
        handleMouseOver('isExcludePrivateAccounts');
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        
        dispatch(setExcludePrivateAccount(isChecked)); // Toggle the checkbox state
        if(isChecked){
            setSearchParam((searchParams) => {
                searchParams.set("excludePrivateAccounts", true)
                return searchParams;
            })
        }else{
            setSearchParam((searchParams) => {
                searchParams.delete("excludePrivateAccounts")
                return searchParams;
            })
        }
    };

    return (
        <>
            <div className="isVerified flex justify-start">
                <div className="relative w-full">
                    <button
                        onClick={handleButtonClick}
                        className="flex items-center w-full justify-between bg-white"
                    >
                        <span className={`text-[#3D3D3D] flex items-center`}>
                            {/* {hoverStates.isExcludePrivateAccounts ? <RxCross2 className='mr-1 text-lg' /> : <BiPlus className='mr-1 text-lg' />} */}
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9] mr-2"
                                checked={isExcludePrivateArray}
                                onChange={handleCheckboxChange}
                            />
                            Exclude Private Accounts
                        </span>
                            <InfoIconTooltip infoContent={'Influencers that have a private account will be excluded from search results'} />
                    </button>
                    {/* <ul
                        className={`w-full z-50 px-2 py-1 pl-0 bg-white rounded-md ${hoverStates.isExcludePrivateAccounts ? "block" : "hidden"}`}
                    >
                        <div className="flex items-center gap-2 w-full">
                            <input
                                id="checkid"
                                type="checkbox"
                                className="rounded-md border-[0.6px] border-[#D9D9D9]"
                                checked={selected_excludePrivateAccounts}
                                onChange={handleCheckboxChange}
                            />
                            <label>Show only Exclude Private Accounts</label>
                        </div>
                    </ul> */}
                </div>
            </div>
        </>
    )
}

export default ExcludePrivateAccountsFilter
