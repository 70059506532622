import React from "react";
import axiosInstance from '../../interseptor';

async function suggestionListRequest(data) {
  try {
    let start = Date.now();

    const formData = new FormData();
    formData.append("logintype", 'suggestions');
    formData.append("search",data);
    formData.append("device_id", 'HHH');

    const response = await axiosInstance.post(`livesearch/suggestions`, formData);
    let timeTaken = Date.now() - start;
    console.log("Total time taken suggestionListRequestssss: " + timeTaken + " milliseconds");
    if (response && response?.status === 200) {
      if (response && response?.data && response?.data?.status === 201) {
        return { message: response?.data?.msg, code: 401 };
      } else if (response && response?.data && response?.data?.status === 2) {
        return { message: response?.data?.msg, code: 401 };
      } else if (response && response?.data && response?.data?.status === 3) {
        return { message: response?.data?.msg, code: 401 };
      }
      return response.data;
    }
  } catch (err) {
    console.log(err);
    return { message: err };
  }
}

export default suggestionListRequest;
