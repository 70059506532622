'use client';

import { useEffect, useState } from 'react';
import { Button, Label, Modal } from 'flowbite-react';
import { FaCloudDownloadAlt, FaFileUpload, FaRegEdit } from 'react-icons/fa';
import { BiDollar } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../pages/ResultPage/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import Papa from 'papaparse';
import axiosInstance from '../../interseptor';

const AddCostModal = ({ setSelectedTab }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const { id } = useParams();

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSetReportName = async (file, reportId) => {
        try {
            setLoading(true);

            const formdata = new FormData();
            formdata.append("logintype", "addreportcost");
            formdata.append("device_id", "seacrh");
            formdata.append("file", file);
            formdata.append("report_id", reportId);

            const response = await axiosInstance.post("livesearch/addreportcost", formdata);
            console.log({response})

            if (response.ok) {
                if (response?.data?.status === 1) {
                    closeModal();
                    toast.success(response?.data?.msg, { pauseOnHover: false });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    setSelectedFile(null)
                } else {
                    closeModal();
                    toast.error(response?.data?.msg, { pauseOnHover: false });
                }
            } else if (response.status === 401) {
                console.log({ message: response?.data?.msg, code: 401 });
                // Handle 401 status
            } else if (response.status === 403) {
                console.log({ message: response?.data?.msg, code: 403 });
                // Handle 403 status
            } else {
                console.log({ message: "Unknown error", code: response?.data?.status });
                // Handle other statuses
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
            setOpenModal(false);
        }


    };

    // const userDataFn = () => {
    //     return realUserData?.list?.length > 0 ? realUserData : userData
    // }

    // const convertToCsv = () => {
    //     const csvHeaders = ["Username", "Followers", "Eng. Rate", "Avg. Likes", "Avg. Comments", "Avg. Plays", "Media Count", "Phone Number", "Email ID", "Status"];

    //     const csvData = [csvHeaders];

    //     userDataFn()?.list.forEach((item) => {
    //         const row = [
    //             item.username,
    //             item.follower,
    //             item.engagement,
    //             item.avg_like_count,
    //             item.avg_comment,
    //             item.avg_play_count,
    //             item.media_count,
    //             item.phone,
    //             item.email,
    //             item.scrap_status,
    //         ];
    //         csvData.push(row);
    //     });

    //     return Papa.unparse(csvData);
    // };

    // const handleDownload = () => {
    //     const csvData = convertToCsv();

    //     const blob = new Blob([csvData], { type: 'text/csv' });
    //     const url = URL.createObjectURL(blob);

    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'userData.csv';
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);

    //     URL.revokeObjectURL(url);
    // };

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <ToastContainer autoClose={1500} />
            <button onClick={() => setOpenModal(true)} className="tab-button cursor-pointer w-24 flex items-center text-sm border border-[#1dbb99] bg-[#1dbb99] text-white px-2 py-1.5 rounded-lg">
                <BiDollar className='text-lg' /> Add Cost
            </button>
            
            <Modal show={openModal} onClose={closeModal}>
                <Modal.Header>Add Cost</Modal.Header>
                <Modal.Body>
                    <div className="flex flex-col gap-2">
                        {/* <button className='w-32 flex justify-center items-center gap-1 border rounded-lg bg-[#323237] text-white py-1.5 px-2'>
                            <FaFileUpload /> Upload CSV
                        </button> */}
                        <div className="mt-5">
                            <Label
                                className="mr-1"
                                htmlFor="addcost"
                                value="Upload a File"
                            />
                            <p className="mb-2 text-xs text-gray-600">
                                Suggested file type: .csv
                            </p>
                            {/* <br /> */}
                            <input
                                className='rounded-lg'
                                type="file"
                                accept=".csv"
                                name="file"
                                id=""
                                onChange={handleFileInputChange}
                            />

                        </div>
                        <button 
                        
                        className='flex items-center gap-1 text-xs'>
                            <FaCloudDownloadAlt /> Download Sample CSV
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                            <Button
                                bg="#6d5eac"
                                style={{ backgroundColor: '#6d5eac' }}
                                onClick={() => handleSetReportName(selectedFile, id)}
                            >
                                <LoadingSpinner /> &nbsp; Uploading
                            </Button>
                            :
                            <Button
                                bg="#6d5eac"
                                style={{ backgroundColor: '#6d5eac' }}
                                onClick={() => handleSetReportName(selectedFile, id)}
                            >
                                Add
                            </Button>
                    }

                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddCostModal
