import { useState, useEffect } from "react";
import { Avatar, Tooltip } from "flowbite-react";
import axiosInstance from "../../interseptor";
import { HiArrowLeft, HiOutlineDocumentDuplicate, HiRefresh } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { BsInstagram, BsTiktok, BsYoutube } from "react-icons/bs";
import HashtagContentInsight from "./HashtagContentInsight";
import HashtagInfluencers from "./HashtagInfluencers";
import HashtagContent from "./HashtagContent";
import HashtagOverlappingInfluencer from "./HashtagOverlappingInfluencer";
import { MdDashboardCustomize, MdOutlineDashboardCustomize, MdOutlinePeopleAlt } from "react-icons/md";
import { BiBookContent } from "react-icons/bi";
const VIEW_REPORT = "/livesearch/viewreport";



const HashtagSingleReport = () => {
    const [selectedTab, setSelectedTab] = useState("Dashboard");
    const [selectedPlatform, setSelectedPlatform] = useState("Instagram");
    const [reportData, setReportData] = useState();
    const [reportName, setReportName] = useState("");
    const [competitorName, setCompetitorName] = useState("");
    const [selectedCompetitor, setSelectedCompetitor] = useState("");
    console.log('competitorName', competitorName)
    const { id } = useParams();
    // console.log("report id", id);


    const getReportData = async () => {
        try {
            let data = new FormData();
            data.append("logintype", "viewhashtagreport");
            data.append("device_id", "seacrh");
            data.append("report_id", id);
            data.append("competitor_mentions", selectedCompetitor);

            const response = await axiosInstance.post(VIEW_REPORT, data);
            // console.log("report response", response.data.list);

            if (response && response?.status === 200) {
                if (response && response?.data && response?.data?.status === 201) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 2
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                } else if (
                    response &&
                    response?.data &&
                    response?.data?.status === 3
                ) {
                    console.log({ message: response?.data?.msg, code: 401 });
                    return false;
                }

                setReportData(response?.data?.list);
                setReportName(response?.data?.report_name);
                setCompetitorName(response?.data?.competitor.split(','));
                return response?.data.list;
            }
        } catch (err) {
            console.log(err);
        }
    };


    function convertUnixTimestampToDateTime(timestamp) {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

        // Get individual components of the date
        const year = date.getFullYear();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Get month name
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        // const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted string
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

        return formattedDate;
    }

    useEffect(() => {

        if (id) { // Add a check for id
            getReportData();
        }

    }, [id, selectedCompetitor]);


    const wordcloudData_ = reportData?.hashtags_used?.reduce((acc, item) => {
        if (item) {
            if (acc[item]) {
                acc[item]++;
            } else {
                acc[item] = 1;
            }
        }
        return acc;
    }, {});


    let wordcloudData = []
    if (wordcloudData_) {
        wordcloudData = Object.entries(wordcloudData_)?.slice(0, 10).map(([x, count]) => ({ x, value: count }));

    }

    const handleRefresh = () => {
        window.location.reload();
    }

    // console.log(wordcloudData)
    // const wordcloudData=[]

    const data = [
        { x: 'Mandarin Chinese', value: 2180000000 },
        { x: 'English', value: 1966000000 },
        { x: 'Hindustani', value: 1088000000 },
        { x: 'Spanish', value: 1054000000 },
        { x: 'Arabic', value: 844000000 },
        { x: 'Malay', value: 562000000 },
        { x: 'Russian', value: 534000000 },
        { x: 'Bengali', value: 522000000 },
        { x: 'Portuguese', value: 458000000 },
        { x: 'French', value: 458000000 },
        { x: 'Hausa', value: 300000000 },
        { x: 'Punjabi', value: 296000000 },
        { x: 'Japanese', value: 258000000 },
        { x: 'German', value: 258000000 },
        { x: 'Persian', value: 242000000 },
        { x: 'Korean', value: 238000000 },
        { x: 'Turkish', value: 164000000 },
        { x: 'Italian', value: 138000000 },
        { x: 'Thai', value: 134000000 },
        { x: 'Dutch', value: 48000000 },
        { x: 'Vietnamese', value: 152000000 },
        { x: 'Polish', value: 90000000 },
        { x: 'Ukrainian', value: 84000000 },
        { x: 'Romanian', value: 48000000 },
        { x: 'Greek', value: 26000000 },
        { x: 'Swedish', value: 20000000 },
        { x: 'Danish', value: 11400000 },
        { x: 'Czech', value: 20000000 },
        { x: 'Hungarian', value: 19600000 },
        { x: 'Finnish', value: 11200000 },
        { x: 'Norwegian', value: 10600000 },
        { x: 'Slovak', value: 10600000 },
        { x: 'Hebrew', value: 20000000 },
        { x: 'Croatian', value: 11000000 },
        { x: 'Bulgarian', value: 10000000 },
    ];


    const renderTabContent = () => {
        switch (selectedTab) {
            case "Dashboard":
                return (
                    <>
                        {/* <StaticPost reportData={reportData} /> */}
                        {/* <Reels reportData={reportData} /> */}
                        {/* <EngagementInsights /> */}
                        {reportData ? <HashtagContentInsight reportData={reportData} height={300} />
                            :
                            <>
                                <div className="w-full h-[300px] mt-10 flex gap-5 justify-between text-gray-500">

                                    <div className="w-2/4 bg-gray-200 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden">

                                    </div>


                                    <div className="w-2/4 bg-gray-200 animate-pulse flex justify-between border rounded-lg shadow-lg p-5">

                                    </div>

                                </div>
                                <div className="w-full h-[100px] mt-10 flex gap-5 justify-between text-gray-500">

                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>
                                    <div className="w-full bg-gray-300 animate-pulse flex justify-between border rounded-lg shadow-lg p-5 overflow-hidden"></div>

                                </div>
                            </>
                        }
                        {/* {wordcloudData && <WordCloud title='Hashtags' data={data} />} */}
                    </>
                );
            case "Influencers":
                return (
                    <div className="">
                        <HashtagInfluencers reportData={reportData} setSelectedTab={setSelectedTab} />
                    </div>
                );
            case "OverlappingInfluencers":
                return (
                    <div className="">
                        <HashtagOverlappingInfluencer reportData={reportData} setSelectedTab={setSelectedTab} />
                    </div>
                );
            case "Content":
                return <HashtagContent reportData={reportData} height={500} />;

            default:
                return null;
        }
    };



    return (
        <div className="p-2 h-full">
            <div className="sticky top-0 z-40 bg-white flex items-center gap-3 border-b-2 border-b-gray-500 pt-1 pb-3">
                <div className="w-full flex justify-between items-center">
                    <div className="flex items-center gap-5">
                        <Link to="/hashtagreport">
                            <HiArrowLeft size={25} />
                        </Link>
                        <div>
                            <p className="text-2xl text-[#323237] font-semibold flex items-center gap-1">
                                {reportName}
                                <Tooltip content="Refresh Report">
                                    <button
                                        onClick={() => handleRefresh()}
                                        className="flex items-center p-2 text-xl rounded-md hover:bg-[#323237] hover:text-white"
                                    >
                                        <HiRefresh className="text-xl hover:scale-110 duration-100" />
                                    </button>
                                </Tooltip>
                            </p>
                            <p className="text-sm flex items-center">
                                Report Last updated on {reportData?.last_updated ? convertUnixTimestampToDateTime(reportData?.last_updated) : <div className="w-32 h-6 ml-2 rounded-lg bg-gray-200 animate-pulse"></div>}
                            </p>
                        </div>
                    </div>

                    <div className="font-semibold text-sm">
                        {
                            competitorName && (
                                <div>
                                    <select
                                        className="rounded-lg border-2 py-3"
                                        name="cars"
                                        id="cars"
                                        onChange={(e) => {
                                            const selectedCompetitor = e.target.value;
                                            if (selectedCompetitor) {
                                                setSelectedCompetitor(selectedCompetitor)
                                            } else if (selectedCompetitor === "") {
                                                setSelectedCompetitor("")
                                            }
                                        }}
                                    >
                                        <option value="" className="py-4">{reportName}</option>
                                        {competitorName.map((data, index) => (
                                            <option className="cursor-pointer py-4" key={index} value={data}>
                                                @{data}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )
                        }

                        {
                            reportData?.report_msg !== "" && reportData?.report_msg
                        }
                    </div>
                </div>
            </div>

            <div className="w-full flex items-center justify-between gap-4 mt-5">
                <div className="flex gap-4">
                    <button
                        className={`tab-button flex items-center gap-0.5 ${selectedTab === "Dashboard" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                        onClick={() => setSelectedTab("Dashboard")}
                    >
                        <MdOutlineDashboardCustomize /> Dashboard
                    </button>
                    <button
                        className={`tab-button flex items-center gap-0.5 ${selectedTab === "Influencers" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                        onClick={() => setSelectedTab("Influencers")}
                    >
                        <MdOutlinePeopleAlt /> Influencers
                    </button>
                    <button
                        className={`tab-button flex items-center gap-0.5 ${selectedTab === "Content" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                        onClick={() => setSelectedTab("Content")}
                    >
                        <BiBookContent /> Content
                    </button>
                    <button
                        className={`tab-button flex items-center gap-0.5 ${selectedTab === "OverlappingInfluencers" ? "active border-b-2 border-b-black font-semibold text-black" : "text-[#6d6eac]"}`}
                        onClick={() => setSelectedTab("OverlappingInfluencers")}
                    >
                        <HiOutlineDocumentDuplicate /> Overlapping Influencers
                    </button>
                </div>

                <div className="flex items-center gap-2">
                    {/* <span className="text-gray-600 text-sm">Showing data for the last </span>
                    <div className="">
                        <select
                            className="rounded-lg border-2 w-32"
                            name="date"
                            id="date"
                            defaultValue="180"
                            onChange={(e) => {
                                const selectedCompetitor = e.target.value;
                                if (selectedCompetitor) {
                                    setSelectedCompetitor(selectedCompetitor)
                                }
                            }}
                        >
                            <option value="1" className={selectedCompetitor === '1' ? 'font-bold' : ''}>1 Day</option>
                            <option value="7" className={selectedCompetitor === '7' ? 'font-bold' : ''}>7 Days</option>
                            <option value="15" className={selectedCompetitor === '15' ? 'font-bold' : ''}>15 Days</option>
                            <option value="30" className={selectedCompetitor === '30' ? 'font-bold' : ''}>1 Month</option>
                            <option value="90" className={selectedCompetitor === '90' ? 'font-bold' : ''}>3 Months</option>
                            <option value="180" className={selectedCompetitor === '180' ? 'font-bold' : ''}>6 Months</option>
                        </select>
                    </div> */}

                    {/* <button
            className={`tab-button w-24 text-sm ${selectedPlatform === "Overview" ? "active border border-[#1dbb99] bg-[#1dbb99] text-white px-2 py-1.5 rounded-lg" : 'bg-black text-white px-2 py-1.5 rounded-lg'}`}
            onClick={() => setSelectedPlatform("Overview")}
          >
            Overview
          </button> */}
                    {
                        reportData?.platform[0] === 1 ?
                            <div
                                className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                onClick={() => setSelectedPlatform("Instagram")}
                            >
                                <BsInstagram />
                                Instagram Report
                            </div>
                            : reportData?.platform[0] === 2 ?
                                <div
                                    className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                    onClick={() => setSelectedPlatform("YouTube")}
                                >
                                    <BsYoutube />
                                    YouTube Report
                                </div> : reportData?.platform[0] === 3 ?
                                    <div
                                        className={`tab-button flex justify-center items-center gap-1 text-md px-2 py-1.5`}
                                        onClick={() => setSelectedPlatform("TikTok")}
                                    >
                                        <BsTiktok />
                                        TikTok Report
                                    </div>
                                    : null
                    }


                </div>
            </div>

            <div className="mt-4">
                {renderTabContent()}
            </div>
        </div>
    )
}

export default HashtagSingleReport
