import { Button, Modal } from 'flowbite-react'
import React, { useState } from 'react'
import { BiSolidHide } from 'react-icons/bi';
import { BsChatFill, BsInstagram, BsTiktok, BsYoutube } from 'react-icons/bs';
import { FaEye, FaImages } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { IoMdHeart } from 'react-icons/io';
import { RiVideoFill } from 'react-icons/ri';

const PostModal = ({ reportData, username, showMore, updateViralBrandList, handleShowMore, convertUnixTimestampToDateTime, selectedCountry, selectedCategory, selectedType }) => {
    const [openModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <div onClick={() => setOpenModal(true)} className="flex flex-wrap gap-5">
                {reportData[username].slice(0, showMore[username] ? reportData[username].length : 1).map((post, index) => (
                    <div key={index} title='Click here to see all posts' className="border h-fit min-w-[280px] rounded-lg overflow-hidden shadow-md" rel="noreferrer">
                        <div className="w-full flex items-center justify-between p-2">
                            <div className="flex items-center gap-1">
                                <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                    <img
                                        className="w-full h-full object-cover"
                                        src={post.profile_image || '/asset/onErrorImage.jpg'}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null || undefined; // prevents looping
                                            currentTarget.src = "/asset/onErrorImage.jpg";
                                        }}
                                        alt="img"
                                    />
                                </div>
                                <div className="">
                                    <p className="">{post?.username}</p>
                                    <p className="text-xs text-gray-600">{convertUnixTimestampToDateTime(post?.post_time)}</p>
                                </div>
                            </div>

                            <div className="flex items-center gap-1">
                                <span className='text-sm cursor-pointer'>{`1/${reportData[username]?.length}`}</span>
                                <FaImages className="text-xl cursor-pointer" />

                                {
                                    reportData?.platform === 1 ?
                                        <BsInstagram className="text-red-600 text-lg" />
                                        : reportData?.platform === 2 ?
                                            <BsYoutube className="text-rose-600 text-lg" />
                                            : reportData?.platform === 3 ?
                                                <BsTiktok className="text-black text-lg" />
                                                : ""
                                }
                            </div>
                        </div>
                        <div
                            className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                        >
                            <img
                                src={post.post_image}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null || undefined; // prevents looping
                                    currentTarget.src = "/asset/onErrorImage.jpg";
                                }}
                                alt=""
                                className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                            />
                        </div>
                        <div className="w-full flex gap-4 p-2 py-3">
                            <div className="flex items-center text-sm">
                                {
                                    post?.post_type === "Video" &&
                                    <RiVideoFill className="text-lg" />
                                }
                            </div>
                            <div className="flex items-center gap-1 text-sm">
                                <FaEye />
                                {Intl.NumberFormat("en-US", {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                }).format(post?.views)}
                            </div>
                            <div className="flex items-center gap-1 text-sm">
                                {
                                    post?.likes === -1 ?
                                        <>
                                            <IoMdHeart className="text-red-500 text-lg" /> <BiSolidHide />
                                        </>
                                        :
                                        <>
                                            <IoMdHeart className="text-red-500 text-lg" />
                                            {Intl.NumberFormat("en-US", {
                                                notation: "compact",
                                                maximumFractionDigits: 1,
                                            }).format(post?.likes)}
                                        </>
                                }
                            </div>

                            {
                                post?.comment > 0 &&
                                <div className="flex items-center gap-1 text-sm">
                                    <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                        notation: "compact",
                                        maximumFractionDigits: 1,
                                    }).format(post?.comment)}
                                </div>
                            }

                        </div>

                    </div>
                ))}
            </div>

            <Modal show={openModal} onClose={closeModal}>

                <Modal.Header>
                    {username}
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap gap-5">
                        {reportData[username].map((post, index) => (
                            <div key={index} className="border h-fit min-w-[280px] rounded-lg overflow-hidden shadow-md" rel="noreferrer">
                                <div className="w-full flex items-center justify-between p-2">
                                    <div className="flex items-center gap-1">
                                        <div className="w-12 h-12 rounded-full shadow-md border overflow-hidden">
                                            <img
                                                className="w-full h-full object-cover"
                                                src={post.profile_image || '/asset/onErrorImage.jpg'}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null || undefined; // prevents looping
                                                    currentTarget.src = "/asset/onErrorImage.jpg";
                                                }}
                                                alt="img"
                                            />
                                        </div>
                                        <div className="">
                                            <p className="">{post?.username}</p>
                                            <p className="text-xs text-gray-600">{convertUnixTimestampToDateTime(post?.post_time)}</p>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-1">

                                        <HiTrash onClick={() => updateViralBrandList(selectedCountry, selectedCategory, selectedType, post?.username, 'archive')} className="text-xl text-gray-500 cursor-pointer" />

                                        {
                                            reportData?.platform === 1 ?
                                                <BsInstagram className="text-red-600 text-lg" />
                                                : reportData?.platform === 2 ?
                                                    <BsYoutube className="text-rose-600 text-lg" />
                                                    : reportData?.platform === 3 ?
                                                        <BsTiktok className="text-black text-lg" />
                                                        : ""
                                        }
                                    </div>
                                </div>
                                <a href={post.post_link} target="_blank">
                                    <div
                                        className={`col-3 bg-white h-[280px] w-[280px] border-gray-200 overflow-hidden`}
                                    >
                                        <img
                                            src={post.post_image}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null || undefined; // prevents looping
                                                currentTarget.src = "/asset/onErrorImage.jpg";
                                            }}
                                            alt=""
                                            className="w-[100%] object-cover h-[100%] hover:scale-110 ease-in duration-100"
                                        />
                                    </div>
                                </a>
                                <div className="w-full flex gap-4 p-2 py-3">
                                    <div className="flex items-center text-sm">
                                        {
                                            post?.post_type === "Video" &&
                                            <RiVideoFill className="text-lg" />
                                        }
                                    </div>
                                    <div className="flex items-center gap-1 text-sm">
                                        <FaEye />
                                        {Intl.NumberFormat("en-US", {
                                            notation: "compact",
                                            maximumFractionDigits: 1,
                                        }).format(post?.views)}
                                    </div>
                                    <div className="flex items-center gap-1 text-sm">
                                        {
                                            post?.likes === -1 ?
                                                <>
                                                    <IoMdHeart className="text-red-500 text-lg" /> <BiSolidHide />
                                                </>
                                                :
                                                <>
                                                    <IoMdHeart className="text-red-500 text-lg" />
                                                    {Intl.NumberFormat("en-US", {
                                                        notation: "compact",
                                                        maximumFractionDigits: 1,
                                                    }).format(post?.likes)}
                                                </>
                                        }
                                    </div>

                                    {
                                        post?.comment > 0 &&
                                        <div className="flex items-center gap-1 text-sm">
                                            <BsChatFill className="text-[#f7cf5f]" /> {Intl.NumberFormat("en-US", {
                                                notation: "compact",
                                                maximumFractionDigits: 1,
                                            }).format(post?.comment)}
                                        </div>
                                    }

                                </div>

                            </div>

                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        Decline
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PostModal
