import { SET_CONTACT } from "../ActionTypes";

const initialState = [];

const ContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTACT:
            const previousValue = state.value;
            console.log('state in TOPICS', previousValue)
            return action.payload;
        default:
            return state;
    }
};

export default ContactReducer